import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { IPostComment, IPostCommentGroup } from 'interfaces'
import { EVENT_TYPE_POST_COMMENT_REPLY } from 'config/app'
import * as userSelectors from 'containers/User/user-selectors'
import { blockUser, unBlockUser } from 'containers/Network/network-actions'
import { Comment, Loader, ErrorMsg } from 'components'
import { useThunkDispatch, useAlertDialog } from 'hooks'
import { showAlertNotify } from 'utils/helpers'
import eventBus from 'utils/EventBus'
import { useFetchComments, useMutationComments } from './hooks'

type CommentsPropType = {
    classes?: string
    postId: number
    isLoad?: boolean
    onLoad?: (comments: IPostComment[]) => void
}

function getGroupId(commentId: number): string {
    return `group_${commentId}_${Date.now()}`
}

/**
 * Группирование коммента с ответами на него (дочерние комменты)
 */
const setCommentGroup = (groups: IPostCommentGroup[], parentId: number, comment: IPostComment)
    : IPostCommentGroup[] => {
    return groups.map((group) => {
        if (group.parent.id === parentId) {
            const child = { id: getGroupId(comment.id), parent: comment, children: [] }
            const children = group?.children?.length ? [...group.children, child] : [child]

            return { ...group, children }
        }
        if (group.children?.length) {
            return { ...group, children: setCommentGroup(group.children, parentId, comment) }
        }

        return group
    })
}

/**
 * Comments module
 * @event EVENT_TYPE_POST_COMMENT_REPLY - reply comment
 */
const Comments: React.FC<CommentsPropType> = ({
    classes,
    postId,
    isLoad = false,
    onLoad = () => {},
}) => {
    const { t } = useTranslation()
    const thunkDispatch = useThunkDispatch()
    const { showAlertDialog } = useAlertDialog()

    const user = useSelector(userSelectors.user)

    const {
        isInitialLoading: isLoading,
        data: commentsData,
        error: commentsError,
        refetch: refetchComments,
    } = useFetchComments({
        postId,
    }, {
        enabled: isLoad,
    })

    const {
        update: mutateUpdateComment,
        deleteComment: mutateDeleteComment,
        restoreComment: mutateRestoreComment,
    } = useMutationComments({ postId })

    const commentGroups: IPostCommentGroup[] = useMemo(() => {
        if (!commentsData?.comment_list.length) {
            return []
        }

        return commentsData.comment_list.reduce((acc: IPostCommentGroup[], comment): IPostCommentGroup[] => {
            if (comment.parent) {
                return setCommentGroup(acc, comment.parent, comment)
            }

            return [...acc, { id: getGroupId(comment.id), parent: comment, children: [] }]
        }, [])
    }, [commentsData])

    const handlerCommentReply = (parent: IPostComment) => {
        eventBus.emit(EVENT_TYPE_POST_COMMENT_REPLY, { parent })
    }

    const handlerCommentEdit = (commentId: number, comment: string) => {
        updateCommentAction(commentId, comment)
    }

    const handlerCommentDelete = (commentId: number, message: string) => {
        showAlertDialog({
            message,
            payload: { id: commentId },
            onConfirm: deleteComment,
        })
    }

    const handlerCommentRestore = (commentId: number, message: string) => {
        showAlertDialog({
            message,
            payload: { id: commentId },
            onConfirm: restoreComment,
        })
    }

    const handlerBlockUser = (userId: number, message: string = '') => {
        showAlertDialog({
            message,
            payload: { userId },
            onConfirm: blockUserComment,
        })
    }

    const handlerUnBlockUser = (userId: number, message: string = '') => {
        showAlertDialog({
            message,
            payload: { userId },
            onConfirm: unBlockUserComment,
        })
    }

    function deleteComment({ id }: { id: number }) {
        deleteCommentAction(id)
    }

    function restoreComment({ id }: { id: number }) {
        restoreCommentAction(id)
    }

    const blockUserComment = ({ userId }: { userId: number }) => {
        handlerBlockUserAction(userId)
    }

    function unBlockUserComment({ userId }: { userId: number }) {
        handlerUnBlockUserAction(userId)
    }

    function updateCommentAction(commentId: number, comment: string) {
        mutateUpdateComment({ id: commentId, comment })
    }

    function deleteCommentAction(commentId: number) {
        mutateDeleteComment({ id: commentId })
    }

    function restoreCommentAction(commentId: number) {
        mutateRestoreComment({ id: commentId })
    }

    function handlerBlockUserAction(userId: number) {
        thunkDispatch(blockUser({ userId }))
            .then(() => {
                refetchComments()
            })
            .catch((err) => {
                handlerActionError(err)
            })
    }

    function handlerUnBlockUserAction(userId: number) {
        thunkDispatch(unBlockUser({ userId }))
            .then(() => {
                refetchComments()
            })
            .catch((err) => {
                handlerActionError(err)
            })
    }

    function handlerActionError(err: AxiosError) {
        const { data } = err?.response || {}
        let errorMsg = ''

        if (typeof data === 'string') {
            errorMsg = data
        }

        showAlertNotify({ type: 'error', message: errorMsg || t('update_error') })
    }

    useEffect(() => {
        if (commentsData) {
            onLoad(commentsData.comment_list)
        }
    }, [commentsData])

    return (
        <div className={classes}>
            {isLoading && (
                <Loader />
            )}

            {!isLoading && commentsError && (
                <ErrorMsg error={commentsError} />
            )}

            {commentGroups.map((group) => (
                <React.Fragment key={group.id}>
                    <Comment
                        data={group.parent}
                        isOwn={group.parent.user.id === user.id}
                        key={group.parent.id}
                        onCommentReply={handlerCommentReply}
                        onCommentEdit={handlerCommentEdit}
                        onCommentDelete={handlerCommentDelete}
                        onCommentRestore={handlerCommentRestore}
                        onBlockUser={handlerBlockUser}
                        onUnBlockUser={handlerUnBlockUser}
                    />

                    {group?.children.map((child) => (
                        <Comment
                            isReply
                            data={child.parent}
                            isOwn={child.parent.user.id === user.id}
                            key={child.parent.id}
                            onCommentReply={handlerCommentReply}
                            onCommentEdit={handlerCommentEdit}
                            onCommentDelete={handlerCommentDelete}
                            onCommentRestore={handlerCommentRestore}
                            onBlockUser={handlerBlockUser}
                            onUnBlockUser={handlerUnBlockUser}
                        />
                    ))}
                </React.Fragment>
            ))}
        </div>
    )
}

export default Comments
