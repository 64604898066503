import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { CardPropType } from 'components/Card/CardV2'
import { Avatar, CardV2 } from 'components'
import style from './UserCard.module.css'

export type UserCardPropType = {
    name: string
    surname: string
    online?: boolean
    photo?: string
    photoSize?: {
        width?: number
        height?: number
    }
} & CardPropType

const UserCard: React.FC<UserCardPropType> = ({
    children,
    name,
    surname,
    url,
    online,
    photo,
    photoSize = { width: 400, height: 400 },
    ...props
}) => {
    const avatarTpl = (
        <Avatar
            isRound={false}
            classes={style.image}
            src=""
            name={name}
            surname={surname}
        />
    )

    return (
        <CardV2 {...props}>
            {online !== undefined && (
                <div className={cn(style.status, { [style.status_active]: online })}>
                    {online ? 'Online' : 'Offline'}
                </div>
            )}
            {photo && (
                <CardV2.Image
                    src={photo}
                    url={url}
                    {...photoSize}
                />
            )}
            {!photo && (
                url ? (
                    <NavLink to={url}>
                        {avatarTpl}
                    </NavLink>
                ) : (
                    avatarTpl
                )
            )}
            <CardV2.Body
                classes={cn(style.body, { [style.body_bRadiusNone]: !!children })}
                classesTitle={style.title}
                title={`${name ?? ''} ${surname ?? ''}`}
                url={url}
            />
            {children && (
                <CardV2.Footer classes={style.footer}>
                    {children}
                </CardV2.Footer>
            )}
        </CardV2>
    )
}

export default UserCard
