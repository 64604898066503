import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import { ICity } from 'interfaces'
import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { useFetchCities } from 'containers/App/hooks'
import { CustomSelect, Loader } from 'components'

type CityDataSelectPropType = {
    selected?: ICity
    countryId?: number
    countryLang?: string
    classes?: string
    classesField?: string
    placeholder?: string
    disabled?: boolean
    onOpen?: () => void
    onSearch?: (value: string) => void
    onChange?: (value: ICity) => void
}

const CityDataSelect: React.FC<CityDataSelectPropType> = ({
    selected,
    countryId,
    countryLang,
    classes,
    classesField,
    placeholder,
    disabled,
    onOpen = () => {},
    onSearch = () => {},
    onChange = () => {},
}) => {
    const { t } = useTranslation()
    const debounce = useDebouncedCallback(searchCallback, INPUT_DEBOUNCE_TIME)

    const [search, setSearch] = useState('')
    const [searchDebounced, setSearchDebounced] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const { isLoading, data: citiesState = [] } = useFetchCities({
        id: countryId ?? 0,
        lang: countryLang,
        limit: 6,
        offset: 0,
        q: searchDebounced,
    }, {
        enabled: isOpen && !!countryId && !!countryLang,
    })

    const handlerOpenOptions = (open: boolean) => {
        setIsOpen(open)

        if (open) {
            onOpen()

            if (!citiesState.length) {
                onSearch(search)
            }
        }
    }

    const handlerChangeCity = (cityId: number) => {
        const city = citiesState.find((item) => item.id === cityId)

        if (city) {
            onChange(city)
        }
    }

    const handlerSearch = (value: string) => {
        debounce(value)
        setSearch(value)
        onSearch(value)
    }

    function searchCallback(value: string) {
        setSearchDebounced(value)
    }

    return (
        <CustomSelect.Select classes={classes}>
            <CustomSelect.Field
                classes={classesField}
                disabled={disabled}
            >
                {selected ? (
                    selected.ru_name
                ) : (
                    <>
                        {placeholder && placeholder}
                        {!placeholder && t('select_city')}
                    </>
                )}
            </CustomSelect.Field>
            <CustomSelect.Options
                search={search}
                searchPlaceholder={t('enter_the_name_of_the_city')}
                onOpen={handlerOpenOptions}
                onSearch={handlerSearch}
            >
                {isLoading ? (
                    <li>
                        <Loader size="small" />
                    </li>
                ) : (
                    citiesState?.map((item) => (
                        <CustomSelect.Option
                            isActive={item.id === selected?.id}
                            id={item.id}
                            key={item.id}
                            onClick={handlerChangeCity}
                        >
                            {item.ru_name}
                        </CustomSelect.Option>
                    ))
                )}
            </CustomSelect.Options>
        </CustomSelect.Select>
    )
}

export default CityDataSelect
