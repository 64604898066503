import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { Chevron } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './PostMainImageTitle.module.css'

type PostMainImageTitlePropType = {
    title: string
    classes?: string
    goodsId?: number
}

const PostMainImageTitle: React.FC<PostMainImageTitlePropType> = ({ title, classes, goodsId }) => {
    const url = useMemo(() => {
        return goodsId ? parseTpl(APP_URL.goods, { ':id': goodsId }, { prefix: '', suffix: '' }) : ''
    }, [])

    const bodyTpl = (
        <div className={style.text}>
            {title}
        </div>
    )

    if (url) {
        return (
            <NavLink className={cn(style.imageTitle, classes)} to={url}>
                {bodyTpl}

                <Chevron classes={style.chevron} type="right" />
            </NavLink>
        )
    }

    return (
        <div className={cn(style.imageTitle, classes)}>
            {bodyTpl}
        </div>
    )
}

export default PostMainImageTitle
