import React, { useState, useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { MenuNavChild, FeedTabsId } from 'enums'
import { APP_URL, COUNTERS } from 'config/app'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchBudget } from 'containers/User/hooks'
import { numberFormat } from 'utils/helpers'
import { MenuNavItem, MenuNavLink, MenuNavDropDown } from './components'
import style from './MenuNav.module.css'

type MenuNavPropType = {
    isMobile?: boolean
}

type TMenuItems = Record<string, IMenuNavItemData>

enum MenuKey {
    feed = 1,
    friends = 2,
    market = 3,
    reports = 4,
    wallet = 5,
    calendar = 6,
    messenger = 7,
    support = 8,
    rank = 9,
}

enum FeedChildrenKey {
    all = 'all',
    posts = 'posts',
    company = 'company',
    leaders = 'leaders',
    president = 'president'
}

const MENU_ITEMS: TMenuItems = {
    [MenuKey.feed]: {
        id: MenuKey.feed,
        translateKey: 'feed',
        icon: 'ic_tab2_feed_svg',
        iconClassName: style.icon,
        url: APP_URL.lenta,
        countEvent: {
            key: COUNTERS.feedPosts,
            value: 0,
        },
        children: {
            [FeedChildrenKey.all]: {
                id: FeedTabsId.allPosts,
                url: APP_URL.lenta,
                translateKey: 'feed_all_articles',
            },
            [FeedChildrenKey.posts]: {
                id: 100,
                url: APP_URL.lentaUser,
                translateKey: 'user_profile_posts_and_checks',
            },
            [FeedChildrenKey.company]: {
                id: FeedTabsId.companyPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.companyPosts}`,
                translateKey: 'company_post',
            },
            [FeedChildrenKey.president]: {
                id: FeedTabsId.presidentPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.presidentPosts}`,
                translateKey: 'president_posts',
            },
        },
        childType: MenuNavChild.byQueryParams,
        mobile: true,
        sort: 1,
    },
    [MenuKey.friends]: {
        id: MenuKey.friends,
        translateKey: 'friends',
        icon: 'ic_tab_friends_svg',
        iconClassName: style.icon,
        url: APP_URL.friends,
        countEvent: {
            key: COUNTERS.friendRequests,
            value: 0,
        },
        mobile: true,
        sort: 2,
    },
    [MenuKey.market]: {
        id: MenuKey.market,
        translateKey: 'stores',
        icon: 'ic_shop_cart_svg',
        iconClassName: style.icon,
        url: APP_URL.market,
        mobile: true,
        sort: 3,
    },
    [MenuKey.reports]: {
        id: MenuKey.reports,
        translateKey: 'reports',
        icon: 'ic_report_svg',
        iconClassName: style.icon,
        url: APP_URL.reports,
        mobile: true,
        sort: 4,
    },
    [MenuKey.wallet]: {
        id: MenuKey.wallet,
        translateKey: 'icon_menu_wallet',
        icon: 'ic_tab2_wallet_svg',
        iconClassName: style.icon,
        url: APP_URL.wallet,
        countEvent: {
            key: COUNTERS.wallet,
            value: 0,
        },
        sort: 5,
    },
    [MenuKey.calendar]: {
        id: MenuKey.calendar,
        translateKey: 'calendar',
        icon: 'ic_kicks_calendar2_svg',
        iconClassName: style.icon,
        url: APP_URL.calendar,
        countValue: {
            key: COUNTERS.calendar,
            value: 0,
        },
        sort: 6,
    },
    [MenuKey.messenger]: {
        id: MenuKey.messenger,
        translateKey: 'menu_chat',
        icon: 'ic_chat_v2_svg',
        iconClassName: style.icon,
        url: APP_URL.messenger,
        countEvent: {
            key: 'chat_count',
            value: 0,
        },
        sort: 7,
        burger: true,
    },
    [MenuKey.support]: {
        id: MenuKey.support,
        translateKey: 'ui_support_create_ticket_title',
        icon: 'ic_support_v2_svg',
        iconClassName: style.icon,
        url: APP_URL.support,
        notify: {
            key: 'support_count',
            value: false,
        },
        sort: 8,
        burger: true,
    },
    [MenuKey.rank]: {
        id: MenuKey.rank,
        translateKey: 'business_menu_kicks_icon_title',
        icon: 'sessia_balance2_svg',
        countValue: {
            key: String(MenuKey.rank),
            value: 0,
        },
        disabled: true,
        sort: 9,
    },
}

const getCurrentItemId = (items: IMenuNavItemData[], pathname: string): number => {
    const currentItem = items.find(({ url }) => {
        return url ? !!matchPath(pathname, { path: url, exact: false }) : undefined
    })
    return currentItem ? currentItem.id : 0
}

const MenuNav: React.FC<MenuNavPropType> = ({ isMobile = false }) => {
    const { pathname } = useLocation()

    const counts = useSelector(userSelectors.counts)

    const [currentItemId, setCurrentItemId] = useState<number>(getCurrentItemId(Object.values(MENU_ITEMS), pathname))

    const { data: dataBudget } = useFetchBudget()

    const itemsValues = useMemo(() => {
        const items = Object.values(MENU_ITEMS)

        return items.sort((a, b) => {
            return typeof a.sort === 'number' && typeof b.sort === 'number' && a.sort > b.sort ? 1 : -1
        })
    }, [])

    const menuMainItems = useMemo(() => {
        return itemsValues.reduce<IMenuNavItemData[]>((acc, item) => {
            if ((isMobile && !item.mobile) || item.burger) {
                return acc
            }
            if (item.countValue?.key && item.countValue.key in counts) {
                return [...acc, { ...item, countValue: { ...item.countValue, value: counts[item.countValue.key] } }]
            }
            if (item.countEvent?.key && item.countEvent.key in counts) {
                return [...acc, { ...item, countEvent: { ...item.countEvent, value: counts[item.countEvent.key] } }]
            }
            if (item.countValue?.key === String(MenuKey.rank) && dataBudget !== undefined) {
                return [...acc, { ...item, countValue: { ...item.countValue, value: numberFormat(dataBudget) } }]
            }

            return [...acc, item]
        }, [])
    }, [isMobile, itemsValues, counts, dataBudget])

    const menuDropDownItems = useMemo(() => {
        return isMobile
            ? itemsValues.filter((item) => !item.mobile)
            : []
    }, [isMobile, itemsValues])

    const handlerChangeSubMenu = (itemId: number) => {
        setCurrentItemId((prevState) => (prevState === itemId ? 0 : itemId))
    }

    return (
        <ul className={cn(style.menuNav, { [style.menuNav_mobile]: isMobile })}>
            {menuMainItems.map((item) => (
                <MenuNavItem key={item.id}>
                    <MenuNavLink
                        isMobile={isMobile}
                        isOpen={item.id === currentItemId}
                        // isDisabled={item.disabled}
                        data={item}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            ))}

            {isMobile && !!menuDropDownItems.length && (
                <MenuNavItem>
                    <MenuNavDropDown
                        items={menuDropDownItems}
                        currentItemId={currentItemId}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            )}
        </ul>
    )
}

export default MenuNav
