import React, { useMemo } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { IOrder, IPostOrder, IStoreCurrency } from 'interfaces'
import { parseTpl, priceFormat } from 'utils/helpers'
import style from './PostCheck.module.css'

type PostCheckPropType = {
    data: IOrder
    postOrder: IPostOrder
}

const PostCheck: React.FC<PostCheckPropType> = ({ data, postOrder }) => {
    const { t } = useTranslation()

    const orderTitle = useMemo(() => {
        return parseTpl(
            t('market_order_title_short'),
            { '%d': data.id, '%s': data.server_create_date },
            { prefix: '', suffix: '' },
        )
    }, [])

    const currencyCvOpts = useMemo<IStoreCurrency>(() => {
        return { symbol: t('market_cv'), leftSymbolPlacement: false, isoCode: '' }
    }, [])

    const currencyOpts = useMemo<IStoreCurrency>(() => {
        return { symbol: postOrder.currency, leftSymbolPlacement: postOrder.left_currency_symbol_placement, isoCode: '' }
    }, [postOrder])

    return (
        <div className={style.check}>
            <div className={style.title}>
                {data.store.name}
            </div>
            <div className={style.body}>
                <div className={style.goods}>
                    {data.content.map((item) => (
                        <div className={style.goodsItem} key={item.goods.id}>
                            <div>
                                {`${item.goods.name}, ${item.quantity} ${t('entity_short')}`}
                            </div>
                            <div>
                                <div className={style.price}>
                                    {priceFormat(item.price, currencyOpts)}
                                </div>
                                {!!item.custom_price && (
                                    <div className={style.customPrice}>
                                        {priceFormat(item.custom_price, currencyCvOpts)}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                {!!data.discount_amount && (
                    <div className={style.discount}>
                        <div className={style.discountInner}>
                            <div className={style.discountText}>
                                {t('market_discount')}
                            </div>
                            <div className={style.discountPrice}>
                                {priceFormat(data.discount_amount, currencyOpts)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={style.footer}>
                <div className={style.total}>
                    <div>
                        {priceFormat(data.order_sum, currencyOpts)}
                    </div>
                    <div>
                        {priceFormat(data.cv_sum, currencyCvOpts)}
                    </div>
                </div>
                <QRCodeSVG
                    className={style.qrCode}
                    size={80}
                    value={`${postOrder.member_id} ${data.order_sum}`}
                />
                <div className={style.order}>
                    <div className={style.orderText}>
                        {t('market_receipt_footer_text')}
                    </div>
                    <div className={style.orderText}>
                        {orderTitle}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostCheck
