import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMlmReportsGraph } from 'interfaces'
import { ChartConfiguration } from 'components/Chart/Chart'
import { DatePeriod } from 'enums'
import { Chart } from 'components'
import noDataText from 'components/Chart/plugins/noDataText'
import { dateTimeFormat, convertHexToRgb, getNormalizeDateValues } from 'utils/helpers'
import { vGray14 } from 'styles/modules/variables.module.css'
import style from './ReportsFreedomChart.module.css'

type ReportsMlmChartPropType = {
    data: IMlmReportsGraph
    period?: DatePeriod
    isLoading?: boolean
}

const ReportsFreedomChart: React.FC<ReportsMlmChartPropType> = ({ data, period, isLoading }) => {
    const { data: dataList, color } = data || {}

    const { t, i18n } = useTranslation()

    const labels = useMemo(() => {
        const items = dataList.map((item) => item.date)
        return items.length === 1 ? ['', ...items] : items
    }, [data])

    const values = useMemo(() => {
        const items = dataList.map((item) => item.value)
        return items.length === 1 ? [0, ...items] : items
    }, [data])

    const fillColor = useMemo(() => {
        const rgbColor = convertHexToRgb(color).join(',')
        return rgbColor ? `rgba(${rgbColor}, 0.1)` : color
    }, [data])

    const labelDateFormatOptions: Intl.DateTimeFormatOptions = useMemo(() => {
        switch (period) {
            case DatePeriod.week:
                return { weekday: 'short' }
            case DatePeriod.month:
                return { day: 'numeric' }
            case DatePeriod.year:
                return { month: 'short' }
            case DatePeriod.custom:
                return { day: 'numeric', month: 'short' }
            default:
                return { month: 'short', year: '2-digit' }
        }
    }, [labels])

    const chartConfig: ChartConfiguration = useMemo(() => {
        return {
            type: 'line',
            data: {
                labels,
                datasets: [{
                    borderColor: color,
                    borderWidth: 1,
                    fill: {
                        target: 'origin',
                        above: fillColor,
                        below: fillColor,
                    },
                    cubicInterpolationMode: 'monotone',
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    pointRadius: 4,
                    pointHoverBackgroundColor: fillColor,
                    pointHoverBorderColor: color,
                    data: values,
                }],
            },
            options: {
                responsive: true,
                aspectRatio: 3,
                hover: {
                    mode: 'index',
                    intersect: false,
                    axis: 'x',
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            autoSkip: true,
                            maxRotation: 0,
                            minRotation: 0,
                            callback(value, index, ticks) {
                                if (!labels[index]) {
                                    return undefined
                                }
                                if (period === DatePeriod.week
                                    || period === DatePeriod.month
                                    || period === DatePeriod.year
                                ) {
                                    return dateTimeFormat(labels[index], i18n.language, labelDateFormatOptions)
                                }
                                if (period === DatePeriod.all) {
                                    const { month, year } = getNormalizeDateValues(labels[index])
                                    return index === 0 || index % 2 ? `${month}/${year.slice(2)}` : undefined
                                }
                                if (period === DatePeriod.custom) {
                                    return dateTimeFormat(labels[index], i18n.language, labelDateFormatOptions)
                                }
                                return undefined
                            },
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            borderDash: [4, 3],
                            drawTicks: false,
                            borderWidth: 0,
                        },
                        ticks: {
                            crossAlign: 'far',
                            padding: 15,
                            font: {
                                size: 10,
                            },
                            color: '#000',
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                        displayColors: false,
                        bodyAlign: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        borderWidth: 1,
                        titleColor: '#808080',
                        bodyColor: '#808080',
                    },
                    noDataText: {
                        enabled: !values.length,
                        font: '14px',
                        fontColor: vGray14,
                        text: t('no_data'),
                    },
                },
            },
            plugins: [noDataText],
        }
    }, [data])

    return (
        <div className={cn(style.wrap, { [style.wrap_updating]: isLoading })}>
            <Chart
                config={chartConfig}
                options={{ height: 200 }}
            />
        </div>
    )
}

export default ReportsFreedomChart
