import React from 'react'
import cn from 'classnames'

import { Block } from 'layout'
import { ImgResource } from 'components'
import style from './NoDataInfo.module.css'

type NoDataBlockPropType = {
    classes?: string
    classesTitle?: string
    classesText?: string
    title?: string
    text?: string
    imgKey?: string
    isShowImg?: boolean
    isHideBackground?: boolean
}

const NoDataInfo: React.FC<NoDataBlockPropType> = ({
    classes,
    classesTitle,
    classesText,
    title,
    text,
    imgKey = 'baba_lucky_png',
    isShowImg = false,
    isHideBackground = isShowImg,
}) => {
    return (
        <Block classes={cn(style.block, { [style.block_noBg]: isHideBackground }, classes)}>
            {isShowImg && (
                <ImgResource
                    classes={style.image}
                    resourceKey={imgKey}
                    isShowLoader={false}
                    width={320}
                    height={292}
                />
            )}
            {title && (
                <div className={cn(style.title, classesTitle)}>
                    {title}
                </div>
            )}
            {text && (
                <div className={cn(style.text, classesText)}>
                    {text}
                </div>
            )}
        </Block>
    )
}

export default NoDataInfo
