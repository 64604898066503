import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { API_HOST } from 'config/api'
import { Card, Chevron, Spinner } from 'components'
import style from './ItemString.module.css'

type ItemStringPropType = {
    data: IReport
    classes?: string
    url?: string
    target?: '_blank'
    onClick?: () => void
}

const ItemString: React.FC<ItemStringPropType> = ({
    data,
    classes,
    url,
    target,
    onClick = () => {},
}) => {
    const isClickable = useMemo(() => data?.hasChilds || data?.link, [data])

    const [isLoadingValue, setIsLoadingValue] = useState(data.countable)

    useEffect(() => {
        if (data.value || data.value === 0) {
            setIsLoadingValue(false)
        }
    }, [data])

    return (
        <Card.Item
            classes={cn(
                style.string,
                classes,
                { [style.string_clickable]: isClickable, [style.string_list]: data.type === 'string' },
            )}
            url={url}
            target={target}
            onClick={onClick}
        >
            <Card.Body classes={style.wrap} styles={{ backgroundColor: data.backgroundColor, color: data.textColor }}>
                <div className={style.row}>
                    {data.icon && (
                        <div
                            className={style.iconWrap}
                            style={{ backgroundColor: data.iconBackgroundColor }}
                        >
                            <img className={style.icon} src={`${API_HOST}${data.icon}`} alt="" />
                        </div>
                    )}
                    <div className={style.text}>
                        {data.name}
                    </div>
                    <div className={style.values}>
                        <div className={style.valueWrap}>
                            {isLoadingValue ? (
                                <Spinner classes={style.valueSpinner} size="small" position="center" />
                            ) : (
                                <>
                                    <span className={cn(
                                        style.value,
                                        { [style.value_grey]: data.value === 0 || data.value === '0' },
                                    )}
                                    >
                                        {data.value}
                                    </span>

                                    {data.unit && (
                                        <span className={style.unit}>
                                            {data.unit}
                                        </span>
                                    )}

                                    {data.sub && (
                                        <div className={style.sub}>
                                            {data.sub}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {(data.link || data.hasChilds || (data.filterId && data.value)) && (
                            <Chevron
                                classes={style.arrow}
                                type="right"
                                size={10}
                            />
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card.Item>
    )
}

export default ItemString
