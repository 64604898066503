import React from 'react'
import cn from 'classnames'

import { DatePeriod } from 'enums'
import { Button } from 'components'
import style from './DateRangeFilterPeriodAction.module.css'

type DateRangeFilterPeriodActionPropType = {
    isIcon?: boolean
    isActive?: boolean
    activeColor?: string
    name: string
    onClick: (period: DatePeriod) => void
}

const DateRangeFilterPeriodAction: React.FC<DateRangeFilterPeriodActionPropType> = ({
    children,
    isIcon = false,
    isActive = false,
    activeColor,
    name,
    onClick,
}) => {
    const handlerClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        const periodName = e.currentTarget.name
        onClick(periodName as DatePeriod)
    }

    return (
        <Button
            classes={cn(style.action, {
                [style.action_button]: !isIcon,
                [style.action_buttonActive]: !isIcon && isActive,
                [style.action_posRight]: isIcon,
            })}
            style={
                !isIcon && isActive && activeColor
                    ? { backgroundColor: activeColor, borderColor: activeColor }
                    : undefined
            }
            styleType={isIcon ? 'transparent' : 'bordered2'}
            size="size36"
            name={name}
            onClick={handlerClick}
        >
            {children}
        </Button>
    )
}

export default DateRangeFilterPeriodAction
