import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TUserServiceError, TUserProfileProps, TProfileResponse } from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TUserProfileProps
export type TFetchFnResponse = TProfileResponse
export type TFetchFnError = AxiosError<TUserServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

const key = QueryCacheKeys.profile

/**
 * Хук API получить профайл юзера
 */
export default function useFetchProfile(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.fetchProfile(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useInvalidateProfile() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = (
        params: TFetchFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}
