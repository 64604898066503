import React, {
    useState,
    useEffect,
    useRef,
} from 'react'
import cn from 'classnames'

import { IGoodsImage } from '../../../../interfaces'
import { getImageResizeSrc } from '../../../../utils/helpers'
import { useOnLoadImage } from '../../../../hooks'
import style from './GoodsImage.module.css'

type GoodsImagePropType = {
    image: IGoodsImage
    setHeight?: boolean
}

type SizeType = {
    width: number
    height: number
}

const GoodsImage: React.FC<GoodsImagePropType> = ({ image, setHeight = true }) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [loadedImg, error] = useOnLoadImage(getImageResizeSrc({ src: image?.path, width: 800, quality: 90 }))

    const [wrapperSize, setWrapperSize] = useState<SizeType>()
    const [imageAdaptiveSize, setImageAdaptiveSize] = useState<SizeType>()

    function getImageAdaptiveSize(size?: SizeType): SizeType | null {
        if (size && image?.width && image?.height) {
            const height = Math.round((size.width / image.width) * image.height)
            return { width: size.width, height }
        }
        return null
    }

    useEffect(() => {
        if (setHeight && wrapperRef?.current) {
            const { clientWidth, clientHeight } = wrapperRef.current

            if (clientWidth || clientHeight) {
                setWrapperSize({ width: clientWidth, height: clientHeight })
            }
        }
    }, [wrapperRef?.current?.clientWidth])

    useEffect(() => {
        const imageSize = getImageAdaptiveSize(wrapperSize)

        if (imageSize) {
            setImageAdaptiveSize(imageSize)
        }
    }, [wrapperSize])

    return (
        <div
            className={style.wrap}
            style={{ height: imageAdaptiveSize?.height ? imageAdaptiveSize.height : '' }}
            ref={wrapperRef}
        >
            <img
                className={cn(style.image, loadedImg || error ? '' : style.image_loading)}
                src={loadedImg ? loadedImg.src : ''}
                alt=""
            />
        </div>
    )
}

export default GoodsImage
