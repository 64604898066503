import { useEffect, useState } from 'react'

import { IUserData } from 'interfaces'
import { useFetchUsersData } from 'containers/User/hooks'

/**
 * Hook получить общих друзей юзера чата
 */
export default function useMessengerChatUsersData(userIds: number[]): { data: Record<string, IUserData> } {
    const [data, setData] = useState({})

    const { data: dataUsersData } = useFetchUsersData({
        users: userIds.join(','),
        limit_friends: 3,
        is_mutual_friends: 1,
    }, {
        enabled: !!userIds.length,
    })

    useEffect(() => {
        if (dataUsersData) {
            setData((prevState) => {
                return dataUsersData.reduce((acc, item) => ({ ...acc, [item.user.id]: item }), prevState)
            })
        }
    }, [dataUsersData])

    return {
        data,
    }
}
