import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { ImagePropType } from 'components/Image/Image'
import { POST_IMAGE_PLACEHOLDER_RESOURCE } from 'config/app'
import { Image, ImgResource } from 'components'
import { PostMainImagePreview } from '../index'
import style from './PostMainImageImg.module.css'

export type PostMainImageImgPropType = {
    src: string
    classes?: string
    preview?: string
    background?: string
    url?: string
    width?: number
    height?: number
    resize?: ImagePropType['resize']
}

const PostMainImageImg: React.FC<PostMainImageImgPropType> = ({
    children,
    src,
    classes,
    preview,
    background,
    url,
    width,
    height,
    resize = { width: 800, quality: 90 },
}) => {
    const [isLoadImage, setIsLoadImage] = useState(false)
    const [isErrorImage, setIsErrorImage] = useState(false)

    const handlerLoadImage = () => {
        setIsLoadImage(true)
    }

    const handlerErrorImage = () => {
        setIsErrorImage(true)
    }

    const imageTpl = src ? (
        <>
            {preview && (
                <PostMainImagePreview
                    isHide={isLoadImage || isErrorImage}
                    preview={preview}
                />
            )}

            <Image
                classesWrap={style.wrap}
                classesImage={style.image}
                src={src}
                width={width}
                height={height}
                backgroundColor={background}
                resize={resize}
                onLoad={handlerLoadImage}
                onError={handlerErrorImage}
            />
        </>
    ) : (
        <ImgResource
            classes={style.imagePlaceholder}
            resourceKey={POST_IMAGE_PLACEHOLDER_RESOURCE}
        />
    )

    return (
        <div className={cn(style.item, classes)}>
            {url ? (
                <NavLink className={style.link} to={url}>
                    {imageTpl}
                </NavLink>
            ) : (
                <>
                    {imageTpl}
                </>
            )}

            {(isLoadImage || isErrorImage) && (
                children
            )}
        </div>
    )
}

export default PostMainImageImg
