import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { REGEXP_TAGS } from 'config/app'
import * as appSelectors from 'containers/App/app-selectors'
import { getId, parseContentStickers, stringifyContentStickers } from 'utils/helpers'
import style from './TextContentEditable.module.css'

type ContentEditablePropType = {
    value: string
    classes?: string
    placeholder?: string
    focus?: boolean
    disabled?: boolean
    onChange?: (text: string) => void
    onSubmitByEnter?: (text: string) => void
}

const TextContentEditable: React.FC<ContentEditablePropType> = ({
    classes,
    value = '',
    placeholder,
    focus,
    disabled,
    onChange = () => {},
    onSubmitByEnter,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const { big_url: stickerUrl } = useSelector(appSelectors.stickers)

    const [id] = useState(getId(false))

    const handlerInput = (e: React.SyntheticEvent<HTMLDivElement>) => {
        // setIsShowPlaceholder(!e.currentTarget.innerText)
    }

    const handlerBlur = (e: React.SyntheticEvent<HTMLDivElement>) => {
        const contentText = stringifyContentStickers(e.currentTarget.innerHTML)
        onChange(contentText)
    }

    const handlerPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const prevContentText = stringifyContentStickers(e.currentTarget.innerHTML)
        const clipboardContentText = stringifyContentStickers(e.clipboardData.getData('text'))

        onChange(prevContentText + clipboardContentText)
    }

    const handlerPasteCapture = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault()
    }

    const handlerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        // not allow line breaks
        if (onSubmitByEnter && e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handlerKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (onSubmitByEnter && e.key === 'Enter') {
            e.preventDefault()

            const contentText = stringifyContentStickers(e.currentTarget.innerHTML)

            if (contentText) {
                onSubmitByEnter(contentText)
            }
        }
    }

    useEffect(() => {
        const content = value.replaceAll(new RegExp(REGEXP_TAGS, 'g'), '')
        const contentHtml = parseContentStickers(content, stickerUrl, style.sticker)

        if (ref.current) {
            const range = document.createRange()
            const selection = window.getSelection()

            ref.current.innerHTML = contentHtml

            if (selection) {
                range.selectNodeContents(ref.current)
                range.collapse(false)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        }
    }, [value])

    useEffect(() => {
        if (focus && ref.current) {
            ref.current.focus()
        }
    }, [focus])

    return (
        // eslint-disable-next-line
        <div
            contentEditable={!disabled}
            suppressContentEditableWarning
            id={id}
            className={cn(style.content, classes)}
            ref={ref}
            placeholder={placeholder}
            onInput={handlerInput}
            onBlur={handlerBlur}
            onPaste={handlerPaste}
            onPasteCapture={handlerPasteCapture}
            onKeyDown={handlerKeyDown}
            onKeyUp={handlerKeyUp}
        />
    )
}

export default TextContentEditable
