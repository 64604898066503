import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IReport, IChartDataPoint } from 'interfaces'
import { ChartConfiguration } from 'components/Chart/Chart'
import { ChartPeriod, ChartPeriodTranslate } from 'enums'
import { useFetchCounterHistory } from 'containers/User/hooks'
import { Chart, Spinner, Tabs } from 'components'
import { numberFormat, dateTimeFormat } from 'utils/helpers'
import noDataText from 'components/Chart/plugins/noDataText'
import { vGray14 } from 'styles/modules/variables.module.css'
import { ItemString } from '../index'
import style from './ItemChart.module.css'

type ItemChartPropType = {
    data: IReport
    classes?: string
    url?: string
    target?: '_blank'
    onClick?: () => void
}

const CHART_TYPE_LINE = 1
const CHART_TYPE_BAR = 2

const ItemChart: React.FC<ItemChartPropType> = ({
    data,
    classes,
    url,
    target,
    onClick = () => {},
}) => {
    const { id, defaultPeriod = 0, deltaByDate } = data

    const { t, i18n } = useTranslation()

    const [chartPeriod, setChartPeriod] = useState<number>(defaultPeriod)

    const {
        isLoading: isLoadingCounterHistory,
        data: dataCounterHistory,
    } = useFetchCounterHistory({
        report: id,
        period: ChartPeriod[chartPeriod] as keyof typeof ChartPeriod,
        delta: deltaByDate,
    })

    const tabs = useMemo(() => {
        if (data.periodTabs) {
            return data.periodTabs.map((item) => ({
                id: item.period,
                name: t(ChartPeriodTranslate[item.period]),
            }))
        }

        return []
    }, [data])

    const chartConfig = useMemo<ChartConfiguration | undefined>(() => {
        if (dataCounterHistory) {
            const { points, sum, unit } = dataCounterHistory

            return {
                type: getChartType(data.chartType),
                data: {
                    labels: points.map((item) => getChartItemLabel(item, chartPeriod)),
                    datasets: [{
                        cubicInterpolationMode: CHART_TYPE_LINE ? 'monotone' : 'default',
                        borderColor: data.chartLineColor,
                        backgroundColor: data.chartLineColor,
                        fill: true,
                        data: points.map((item) => item.value),
                    }],
                },
                options: {
                    scales: {
                        x: {
                            display: !!points.length,
                        },
                        y: {
                            display: !!points.length,
                        },
                    },
                    plugins: {
                        title: {
                            display: sum !== undefined,
                            text: `${t('sum_for_period')}: ${numberFormat(sum)} ${unit}`,
                            font: {
                                weight: 'normal',
                                size: 18,
                            },
                        },
                        legend: {
                            display: false,
                        },
                        noDataText: {
                            enabled: !points.length,
                            font: '16px',
                            fontColor: vGray14,
                            text: t('no_data'),
                        },
                    },
                },
                plugins: [noDataText],
            }
        }

        return undefined
    }, [dataCounterHistory])

    const handlerChangeTab = (value: number) => {
        setChartPeriod(value)
    }

    function getChartType(type?: number) {
        switch (type) {
            case CHART_TYPE_LINE:
                return 'line'
            case CHART_TYPE_BAR:
            default:
                return 'bar'
        }
    }

    function getChartItemLabel({ date }: IChartDataPoint, period: number) {
        if (date) {
            const yearShort = dateTimeFormat(date, i18n.language, { year: '2-digit' })
            const monthShort = dateTimeFormat(date, i18n.language, { month: 'short' })
            const day = dateTimeFormat(date, i18n.language, { day: '2-digit' })

            switch (ChartPeriod[period]) {
                case 'all':
                case 'year':
                    return `${monthShort} ${yearShort}`
                case 'month':
                case 'week':
                    return `${day} ${monthShort}`
                default:
                    return ''
            }
        }

        return ''
    }

    return (
        <div className={cn(style.chart, classes)}>
            <ItemString
                data={data}
                url={url}
                target={target}
                onClick={onClick}
            />

            <div className={style.wrap}>
                {isLoadingCounterHistory && (
                    <div className={style.loader}>
                        <Spinner position="center" size="medium" />
                    </div>
                )}

                <div className={style.tabsWrap}>
                    <Tabs
                        classes={style.tabs}
                        classesItem={style.tabsItem}
                        classesActive={style.tabsItemActive}
                        classesText={style.tabsItemText}
                        items={tabs}
                        active={chartPeriod}
                        onChange={handlerChangeTab}
                    />
                </div>

                <Chart config={chartConfig} />
            </div>
        </div>
    )
}

export default ItemChart
