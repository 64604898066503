import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IFriendProfile } from 'interfaces'
import { MessengerNothing } from 'containers/Messenger/components'
import { Loader, InfiniteScroll } from 'components'
import { useFetchInfiniteFriends } from 'containers/User/hooks'

type MessengerFriendsPropType = {
    limit?: number
    offset?: number
    render: (data: IFriendProfile) => void
}

const MessengerFriends: React.FC<MessengerFriendsPropType> = ({
    limit = 20,
    offset = 0,
    render,
}) => {
    const { t } = useTranslation()
    const [isHideLoaderFriends, setIsHideLoaderFriends] = useState(false)

    const {
        isInitialLoading: isLoadingFriends,
        isFetching: isFetchingFriends,
        data: dataFriends,
        fetchNextPage: fetchNextPageFriends,
    } = useFetchInfiniteFriends({
        limit,
        offset,
    })

    const handlerLoadFriends = () => {
        if (!isFetchingFriends) {
            fetchNextPageFriends()
        }
    }

    useEffect(() => {
        if (dataFriends) {
            const { pages } = dataFriends
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].list.length < limit) {
                setIsHideLoaderFriends(true)
            }
        }
    }, [dataFriends])

    return (
        <div>
            {isLoadingFriends && (
                <Loader />
            )}

            {!isLoadingFriends
                && dataFriends
                && dataFriends.pages.length
                && !dataFriends.pages[0].list.length
            && (
                <MessengerNothing nothingText={t('friends_not_found')} />
            )}

            <InfiniteScroll
                isActive={!!dataFriends}
                isHideLoader={isHideLoaderFriends}
                onLoad={handlerLoadFriends}
            >
                {dataFriends?.pages.map((page, i) => (
                    // eslint-disable-next-line
                    <React.Fragment key={i}>
                        {page.list.map((item) => render(item))}
                    </React.Fragment>
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default MessengerFriends
