import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IProfile } from 'interfaces'
import { APP_URL } from 'config/app'
import {
    Avatar,
    Link,
    Popover,
    MenuList,
} from 'components'
import { useAlertDialog, useTimeAgo } from 'hooks'
import style from './MessengerHeaderChat.module.css'

type MessengerChatHeaderPropType = {
    chatUserProfile?: IProfile
    onBlockUser: ({ userId }: { userId: number }) => void
    onUnBlockUser: ({ userId }: { userId: number }) => void
}

const MessengerHeaderChat: React.FC<MessengerChatHeaderPropType> = ({
    chatUserProfile,
    onBlockUser,
    onUnBlockUser,
}) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { showAlertDialog } = useAlertDialog()

    const { getTimeAgo } = useTimeAgo({}, i18n.language)

    const urlChatUserProfile = useMemo(() => {
        return chatUserProfile ? APP_URL.profile.replace(':id', String(chatUserProfile.id)) : undefined
    }, [chatUserProfile])

    const lastLoginDateChatUser = useMemo(() => {
        return chatUserProfile?.last_login_date ? getTimeAgo(chatUserProfile.last_login_date) : undefined
    }, [chatUserProfile])

    const handlerClickAddChat = () => {
        history.push(APP_URL.messengerChatNew)
    }

    const handlerClickBlockUser = () => {
        if (chatUserProfile) {
            showAlertDialog({
                message: t('post_menu_block_user'),
                payload: { userId: chatUserProfile.id },
                onConfirm: onBlockUser,
            })
        }
    }

    const handlerClickUnBlockUser = () => {
        if (chatUserProfile) {
            showAlertDialog({
                message: t('post_menu_unblock_user'),
                payload: { userId: chatUserProfile.id },
                onConfirm: onUnBlockUser,
            })
        }
    }

    return (
        <div className={style.header}>
            <Link className={style.avatar} url={urlChatUserProfile}>
                <Avatar
                    name={chatUserProfile?.name}
                    surname={chatUserProfile?.surname}
                    src={chatUserProfile?.photo}
                    width={48}
                    height={48}
                />
            </Link>
            <div className={style.content}>
                <div>
                    <Link className={style.name} url={urlChatUserProfile}>
                        {`${chatUserProfile?.name ?? ''} ${chatUserProfile?.surname ?? ''}`}
                    </Link>
                </div>
                <div>
                    <Link className={style.date} url={urlChatUserProfile}>
                        {lastLoginDateChatUser && `${t('user_profile_was')} ${lastLoginDateChatUser}`}
                    </Link>
                </div>
            </div>
            <div className={style.menu}>
                <Popover
                    isDisabled={!chatUserProfile}
                    isShowArrow={false}
                    position="bottom"
                    side="left"
                    trigger={<Popover.TriggerDots />}
                >
                    <MenuList type="large">
                        <MenuList.Item onClick={handlerClickAddChat}>
                            {t('chat_add')}
                        </MenuList.Item>
                        {chatUserProfile && chatUserProfile.block && (
                            <MenuList.Item onClick={handlerClickUnBlockUser}>
                                {t('post_menu_unblock_user')}
                            </MenuList.Item>
                        )}
                        {chatUserProfile && !chatUserProfile.block && (
                            <MenuList.Item onClick={handlerClickBlockUser}>
                                {t('post_menu_block_user')}
                            </MenuList.Item>
                        )}
                        <MenuList.Item disabled>
                            {t('report_content')}
                        </MenuList.Item>
                    </MenuList>
                </Popover>
            </div>
        </div>
    )
}

export default MessengerHeaderChat
