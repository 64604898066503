import React from 'react'

import { IDeliveryCost } from '../../../../interfaces'
import { StoreOrderTariffsItemPropType } from '../StoreOrderTariffsItem/StoreOrderTariffsItem'
import { StoreOrderTariffsItem } from '../index'

import styleColumns from '../../../../styles/modules/columns.module.css'
import styleForm from '../../../../styles/modules/form.module.css'

type StoreOrderTariffsPropType = {
    items: IDeliveryCost[]
    error?: string | boolean
} & Pick<StoreOrderTariffsItemPropType, 'activeId' | 'currency' | 'onSelect'>

const StoreOrderTariffs: React.FC<StoreOrderTariffsPropType> = ({
    items,
    activeId,
    currency,
    error,
    onSelect,
}) => {
    return (
        <>
            <div className={styleForm.dangerText}>
                {error}
            </div>
            <div className={styleColumns.columns}>
                {items?.map((item) => (
                    <StoreOrderTariffsItem
                        data={item}
                        activeId={activeId}
                        currency={currency}
                        onSelect={onSelect}
                        key={item.id}
                    />
                ))}
            </div>
        </>
    )
}

export default StoreOrderTariffs
