import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IRootState, ICountry, IDeliveryAddress } from 'interfaces'
import { initAddressDataType, formAddressDataType } from 'forms/DeliveryAddressForm/DeliveryAddressForm'
import { addDeliveryAddressesPropType, updateDeliveryAddressesPropType } from 'services/MarketService'
import { Form, formErrorsType } from 'utils/Form'
import { DeliveryAddressForm } from 'forms'
import { MarketService } from 'services'
import { TValidationFieldRule, validation } from 'utils/validators'
import { showAlertNotify } from 'utils/helpers'

export type DeliveryAddressDataType = { id?: number } & initAddressDataType

export type DeliveryAddressActionPropType = {
    actionType: 'addDeliveryAddresses' | 'updateDeliveryAddresses'
    data: DeliveryAddressDataType
    classesContent?: string
    classesControls?: string
    onSuccess?: (data: IDeliveryAddress) => void
}

const DeliveryAddressAction: React.FC<DeliveryAddressActionPropType> = ({
    actionType,
    data,
    classesContent,
    classesControls,
    onSuccess = () => {},
}) => {
    const { t } = useTranslation()

    const countries: ICountry[] = useSelector((state: IRootState) => state.app.countries)

    const formControlText = useMemo(() => {
        switch (actionType) {
            case 'addDeliveryAddresses':
                return t('add')
            case 'updateDeliveryAddresses':
                return t('save')
            default:
                return t('ok')
        }
    }, [actionType])

    const [formErrors, setFormErrors] = useState<formErrorsType>({})
    const [isLoading, setIsLoading] = useState(false)

    const VALIDATION_FIELDS: TValidationFieldRule[] = [{
        field: 'country',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'city',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'full_name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'post_code',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'street',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'home_number',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'email',
        rules: [
            { rule: 'required', error: t('error_field_is_empty') },
            { rule: 'email', error: t('invalid_email') },
        ],
    }]

    const handlerFormSubmit = (formData: formAddressDataType) => {
        const phoneValidation = setPhoneValidation(formData.country)
        const errors = validation([...VALIDATION_FIELDS, phoneValidation], formData)

        setFormErrors(errors)

        if (Form.isSuccess(errors)) {
            const {
                country: countryData,
                city: cityData,
                city_name,
                post_code,
                full_name,
                phone,
                ...fields
            } = formData
            const phoneData = data.country?.code ? `${data.country.code}${phone}` : phone

            switch (actionType) {
                case 'addDeliveryAddresses': {
                    if (countryData && cityData && city_name && post_code && full_name) {
                        addDeliveryAddressesAction({
                            country: countryData,
                            city: cityData,
                            city_name,
                            post_code,
                            full_name,
                            phone: phoneData,
                            ...fields,
                        })
                    }
                    break
                }
                case 'updateDeliveryAddresses': {
                    if (data.id && countryData && cityData && city_name && post_code && full_name) {
                        updateDeliveryAddressesAction({
                            id: data.id,
                            country: countryData,
                            city: cityData,
                            city_name,
                            post_code,
                            full_name,
                            phone: phoneData,
                            ...fields,
                        })
                    }
                    break
                }
                default:
                    //
            }
        }
    }

    function setPhoneValidation(countryId?: number): TValidationFieldRule {
        const currentCountry = countries?.find((item) => item.id === countryId)
        return {
            field: 'phone',
            rules: [{
                rule: 'required',
                error: t('error_field_is_empty'),
            }, {
                rule: 'phone',
                options: { mask: currentCountry?.mask },
                error: t('error_field_is_empty'),
            }],
        }
    }

    function addDeliveryAddressesAction(params: addDeliveryAddressesPropType) {
        setIsLoading(true)

        MarketService.addDeliveryAddresses(params)
            .then(({ data: dataDeliveryAddress }) => {
                if (dataDeliveryAddress) {
                    onSuccess(dataDeliveryAddress)
                }
            })
            .catch((err) => {
                errHandlerAddressForm(err, params, setFormErrors)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function updateDeliveryAddressesAction(params: updateDeliveryAddressesPropType) {
        setIsLoading(true)

        MarketService.updateDeliveryAddresses(params)
            .then(({ data: dataDeliveryAddress }) => {
                if (dataDeliveryAddress) {
                    onSuccess(dataDeliveryAddress)
                }
            })
            .catch((err) => {
                errHandlerAddressForm(err, params, setFormErrors)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function errHandlerAddressForm(
        err: any,
        state: any,
        setState: React.Dispatch<React.SetStateAction<formErrorsType>>,
    ) {
        const responseData = err?.response?.data

        if (responseData) {
            const errKeys = Object.keys(responseData)

            if (errKeys.includes('error')) {
                showAlertNotify({ type: 'error', message: responseData.error })
            } else {
                errKeys.forEach((errKey) => {
                    if (errKey in state) {
                        const errValue = responseData[errKey]

                        setState((prevState) => ({
                            ...prevState,
                            [errKey]: Array.isArray(errValue) ? errValue[0] : errValue,
                        }))
                    }
                })
            }
        } else {
            showAlertNotify({ type: 'error', message: t('update_error') })
        }
    }

    return (
        <DeliveryAddressForm
            data={data}
            errors={formErrors}
            controlText={formControlText}
            classesContent={classesContent}
            classesControls={classesControls}
            isDisabled={isLoading}
            onSubmit={handlerFormSubmit}
        />
    )
}

export default DeliveryAddressAction
