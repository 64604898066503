import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { Card } from 'components'
import style from './ItemProgressBar.module.css'

type ItemProgressBarPropType = {
    data: IReport
    classes?: string
}

const ItemProgressBar: React.FC<ItemProgressBarPropType> = ({ data, classes }) => {
    const valueWrapRef = useRef<HTMLDivElement>(null)
    const valueRef = useRef<HTMLDivElement>(null)

    const [progressPos, setProgressPos] = useState(0) // %
    const [valuePos, setValuePos] = useState(0) // px

    /**
     * Get progress in percent: min = 0, max = 100
     * @return {number} %
     */
    const progress = useMemo(() => {
        if (data) {
            const { startValue, endValue, currentValue } = data || {}

            if ((startValue || startValue === 0) && endValue && (currentValue || currentValue === 0)) {
                const valuePercent = ((currentValue - startValue) / (endValue - startValue)) * 100
                return valuePercent > 100 ? 100 : valuePercent
            }
        }

        return 0
    }, [data])

    function getValuePos(progressPercentVal: number) {
        const widthWrap = valueWrapRef?.current ? valueWrapRef.current.clientWidth : 0
        const widthValue = valueRef?.current ? valueRef.current.scrollWidth : 0

        const halfWidthValue = Math.round(widthValue / 2) // px
        const offsetValue = Math.round((progressPercentVal * widthWrap) / 100) // px

        if (widthWrap - offsetValue <= halfWidthValue) { // right position
            setValuePos(widthWrap - widthValue)
        } else if (widthWrap - offsetValue <= widthValue || offsetValue >= halfWidthValue) { // left position
            setValuePos(offsetValue - halfWidthValue)
        }
    }

    useEffect(() => {
        if (valueWrapRef?.current && valueRef?.current && progress) {
            setProgressPos(progress)
            getValuePos(progress)
        }
    }, [valueWrapRef?.current, valueRef?.current, progress])

    return (
        <Card.Item classes={cn(style.progressBar, classes)}>
            <Card.Body
                classes={style.wrap}
                // styles={{ backgroundColor: data.backgroundColor }}
            >
                <div
                    className={style.valueWrap}
                    style={{
                        backgroundImage: `linear-gradient(to right, ${data.progressBarStartColor}, ${data.progressBarEndColor})`,
                        paddingLeft: `${valuePos}px`,
                    }}
                    ref={valueWrapRef}
                >
                    <span
                        className={style.value}
                        style={{ color: data.progressBarStartColor }}
                        ref={valueRef}
                    >
                        {data.currentValue}
                        {' '}
                        {data.currentValueUnit}
                    </span>
                </div>
                <div
                    className={style.progressWrap}
                    style={{ background: `linear-gradient(to right, ${data.progressBarStartColor}, ${data.progressBarEndColor})` }}
                >
                    <div
                        className={style.progress}
                        style={{ marginLeft: `${progressPos}%` }}
                    >
                        {data.startValue === data.currentValue && (
                            <div
                                className={style.progressDefaultValue}
                                style={{ backgroundColor: data.progressBarStartColor }}
                            />
                        )}
                    </div>
                </div>
                <div
                    className={style.desc}
                    // style={{ color: data.textColor }}
                >
                    <span>
                        {data.startValue}
                        {' '}
                        {`(${data.startValueUnit})`}
                    </span>
                    <span>
                        {data.sub}
                    </span>
                    <span>
                        {data.endValue}
                        {' '}
                        {`(${data.endValueUnit})`}
                    </span>
                </div>
            </Card.Body>
        </Card.Item>
    )
}

export default ItemProgressBar
