import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { ImagePropType } from 'components/Image/Image'
import { Video } from 'components'
import { getImageResizeSrc } from 'utils/helpers'
import style from './PostMainImageVideo.module.css'

type PostMainImageItemVideoPropType = {
    type: string
    src: string
    classes?: string
    poster?: string
    background?: string
    url?: string
    resize?: ImagePropType['resize']
    isCanPlay?: boolean
}

const PostMainImageVideo: React.FC<PostMainImageItemVideoPropType> = ({
    children,
    type,
    src,
    classes,
    poster,
    background,
    url,
    resize,
    isCanPlay,
}) => {
    const [width, setWidth] = useState<number>()
    const [height, setHeight] = useState<number>()

    const [isShowPlayIcon, setIsShowPlayIcon] = useState(false)

    const posterSrc = useMemo(() => {
        if (poster) {
            return resize
                ? getImageResizeSrc({ src: poster, ...resize })
                : poster
        }
        return undefined
    }, [poster])

    const handlerLoadVideo = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        const { currentTarget } = e

        if (currentTarget) {
            if (currentTarget.clientWidth) {
                setWidth(currentTarget.clientWidth)
            }
            if (currentTarget.clientHeight) {
                setHeight(currentTarget.clientHeight)
            }
            if (isCanPlay) {
                setIsShowPlayIcon(true)
            }
        }
    }

    const handlerClickVideo = (e: React.SyntheticEvent) => {
        if (isCanPlay) {
            playVideoAction((e.target as HTMLVideoElement))
        }
    }

    function playVideoAction(player?: HTMLVideoElement | null) {
        const vp = player

        if (vp) {
            vp.play()
                .then(() => {
                    setIsShowPlayIcon(false)
                    vp.controls = true
                })
                .catch(() => {})
        }
    }

    const videoTpl = (
        <Video
            isPlayIcon={isShowPlayIcon}
            classes={style.video}
            width={width}
            height={height}
            poster={posterSrc}
            src={src}
            type={type}
            onLoadedData={handlerLoadVideo}
            onClick={handlerClickVideo}
        />
    )

    return (
        <div className={cn(style.wrap, classes)} style={{ backgroundColor: background }}>
            {url ? (
                <NavLink className={style.link} to={url}>
                    {videoTpl}
                </NavLink>
            ) : (
                <>
                    {videoTpl}
                </>
            )}

            {children}
        </div>
    )
}

export default PostMainImageVideo
