import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { VideoPropType } from 'components/Video/Video'
import { ImagePropType } from 'components/Image/Image'
import { Video } from 'components'
import { getImageResizeSrc } from 'utils/helpers'
import style from './PostTileVideo.module.css'

type PostTileVideoPropType = {
    url?: string
    backgroundColor?: string
    resize?: ImagePropType['resize']
} & VideoPropType

const PostTileVideo: React.FC<PostTileVideoPropType> = ({
    url,
    poster,
    backgroundColor,
    resize,
    ref,
    ...props
}) => {
    const posterSrc = useMemo(() => {
        if (poster) {
            return resize
                ? getImageResizeSrc({ src: poster, ...resize })
                : poster
        }
        return undefined
    }, [poster])

    const videoTpl = (
        <Video
            classes={style.video}
            poster={posterSrc}
            {...props}
        />
    )

    return (
        <div className={style.wrap} style={{ backgroundColor }}>
            {url ? (
                <NavLink className={style.link} to={url}>
                    {videoTpl}
                </NavLink>
            ) : (
                videoTpl
            )}
        </div>
    )
}

export default PostTileVideo
