import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { APP_URL } from 'config/app'
import * as appSelectors from 'containers/App/app-selectors'
import { scrollTop } from 'utils/helpers'
import ReportsV2 from './ReportsV2'

const Reports: React.FC = () => {
    const history = useHistory()

    const { reportsNew: isNewReports } = useSelector(appSelectors.settings)

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (!isNewReports) {
            history.replace(APP_URL.reportsAccounts)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            {isNewReports && (
                <ReportsV2 />
            )}
        </>
    )
}

export default Reports
