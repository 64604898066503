import React, { useState } from 'react'

import translations from 'i18n/translations.json'
import { TranslationService } from 'services'
import { dateTimeFormat, copyTextClipboard } from 'utils/helpers'
import errorLog from 'utils/errorLog'

const Translations: React.FC = () => {
    const [jsonTranslations, setJsonTranslations] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handlerClickUpdateTranslations = () => {
        setIsLoading(true)
        TranslationService.fetchTranslations()
            .then(({ data }) => {
                if (data) {
                    setJsonTranslations(JSON.stringify(data))
                }

                setIsLoading(false)
            })
            .catch(() => {
                errorLog('Ошибка при получении переводов!')
                setIsLoading(false)
            })
    }

    const handlerClickCopyTranslations = () => {
        copyTextClipboard(jsonTranslations)
            .then(() => {
                errorLog('Скопировано!')
            })
            .catch(() => {
                errorLog('Ошибка копирования!')
            })
    }

    return (
        <div>
            <span>
                Дата сборки переводов:
            </span>

            <span style={{ marginLeft: '5px' }}>
                {dateTimeFormat(translations?.date, '', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                })}
            </span>

            <span style={{ marginLeft: '20px' }}>
                <button disabled={isLoading} onClick={handlerClickUpdateTranslations}>
                    Получить переводы
                </button>
            </span>

            {jsonTranslations && (
                <span style={{ marginLeft: '20px' }}>
                    <button onClick={handlerClickCopyTranslations}>
                        Копировать JSON
                    </button>
                </span>
            )}
        </div>
    )
}

export default Translations
