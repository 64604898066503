import { API_URL } from 'config/api'
import { CAPTCHA_SCRIPT_URL, EVENT_TYPE_CAPTCHA_INIT } from 'config/app'
import { UserService } from 'services'
import { showAlertNotify, parseTpl } from 'utils/helpers'
import requestClient from 'utils/requestClient'
import eventBus from 'utils/EventBus'
import i18n from 'i18n'

declare global {
    interface Window {
        initGeetest?: (userConfig: any, callback: (captchaObj: any) => void) => void
        captchaObj?: any
    }
}

type initCaptchaDataType = {
    success: number // 0 | 1
    gt: string
    challenge: string
    new_captcha: number
    captcha_name: string
}

type unBlockingDataType = {
    geetest_challenge: string
    geetest_validate: string
    geetest_seccode: string
}

/**
 * API Geetest captcha
 *
 * @see https://docs.geetest.com/captcha/apirefer/api/web
 * @requires https://static.geetest.com/static/tools/gt.js
 */
class CaptchaGeetestService {
    static initCaptcha(data: initCaptchaDataType): Promise<void> {
        const config = {
            gt: data.gt,
            challenge: data.challenge,
            offline: !data.success,
            new_captcha: !!data.new_captcha,
            product: 'bind',
            lang: i18n.language,
            https: window.location.protocol === 'https:',
        }

        return CaptchaGeetestService.init(config, data.captcha_name)
    }

    /**
     * Create captcha instance
     */
    static init(config: any, captchaName: string): Promise<void> {
        const run = () => {
            return new Promise<void>((resolve, reject) => {
                window.initGeetest!(config, (captchaObj) => {
                    const handlerCaptchaVerify = () => {
                        captchaObj.verify()
                    }

                    window.captchaObj = captchaObj

                    eventBus.on(EVENT_TYPE_CAPTCHA_INIT, handlerCaptchaVerify)

                    captchaObj
                        .onReady(() => {
                            captchaObj.verify()
                        })
                        .onSuccess(() => {
                            const result = captchaObj.getValidate()

                            CaptchaGeetestService.unBlocking(captchaName, result)
                                .then(() => {
                                    CaptchaGeetestService.reset(captchaObj)
                                    eventBus.off(EVENT_TYPE_CAPTCHA_INIT, handlerCaptchaVerify)
                                    resolve()
                                })
                                .catch((err) => {
                                    reject(err)
                                })
                        })
                        .onError(() => {
                            reject()
                        })
                        .onClose(() => {
                            showAlertNotify({
                                type: 'error',
                                action: EVENT_TYPE_CAPTCHA_INIT,
                                message: i18n.t('captcha_not_solved_error'),
                            })
                        })
                })
            })
        }

        /**
         * кейс маловероятен, т.к. при onSuccess инстанс каптчи удаляется
         */
        if (window.captchaObj) {
            CaptchaGeetestService.reset(window.captchaObj)
            return run()
        }
        if (window.initGeetest) {
            return run()
        }

        return CaptchaGeetestService.loadCaptchaScript()
            .then(() => {
                return run()
            })
            .catch(() => {
                return Promise.reject()
            })
    }

    /**
     * Load remote captcha script
     */
    static loadCaptchaScript() {
        if ('initGeetest' in window) {
            return Promise.resolve()
        }

        return new Promise<void>((resolve, reject) => {
            const body = document.querySelector('body')
            const script = document.createElement('script')

            script.src = `${CAPTCHA_SCRIPT_URL}?_t=${Date.now()}`

            script.onload = function onload() {
                resolve()
            }

            script.onerror = function onerror() {
                showAlertNotify({
                    type: 'error',
                    action: EVENT_TYPE_CAPTCHA_INIT,
                    message: i18n.t('error_captcha'),
                })
                reject()
            }

            if (body) {
                body.appendChild(script)
            }
        })
    }

    /**
     * Reset captcha instance
     */
    static reset(captcha: any) {
        captcha.destroy()
        window.captchaObj = undefined
    }

    /**
     * Block user and get 429 response status
     */
    static getCaptchaConfig(captchaName: string): Promise<{ data: initCaptchaDataType }> {
        const url = parseTpl(API_URL.captcha, { captcha_name: captchaName })
        return requestClient(url)
    }

    /**
     * Unblocking user with solved captcha
     */
    static unBlocking(captchaName: string, data: unBlockingDataType) {
        const url = parseTpl(API_URL.captcha, { captcha_name: captchaName })

        return new Promise<void>((resolve, reject) => {
            requestClient(url, { method: 'post', data })
                .then((res) => {
                    const { data: dataToken } = res

                    if (dataToken?.sessia_token) {
                        UserService.saveSessionData(dataToken.sessia_token)
                    }

                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}

export default CaptchaGeetestService
