import React from 'react'
import cn from 'classnames'

import style from './Spinner.module.css'

export type SpinnerPropType = {
    classes?: string
    size?: 'small' | 'medium' | 'large'
    position?: 'top' | 'center' | 'bottom'
}

const Spinner: React.FC<SpinnerPropType> = ({ classes, size = 'small', position = 'center' }) => {
    return (
        <div className={cn(style.container, style[position], classes)}>
            <div className={style[size]} />
        </div>
    )
}

export default Spinner
