import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IReport } from 'interfaces'
import { fetchReportsFilterValuePropType } from 'services/ReportService'
import { ReportService } from 'services'

const key = 'reportFilterValue'

export type TFetchFnParams = fetchReportsFilterValuePropType
export type TError = string
export type TQueryKey = [typeof key, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IReport[], TError, IReport[], TQueryKey>

/**
 * Хук API получить значения фильтра в отчете
 */
export default function useFetchReportFilterValue(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return ReportService.fetchReportsFilterValue(params)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                // TODO get error
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
