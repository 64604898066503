import React, { useMemo } from 'react'

import { ICalendarHour } from 'interfaces'
import { dateTimeFormat } from 'utils/helpers'
import style from './CalendarDayCell.module.css'

type CalendarDayCellPropType = {
    data: ICalendarHour
    gridItemHeight: number
    onClickAddEvent: (value: Date) => void
}

const CalendarDayCell: React.FC<CalendarDayCellPropType> = ({
    children,
    data,
    gridItemHeight,
    onClickAddEvent,
}) => {
    const date = useMemo(() => {
        return dateTimeFormat(data.date, 'en-US', { hour12: true, hour: 'numeric' })
    }, [data])

    const handlerClick = () => {
        onClickAddEvent(data.date)
    }

    return (
        <div className={style.cell} style={{ height: gridItemHeight }}>
            <div className={style.date}>
                <div className={style.dateValue}>
                    {date}
                </div>
            </div>
            <div
                className={style.content}
                role="button"
                aria-label="Add event"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={handlerClick}
            >
                {children}
            </div>
        </div>
    )
}

export default CalendarDayCell
