import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

import { IAgreementCompany } from 'interfaces'

export type TAgreementResponse = IAgreementCompany[]

export type TAgreementSignedResponse = { success: boolean }

/**
 * API agreement
 */
class AgreementService {
    static getAgreement() {
        return requestClient<TAgreementResponse>(API_URL.agreement)
    }

    static setAgreementSigned(id: number) {
        return requestClient<TAgreementSignedResponse>(`${API_URL.agreementSign}/${id}/sign`, { method: 'post' })
    }
}

export default AgreementService
