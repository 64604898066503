import { combineReducers } from 'redux'

import { INetwork } from 'interfaces'
import {
    UNSUBSCRIBE_USER,
    UNSUBSCRIBE_STORE,
    UNSUBSCRIBE_STORE_USER,
    UNSUBSCRIBE_COMMUNITY,
} from 'reducers/types'
import {
    unsubscribeUserActionType,
    unsubscribeStoreActionType,
    unsubscribeStoreUserActionType,
    unsubscribeCommunityActionType,
} from './network-actions-type'

export const unsubscribeUserReducer = (state: INetwork['unsubscribedUsers'] = [], action: unsubscribeUserActionType):
    INetwork['unsubscribedUsers'] => {
    switch (action.type) {
        case UNSUBSCRIBE_USER:
            return [...state, action.payload]

        default:
            return state
    }
}

export const unsubscribeStoreReducer = (state: INetwork['unsubscribedStores'] = [], action: unsubscribeStoreActionType):
    INetwork['unsubscribedStores'] => {
    switch (action.type) {
        case UNSUBSCRIBE_STORE:
            return [...state, action.payload]

        default:
            return state
    }
}

export const unsubscribeStoreUserReducer = (state: INetwork['unsubscribedStoreUsers'] = {}, action: unsubscribeStoreUserActionType):
    INetwork['unsubscribedStoreUsers'] => {
    switch (action.type) {
        case UNSUBSCRIBE_STORE_USER: {
            const { storeId, userId } = action.payload
            const store = state[storeId]

            return store ? { ...state, [storeId]: [...store, userId] } : { ...state, [storeId]: [userId] }
        }

        default:
            return state
    }
}

export const unsubscribeCommunityReducer = (state: INetwork['unsubscribedCommunity'] = [], action: unsubscribeCommunityActionType):
    INetwork['unsubscribedCommunity'] => {
    switch (action.type) {
        case UNSUBSCRIBE_COMMUNITY:
            return [...state, action.payload]

        default:
            return state
    }
}

export const networkReducer = combineReducers({
    unsubscribedUsers: unsubscribeUserReducer,
    unsubscribedStores: unsubscribeStoreReducer,
    unsubscribedStoreUsers: unsubscribeStoreUserReducer,
    unsubscribedCommunity: unsubscribeCommunityReducer,
})
