import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { TIntersectionObserverProps } from 'hooks/useIntersectionObserver'
import { useIntersectionObserver } from 'hooks'
import { Context } from '../Context'
import style from './CardItem.module.css'

export type CardItemPropType = {
    classes?: string
    url?: string
    target?: '_blank'
    observerOpts?: TIntersectionObserverProps
    onIntersecting?: () => void
    onClick?: () => void
}

const CardItem: React.FC<CardItemPropType> = ({
    children,
    classes,
    url,
    target,
    observerOpts = {},
    onIntersecting = () => {},
    onClick,
}) => {
    const OBSERVER_OPTS_DEFAULT = {
        rootMargin: '50% 0px 50% 0px',
        freezeOnceVisible: true,
    }

    const ref = useRef(null)
    const [isIntersecting] = useIntersectionObserver(ref, { ...OBSERVER_OPTS_DEFAULT, ...observerOpts })

    const [context, setContext] = useState({ isIntersecting: false })

    const bodyTpl = (
        <Context.Provider value={context}>
            {children}
        </Context.Provider>
    )

    const handlerClick = () => {
        if (onClick) {
            onClick()
        }
    }

    useEffect(() => {
        if (isIntersecting) {
            setContext({ isIntersecting: true })
            onIntersecting()
        }
    }, [isIntersecting])

    if (url) {
        return (
            target ? (
                // external link
                <a className={cn(style.item, style.item_link, classes)} href={url} target={target} ref={ref}>
                    {bodyTpl}
                </a>
            ) : (
                // inner link
                <NavLink className={cn(style.item, style.item_link, classes)} to={url} ref={ref}>
                    {bodyTpl}
                </NavLink>
            )
        )
    }

    return (
        onClick ? (
            // eslint-disable-next-line
            <div className={cn(style.item, classes)} ref={ref} onClick={handlerClick}>
                {bodyTpl}
            </div>
        ) : (
            <div className={cn(style.item, classes)} ref={ref}>
                {bodyTpl}
            </div>
        )
    )
}

export default CardItem
