import React from 'react'
import cn from 'classnames'

import { SvgResource } from 'components'
import style from './PostMainImageDocument.module.css'

type PostMainImageDocumentPropType = {
    classes?: string
    src: string
    title?: string
}

const PostMainImageDocument: React.FC<PostMainImageDocumentPropType> = ({ classes, src, title }) => {
    return (
        <div className={cn(style.item, classes)}>
            <a
                className={style.link}
                href={src}
                title={title}
                target="_blank"
                rel="noreferrer noopener"
                aria-label="link"
            >
                <SvgResource
                    resourceKey="ic_document_svg"
                    width={60}
                    height={60}
                />
            </a>
        </div>
    )
}

export default PostMainImageDocument
