import React from 'react'
import { useForm } from 'react-final-form'

import { IUploadedFileData } from 'interfaces'
import { Input } from 'components'
import { AppService, SimService } from 'services'
import styleForm from 'styles/modules/form.module.css'
import { getFileTypeByMimeType } from 'utils/helpers'

export type TFile = {
    file: File
    image?: HTMLImageElement
    fileData?: IUploadedFileData
}

type FieldFilePropType = {
    classes?: string
    name: string
    accept?: string
    isUpload?: boolean
    isNormalizeImageFile?: boolean
    imageMaxSide?: number
    onUploading?: (value: boolean) => void
    onError?: (value: any) => void
}

const FieldFile: React.FC<FieldFilePropType> = ({
    children,
    classes,
    name,
    accept,
    isUpload = true,
    isNormalizeImageFile = true,
    imageMaxSide,
    onUploading = () => {},
    onError = () => {}, // FIXME ? inside react-final-form
}) => {
    const { mutators } = useForm()

    const handlerAddFiles = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target

        if (files?.length) {
            handleFiles(files)
        }
    }

    function mutateFieldValue(value: TFile[]) {
        if (name in mutators) {
            mutators[name](name, value)
        }
    }

    function handleFiles(files: FileList) {
        onUploading(true)

        if (isUpload) {
            const fileRequests: Promise<TFile>[] = Array.from(files).map((file) => uploadFile(file))
            Promise.all(fileRequests)
                .then((res) => {
                    mutateFieldValue(res)
                })
                .catch((err) => {
                    onError(err)
                })
                .finally(() => {
                    onUploading(false)
                })
        } else {
            const fileRequests: Promise<TFile>[] = Array.from(files).map((file) => getFile(file))
            Promise.all(fileRequests)
                .then((res) => {
                    mutateFieldValue(res)
                })
                .catch((err) => {
                    onError(err)
                })
                .finally(() => {
                    onUploading(false)
                })
        }
    }

    function normalizeImageFile(file: File) {
        return AppService.normalizeImageFile(file, { imageMaxSide })
            .then((data) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    function getFile(file: File) {
        if (isNormalizeImageFile) {
            const { type: fileType } = getFileTypeByMimeType(file.type)

            if (fileType === 'image') {
                return normalizeImageFile(file)
            }
        }

        return Promise.resolve({ file, image: undefined })
    }

    function uploadFile(file: File) {
        if (isNormalizeImageFile) {
            const { type: fileType } = getFileTypeByMimeType(file.type)

            if (fileType === 'image') {
                let imageFile: File
                let image: HTMLImageElement

                return normalizeImageFile(file)
                    .then((data) => {
                        imageFile = data.file
                        image = data.image

                        return uploadFileAction(data.file)
                    })
                    .then((data) => {
                        return { file: imageFile, fileData: data, image }
                    })
                    .catch(() => {
                        return Promise.reject()
                    })
            }
        }

        return uploadFileAction(file)
            .then((data) => {
                return { file, fileData: data, image: undefined }
            })
            .catch(() => {
                return Promise.reject()
            })
    }

    function uploadFileAction(file: File) {
        return SimService.filesUpload(file)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    return (
        <label className={classes}>
            <Input
                classes={styleForm.inputFile}
                styleType="clear"
                type="file"
                accept={accept}
                name={name}
                onChange={handlerAddFiles}
            />
            {children}
        </label>
    )
}

export default FieldFile
