import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'

import { IChannel } from 'interfaces'
import { FormDataType, FieldName } from 'forms/ChannelForm/ChannelForm'
import { TFile } from 'forms/fields/FieldFile/FieldFile'
import { TCreateChannelProps } from 'services/ChatMicroService'
import { useMutationChannel } from 'containers/Messenger/hooks'
import { ChannelForm } from 'forms'

type ChannelActionPropType = {
    classes?: string
    isSetMembers: boolean
    isDisabled: boolean
    onComplete?: (value: IChannel) => void
    onError?: (value: string) => void
}

const ChannelAction: React.FC<ChannelActionPropType> = ({
    classes,
    isSetMembers,
    isDisabled,
    onComplete = () => {},
    onError = () => {},
}) => {
    const isMounted = useRef(false)
    const { t } = useTranslation()

    const { create } = useMutationChannel()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handlerSubmit = (params: FormDataType) => {
        if (isSetMembers) {
            createChannelAction(params)
        } else {
            onError(t('chat_required_users'))
        }
    }

    function createChannelAction(params: TCreateChannelProps) {
        setIsSubmitting(true)
        create.mutate(params, {
            onSuccess: (res) => {
                onComplete(res)
            },
            onError: (err) => {
                onError(err?.length ? err[0] : t('update_error'))
            },
            onSettled: () => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            },
        })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Form
            onSubmit={handlerSubmit}
            mutators={{
                [FieldName.image]: ([key, value]: [FieldName.image, TFile[]], state, { changeValue }) => {
                    const [file] = value || []
                    changeValue(state, key, () => file.file)
                },
            }}
            validate={(values) => {
                const errors = {}

                if (values[FieldName.title] === undefined || !values[FieldName.title].trim()) {
                    errors[FieldName.title] = true
                }

                return errors
            }}
            render={({ handleSubmit }) => (
                <ChannelForm
                    classes={classes}
                    isSubmitting={isSubmitting}
                    isDisabled={isDisabled}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ChannelAction
