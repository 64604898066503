import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ITransaction } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { InfiniteScroll } from 'components'
import {
    // getId,
    getDateValues,
    defaultDateFormat,
    dateTimeFormat,
} from 'utils/helpers'
import { WalletTransaction } from '../index'
import style from './WalletTransactions.module.css'

type WalletTransactionsPropType = {
    classes?: string
    data?: ITransaction[]
    isActive?: boolean
    isHideLoader?: boolean
    onLoad: (value: LoadDataType) => void
}

type TTransactionGroup = {
    date: string
    dateFormatted: string
    items: ITransaction[]
}

const DATE_OPTIONS_CURRENT_YEAR: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' }
const DATE_OPTIONS_PREV_YEAR: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const WalletTransactions: React.FC<WalletTransactionsPropType> = ({
    classes,
    data,
    isActive,
    isHideLoader,
    onLoad,
}) => {
    const { t } = useTranslation()

    const [{
        year: todayYear,
        month: todayMonth,
        day: todayDay,
    }] = useState(getDateValues(new Date()))

    const [{
        year: yesterdayYear,
        month: yesterdayMonth,
        day: yesterdayDay,
    }] = useState(getDateValues(new Date(todayYear, todayMonth, todayDay - 1)))

    /** Transactions grouped by date */
    const transactionGroups = useMemo(() => {
        if (data) {
            return data.reduce((acc, item) => {
                const dateKey = defaultDateFormat(item.date)
                const groupIndex = acc.findIndex((group) => group.date === dateKey)

                if (groupIndex >= 0) {
                    acc[groupIndex].items.push(item)
                    return acc
                }

                return [
                    ...acc,
                    {
                        date: dateKey,
                        dateFormatted: getGroupFormattedDate(item.date),
                        items: [item],
                    },
                ]
            }, [] as TTransactionGroup[])
        }

        return []
    }, [data])

    function getGroupFormattedDate(date: string) {
        const { year, month, day } = getDateValues(new Date(date))

        if (year === todayYear && month === todayMonth && day === todayDay) {
            return t('today')
        }
        if (year === yesterdayYear && month === yesterdayMonth && day === yesterdayDay) {
            return t('yesterday')
        }

        return dateTimeFormat(
            date,
            undefined,
            year === todayYear ? DATE_OPTIONS_CURRENT_YEAR : DATE_OPTIONS_PREV_YEAR,
            { sliceRusYearStr: true },
        )
    }

    return (
        <div className={classes}>
            <InfiniteScroll
                isActive={isActive}
                isHideLoader={isHideLoader}
                onLoad={onLoad}
            >
                {transactionGroups.map((group) => (
                    <div className={style.group} key={group.date}>
                        <div className={style.date}>
                            {group.dateFormatted}
                        </div>

                        {group.items.map((item) => (
                            <WalletTransaction
                                classes={style.transaction}
                                data={item}
                                key={item.id}
                            />
                        ))}
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default WalletTransactions
