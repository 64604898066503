import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICountry } from 'interfaces'
import { API_HOST_PRODUCTION } from 'config/api'
import { CustomSelect, Spinner } from 'components'
import { filterArrayObjByField } from 'utils/helpers'
import style from './CountryDataSelect.module.css'

type CountryDataSelectPropType = {
    countries: ICountry[]
    selected?: Pick<ICountry, 'id' | 'icon' | 'name'>
    selectedIsIcon?: boolean
    isCanEmpty?: boolean
    isShowSelectArrow?: boolean
    classes?: string
    classesField?: string
    placeholder?: string
    disabled?: boolean
    onChange?: (value: ICountry) => void
}

const CountryDataSelect: React.FC<CountryDataSelectPropType> = ({
    children,
    countries,
    selected,
    selectedIsIcon = true,
    isCanEmpty = false,
    isShowSelectArrow = false,
    classes,
    classesField,
    placeholder,
    disabled,
    onChange = () => {},
}) => {
    const { t } = useTranslation()
    const [search, setSearch] = useState<string>('')
    const [filteredCountries, setFilteredCountries] = useState<ICountry[]>([])

    const handlerSearch = (value: string) => {
        setSearch(value)
        setFilteredCountries(countryFilter(value))
    }

    const handlerChangeCountry = (countryId: number) => {
        const country = filteredCountries.find((item) => item.id === countryId)

        if (country) {
            onChange(country)
        }
    }

    function countryFilter(value: string) {
        return filterArrayObjByField(countries, 'name', value)
    }

    useEffect(() => {
        if (countries?.length) {
            setFilteredCountries(countries)
        }
    }, [countries])

    return (
        <CustomSelect.Select classes={classes}>
            <CustomSelect.Field
                classes={classesField}
                showArrow={isShowSelectArrow}
                disabled={disabled}
            >
                {selected ? (
                    <>
                        {selectedIsIcon && (
                            <img
                                className={style.image}
                                src={`${API_HOST_PRODUCTION}${selected.icon}`}
                                alt={selected.name}
                            />
                        )}
                        {!children && !selectedIsIcon && selected.name}
                        {children && children}
                    </>
                ) : (
                    <>
                        {isCanEmpty && placeholder}
                        {!isCanEmpty && <Spinner size="small" position="center" />}
                    </>
                )}
            </CustomSelect.Field>
            <CustomSelect.Options
                search={search}
                searchPlaceholder={t('enter_the_name_of_the_country')}
                onSearch={handlerSearch}
            >
                {filteredCountries?.map((item) => (
                    <CustomSelect.Option
                        classes={style.item}
                        isActive={item.id === selected?.id}
                        id={item.id}
                        key={item.id}
                        onClick={handlerChangeCountry}
                    >
                        <span>
                            <img
                                className={style.itemImg}
                                src={`${API_HOST_PRODUCTION}${item.icon}`}
                                alt=""
                            />
                        </span>
                        {' '}
                        <span>
                            {item.name}
                        </span>
                    </CustomSelect.Option>
                ))}
            </CustomSelect.Options>
        </CustomSelect.Select>
    )
}

export default CountryDataSelect
