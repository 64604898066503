import React, { useEffect, useMemo } from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { API_HOST, IMAGE_RESOURCE_PATH } from 'config/api'
import { Card } from 'components'
import { scrollTop } from 'utils/helpers'
import style from './ItemFolder.module.css'

type ItemFolderPropType = {
    data: IReport
    classes?: string
    url?: string
    target?: '_blank'
    onClick?: () => void
}

const DEFAULT_IMAGE = `${IMAGE_RESOURCE_PATH}/report_placeholder_picture`

const getImage = (report: IReport) => {
    switch (report.type) {
        case 'folder':
        case 'folder_narrow':
        case 'folder_grid':
            return report.icon ? `${API_HOST}${report.icon}` : DEFAULT_IMAGE
        default:
            return DEFAULT_IMAGE
    }
}

const getBackground = (report: IReport) => {
    if (report.icon) {
        return report?.iconBackgroundColor
    }
    if (report.picture) {
        return report?.backgroundColor
    }
    return ''
}

const getContent = (report: IReport) => {
    switch (report.type) {
        case 'folder':
            return report.name || report.sub || ''
        case 'folder_narrow':
        case 'folder_grid':
        case 'string':
            return report.name || ''
        default:
            return ''
    }
}

const ItemFolder: React.FC<ItemFolderPropType> = ({
    data,
    classes,
    url,
    target,
    onClick = () => {},
}) => {
    const image = useMemo(() => getImage(data), [data])
    const background = useMemo(() => getBackground(data), [data])
    const content = useMemo(() => getContent(data), [data])

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <Card.Item
            classes={cn(style.folder, classes, { [style.folder_clickable]: data.hasChilds })}
            url={url}
            target={target}
            onClick={onClick}
        >
            {image && (
                <Card.Image
                    src={image}
                    backgroundColor={background}
                    width={data.width || 400}
                    height={data.height || 300}
                />
            )}

            {content && (
                <Card.Body classes={style.content}>
                    {content}
                </Card.Body>
            )}
        </Card.Item>
    )
}

export default ItemFolder
