import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IFriend } from 'interfaces'
import { MessengerNothing } from 'containers/Messenger/components'
import { useFetchInfiniteSearchFriendsChat } from 'containers/User/hooks'
import { Loader, InfiniteScroll } from 'components'
import style from './MessengerFriendsSearch.module.css'

type MessengerFriendsSearchPropType = {
    classes?: string
    text: string
    keepPrevData?: boolean
    limit?: number
    offset?: number
    render: (data: IFriend) => void
}

const MessengerFriendsSearch: React.FC<MessengerFriendsSearchPropType> = ({
    classes,
    text,
    keepPrevData,
    limit = 20,
    offset = 0,
    render,
}) => {
    const { t } = useTranslation()

    const [searchText, setSearchText] = useState(text)
    const [isHideLoaderSearchFriends, setIsHideLoaderSearchFriends] = useState(false)

    const {
        isInitialLoading: isLoadingSearchFriends,
        isFetching: isFetchingSearchFriends,
        data: dataSearchFriends,
        fetchNextPage: fetchNextPageSearchFriends,
    } = useFetchInfiniteSearchFriendsChat({
        name: searchText,
        limit,
        offset,
    }, {
        enabled: !!searchText,
        keepPreviousData: keepPrevData,
    })

    const handlerLoadSearchFriends = () => {
        if (!isFetchingSearchFriends) {
            fetchNextPageSearchFriends()
        }
    }

    useEffect(() => {
        setSearchText(text)

        if (isHideLoaderSearchFriends) {
            setIsHideLoaderSearchFriends(false)
        }
    }, [text])

    useEffect(() => {
        if (dataSearchFriends) {
            const { pages } = dataSearchFriends
            const pagesLen = pages.length

            const lastListLen = pagesLen ? pages[pagesLen - 1].results.length : 0

            if (lastListLen < limit) {
                setIsHideLoaderSearchFriends(true)
            }
        }
    }, [dataSearchFriends])

    return (
        <div className={cn(style.list, classes)}>
            {isLoadingSearchFriends && (
                <Loader position="top" size="medium" />
            )}

            {!isLoadingSearchFriends
                && dataSearchFriends
                && dataSearchFriends.pages.length
                && !dataSearchFriends.pages[0].results.length
            && (
                <MessengerNothing
                    nothingText={`${t('nothing_found')}\n${t('Please try again')}`}
                />
            )}

            <InfiniteScroll
                isActive={!!dataSearchFriends}
                isHideLoader={isHideLoaderSearchFriends}
                onLoad={handlerLoadSearchFriends}
            >
                {dataSearchFriends?.pages.map((page, i) => (
                    // eslint-disable-next-line
                    <React.Fragment key={i}>
                        {page.results.map((item) => render(item))}
                    </React.Fragment>
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default MessengerFriendsSearch
