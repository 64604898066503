import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IDeliveryAddress } from 'interfaces'
import { StoreOrderDeliveryItemPropType } from 'containers/StoreOrderMake/components/StoreOrderDeliveryItem/StoreOrderDeliveryItem'
import { BREAKPOINTS } from 'config/app'
import { Button } from 'components'
import { useWindowResize } from 'hooks'
import { StoreOrderDeliveryItem } from 'containers/StoreOrderMake/components'

import styleColumns from 'styles/modules/columns.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './StoreOrderDelivery.module.css'

type StoreOrderDeliveryPropType = {
    items: IDeliveryAddress[]
    error?: string | boolean
    onAdd: () => void
} & Pick<StoreOrderDeliveryItemPropType, 'activeId' | 'onSelect' | 'onEdit' | 'onDelete'>

const StoreOrderDelivery: React.FC<StoreOrderDeliveryPropType> = ({
    items,
    activeId,
    error,
    onSelect,
    onAdd,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    return (
        <>
            <div className={styleForm.dangerText}>
                {error}
            </div>
            <div className={styleColumns.columns}>
                {items?.map((item, index) => (
                    <StoreOrderDeliveryItem
                        classes={cn(
                            styleColumns.column,
                            windowWidth >= BREAKPOINTS.tablet ? styleColumns.column_1_2 : '',
                        )}
                        data={item}
                        activeId={activeId}
                        index={index}
                        onSelect={onSelect}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        key={item.id}
                    />
                ))}

                <Button
                    classes={cn(
                        styleColumns.column,
                        windowWidth >= BREAKPOINTS.tablet ? styleColumns.column_1_2 : '',
                        style.addressNew,
                    )}
                    styleType="transparent"
                    onClick={onAdd}
                >
                    <span className={style.iconAdd} />
                    <span className={style.addressNewText}>
                        {t('add_address')}
                    </span>
                </Button>
            </div>
        </>
    )
}

export default StoreOrderDelivery
