import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPayoutOptionsProps,
    TPayoutOptionsResponse,
} from 'services/PayoutService'
import { QueryCacheKeys } from 'enums'
import { PayoutService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPayoutOptionsProps
export type TQueryFnOpts = UseQueryOptions<
    TPayoutOptionsResponse,
    TRequestError<TFetchFnParams>,
    TPayoutOptionsResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.payoutOption

/**
 * Hook API payout wallet list
 */
export default function useFetchPayoutOptions(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutOptions(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
