import React from 'react'
import cn from 'classnames'

import style from './SideBarContainer.module.css'

type SideBarContainerPropType = {
    classes?: string
    position: 'left' | 'right'
}

const SideBarContainer: React.FC<SideBarContainerPropType> = ({
    children,
    classes,
    position,
}) => {
    return (
        <aside className={cn(style.sidebar, style[`sidebar_${position}`], classes)}>
            {children}
        </aside>
    )
}

export default SideBarContainer
