import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Card, Avatar } from 'components'
import style from './UserListItem.module.css'

type UserListItemPropType = {
    classes?: string
    classesBody?: string
    name: string
    surname: string
    online: boolean
    title?: string
    photo?: string
    url?: string
    avatarPos?: 'top' | 'middle' | 'bottom'
    avatarSize?: number
    avatar?: React.ReactNode
    content?: React.ReactNode
}

const UserListItem: React.FC<UserListItemPropType> = ({
    children,
    classes,
    classesBody,
    name = '',
    surname = '',
    online,
    title,
    photo,
    url,
    avatarPos = 'top',
    avatarSize = 60,
    avatar,
    content,
}) => {
    const { t } = useTranslation()

    return (
        <Card.Item
            classes={cn(
                style.item, classes,
                { [style.item_highlight]: !!url },
            )}
            url={url}
        >
            <Card.Body classes={cn(style.body, classesBody)}>
                <div className={cn(style.avatar, style[`avatar_${avatarPos}`])}>
                    <div className={style.avatarImageWrap}>
                        {online && (
                            <span className={style.online} title={t('user_profile_online')} />
                        )}
                        {avatar && avatar}
                        {!avatar && (
                            <Avatar
                                isDoubleSize
                                src={photo}
                                name={name}
                                surname={surname}
                                width={2 * avatarSize}
                                height={2 * avatarSize}
                            />
                        )}
                    </div>
                </div>
                {content && content}
                {!content && (
                    <div className={style.content}>
                        <div className={style.name}>
                            {title || `${name} ${surname}`}
                        </div>
                        {children}
                    </div>
                )}
            </Card.Body>
        </Card.Item>
    )
}

export default UserListItem
