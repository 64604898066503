import {
    ITransaction,
} from 'interfaces'
import {
    FETCH_PAYOUT_HISTORY,
} from 'reducers/types'
import {
    fetchPayoutHistoryActionType,
} from './wallet-actions-type'

type transactionActionType = fetchPayoutHistoryActionType

export const transactionReducer = (state: ITransaction[] = [], action: transactionActionType): ITransaction[] => {
    switch (action.type) {
        case FETCH_PAYOUT_HISTORY: {
            const { type } = action.meta
            return type === 'add' ? [...state, ...action.payload] : action.payload
        }

        default:
            return state
    }
}
