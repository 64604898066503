import { APP_URL } from 'config/app'

/**
 * Hook app variables
 */
export default function useApp() {
    return {
        mainPrivateRoute: APP_URL.lenta,
    }
}
