import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

import { useOutsideClick } from 'hooks'
import style from './MenuContext.module.css'

type MenuContextPropType = {
    isOpen: boolean
    isAutoClose?: boolean
    x: number
    y: number
    onClose: () => void
}

/**
 * Context menu by right click on element
 */
const MenuContext: React.FC<MenuContextPropType> = ({
    children,
    isOpen,
    isAutoClose = true,
    x,
    y,
    onClose,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    // TODO
    // x + sizeMenu > window.innerWidth ? x = window.innerWidth - sizeMenu : x

    const handlerClick = () => {
        if (isAutoClose) {
            onClose()
        }
    }

    useOutsideClick(ref, () => {
        onClose()
    })

    return createPortal(
        isOpen && (
            // eslint-disable-next-line
            <div
                className={style.menu}
                style={{ left: x, top: y }}
                onClick={handlerClick}
                ref={ref}
            >
                {children}
            </div>
        ),
        document.body,
    )
}

export default MenuContext
