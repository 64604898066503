import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    SetDataOptions,
    InfiniteData,
    Updater,
    useQueryClient,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TChatMicroServiceError,
    TConversationListProps,
    TConversationListResponse,
} from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TConversationListProps
export type TFetchFnResponse = TConversationListResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TInfiniteFnParams = TConversationListProps
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

const key = QueryCacheKeys.conversationList

/**
 * Хук API получение списка чатов
 */
export default function useFetchConversationList(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchConversationList(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteConversationList(initParams: TInfiniteFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return ChatMicroService.fetchConversationList(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, page: pages.length + 1 }
        },
        ...opts,
    })
}

export function useInvalidateConversationList() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = (
        params: TFetchFnParams | TInfiniteFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

/**
 * Operation with cached data
 */
export function useSetQueryDataConversationList() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    /** Update cached data of multiple queries */
    const setQueryData = (
        params: TFetchFnParams | TInfiniteFnParams,
        updater: Updater<
            TFetchFnResponse | InfiniteData<TFetchFnResponse> | undefined,
            TFetchFnResponse | InfiniteData<TFetchFnResponse> | undefined
        >,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueriesData([`${key}-${user.id}`, params], updater, options)
    }

    return {
        setQueryData,
    }
}
