import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPostFile } from 'interfaces'
import { SvgResource, Button } from 'components'
import { getFileTypeByMimeType } from 'utils/helpers'
import style from './PostAttachItem.module.css'

type PostAttachItemPropType = {
    file: IPostFile
    isShowDesc?: boolean
    onClick: (file: IPostFile, type: string) => void
}

const PostAttachItem: React.FC<PostAttachItemPropType> = ({ file, isShowDesc = false, onClick = () => {} }) => {
    const { t } = useTranslation()

    const { type } = useMemo(() => getFileTypeByMimeType(file.type), [file])
    const desc = useMemo(() => getDesc(type), [type])
    const iconKey = useMemo(() => getIconKey(type), [type])

    const handlerClick = () => {
        onClick(file, type)
    }

    const innerTpl = (
        <>
            <span className={style.icon}>
                <SvgResource
                    resourceKey={iconKey}
                    width={16}
                    height={12}
                />
            </span>
            <span className={style.text}>
                {desc}
            </span>
        </>
    )

    function getDesc(attachType: string) {
        switch (attachType) {
            case 'image':
                return t('post_attach_image')
            case 'video':
                return t('post_attach_video')
            case 'audio':
                return t('post_attach_audio')
            case 'text':
            case 'application':
                return t('post_attach_doc')
            default:
                return ''
        }
    }

    function getIconKey(attachType: string) {
        switch (attachType) {
            case 'image':
                return 'ic_attach_img_svg'
            case 'video':
                return 'ic_attach_video_svg'
            case 'audio':
                return 'ic_attach_audio_svg'
            case 'text':
            case 'application':
                return 'ic_attach_doc_svg'
            default:
                return ''
        }
    }

    return (
        <div className={cn(style.attach, isShowDesc ? style.attach_row : style.attach_column)}>
            {type === 'image' || type === 'video' ? (
                <Button classes={style.button} styleType="text" onClick={handlerClick}>
                    {innerTpl}
                </Button>
            ) : (
                <a className={style.link} href={file.src} target="_blank" rel="noreferrer" download>
                    {innerTpl}
                </a>
            )}

            {isShowDesc && (
                <span className={style.desc}>
                    {file.title}
                </span>
            )}
        </div>
    )
}

export default PostAttachItem
