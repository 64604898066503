import React from 'react'
import cn from 'classnames'

import { SpinnerPropType } from 'components/Spinner/Spinner'
import { Spinner } from 'components'
import style from './Loader.module.css'

type LoaderPropType = SpinnerPropType

/**
 * Page spinner on load
 * @param classes
 * @param size
 * @param position
 */
const Loader: React.FC<LoaderPropType> = ({ classes, size = 'medium', position = 'center' }) => {
    return (
        <div className={cn(style.loader, classes)}>
            <Spinner size={size} position={position} />
        </div>
    )
}

export default Loader
