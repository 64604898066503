import React from 'react'

import style from './Switch.module.css'

type SwitchPropType = {
    name: string
    checked?: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch: React.FC<SwitchPropType> = ({
    name,
    checked,
    onChange,
}) => {
    return (
        <label className={style.label}>
            <input
                className={style.input}
                type="checkbox"
                name={name}
                defaultChecked={!!checked}
                onChange={onChange}
            />
            <span className={style.switch} />
        </label>
    )
}

export default Switch
