import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import style from './WalletWallet.module.css'

type WalletPayoutCommonPropType = {
    classes?: string
    amount: string
    onClick: () => void
}

const WalletWallet: React.FC<WalletPayoutCommonPropType> = ({ classes, amount, onClick }) => {
    const { t } = useTranslation()

    return (
        <div
            className={cn(style.wallet, classes)}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className={style.title}>
                {t('wallet')}
            </div>
            <div className={style.amount}>
                {amount}
            </div>
            <div className={style.desc}>
                {t('payout_total_funds')}
            </div>
        </div>
    )
}

export default WalletWallet
