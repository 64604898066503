import React from 'react'

import { Avatar, SvgResource } from 'components'
// import style from './TicketMessageAvatar.module.css'

type TicketMessageAvatarPropType = {
    classes?: string
    data?: {
        name?: string
        surname?: string
        photo?: string
    }
}

const TicketMessageAvatar: React.FC<TicketMessageAvatarPropType> = ({ classes, data }) => {
    return (
        data ? (
            <Avatar
                classes={classes}
                name={data.name ?? ''}
                surname={data.surname ?? ''}
                src={data.photo}
                width={48}
                height={48}
            />
        ) : (
            <SvgResource
                classes={classes}
                resourceKey="avatar_support_bot"
                width={48}
                height={48}
            />
        )
        /* TODO */
        /* <div className={style.avatarWrap}>
            {data ? (
                <Avatar
                    classes={style.userAvatar}
                    name={data.name ?? ''}
                    surname={data.surname ?? ''}
                    src={data.photo}
                    width={48}
                    height={48}
                />
            ) : (
                <>
                    <SvgResource
                        classes={style.botAvatar}
                        resourceKey="avatar_support_bot"
                        width={48}
                        height={48}
                    />
                    <div className={style.botAvatarName}>
                        {t('support_title')}
                    </div>
                </>
            )}
        </div> */
    )
}

export default TicketMessageAvatar
