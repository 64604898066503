import React, { useMemo, useState, useRef } from 'react'
import { useHistory, NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMlmRank } from 'interfaces'
import { AppScheme, AppTitle } from 'enums'
import { APP_URL, SLIDER_ITEM_CLASS, BREAKPOINTS } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    CardContainer,
    Block,
} from 'layout'
import {
    Slider,
    Card,
    Loader,
    ErrorMsg,
    SvgResource,
    InfoBlock,
    Tabs,
} from 'components'
import { useFetchMlmReports } from 'containers/ReportsFreedom/hooks'
import { useFetchMlmPvBusinessPack, useFetchMlmRankStats } from 'containers/User/hooks'
import { useWindowResize } from 'hooks'
import { dateTimeFormat, priceFormat } from 'utils/helpers'
import {
    ReportsMlmActivity,
    ReportsMlmRank,
    ReportsMlmChart,
    ReportsMlmMembers,
} from './components'
import style from './Reports.module.css'

const tabs = [{
    id: 1,
    name: AppTitle.pv,
    key: AppScheme.pv,
}, {
    id: 2,
    name: AppTitle.cc,
    key: AppScheme.cc,
}]

const getReportChartUrl = (id: string) => {
    switch (id) {
        case 'clients':
            return APP_URL.reportsFreedomClients
        case 'active-partners':
            return APP_URL.reportsFreedomActivePartners
        case 'turnover':
            return APP_URL.reportsFreedomTurnover
        case 'income':
            return APP_URL.reportsFreedomIncome
        default:
            return ''
    }
}

const ReportsV2: React.FC = () => {
    const { state }: { state?: { type: keyof typeof AppScheme} } = useLocation()
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [type, setType] = useState<keyof typeof AppScheme>(state?.type ?? AppScheme.pv)

    const typeRef = useRef(type)

    const {
        isInitialLoading: isLoading,
        data: dataReports,
        error: reportsError,
    } = useFetchMlmReports({ type: typeRef.current })

    const {
        data: dataMlmPvBusinessPack,
    } = useFetchMlmPvBusinessPack({ retry: 0 }) // FIXME if error === 404 => retry = 0

    const {
        data: dataMlmRankStats,
    } = useFetchMlmRankStats({ enabled: !!dataReports })

    const activeTabId: number = useMemo(() => (type === AppScheme.pv ? 1 : 2), [type])

    const privilegeActivityRestValue = useMemo(() => {
        if (dataReports && !dataReports.activity.isCompleted) {
            const { activity: { restAmount, restCurrency } } = dataReports
            const currencyOptions = {
                isoCode: restCurrency.code,
                symbol: restCurrency.show_code,
                leftSymbolPlacement: restCurrency.left_currency_symbol_placement,
            }

            return !dataReports.activity.isCompleted
                ? priceFormat(restAmount, currencyOptions)
                : undefined
        }
        return undefined
    }, [dataReports])

    const isSingleSlide = useMemo(() => {
        return typeRef.current !== AppScheme.pv || !dataMlmPvBusinessPack
    }, [])

    const handlerChangeTab = (id: number) => {
        const newTab = tabs.find((item) => item.id === id)

        if (id !== activeTabId && newTab) {
            setType(newTab.key)
            typeRef.current = newTab.key
            // history.replace()
        }
    }

    const handlerClickMlmRankMembers = (rank?: IMlmRank) => {
        history.push(APP_URL.reportsRankUsers, { type: typeRef.current, rank })
    }

    const handlerClickReportsChart = (id: string) => {
        const url = getReportChartUrl(id)

        if (url) {
            history.push(url, { type: typeRef.current })
        }
    }

    return (
        <>
            <ContentContainer size="half">
                <InfoBlock classes={style.nav}>
                    <InfoBlock.Body classes={style.navBody}>
                        <div className={style.navTitle}>
                            {t('reports')}
                        </div>
                        <NavLink title={t('reports')} to={APP_URL.reportsAccounts}>
                            <SvgResource
                                resourceKey="ic_tab_report_svg"
                                width={24}
                                height={24}
                            />
                        </NavLink>
                    </InfoBlock.Body>
                    <InfoBlock.Footer classes={style.footer}>
                        <Tabs
                            classes={style.tabs}
                            classesItem={style.tabsItem}
                            items={tabs}
                            active={activeTabId}
                            onChange={handlerChangeTab}
                        />
                    </InfoBlock.Footer>
                </InfoBlock>

                {isLoading && (
                    <Loader />
                )}

                {!isLoading && dataReports && (
                    <>
                        <div className={style.sliderWrap}>
                            <Slider
                                arrows
                                classes={cn(
                                    style.slider,
                                    { [style.slider_gapBottom]: isSingleSlide },
                                )}
                            >
                                <div
                                    className={cn(SLIDER_ITEM_CLASS, style.sliderItem)}
                                    key={dataReports.rank.current.id}
                                >
                                    <Block classes={cn(style.block, style.blockSlider)}>
                                        <ReportsMlmActivity
                                            isImageSprite
                                            type={typeRef.current}
                                            title={
                                                dataReports.activity.isCompleted
                                                    ? t('mlm_privilege_activity_completed')
                                                    : t('mlm_privilege_activity_uncompleted')
                                            }
                                            subtitle={(
                                                !dataReports.activity.isCompleted
                                                    ? (
                                                        <>
                                                            {t('mlm_privilege_activity_rest')}
                                                            {' '}
                                                            <span className={style.restValue}>
                                                                {privilegeActivityRestValue}
                                                            </span>
                                                        </>
                                                    ) : undefined
                                            )}
                                            imageKey={
                                                dataReports.activity.isCompleted
                                                    ? 'activity_completed_png'
                                                    : 'activity_uncompleted_png'
                                            }
                                            dataText={t('mlm_privilege_personal_turnover')}
                                            dataValue={dataReports.activity.turnover}
                                            options={{ subtitle: { danger: !dataReports.activity.isCompleted } }}
                                        />
                                    </Block>
                                </div>
                                {typeRef.current === 'pv' && dataMlmPvBusinessPack && (
                                    <div
                                        className={cn(SLIDER_ITEM_CLASS, style.sliderItem)}
                                        key={dataMlmPvBusinessPack.valid_to_date}
                                    >
                                        <Block classes={cn(style.block, style.blockSlider)}>
                                            <ReportsMlmActivity
                                                isImageSprite={false}
                                                type={typeRef.current}
                                                title={
                                                    dataMlmPvBusinessPack.is_valid
                                                        ? t('mlm_privilege_business_pack_active')
                                                        : t('mlm_privilege_business_pack_not_active')
                                                }
                                                imageKey={
                                                    dataMlmPvBusinessPack.is_valid
                                                        ? 'business_pack_active_png'
                                                        : 'business_pack_no_active_png'
                                                }
                                                dataText={t('mlm_privilege_valid_until')}
                                                dataValue={dateTimeFormat(dataMlmPvBusinessPack.valid_to_date)}
                                                options={{
                                                    title: {
                                                        success: dataMlmPvBusinessPack.is_valid,
                                                        danger: !dataMlmPvBusinessPack.is_valid,
                                                    },
                                                }}
                                            />
                                        </Block>
                                    </div>
                                )}
                            </Slider>
                        </div>

                        <Slider
                            arrows
                            classes={cn(
                                style.slider,
                                { [style.slider_gapBottom]: dataReports.rank.progressList.length === 1 },
                            )}
                        >
                            {dataReports.rank.progressList.map((item) => (
                                <NavLink
                                    className={cn(SLIDER_ITEM_CLASS, style.sliderItem, style.sliderItem_link)}
                                    to={{
                                        pathname: APP_URL.reportsFreedomRanks,
                                        state: { type: typeRef.current },
                                    }}
                                    key={item.name}
                                >
                                    <Block classes={cn(style.block, style.blockSlider)}>
                                        <ReportsMlmRank
                                            type={typeRef.current}
                                            current={dataReports.rank.current}
                                            next={dataReports.rank.next}
                                            progress={item}
                                        />
                                    </Block>
                                </NavLink>
                            ))}
                        </Slider>

                        <CardContainer classes={style.charts}>
                            {dataReports.blockList.map((item) => (
                                <Card.Item
                                    classes={cn(style.card, style.card_link)}
                                    key={item.id}
                                    onClick={() => handlerClickReportsChart(item.id)}
                                >
                                    <Block classes={cn(style.block, style.blockCard)}>
                                        <ReportsMlmChart type={typeRef.current} data={item} />
                                    </Block>
                                </Card.Item>
                            ))}
                        </CardContainer>

                        {windowWidth < BREAKPOINTS.desktop && (
                            !isLoading && dataMlmRankStats && (
                                <Block classes={cn(style.stats, style.block)}>
                                    <ReportsMlmMembers
                                        type={typeRef.current}
                                        data={dataMlmRankStats}
                                        options={{ options: { aspectRatio: 3 } }}
                                        onClickMembers={handlerClickMlmRankMembers}
                                        onClickAllMembers={handlerClickMlmRankMembers}
                                    />
                                </Block>
                            )
                        )}
                    </>
                )}

                {reportsError && (
                    <ErrorMsg error={reportsError} />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    {!isLoading && dataMlmRankStats && (
                        <Block classes={cn(style.stats, style.block)}>
                            <ReportsMlmMembers
                                type={typeRef.current}
                                data={dataMlmRankStats}
                                options={{ options: { aspectRatio: 2 } }}
                                onClickMembers={handlerClickMlmRankMembers}
                                onClickAllMembers={handlerClickMlmRankMembers}
                            />
                        </Block>
                    )}
                </SideBarContainer>
            )}
        </>
    )
}

export default ReportsV2
