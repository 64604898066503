import React from 'react'
import cn from 'classnames'

import { AppScheme } from 'enums'
import { ImgResource } from 'components'
import { useImgResource } from 'hooks'
import style from './ReportsMlmActivity.module.css'

type ReportsMlmActivityPropType = {
    type: keyof typeof AppScheme
    title: string
    isImageSprite: boolean
    imageKey: string
    dataText: string
    dataValue: string
    subtitle?: string | React.ReactNode
    options?: {
        title?: {
            success?: boolean
            danger?: boolean
        }
        subtitle?: {
            success?: boolean
            danger?: boolean
        }
    }
}

const ReportsMlmActivity: React.FC<ReportsMlmActivityPropType> = ({
    type,
    title,
    subtitle,
    isImageSprite,
    imageKey,
    dataText,
    dataValue,
    options = {},
}) => {
    const { url } = useImgResource(imageKey)

    return (
        <div className={style.wrap}>
            <div className={style.info}>
                {isImageSprite ? (
                    <div
                        className={cn(style.infoImage, {
                            [style.infoImage_pv]: type === AppScheme.pv,
                            [style.infoImage_cc]: type === AppScheme.cc,
                        })}
                        style={{
                            backgroundImage: `url(${url})`,
                        }}
                    />
                ) : (
                    <ImgResource
                        classes={style.infoImage}
                        resourceKey={imageKey}
                        isShowLoader={false}
                        width={120}
                        height={90}
                    />
                )}
                <div className={cn(style.infoTitle, {
                    [style.dataTitle_success]: options.title?.success,
                    [style.dataTitle_danger]: options.title?.danger,
                })}
                >
                    {title}
                </div>
                {subtitle && (
                    <div className={cn(style.infoSubtitle, {
                        [style.dataSubtitle_success]: options.subtitle?.success,
                        [style.dataSubtitle_danger]: options.subtitle?.danger,
                    })}
                    >
                        {subtitle}
                    </div>
                )}
            </div>
            <div className={style.data}>
                <div className={style.dataText}>
                    {dataText}
                </div>
                <div className={style.dataValue}>
                    {dataValue}
                </div>
            </div>
        </div>
    )
}

export default ReportsMlmActivity
