import React from 'react'
import cn from 'classnames'

import style from './Chevron.module.css'

type ChevronPropType = {
    type?: 'down' | 'up' | 'left' | 'right'
    size?: number
    color?: string
    classes?: string
}

/**
 * Chevron arrow
 * @param type
 * @param size
 * @param color
 * @param classes
 */
const Chevron: React.FC<ChevronPropType> = ({
    type = 'down',
    size,
    color,
    classes,
}) => {
    return (
        <span
            className={cn(style.chevron, style[type], classes)}
            style={{ borderColor: color, width: size, height: size }}
        />
    )
}

export default Chevron
