import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ICalendarPost } from 'interfaces'
import { APP_URL } from 'config/app'
import { Button, SvgResource } from 'components'
import { dateTimeFormat, getItemFromArray } from 'utils/helpers'
import style from './CalendarDateEventListItem.module.css'

type CalendarDateEventListItemPropType = {
    data: ICalendarPost
    colors: string[]
    isOwn: boolean
    onClickEdit: (data: ICalendarPost) => void
    onClickDelete: (data: ICalendarPost) => void
}

const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }

const CalendarDateEventListItem: React.FC<CalendarDateEventListItemPropType> = ({
    data,
    colors,
    isOwn,
    onClickEdit,
    onClickDelete,
}) => {
    const { t, i18n } = useTranslation()

    const date = useMemo(() => {
        if (data) {
            const timeStart = dateTimeFormat(data.calendar_date_from, i18n.language, TIME_OPTIONS)
            const timeEnd = dateTimeFormat(data.calendar_date_to, i18n.language, TIME_OPTIONS)

            return `${timeStart}-${timeEnd}`
        }

        return ''
    }, [data])

    const color = useMemo(() => {
        return getItemFromArray(colors, data.id)
    }, [data])

    const url = useMemo(() => {
        return data ? APP_URL.post.replace(':id', String(data.id)) : ''
    }, [data])

    const handlerClickEdit = () => {
        onClickEdit(data)
    }

    const handlerClickDelete = () => {
        onClickDelete(data)
    }

    return (
        <div className={style.item}>
            <NavLink className={style.body} to={url}>
                <div className={style.date}>
                    {date}
                </div>
                <div className={style.title} style={{ color }}>
                    {data.title}
                </div>
            </NavLink>
            {isOwn && (
                <div className={style.actions}>
                    <Button
                        classes={style.control}
                        styleType="transparent"
                        title={t('change')}
                        onClick={handlerClickEdit}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconEdit}
                            resourceKey="ic_edit_svg"
                            width={24}
                            height={28}
                        />
                    </Button>
                    <Button
                        classes={style.control}
                        styleType="transparent"
                        title={t('delete')}
                        onClick={handlerClickDelete}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconTrash}
                            resourceKey="trash_can_svg"
                            width={24}
                            height={28}
                        />
                    </Button>
                </div>
            )}
        </div>
    )
}

export default CalendarDateEventListItem
