import React, { useMemo } from 'react'
import cn from 'classnames'

import { IUserAccountFilter } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Card } from 'components'
import style from './ItemAccount.module.css'

type ItemAccountPropType = {
    data: IUserAccountFilter
    classes?: string
}

const ItemAccount: React.FC<ItemAccountPropType> = ({ data, classes }) => {
    const userUrl = useMemo(() => APP_URL.profile.replace(':id', String(data.user.id)), [data])

    return (
        <Card.Item classes={cn(style.account, classes)} url={userUrl}>
            <Card.Body classes={style.body}>
                <Avatar
                    src={data.photo}
                    name={data.fio}
                    width={60}
                    height={60}
                />

                <div className={style.content}>
                    <div className={style.parent}>
                        {data.parent_name}
                    </div>
                    <div className={style.name}>
                        {data.fio}
                    </div>
                    <div className={style.region}>
                        {data.region}
                    </div>
                    <div>
                        {data.status_icon && (
                            <img src={data.status_icon} alt="" />
                        )}
                        {data.status && (
                            <span className={style.status}>
                                {data.status}
                            </span>
                        )}
                        <span className={style.value}>
                            {data.value}
                        </span>
                    </div>
                </div>
            </Card.Body>
        </Card.Item>
    )
}

export default ItemAccount
