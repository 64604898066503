import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TUserDataRecord } from 'containers/User/hooks/useFetchUsersData'
import { FormDataType } from 'forms/FriendFilterForm/FriendFilterForm'
import { TFriendsRequestConfirmProps, TFriendsRequestRejectProps, TFriendsSearchProps } from 'services/UserService'
import { CardSize } from 'enums'
import { BREAKPOINTS } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    CardContainer,
    Block,
} from 'layout'
import { FriendsSearch, FriendsUserCard, FriendsRequests } from 'containers/Friends/components'
import * as userSelectors from 'containers/User/user-selectors'
import { fetchCounts } from 'containers/User/user-actions'
import {
    useFetchInfiniteProfileFriends,
    useFetchInfiniteSearchFriends,
    useFetchFriendsRequest,
    useMutationFriendsRequest,
    useInvalidateFriendsRequest,
    useFetchUsersData,
    useCacheUsersData,
} from 'containers/User/hooks'
import {
    PageHeader,
    Button,
    InfiniteScroll,
    Modal,
    Loader,
    Icon,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { FriendFilterAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import {
    scrollTop,
    showAlertNotify,
    numberFormat,
    parseTpl,
} from 'utils/helpers'
import style from './Friends.module.css'

const LIMIT_FRIENDS = 12
const LIMIT_FRIENDS_REQUEST = 5
const OFFSET_FRIENDS = 0
const OFFSET_FRIENDS_REQUEST = 0

const Friends: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)

    const [usersDataIds, setUsersDataIds] = useState('')
    const [usersData, setUsersData] = useState<TUserDataRecord>({})
    const [friendsSearchText, setFriendsSearchText] = useState<string>('')
    const [friendsSearchFilters, setFriendsSearchFilters] = useState<TFriendsSearchProps>()
    const [isUpdatingFriendsRequestId, setIsUpdatingFriendsRequestId] = useState(0)
    const [isHideLoaderFriends, setIsHideLoaderFriends] = useState(false)
    const [isHideLoaderSearchFriends, setIsHideLoaderSearchFriends] = useState(false)
    const [isShowModalSearch, setIsShowModalSearch] = useState(false)
    const [isShowModalFilters, setIsShowModalFilters] = useState(false)

    const {
        isInitialLoading: isLoadingFriends,
        data: dataFriends,
        error: errorFriends,
        fetchNextPage: fetchNextPageFriends,
        refetch: refetchFriends,
    } = useFetchInfiniteProfileFriends({
        userId: user.id,
        limit: LIMIT_FRIENDS,
        offset: OFFSET_FRIENDS,
    }, {
        enabled: !!user,
        staleTime: 5 * 60 * 1000, // 5m
        cacheTime: 5 * 60 * 1000, // 5m
    })

    const { data: dataFriendsRequest } = useFetchFriendsRequest({
        limit: LIMIT_FRIENDS_REQUEST,
        offset: OFFSET_FRIENDS_REQUEST,
    }, {
        refetchInterval: (res) => (res ? 30 * 1000 : 60 * 1000), // 30s | 1m
    })

    const {
        isInitialLoading: isLoadingSearchFriends,
        isFetching: isFetchingSearchFriends,
        data: dataSearchFriends,
        fetchNextPage: fetchNextPageSearchFriends,
    } = useFetchInfiniteSearchFriends({
        limit: LIMIT_FRIENDS,
        offset: OFFSET_FRIENDS,
        q: friendsSearchText,
        ...friendsSearchFilters,
    }, {
        enabled: !!friendsSearchText || !!friendsSearchFilters,
    })

    const { data: dataUsersData } = useFetchUsersData({
        users: usersDataIds,
        is_mutual_friends: 1,
        limit_friends: 3,
        is_checks: 1,
        is_subs_community: 1,
    }, {
        enabled: !!usersDataIds,
        staleTime: 5 * 60 * 1000, // 5m
        cacheTime: 5 * 60 * 1000, // 5m
    })

    const { invalidate: invalidateFriendsRequest } = useInvalidateFriendsRequest({}, {
        exact: false,
        refetchType: 'all',
    })

    const { set: setCacheUsersData } = useCacheUsersData()
    const { confirm: confirmFriendsRequest, reject: rejectFriendsRequest } = useMutationFriendsRequest()

    const clientsText = useMemo(() => {
        if (dataSearchFriends?.pages.length) {
            return parseTpl(t('found_people_number'), { d: '' }, { prefix: '%', suffix: '%' })
        }

        return t('friends')
    }, [dataFriends, dataSearchFriends])

    const clientsCount = useMemo(() => {
        if (dataSearchFriends?.pages.length) {
            return numberFormat(dataSearchFriends.pages[0].count)
        }
        if (dataFriends?.pages.length) {
            return numberFormat(dataFriends.pages[0].total)
        }
        return undefined
    }, [dataFriends, dataSearchFriends])

    const isSetFriendsSearchFilters = useMemo(() => {
        return friendsSearchFilters && Object.values(friendsSearchFilters).some((item) => item !== undefined)
    }, [friendsSearchFilters])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerLoadFriends = () => {
        if (!isLoadingFriends) {
            fetchNextPageFriends()
        }
    }

    const handlerLoadSearchFriends = () => {
        if (!isLoadingSearchFriends) {
            fetchNextPageSearchFriends()
        }
    }

    const handlerFriendRequestConfirm = (userId?: number) => {
        friendRequestConfirmAction({ userId })
    }

    const handlerFriendRequestReject = (userId: number) => {
        friendRequestRejectAction({ userId })
    }

    const handlerSearch = (value: string) => {
        setFriendsSearchText(value)
        setIsShowModalSearch(false)
    }

    const handlerResetSearch = () => {
        setFriendsSearchText('')
    }

    const handlerFilters = (value: FormDataType) => {
        setFriendsSearchFilters(value)
        setIsShowModalFilters(false)
        setIsShowModalSearch(false)
    }

    const handlerResetFilters = () => {
        setFriendsSearchFilters(undefined)
    }

    function friendRequestConfirmAction({ userId }: TFriendsRequestConfirmProps) {
        if (userId) {
            setIsUpdatingFriendsRequestId(userId)
        }

        confirmFriendsRequest.mutate({ userId }, {
            onSuccess: () => {
                invalidateFriendsRequest().finally(() => {
                    setIsUpdatingFriendsRequestId(0)
                })
                refetchFriends()
                dispatch(fetchCounts())
            },
            onError: ([errorText]) => {
                setIsUpdatingFriendsRequestId(0)
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function friendRequestRejectAction({ userId }: TFriendsRequestRejectProps) {
        setIsUpdatingFriendsRequestId(userId)
        rejectFriendsRequest.mutate({ userId }, {
            onSuccess: () => {
                invalidateFriendsRequest().finally(() => {
                    setIsUpdatingFriendsRequestId(0)
                })
                dispatch(fetchCounts())
            },
            onError: ([errorText]) => {
                setIsUpdatingFriendsRequestId(0)
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataFriends) {
            const { pages } = dataFriends
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].list.length : 0

            if (lastListLen) {
                setUsersDataIds(pages[pagesLen - 1].list.map((item) => item.id).join(','))
            }
            if (lastListLen < LIMIT_FRIENDS) {
                setIsHideLoaderFriends(true)
            }
        }
    }, [dataFriends])

    useEffect(() => {
        if (dataSearchFriends) {
            const { pages } = dataSearchFriends
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].results.length : 0

            if (lastListLen < LIMIT_FRIENDS) {
                setIsHideLoaderSearchFriends(true)
            }
        }
    }, [dataSearchFriends])

    useEffect(() => {
        if (dataUsersData) {
            const usersDataItems = dataUsersData.reduce<TUserDataRecord>((acc, item) => ({
                ...acc,
                [item.user.id]: item,
            }), {})
            const newUsersDataItems = setCacheUsersData(usersDataItems)

            if (newUsersDataItems) {
                setUsersData(newUsersDataItems)
            }
        }
    }, [dataUsersData])

    useEffect(() => {
        if (errorFriends) {
            setIsHideLoaderFriends(true)
        }
    }, [errorFriends])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader
                    classesContent={style.headerContent}
                    title={clientsText}
                    titlePos="none"
                >
                    <div className={style.headerContentCount}>
                        {clientsCount}
                    </div>
                    <div className={style.headerContentActions}>
                        <Button
                            classes={style.headerContentAction}
                            styleType="transparent"
                            title={t('search')}
                            onClick={() => setIsShowModalSearch(true)}
                        >
                            <Icon
                                isImgTag={false}
                                classes={style.iconSearch}
                                resourceKey="ic_search_white_svg"
                                width={26}
                                height={26}
                                notify={!!friendsSearchText || isSetFriendsSearchFilters}
                            />
                        </Button>
                        {/* <Button
                            disabled
                            classes={style.headerContentAction}
                            styleType="transparent"
                            onClick={() => {}}
                        >
                            <Icon
                                isImgTag={false}
                                classes={style.iconAdd}
                                resourceKey="ic_chat_add2_svg"
                                width={32}
                                height={24}
                            />
                        </Button> */}
                    </div>
                </PageHeader>

                {windowWidth < BREAKPOINTS.desktop && (
                    !!dataFriendsRequest?.total && (
                        <Block classes={cn(style.blockRequests, style.blockRequests_mobile)}>
                            <FriendsRequests
                                isMobileView
                                isUpdatingFriendsRequestId={isUpdatingFriendsRequestId}
                                data={dataFriendsRequest}
                                maxItemsShow={1}
                                onFriendRequestConfirm={handlerFriendRequestConfirm}
                                onFriendRequestReject={handlerFriendRequestReject}
                            />
                        </Block>
                    )
                )}

                {(isLoadingFriends || isLoadingSearchFriends) && (
                    <Loader />
                )}

                {!isLoadingFriends && errorFriends?.length && (
                    <ErrorMsg error={errorFriends[0]} />
                )}

                {!isLoadingFriends
                    && !dataSearchFriends
                    && !errorFriends
                    && dataFriends?.pages.length
                    && dataFriends.pages[0].total === 0
                && (
                    <NoDataInfo text={t('you_have_no_friends_in_sessia')} />
                )}

                {!isLoadingSearchFriends
                    && dataSearchFriends?.pages.length
                    && dataSearchFriends.pages[0].count === 0
                && (
                    <NoDataInfo text={t('nothing_found')} />
                )}

                <CardContainer>
                    {dataSearchFriends && (
                        <InfiniteScroll
                            options={{ rootMargin: '0px 0px 50% 0px' }}
                            isHideLoader={isHideLoaderSearchFriends}
                            onLoad={handlerLoadSearchFriends}
                        >
                            {dataSearchFriends.pages.map((page, i) => (
                                // eslint-disable-next-line
                                <React.Fragment key={i}>
                                    {page.results.map((item) => (
                                        <FriendsUserCard
                                            isShowCounts
                                            user={item}
                                            size={itemSize}
                                            data={usersData[item.id]}
                                            key={item.id}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                    {!isFetchingSearchFriends && !dataSearchFriends && dataFriends && (
                        <InfiniteScroll
                            options={{ rootMargin: '0px 0px 50% 0px' }}
                            isHideLoader={isHideLoaderFriends}
                            onLoad={handlerLoadFriends}
                        >
                            {dataFriends.pages.map((page, i) => (
                                // eslint-disable-next-line
                                <React.Fragment key={i}>
                                    {page.list.map((item) => (
                                        <FriendsUserCard
                                            isShowCounts
                                            user={item}
                                            size={itemSize}
                                            data={usersData[item.id]}
                                            key={item.id}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                </CardContainer>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    {!!dataFriendsRequest?.total && (
                        <Block classes={style.blockRequests}>
                            <FriendsRequests
                                isUpdatingFriendsRequestId={isUpdatingFriendsRequestId}
                                data={dataFriendsRequest}
                                onFriendRequestConfirm={handlerFriendRequestConfirm}
                                onFriendRequestReject={handlerFriendRequestReject}
                            />
                        </Block>
                    )}
                </SideBarContainer>
            )}

            <Modal
                classes={style.modalSearch}
                isOpen={isShowModalSearch}
                onClose={() => setIsShowModalSearch(false)}
            >
                <Modal.Body>
                    <FriendsSearch
                        classes={style.search}
                        searchText={friendsSearchText}
                        isSetFilters={!!friendsSearchFilters}
                        onClickFilters={() => setIsShowModalFilters(true)}
                        onReset={handlerResetSearch}
                        onSearch={handlerSearch}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isShowModalFilters}
                onClose={() => setIsShowModalFilters(false)}
            >
                <Modal.Header
                    isCloseButton
                    title={t('settings_tab')}
                    titlePos="center"
                />
                <Modal.Body>
                    <FriendFilterAction
                        classes={style.filter}
                        filterProps={friendsSearchFilters}
                        onReset={handlerResetFilters}
                        onSuccess={handlerFilters}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Friends
