import React, { useMemo, useState } from 'react'

import { IChat, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Link } from 'components'
import useFetchHistoryChat from 'containers/Messenger/hooks/useFetchHistoryChat'
import { MessengerItemInner } from 'containers/Messenger/components'
import style from './MessengerSearchItemChat.module.css'

type MessengerSearchChatPropType = {
    classes?: string
    data: IChat
    user: IUser
}

const MessengerSearchItemChat: React.FC<MessengerSearchChatPropType> = ({ classes, data, user }) => {
    const [chatUser] = useState(user.id === data.starterUserId ? data.followerUser : data.starterUser)

    const { data: dataHistoryChat } = useFetchHistoryChat({
        chatUserId: chatUser.id,
    })

    const urlChat = useMemo(() => {
        return chatUser ? APP_URL.messengerChat.replace(':id', String(chatUser.id)) : ''
    }, [chatUser])

    const lastMessage = useMemo(() => {
        return dataHistoryChat?.length ? (dataHistoryChat[0].text || dataHistoryChat[0].forwardedMessage) : ''
    }, [dataHistoryChat])

    const avatarTpl = (
        <Link className={style.user} url={urlChat}>
            <Avatar
                name={chatUser?.name}
                surname={chatUser?.surname}
                src={chatUser?.photoFullUrl}
                width={48}
                height={48}
            />
        </Link>
    )

    return (
        <MessengerItemInner
            isSimpleMessage
            classes={classes}
            title={`${chatUser?.name ?? ''} ${chatUser?.surname ?? ''}`}
            avatar={avatarTpl}
            message={lastMessage}
            date={data.createdAt}
            url={urlChat}
        />
    )
}

export default MessengerSearchItemChat
