import React, { useMemo } from 'react'

import { IMlmIncome } from 'interfaces'
import { APP_URL } from 'config/app'
import { API_HOST } from 'config/api'
import { UserListItem } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './ReportsFreedomIncomeListItem.module.css'

type ReportsFreedomIncomeListItemPropType = {
    data: IMlmIncome
}

const ReportsFreedomIncomeListItem: React.FC<ReportsFreedomIncomeListItemPropType> = ({
    children,
    data,
}) => {
    const photo = useMemo(() => {
        return data.icon ? `${API_HOST}${data.icon}` : undefined
    }, [data])

    const url = useMemo(() => {
        const params = { prefix: '', suffix: '' }

        if (data.post_id) {
            return parseTpl(APP_URL.post, { ':id': data.post_id }, params)
        }
        if (data.store_id) {
            return parseTpl(APP_URL.orders, { ':store_id': data.store_id }, params)
        }

        return undefined
    }, [data])

    return (
        <UserListItem
            classes={style.item}
            classesBody={style.body}
            name={data.order_user_name ?? ''}
            surname={data.order_user_surname ?? ''}
            photo={photo}
            online={false}
            avatarPos="middle"
            avatarSize={50}
            url={url}
            content={children}
        />
    )
}

export default ReportsFreedomIncomeListItem
