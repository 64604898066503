import React, { useEffect, useState } from 'react'

import { IPost } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { PostsPropType } from 'components/Posts/Posts'
import { fetchPostsUserPropType } from 'services/CommunityService'
import { Community } from 'containers'
import { useFetchInfinitePostsUser, useInvalidatePostsUser, useSetInfiniteQueryDataPostsUser } from 'containers/Community/hooks'
import { Posts } from 'components'
import { showAlertNotify } from 'utils/helpers'

type FeedPostsCommunityPropType = {
    queryParams: fetchPostsUserPropType
    isPostTile?: boolean
} & Pick<PostsPropType, 'noDataTitleKey' | 'noDataTextKey'>

const FeedPostsUser: React.FC<FeedPostsCommunityPropType> = ({
    queryParams,
    isPostTile,
    ...props
}) => {
    const { limit = 10, offset = 0, ...params } = queryParams

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoading,
        isLoadingError,
        data,
        error,
        fetchNextPage,
    } = useFetchInfinitePostsUser({
        limit,
        offset,
        ...params,
    })

    const { invalidate } = useInvalidatePostsUser({
        limit,
        offset,
        ...params,
    })

    const { setQueryData } = useSetInfiniteQueryDataPostsUser({
        limit,
        offset,
        ...params,
    })

    const handlerOnLoad = ({ page }: LoadDataType) => {
        if (page > 0) {
            fetchNextPage()
        }
    }

    const handlerBlockUserComplete = (value: string) => {
        showAlertNotify({ type: 'success', message: value })
    }

    const handlerCompletePost = (post: IPost) => {
        setQueryData(post)
    }

    const handlerDeleteCompletePost = () => {
        invalidate()
    }

    const handlerErrorPost = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    useEffect(() => {
        if (isLoadingError) {
            setIsHideLoader(true)
        }
    }, [isLoadingError])

    useEffect(() => {
        if (data) {
            const { pages } = data
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < limit) {
                setIsHideLoader(true)
            }
        }
    }, [data])

    return (
        <Community
            onBlockUserComplete={handlerBlockUserComplete}
            onLikeCompletePost={handlerCompletePost}
            onEditCompletePost={handlerCompletePost}
            onDeleteCompletePost={handlerDeleteCompletePost}
            onError={handlerErrorPost}
        >
            <Posts
                isLoading={isLoading}
                isHideLoader={isHideLoader}
                isPostTile={isPostTile}
                data={data}
                error={error}
                onLoad={handlerOnLoad}
                {...props}
            />
        </Community>
    )
}

export default FeedPostsUser
