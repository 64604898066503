import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    IBasket,
    IStoreProfile,
    IStoreCurrency,
    IGoodsImage,
} from 'interfaces'
import { APP_URL } from 'config/app'
import { Button, Image } from 'components'
import { getImageResizeSrc, getStoreCurrency, priceFormat } from 'utils/helpers'
import style from './StoreBasketBar.module.css'

type StoreBasketBarPropType = {
    storeId: number
    data: IBasket
    storeProfile?: IStoreProfile
}

const GOODS_ITEMS_MAX_SHOW = 4

const getImagePreview = (images: IGoodsImage[]) => {
    const image = images.find((img) => img.format === 1)
    return image ? getImageResizeSrc({
        src: image.path,
        width: 64,
        height: 48,
        quality: 90,
        fit: 'cover',
    }) : ''
}

const StoreBasketBar: React.FC<StoreBasketBarPropType> = ({ storeId, data, storeProfile }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const storeCurrency: IStoreCurrency | undefined = useMemo(() => {
        return storeProfile ? getStoreCurrency(storeProfile) : undefined
    }, [storeProfile])

    const totalSumFormat = useMemo(() => {
        const { content_sum = 0, discount_sum = 0 } = data || {}
        return priceFormat(content_sum - discount_sum, storeCurrency)
    }, [data, storeCurrency])

    const moreCount = useMemo(() => {
        const total = data.content.reduce((acc, item) => {
            return acc + item.quantity
        }, 0)

        const more = data.content.reduce((acc, item, index) => {
            if (index < GOODS_ITEMS_MAX_SHOW) {
                return acc + item.quantity
            }
            return acc
        }, 0)

        return total - more
    }, [data])

    const handlerClick = () => {
        history.push(APP_URL.basket.replace(':id', String(storeId)))
    }

    return (
        <div className={style.bar}>
            <div className={style.content}>
                <div className={style.items}>
                    {data.content.slice(0, GOODS_ITEMS_MAX_SHOW).map((item) => (
                        <div className={style.item} key={item.goods.id}>
                            <Image
                                isPlaceholder={false}
                                classesWrap={style.goodsWrap}
                                classesImage={style.goods}
                                src={getImagePreview(item.goods.images)}
                                width={64}
                                height={48}
                            />
                            <div className={style.quantity}>
                                {`x ${item.quantity}`}
                            </div>
                        </div>
                    ))}
                    {data.content.length > GOODS_ITEMS_MAX_SHOW && (
                        <div className={style.more}>
                            {`+ ${t('market_product_more')} ${moreCount}`}
                        </div>
                    )}
                </div>
                <div className={style.total}>
                    <div>
                        {t('market_product_total')}
                    </div>
                    <div className={style.totalValue}>
                        {totalSumFormat}
                    </div>
                </div>
                <div className={style.actions}>
                    <Button
                        textUpper
                        classes={style.action}
                        size="size46"
                        text={t('market_checkout_button')}
                        onClick={handlerClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default StoreBasketBar
