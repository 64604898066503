import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreCurrency } from 'interfaces'
import { Checkbox } from 'components'
import { priceFormat } from 'utils/helpers'

import styleForm from 'styles/modules/form.module.css'
import style from '../../StoreOrderMake.module.css'

export type StoreOrderMakeFormPaymentsPropType = {
    totalBonus?: number
    totalPayout?: number
    sumBonus?: number
    sumPayout?: number
    isCheckedBonus?: boolean
    isCheckedPayout?: boolean
    currency?: IStoreCurrency
    onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void
}

const StoreOrderMakePayments: React.FC<StoreOrderMakeFormPaymentsPropType> = ({
    totalBonus,
    totalPayout,
    sumBonus,
    sumPayout,
    isCheckedBonus,
    isCheckedPayout,
    currency,
    onChange,
}) => {
    const { t } = useTranslation()

    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        if (totalBonus || totalPayout) {
            setIsShow(true)
        }
    }, [totalBonus, totalPayout])

    return (
        <>
            {isShow && (
                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <div className={cn(styleForm.fieldHead, style.fieldLabel)}>
                        {t('market_order_purchase_title')}
                    </div>

                    {!!totalBonus && (
                        <div className={cn(styleForm.row, styleForm.row_24)}>
                            <div className={cn(styleForm.group, styleForm.group_spaceBetween)}>
                                <Checkbox
                                    text={t('market_bonus_payment_by_wallet')}
                                    name="payment_by_wallet"
                                    checked={isCheckedBonus}
                                    onChange={onChange}
                                />
                                {!!sumBonus && (
                                    <div>
                                        {priceFormat(sumBonus, currency)}
                                    </div>
                                )}
                            </div>
                            <div className={style.fieldDesc}>
                                {t('market_bonus_available')}
                                {' '}
                                {priceFormat(totalBonus, currency)}
                            </div>
                        </div>
                    )}

                    {!!totalPayout && (
                        <div className={cn(styleForm.row, styleForm.row_24)}>
                            <div className={cn(styleForm.group, styleForm.group_spaceBetween)}>
                                <Checkbox
                                    text={t('market_bonus_payment_by_payout')}
                                    name="payment_by_payout"
                                    checked={isCheckedPayout}
                                    onChange={onChange}
                                />
                                {!!sumPayout && (
                                    <div>
                                        {priceFormat(sumPayout, currency)}
                                    </div>
                                )}
                            </div>
                            <div className={style.fieldDesc}>
                                {t('market_bonus_available')}
                                {' '}
                                {priceFormat(totalPayout, currency)}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default StoreOrderMakePayments
