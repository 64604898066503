import React from 'react'
import { useForm, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { TextContentEditable } from 'components'

type TextTextContentEditablePropType = {
    classes?: string
    name: string
    placeholder?: string
    isFocus?: boolean
    isDisabled?: boolean
}

const FieldTextContentEditable: React.FC<TextTextContentEditablePropType> = ({
    classes,
    name,
    placeholder,
    isFocus,
    isDisabled,
}) => {
    const { t } = useTranslation()

    const { mutators, submit } = useForm()
    const { input } = useField<string>(name)

    const handlerSubmitByEnter = (value: string) => {
        mutateFieldValue(value)
        submit()
    }

    const handlerChange = (value: string) => {
        mutateFieldValue(value)
    }

    function mutateFieldValue(value: string) {
        if (name in mutators) {
            mutators[name](name, value)
        }
    }

    return (
        <TextContentEditable
            classes={classes}
            placeholder={placeholder || t('message')}
            value={input.value}
            focus={isFocus}
            disabled={isDisabled}
            onChange={handlerChange}
            onSubmitByEnter={handlerSubmitByEnter}
        />
    )
}

export default FieldTextContentEditable
