import {
    ITicket,
    ITicketItem,
} from 'interfaces'
import {
    FETCH_TICKETS,
    FETCH_TICKET, ADD_TICKET,
} from 'reducers/types'
import {
    fetchTicketsActionType,
    fetchTicketActionType,
    addTicketActionType,
} from './support-actions-type'

type ticketActionType = fetchTicketsActionType & fetchTicketActionType & addTicketActionType

export const ticketReducer = (state: (ITicket | ITicketItem)[] = [], action: ticketActionType)
    : (ITicket | ITicketItem)[] => {
    switch (action.type) {
        case FETCH_TICKETS:
            return (action.payload as ITicketItem[])

        case FETCH_TICKET: {
            const ticket = (action.payload as ITicket)
            return state.length
                ? state.map((item) => (item.id === ticket.id ? { ...item, ...ticket } : item))
                : [ticket]
        }

        case ADD_TICKET: {
            const ticket = (action.payload as ITicketItem)
            return [ticket, ...state]
        }

        default:
            return state
    }
}
