import React from 'react'
import { createPortal } from 'react-dom'

import style from './ModalTopBar.module.css'

export type ModalTopBarPropType = {
    isOpen: boolean
}

const ModalTopBar: React.FC<ModalTopBarPropType> = ({ children, isOpen }) => {
    return (
        createPortal(
            isOpen && (
                <div className={style.bar}>
                    {children}
                </div>
            ),
            document.body,
        )
    )
}

export default ModalTopBar
