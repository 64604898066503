import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BudgetService } from 'services'
import { TBudget } from 'services/BudgetService'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TError = string
export type TQueryKey = string[]
export type TQueryFnOpts = UseQueryOptions<TBudget, string, TBudget, TQueryKey>

/**
 * Хук API получение списка транзакций
 */
export default function useFetchBudget({
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`budget-${user.id}`], () => {
        return BudgetService.fetchBudget()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
