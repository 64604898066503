import React from 'react'
import cn from 'classnames'

import { IMAGE_RESOURCE_PATH } from 'config/api'
import style from './ContentBackgroundBottom.module.css'

type ContentBackgroundBottomPropType = {
    classes?: string
    background?: string
    isWidthGutter?: boolean
}

const BACKGROUND_IMAGE_PATH = `${IMAGE_RESOURCE_PATH}/wallet_no_transactions_bottom_bg`

/**
 * Отображение background изображения контентной части, прижатой к низу 1го экрана
 */
const ContentBackgroundBottom: React.FC<ContentBackgroundBottomPropType> = ({
    classes,
    // styles,
    background = BACKGROUND_IMAGE_PATH,
    isWidthGutter = false,
}) => {
    return (
        <div
            className={cn(style.background, { [style.background_widthGutter]: isWidthGutter }, classes)}
            style={{ backgroundImage: `url(${background})` }}
        />
    )
}

export default ContentBackgroundBottom
