import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'
import cn from 'classnames'

import * as userSelectors from 'containers/User/user-selectors'
import { IMenuNavItemData } from 'interfaces'
import {
    SvgResource,
    Popover,
    MenuList,
    Modal,
    Link,
} from 'components'
import MenuNavDropDownChild from '../MenuNavDropDownChild/MenuNavDropDownChild'
import style from './MenuNavDropDown.module.css'

type MenuNavDropDownPropType = {
    items: IMenuNavItemData[]
    currentItemId: number
    onChangeSubMenu: (itemId: number) => void
}

const MenuNavDropDown: React.FC<MenuNavDropDownPropType> = ({
    items = [],
    currentItemId,
    onChangeSubMenu,
}) => {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const [isOpenPopover, setIsOpenPopover] = useState(false)

    const onOpenPopover = () => {
        setIsOpenPopover(true)
    }

    const onClosePopover = () => {
        setIsOpenPopover(false)
    }

    const handleOpenModal = () => {
        setIsShowModal(true)
    }

    const handleCloseModal = () => {
        setIsShowModal(false)
    }

    const dropDownTpl = (
        <SvgResource
            isImgTag={false}
            resourceKey="ic_tab_menu_svg"
            width={30}
            height={30}
        />
    )

    return (
        <>
            <Popover
                trigger={dropDownTpl}
                side="left"
                isAutoClose={false}
                isOpen={isOpenPopover}
                onOpen={onOpenPopover}
                onClose={onClosePopover}
            >
                <MenuList>
                    {items.map((item) => (
                        <MenuList.Item key={item.id}>
                            {item.children && (
                                <MenuNavDropDownChild
                                    item={item}
                                    isOpen={item.id === currentItemId}
                                    onChangeSubMenu={onChangeSubMenu}
                                    onClosePopover={onClosePopover}
                                />
                            )}
                            {!item.children && item.disabled && (
                                <div className={cn(style.link, { [style.link_disabled]: item.disabled })}>
                                    {t(item.translateKey!)}
                                </div>
                            )}
                            {!item.children && !item.disabled && (
                                <Link className={style.link} url={item.url} onClick={onClosePopover}>
                                    {t(item.translateKey!)}
                                </Link>
                            )}
                        </MenuList.Item>
                    ))}
                    <MenuList.Item onClick={handleOpenModal}>
                        {t('help_context_qr_title')}
                    </MenuList.Item>
                </MenuList>
            </Popover>

            <Modal
                isOpen={isShowModal}
                size="medium"
                classes=""
                onClose={handleCloseModal}
            >
                <Modal.Header title={t('help_context_qr_title')} />
                <Modal.Body>
                    <div className={style.qr}>
                        <QRCodeSVG
                            value={user?.member_id}
                            size={400}
                            bgColor="#ffffff"
                            fgColor="#000000"
                            level="L"
                            includeMargin={false}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MenuNavDropDown
