import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IProfile } from 'interfaces'
import { InfoBlock } from 'components'
import { dateTimeFormat, splitStr } from 'utils/helpers'
import style from './UserProfileInfo.module.css'

type UserProfileInfoPropType = {
    data: IProfile
}

const UserProfileInfo: React.FC<UserProfileInfoPropType> = ({ data }) => {
    const { t } = useTranslation()

    const textWithUs = useMemo(() => {
        const date = data ? dateTimeFormat(data.created_date) : '-'
        const text = t('profile_info_with_us_date_prefix').replace('%s', date)

        return splitStr(text)
    }, [data])

    const dateBirthday = useMemo(() => (data?.birth_date ? dateTimeFormat(data.birth_date) : '-'), [data])

    return (
        <InfoBlock>
            <div className={style.title}>
                {t('info')}
            </div>
            <div className={style.row}>
                {textWithUs.map((item) => (
                    <div className={style.text} key={item.id}>
                        {item.text}
                    </div>
                ))}
            </div>
            <div className={style.row}>
                <div className={style.text}>
                    {t('borndate')}
                </div>
                <div className={style.text}>
                    {dateBirthday}
                </div>
            </div>
            <div className={style.row}>
                <div className={style.text}>
                    {t('contract_number')}
                </div>
                <div className={style.text}>
                    {data.account.member_id}
                </div>
            </div>
        </InfoBlock>
    )
}

export default UserProfileInfo
