/**
 * @deprecated
 * sue useAppSelector
 */

import { IRootState } from 'interfaces'

export const modals = (state: IRootState) => state.app.modals

export const settings = (state: IRootState) => state.app.settings

export const darkDoor = (state: IRootState) => state.app.darkDoor

export const languages = (state: IRootState) => state.app.languages

export const countries = (state: IRootState) => state.app.countries

export const country = (state: IRootState) => state.app.country.country

export const resources = (state: IRootState) => state.app.resources

export const stickers = (state: IRootState) => state.app.stickers
