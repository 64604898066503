import {
    Chart,
    ChartTypeRegistry,
    Plugin,
    DoughnutController,
} from 'chart.js'

export type DoughnutBackgroundOptions = {
    enabled?: boolean
    color?: string
}

const defaults = {
    color: '#eff0f4',
}

const doughnutBackground: Plugin = {
    id: 'doughnutBackground',
    beforeDraw(
        chart: Chart<keyof ChartTypeRegistry>,
        args: { cancelable: true },
        options: DoughnutBackgroundOptions = {},
    ) {
        const { ctx, chartArea, data } = chart
        const { top, width, height } = chartArea

        const { innerRadius } = chart.getDatasetMeta(data.datasets.length - 1).controller as DoughnutController
        const { outerRadius } = chart.getDatasetMeta(0).controller as DoughnutController
        const radiusLength = outerRadius - innerRadius

        if (options.enabled) {
            ctx.beginPath()
            ctx.arc(width / 2, top + (height / 2), outerRadius - radiusLength / 2, 0, 2 * Math.PI)
            ctx.lineWidth = radiusLength
            ctx.strokeStyle = options.color ?? defaults.color
            ctx.stroke()
        }
    },
}

export default doughnutBackground
