import React from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import style from './ItemCaption.module.css'

type ItemCaptionPropType = {
    classes?: string
    data: IReport
}

const ItemCaption: React.FC<ItemCaptionPropType> = ({ classes, data }) => {
    return (
        <div className={cn(style.caption, classes)}>
            {data.name}
        </div>
    )
}

export default ItemCaption
