import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'

import style from './MultiRangeSlider.module.css'

export type TRangeValue = { from: number, to: number }

const getPercent = (value: number, min: number, max: number) => {
    return Math.round(((value - min) / (max - min)) * 100)
}

type MultiRangeSliderPropsType = {
    classes?: string
    min: number
    max: number
    from?: number
    to?: number
    onMount?: ({ from, to }: TRangeValue) => void
    onChange: ({ from, to }: TRangeValue) => void
}

const MultiRangeSlider: React.FC<MultiRangeSliderPropsType> = ({
    classes,
    min,
    max,
    from,
    to,
    onMount,
    onChange,
}) => {
    const minValRef = useRef<HTMLInputElement>(null)
    const maxValRef = useRef<HTMLInputElement>(null)
    const rangeRef = useRef<HTMLDivElement>(null)

    const [isInit, setIsInit] = useState(false)
    const [minVal, setMinVal] = useState(from || min)
    const [maxVal, setMaxVal] = useState(to || max)

    const handlerChangeMinVal = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setMinVal(Math.min(Number(target.value), maxVal - 1))
    }

    const handlerChangeMaxVal = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setMaxVal(Math.max(Number(target.value), minVal + 1))
    }

    useEffect(() => {
        setIsInit(true)

        if (onMount) {
            onMount({ from: minVal, to: maxVal })
        }

        return () => {
            setIsInit(false)
        }
    }, [])

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal, min, max)
            const maxPercent = getPercent(Number(maxValRef.current.value), min, max)

            if (rangeRef.current) {
                rangeRef.current.style.left = `${minPercent}%`
                rangeRef.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [minVal])

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(Number(minValRef.current.value), min, max)
            const maxPercent = getPercent(maxVal, min, max)

            if (rangeRef.current) {
                rangeRef.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [maxVal])

    useEffect(() => {
        if (isInit) {
            onChange({ from: minVal, to: maxVal })
        }
    }, [minVal, maxVal])

    return (
        <div className={cn(style.slider, classes)}>
            <input
                className={cn(style.thumb, style.thumb_zindex3, {
                    [style.thumb_zindex5]: minVal > max - 100,
                })}
                type="range"
                min={min}
                max={max}
                defaultValue={minVal}
                ref={minValRef}
                onChange={handlerChangeMinVal}
            />
            <input
                className={cn(style.thumb, style.thumb_zindex4)}
                type="range"
                min={min}
                max={max}
                defaultValue={maxVal}
                ref={maxValRef}
                onChange={handlerChangeMaxVal}
            />
            <div className={style.track} />
            <div className={style.range} ref={rangeRef} />
        </div>
    )
}

export default MultiRangeSlider
