import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { TabsPropType } from 'components/Tabs/Tabs'
import { AppSettings } from 'enums'
import { Block } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import { useAppSettings } from 'containers/App/hooks'
import { Tabs, Button, SvgResource } from 'components'
import style from './FeedNav.module.css'

type FeedNavPropType = {
    isShowPostViewActions?: boolean
    onChangePostViewTile?: (value: boolean) => void
} & Omit<TabsPropType, 'classes'>

const FeedNav: React.FC<FeedNavPropType> = ({
    isShowPostViewActions = true,
    onChangePostViewTile = () => {},
    ...tabsProps
}) => {
    const { feedPostTile: isPostTile } = useSelector(appSelectors.settings)

    const { update: updateAppSettings } = useAppSettings()

    const handlerChangePostView = () => {
        const value = !isPostTile

        updateAppSettings(AppSettings.feedPostTile, value)
        onChangePostViewTile(value)
    }

    return (
        <Block classes={style.nav}>
            <div className={style.tabs}>
                <Tabs
                    {...tabsProps}
                />
            </div>
            <div className={style.actions}>
                {isShowPostViewActions && (
                    <>
                        <Button
                            classes={cn(style.action, { [style.action_active]: !isPostTile })}
                            styleType="transparent"
                            onClick={handlerChangePostView}
                        >
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.iconLenta1, { [style.iconLenta1_active]: !isPostTile })}
                                resourceKey="profile_lenta1_svg"
                                width={20}
                                height={20}
                            />
                        </Button>
                        <Button
                            classes={cn(style.action, { [style.action_active]: isPostTile })}
                            styleType="transparent"
                            onClick={handlerChangePostView}
                        >
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.iconLenta2, { [style.iconLenta2_active]: isPostTile })}
                                resourceKey="profile_lenta2_svg"
                                width={20}
                                height={20}
                            />
                        </Button>
                    </>
                )}
            </div>
        </Block>
    )
}

export default FeedNav
