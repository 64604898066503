import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { APP_URL } from 'config/app'
import { useApp } from 'containers/App/hooks'
import { parseTpl, copyTextClipboard, showAlertNotify } from 'utils/helpers'
import {
    ItemFolder,
    ItemCaption,
    ItemString,
    ItemPictureString,
    ItemFilter,
    ItemProgressBar,
    ItemReward,
    ItemChart,
    // ItemDelimiter,
} from '../index'
import style from './ReportItem.module.css'

type ReportsItemPropType = {
    data: IReport
    classes?: string
    isSetReportLink?: boolean // генерировать ссылку на страницу с отчетом
    onClick?: (data: IReport) => void
}

const REGEXP_COPY = /copy:\/\//
const REGEXP_SHARE = /share:\/\//
const PARSE_TPL_DEFAULTS = { prefix: '', suffix: '' }

const getReportItemWidth = (type: string): string => {
    switch (type) {
        case 'folder_narrow':
            return style.halfWidth
        case 'folder_grid':
        case 'reward':
            return style.thirdWidth
        case 'folder':
        case 'caption':
        case 'string':
        case 'filter':
        case 'chart':
        case 'delimiter':
        default:
            return style.fullWidth
    }
}

const getReportItemInnerUrl = (defaultPath: string, link?: string): string => {
    if (link) {
        const isMarketPath = /\/marketplace\/categories/.test(link)
        const isBalancePath = /\/balance/.test(link)
        const isPayoutPath = /\/payout/.test(link)
        const isOldStorePath = /\/shop\/\d+/.test(link)
        const isStorePath = /\/store\/\d+/.test(link)
        const isPostPath = /\/post\/\d+/.test(link)
        const isCopy = REGEXP_COPY.test(link)
        const isShare = REGEXP_SHARE.test(link)

        if (isMarketPath) {
            return APP_URL.market
        }
        if (isBalancePath) {
            //
        }
        if (isPayoutPath) {
            //
        }
        if (isOldStorePath || isStorePath) {
            const matched = link.match(/\d+/)
            const id = matched?.length ? matched[0] : null
            const path = id ? parseTpl(APP_URL.store, { ':id': id }, PARSE_TPL_DEFAULTS) : ''

            return path || defaultPath
        }
        if (isPostPath) {
            const matched = link.match(/\d+/)
            const id = matched?.length ? matched[0] : null
            const path = id ? parseTpl(APP_URL.post, { ':id': id }, PARSE_TPL_DEFAULTS) : ''

            return path || defaultPath
        }
        if (isCopy || isShare) {
            return ''
        }

        return defaultPath
    }

    return ''
}

const hasRemoteUrl = (path?: string) => {
    return !!path?.includes('http')
}

const ReportItem: React.FC<ReportsItemPropType> = ({
    classes,
    data,
    isSetReportLink = true,
    onClick = () => {},
}) => {
    const { t } = useTranslation()
    const { mainPrivateRoute } = useApp()

    const classWidth = useMemo(() => ('type' in data ? getReportItemWidth(data.type) : ''), [data])

    const itemUrl = useMemo(() => {
        const {
            id,
            hasChilds,
            filterId,
            value,
            link,
            linkIsExternal,
        } = data
        const isReportLink = hasChilds || !!(filterId && value)
        const isRemoteLink = link && linkIsExternal

        if (isReportLink) {
            return isSetReportLink ? parseTpl(APP_URL.report, { ':id': id }, PARSE_TPL_DEFAULTS) : undefined
        }
        if (isRemoteLink) {
            return link
        }

        return getReportItemInnerUrl(mainPrivateRoute, link)
    }, [mainPrivateRoute, data])

    const urlTarget = useMemo(() => (hasRemoteUrl(itemUrl) ? '_blank' : undefined), [itemUrl])

    const handlerClick = () => {
        const { link, linkIsExternal } = data

        if (link) {
            if (linkIsExternal && !hasRemoteUrl(itemUrl)) {
                window.open(link, '_blank')
            } else if (REGEXP_COPY.test(link)) {
                copyTextClipboard(link.replace(new RegExp(REGEXP_COPY), ''))
                    .then(() => {
                        showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
                    })
                    .catch(() => {
                        showAlertNotify({ type: 'error', message: t('update_error') })
                    })
            } else if (REGEXP_SHARE.test(link)) {
                copyTextClipboard(link.replace(new RegExp(REGEXP_SHARE), ''))
                    .then(() => {
                        showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
                    })
                    .catch(() => {
                        showAlertNotify({ type: 'error', message: t('update_error') })
                    })
            }
        } else {
            onClick(data)
        }
    }

    switch (data.type) {
        case 'folder':
        case 'folder_narrow':
        case 'folder_grid':
            return (
                <ItemFolder
                    classes={classWidth}
                    data={data}
                    url={itemUrl}
                    target={urlTarget}
                    onClick={handlerClick}
                />
            )
        case 'caption':
            return (
                <ItemCaption
                    classes={classWidth}
                    data={data}
                />
            )
        case 'string':
            return (
                <ItemString
                    classes={cn(classWidth, classes)}
                    data={data}
                    url={itemUrl}
                    target={urlTarget}
                    onClick={handlerClick}
                />
            )
        case 'picture_string':
            return (
                <ItemPictureString
                    classes={classWidth}
                    data={data}
                />
            )
        case 'filter':
            return (
                <ItemFilter
                    classes={cn(classWidth, classes)}
                    data={data}
                    url={itemUrl}
                    target={urlTarget}
                    onClick={handlerClick}
                />
            )
        case 'chart':
            return (
                <ItemChart
                    classes={classWidth}
                    data={data}
                    url={itemUrl}
                    target={urlTarget}
                    onClick={handlerClick}
                />
            )
        case 'progress_bar':
            return <ItemProgressBar classes={classWidth} data={data} />
        case 'reward':
            return <ItemReward classes={classWidth} data={data} />
        // case 'delimiter':
        //     return <ItemDelimiter classes={classWidth} data={data} />
        default:
            return (
                <div />
            )
    }
}

export default ReportItem
