import React, { useState } from 'react'
import cn from 'classnames'

import { Context, ContextType } from './Context'
import MenuListItem, { MenuListItemPropType } from './MenuListItem/MenuListItem'
import style from './MenuList.module.css'

type MenuListPropType = {
    classes?: string
    type?: ContextType['type']
    hover?: ContextType['hover']
}

type MenuLisComposition = {
    Item: React.FC<MenuListItemPropType>
}

const MenuList: React.FC<MenuListPropType> & MenuLisComposition = ({
    children,
    classes,
    type = 'normal',
    hover = false,
}) => {
    const [context] = useState<ContextType>({ type, hover })

    return (
        <ul className={cn(style.list, style[`list_${type}`], classes)}>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>
        </ul>
    )
}

MenuList.Item = MenuListItem

export default MenuList
