import React from 'react'

import { IStoreFolder } from 'interfaces'
import { CardSize } from 'enums'
import { CardV2 } from 'components'
import styleCatalog from '../../CatalogShowcase.module.css'

export type CatalogShowcaseFolderPropType = {
    classes?: string
    data: IStoreFolder
    size: keyof typeof CardSize
    url?: string
    onClick?: (value: IStoreFolder) => void
}

const CatalogShowcaseFolder: React.FC<CatalogShowcaseFolderPropType> = ({
    children,
    classes,
    data,
    size,
    url,
    onClick,
}) => {
    const handlerClick = () => {
        if (onClick) {
            onClick(data)
        }
    }

    return (
        <CardV2
            classes={classes}
            size={size}
        >
            <CardV2.Image
                src={data.image}
                width={400}
                height={300}
                url={url}
                onClick={onClick && handlerClick}
            />
            <CardV2.Body
                classes={styleCatalog.body}
                classesTitle={styleCatalog.title}
                title={data.name}
                url={url}
                onClick={onClick && handlerClick}
            />
            {children}
        </CardV2>
    )
}

export default CatalogShowcaseFolder
