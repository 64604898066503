import React from 'react'

import style from './MenuNavItem.module.css'

const MenuNavItem: React.FC = ({ children }) => {
    return (
        <li className={style.item}>
            {children}
        </li>
    )
}

export default MenuNavItem
