import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IShop } from 'interfaces'
import { APP_URL, STORE_FRIENDS_SHOW_COUNT } from 'config/app'
import { Card, UsersAvatarGroup } from 'components'
import { useFetchFriendsStore } from 'containers/User/hooks'
import { parseTpl, getStoreFriendsCountText } from 'utils/helpers'

import styleLabel from 'styles/modules/label.module.css'
import style from './ShopItem.module.css'

type ShopItemPropType = {
    shop: IShop
}

const ShopItem: React.FC<ShopItemPropType> = ({ shop }) => {
    const { t } = useTranslation()

    const [friendsCountText, setFriendsCountText] = useState('')
    const [isIntersecting, setIsIntersecting] = useState(false)

    const url = useMemo(() => getUrl(shop.id), [shop])

    const { data: friendsData } = useFetchFriendsStore({
        storeId: shop.id,
        limit: STORE_FRIENDS_SHOW_COUNT,
        with_count: true,
    }, {
        enabled: isIntersecting,
    })

    const handlerIntersecting = () => {
        setIsIntersecting(true)
    }

    function getUrl(id: string | number): string {
        return parseTpl(APP_URL.store, { ':id': id }, { prefix: '', suffix: '' })
    }

    useEffect(() => {
        if (friendsData) {
            setFriendsCountText(getStoreFriendsCountText(friendsData.count, [t('friend_1x'), t('friend_2x'), t('friend_5x')]))
        }
    }, [friendsData])

    return (
        <Card.Item classes={style.item} url={url} onIntersecting={handlerIntersecting}>
            <Card.Image src={shop.logo_image} width={400}>
                <div className={style.labels}>
                    {shop.kick_loyalty_settings && (
                        <div className={cn(styleLabel.label, styleLabel.labelWhite)}>
                            {shop.kick_loyalty_settings}
                        </div>
                    )}
                    {shop.kick_max_loyalty_settings && (
                        <div className={cn(styleLabel.label, styleLabel.labelBlue)}>
                            {shop.kick_max_loyalty_settings}
                        </div>
                    )}
                </div>
            </Card.Image>
            <Card.Body classes={style.body} title={shop.name} desc={shop.sub_title} />
            <Card.Footer>
                {friendsData?.users && (
                    <UsersAvatarGroup
                        containerClasses={!friendsCountText ? style.avatarGroupContainer : ''}
                        users={friendsData.users}
                        size={54}
                        desc={friendsCountText}
                    />
                )}
            </Card.Footer>
        </Card.Item>
    )
}

export default ShopItem
