import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TPayoutMarketAvailableSumProps, TMarketAvailableSumResponse } from 'services/PayoutService'
import * as userSelectors from 'containers/User/user-selectors'
import { PayoutService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TPayoutMarketAvailableSumProps
export type TError = string
export type TQueryKey = [string, TPayoutMarketAvailableSumProps]
export type TQueryFnOpts = UseQueryOptions<TMarketAvailableSumResponse, TError, TMarketAvailableSumResponse, TQueryKey>

const key = 'payoutAvailableSum'

/**
 * Хук API получить сумму вознаграждений в магазине
 */
export default function useFetchPayoutMarketAvailableSum(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutMarketAvailableSum(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
