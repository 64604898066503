import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ITicketMessageGroup } from 'interfaces'
import { useTimeAgo } from 'hooks'
import style from './TicketMessageGroup.module.css'

type TicketMessageGroupPropType = {
    data: ITicketMessageGroup
}

const TicketMessageGroup: React.FC<TicketMessageGroupPropType> = ({ children, data }) => {
    const { i18n } = useTranslation()

    const { getTimeAgo } = useTimeAgo({ today: 'today' }, i18n.language)

    const date = useMemo(() => {
        return data.date ? getTimeAgo(data.date) : undefined
    }, [data])

    return (
        <div className={style.group}>
            {date && (
                <div className={style.dateWrap}>
                    <div className={style.date}>
                        {date}
                    </div>
                </div>
            )}
            {children}
        </div>
    )
}

export default TicketMessageGroup
