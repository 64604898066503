import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TBasketUpdateProps } from 'services/MarketService'
import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    PageTitle,
    StoreNav,
    StoreBreadcrumbs,
    StoreContacts,
    StoreBannerGift,
    StoreBasketBar,
    Loader,
    ErrorMsg,
} from 'components'
import { ContentContainer, SideBarContainer } from 'layout'
import { useFetchStoreProfile, useFetchStoreCategories } from 'containers/Store/hooks'
import { useFetchGoods, useFetchBasket, useMutationBasket } from 'containers/Market/hooks'
import { StoreInfo } from 'containers'
import { useWindowResize } from 'hooks'
import { parseTpl, scrollTop } from 'utils/helpers'
import { GoodsContent } from './components'
import style from './Goods.module.css'

const CURRENT_NAV_TAB_ID = 1

const Goods: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const [storeId, setStoreId] = useState(0)
    const [catalogId, setCatalogId] = useState(0)
    const [error, setError] = useState('')

    const goodsId = useMemo(() => Number(id), [id])

    const {
        isInitialLoading: isLoadingGoods,
        data: dataGoods,
        error: errorGoods,
    } = useFetchGoods({ id: goodsId }, { enabled: !Number.isNaN(goodsId) })

    const { data: dataStoreProfile } = useFetchStoreProfile({ id: storeId }, { enabled: !!storeId })

    const { data: dataStoreCategories } = useFetchStoreCategories({ storeId }, { enabled: !!dataStoreProfile })

    const { data: dataBasket } = useFetchBasket({ storeId }, { enabled: !!dataStoreProfile })

    const { update: updateBasket } = useMutationBasket()

    const goodsQuantity: number = useMemo(() => {
        const goodsInBasket = dataBasket?.content?.find((item) => item.goods.id === Number(id))
        return goodsInBasket ? goodsInBasket.quantity : 0
    }, [dataBasket])

    const handlerAddToBasket = () => {
        if (goodsQuantity) {
            history.push(parseTpl(APP_URL.basket, { ':id': storeId }, { prefix: '', suffix: '' }))
        } else {
            addGoodsAction({ storeId, goods: Number(id), quantity: goodsQuantity + 1 })
        }
    }

    function addGoodsAction(params: TBasketUpdateProps) {
        updateBasket.mutate(params)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (Number.isNaN(goodsId)) {
            setError(t('market_product_not_found'))
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataGoods) {
            setStoreId(dataGoods.store.id)

            if (dataGoods.store) {
                setCatalogId(dataGoods.store.id)
            } else if (dataGoods.store_catalog?.length) {
                setCatalogId(dataGoods.store_catalog[0].id)
            }
        }
    }, [dataGoods])

    return (
        <>
            <ContentContainer
                classes={cn(
                    style.content,
                    { [style.content_bottomGap]: !!dataBasket?.content?.length },
                )}
                size="half"
            >
                <StoreNav
                    storeId={Number(storeId)}
                    activeId={CURRENT_NAV_TAB_ID}
                    data={dataStoreProfile}
                    basketCounts={dataBasket?.content?.length}
                />
                <PageTitle classes={style.breadcrumbs}>
                    <StoreBreadcrumbs
                        storeId={storeId}
                        catalogId={catalogId}
                        categories={dataStoreCategories}
                        goods={dataGoods}
                    />
                </PageTitle>

                {dataBasket && (
                    <StoreBannerGift basket={dataBasket} />
                )}

                {isLoadingGoods && (
                    <Loader />
                )}

                {!isLoadingGoods && (errorGoods || error) && (
                    <ErrorMsg error={(errorGoods && errorGoods[0]) || error} />
                )}

                {!!dataGoods && (
                    <GoodsContent
                        data={dataGoods}
                        inBasket={!!goodsQuantity}
                        onAddToBasket={handlerAddToBasket}
                    />
                )}

                {!!dataBasket?.content.length && (
                    <StoreBasketBar
                        storeId={storeId}
                        data={dataBasket}
                        storeProfile={dataStoreProfile}
                    />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    <StoreInfo data={dataStoreProfile} />

                    <StoreContacts data={dataStoreProfile} />
                </SideBarContainer>
            )}
        </>
    )
}

export default Goods
