import { ThunkAction } from 'redux-thunk'

import { IRootState } from 'interfaces'
// eslint-disable-next-line
import {
    unsubscribeUserActionType,
    unsubscribeStoreActionType,
    unsubscribeStoreUserActionType,
    unsubscribeCommunityActionType,
    unsubscribeCompanyActionType,
    blockUserActionType,
    unBlockUserActionType,
} from './network-actions-type'
import {
    UNSUBSCRIBE_USER,
    UNSUBSCRIBE_STORE,
    UNSUBSCRIBE_STORE_USER,
    UNSUBSCRIBE_COMMUNITY,
    UNSUBSCRIBE_COMPANY,
    BLOCK_USER,
    // UN_BLOCK_USER,
} from 'reducers/types'
import { NetworkService, CommunityService } from 'services'
import {
    TUnsubscribeUserProps,
    unsubscribeStorePropType,
    unsubscribeStoreUserPropType,
    unsubscribeCompanyPropType,
    TBlockUserProps,
    TUnblockUserProps,
} from 'services/NetworkService'
import { unsubscribeCommunityPropType } from 'services/CommunityService'
import errorLog from 'utils/errorLog'

export function unsubscribeUser(params: TUnsubscribeUserProps)
    : ThunkAction<Promise<void>, IRootState, unknown, unsubscribeUserActionType> {
    return async (dispatch) => {
        NetworkService.unsubscribeUser(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_USER, payload: params.userId })
        }).catch((err) => {
            errorLog('unsubscribeUser', err)
        })
    }
}

export function unsubscribeStore(params: unsubscribeStorePropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeStoreActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeStore(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_STORE, payload: params.storeId })
        }).catch((err) => {
            errorLog('unsubscribeStore', err)
        })
    }
}

export function unsubscribeStoreUser(params: unsubscribeStoreUserPropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeStoreUserActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeStoreUser(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_STORE_USER, payload: params })
            // TODO ? REMOVE_POSTS_FROM_STATE REMOVE_CHECKS_FROM_STATE
        }).catch((err) => {
            errorLog('unsubscribeStoreUser', err)
        })
    }
}

export function unsubscribeCommunity(params: unsubscribeCommunityPropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeCommunityActionType
    > {
    return async (dispatch) => {
        CommunityService.unsubscribeCommunity(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_COMMUNITY, payload: params.communityId })
        }).catch((err) => {
            errorLog('unsubscribeCommunity', err)
        })
    }
}

export function unsubscribeCompany(params: unsubscribeCompanyPropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeCompanyActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeCompany(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_COMPANY, payload: params.companyId })
        }).catch((err) => {
            errorLog('unsubscribeCompany', err)
        })
    }
}

export function blockUser(params: TBlockUserProps)
    : ThunkAction<Promise<string>, IRootState, unknown, blockUserActionType> {
    return (dispatch) => {
        return NetworkService.blockUser(params)
            .then(({ data }) => {
                dispatch({ type: BLOCK_USER, payload: params.userId })

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function unBlockUser(params: TUnblockUserProps)
    : ThunkAction<Promise<string>, IRootState, unknown, unBlockUserActionType> {
    return () => {
        return NetworkService.unBlockUser(params)
            .then(({ data }) => {
                // dispatch({ type: UN_BLOCK_USER, payload: params.userId })
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}
