import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IUserData } from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import {
    useFetchInfiniteMutualFriends,
    useFetchUsersData,
    useCacheUsersData,
    useFetchProfile,
} from 'containers/User/hooks'
import { FriendsUserCard } from 'containers/Friends/components'
import {
    PageTitle,
    Breadcrumbs,
    InfiniteScroll,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
// import style from './FriendsMutual.module.css'

const LIMIT_MUTUAL_FRIENDS = 12

const FriendsMutual: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()

    const [windowWidth] = useWindowResize()

    const [usersDataIds, setUsersDataIds] = useState('')
    const [usersData, setUsersData] = useState<Record<number, IUserData>>({})
    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isLoading: isLoadingMutualFriends,
        data: dataMutualFriends,
        error: errorMutualFriends,
        fetchNextPage: fetchNextPageMutualFriends,
    } = useFetchInfiniteMutualFriends({
        users: id,
        limit: LIMIT_MUTUAL_FRIENDS,
        offset: 0,
    }, {
        enabled: !!id,
        staleTime: 300 * 1000,
        cacheTime: 300 * 1000,
    })

    const { data: dataUsersData } = useFetchUsersData({
        users: usersDataIds,
        is_mutual_friends: 1,
        limit_friends: 3,
        is_checks: 1,
        is_subs_community: 1,
    }, {
        enabled: !!usersDataIds,
        staleTime: 300 * 1000,
        cacheTime: 300 * 1000,
    })

    const { data: dataProfile } = useFetchProfile({ userId: Number(id) }, { enabled: !Number.isNaN(id) })

    const { set: setCacheUsersData } = useCacheUsersData('mutual-users-data-records')

    const breadcrumbs: IBreadcrumb[] = useMemo(() => {
        const items = [
            { id: 1, name: t('friends'), url: APP_URL.friends },
            { id: 2, name: t('mutual_friends') },
        ]

        if (dataProfile && (dataProfile.name || dataProfile.surname)) {
            return [...items, { id: 3, name: `${dataProfile.name} ${dataProfile.surname}` }]
        }

        return items
    }, [dataProfile])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerLoad = () => {
        if (!isLoadingMutualFriends) {
            fetchNextPageMutualFriends()
        }
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (isHideLoader) {
            setIsHideLoader(false)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [id])

    useEffect(() => {
        if (dataMutualFriends) {
            const { pages } = dataMutualFriends
            const pagesLen = pages.length
            const lastPageLen = pagesLen ? pages[pagesLen - 1].length : 0
            const lastListLen = lastPageLen ? pages[pagesLen - 1][0].friends.length : 0

            if (lastListLen) {
                setUsersDataIds(pages[pagesLen - 1][0].friends.map((item) => item.id).join(','))
            }
            if (lastListLen < LIMIT_MUTUAL_FRIENDS) {
                setIsHideLoader(true)
            }
        }
    }, [dataMutualFriends])

    useEffect(() => {
        if (dataUsersData) {
            const usersDataItems = dataUsersData.reduce((acc, item) => {
                return { ...acc, [item.user.id]: item }
            }, {})

            const newUsersDataItems = setCacheUsersData(usersDataItems)

            if (newUsersDataItems) {
                setUsersData(newUsersDataItems)
            }
        }
    }, [dataUsersData])

    useEffect(() => {
        if (errorMutualFriends) {
            setIsHideLoader(true)
        }
    }, [errorMutualFriends])

    return (
        <ContentContainer size="half">
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {!isLoadingMutualFriends && errorMutualFriends && (
                <ErrorMsg error={errorMutualFriends} />
            )}

            {!isLoadingMutualFriends
                && !errorMutualFriends
                && !!dataMutualFriends?.pages.length
                && (!dataMutualFriends.pages[0].length || dataMutualFriends.pages[0][0].total === 0)
            && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            <CardContainer>
                <InfiniteScroll
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideLoader}
                    onLoad={handlerLoad}
                >
                    {dataMutualFriends?.pages.map((page, i) => (
                        page && !!page.length && (
                            // eslint-disable-next-line
                            <React.Fragment key={i}>
                                {page[0].friends.map((item) => (
                                    <FriendsUserCard
                                        isShowCounts
                                        user={item}
                                        size={itemSize}
                                        data={usersData[item.id]}
                                        key={item.id}
                                    />
                                ))}
                            </React.Fragment>
                        )
                    ))}
                </InfiniteScroll>
            </CardContainer>
        </ContentContainer>
    )
}

export default FriendsMutual
