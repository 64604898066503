import React, { useMemo } from 'react'
import cn from 'classnames'

import { IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Link } from 'components'
import style from './UserMessageFormWrap.module.css'

type UserMessageFormWrapPropType = {
    classes?: string
    user: IUser
}

const UserMessageFormWrap: React.FC<UserMessageFormWrapPropType> = ({
    children,
    classes,
    user,
}) => {
    const userUrl = useMemo(() => (user ? APP_URL.profile.replace(':id', String(user.id)) : undefined), [user])

    return (
        <div className={cn(style.wrap, classes)}>
            {user && (
                <Link url={userUrl}>
                    <Avatar
                        classes={style.avatar}
                        src={user.photo}
                        width={54}
                        height={54}
                        name={user.name}
                        surname={user.surname}
                    />
                </Link>
            )}

            <div className={style.body}>
                {children}
            </div>
        </div>
    )
}

export default UserMessageFormWrap
