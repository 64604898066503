import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import style from './CardBody.module.css'

export type CardBodyPropType = {
    fullHeight?: boolean
    title?: string
    desc?: string
    styles?: object
    classes?: string
    classesTitle?: string
    classesDesc?: string
    url?: string
    onClick?: (e: React.SyntheticEvent) => void
}

const CardBody: React.FC<CardBodyPropType> = ({
    children,
    fullHeight = true,
    title,
    desc,
    styles,
    classes,
    classesTitle,
    classesDesc,
    url,
    onClick,
}) => {
    const handlerClick = (e: React.SyntheticEvent) => {
        if (onClick) {
            onClick(e)
        }
    }

    return (
        <div className={cn(style.body, { [style.body_fullHeight]: fullHeight }, classes)} style={styles}>
            {title && (
                <>
                    {url ? (
                        <NavLink className={cn(style.title, style.title_link, classesTitle)} to={url}>
                            {title}
                        </NavLink>
                    ) : (
                        // eslint-disable-next-line
                        <div className={cn(style.title, { [style.title_link]: onClick }, classesTitle)} onClick={handlerClick}>
                            {title}
                        </div>
                    )}
                </>
            )}

            {desc && (
                <div className={cn(style.desc, classesDesc)}>
                    {desc}
                </div>
            )}

            {children}
        </div>
    )
}

export default CardBody
