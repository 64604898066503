import React, { useMemo } from 'react'

import { IPostFile } from 'interfaces'
import { Image, Video, DeleteButton } from 'components'
import { getFileTypeByMimeType } from 'utils/helpers'
import style from './PostContentFile.module.css'

export type PostContentFilePropType = {
    file: Partial<IPostFile>
    onDelete?: (id: number) => void
}

/**
 * View for single file image or video
 */
const PostContentFile: React.FC<PostContentFilePropType> = ({
    file,
    onDelete,
}) => {
    const fileOptions = useMemo(() => {
        const {
            src,
            type,
            width,
            height,
        } = file || {}

        return file ? {
            src,
            type,
            width,
            height,
            placeholderColor: 'placeholder_color' in file ? file.placeholder_color : undefined,
            previewSrc: 'preview_src' in file ? file.preview_src : undefined,
        } : {}
    }, [file])

    const { type: fileType } = useMemo(() => getFileTypeByMimeType(file?.type), [file])

    const handlerDelete = () => {
        if (file?.id && onDelete) {
            onDelete(file.id)
        }
    }

    return (
        <>
            {file ? (
                <>
                    {fileType === 'image' && (
                        <Image
                            classesWrap={style.wrap}
                            classesImage={style.image}
                            backgroundColor={fileOptions.placeholderColor}
                            src={fileOptions.src}
                            width={fileOptions.width}
                            height={fileOptions.height}
                            resize={{ width: 800, quality: 90 }}
                        />
                    )}
                    {fileType === 'video' && (
                        <Video
                            controls
                            classes={style.video}
                            styles={{ backgroundColor: fileOptions.placeholderColor }}
                            poster={fileOptions.previewSrc}
                            src={fileOptions.src}
                            type={fileOptions.type}
                        />
                    )}
                    {onDelete && (
                        <DeleteButton onClick={handlerDelete} />
                    )}
                </>
            ) : (
                <Image
                    classesImage={style.image}
                    src=""
                    width={800}
                    height={600}
                />
            )}
        </>
    )
}

export default PostContentFile
