import React from 'react'
import { useForm } from 'react-final-form'

import { BREAKPOINTS } from 'config/app'
import { Stickers } from 'components'
import { useWindowResize } from 'hooks'

type FieldStickersPropType = {
    classes?: string
    name: string
}

const FieldStickers: React.FC<FieldStickersPropType> = ({
    children,
    classes,
    name,
}) => {
    const [windowWidth] = useWindowResize()

    const { mutators } = useForm()

    const handlerClickSticker = (type: string, id: number) => {
        mutateFieldValue(`{[${type},${id}]}`)
    }

    function mutateFieldValue(value: string) {
        if (name in mutators) {
            mutators[name](name, value)
        }
    }

    return (
        <Stickers
            classes={classes}
            side={windowWidth < BREAKPOINTS.desktop ? 'right' : 'left'}
            trigger={children}
            onClick={handlerClickSticker}
        />
    )
}

export default FieldStickers
