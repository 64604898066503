import React from 'react'
import cn from 'classnames'

import { Button, Input, SvgResource } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from '../../PostForm.module.css'

type AttachControlsPropType = {
    classes?: string
    controlId?: number
    onAddFiles: (files: FileList, controlId?: number) => void
    onAddGoods: (controlId?: number) => void
    onAddImage: (controlId?: number) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'
const ACCEPT_VIDEO = 'video/mp4,video/mpeg,video/quicktime'

const AttachControls: React.FC<AttachControlsPropType> = ({
    classes,
    controlId,
    onAddFiles,
    onAddGoods,
    onAddImage,
}) => {
    const handlerAddFiles = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target

        if (files?.length) {
            onAddFiles(files, controlId)
        }
    }

    const handlerAddGoods = () => {
        onAddGoods(controlId)
    }

    const handlerAddImage = () => {
        onAddImage(controlId)
    }

    return (
        <div className={classes}>
            <Button
                classes={style.control}
                styleType="text"
                onClick={handlerAddImage}
            >
                <SvgResource
                    isImgTag={false}
                    classes={style.iconPhotoBank}
                    resourceKey="ic_photo_bank_picker_white_svg"
                    width={30}
                    height={30}
                />
            </Button>
            <label className={cn(style.control, styleForm.field, styleForm.field_attachIcon)}>
                <Input
                    multiple
                    classes={styleForm.inputFile}
                    type="file"
                    styleType="clear"
                    accept={ACCEPT_IMAGE}
                    onChange={handlerAddFiles}
                />
                <SvgResource
                    isImgTag={false}
                    classes={style.iconImage}
                    resourceKey="ic_picker_empty_svg"
                    width={30}
                    height={30}
                />
            </label>
            <label className={cn(style.control, styleForm.field, styleForm.field_attachIcon)}>
                <Input
                    classes={styleForm.inputFile}
                    type="file"
                    styleType="clear"
                    accept={ACCEPT_VIDEO}
                    onChange={handlerAddFiles}
                />
                <SvgResource
                    isImgTag={false}
                    classes={style.iconVideo}
                    resourceKey="video_picker_empty_svg"
                    width={30}
                    height={30}
                />
            </label>
            <Button
                classes={style.control}
                styleType="text"
                onClick={handlerAddGoods}
            >
                <SvgResource
                    isImgTag={false}
                    classes={style.iconGoods}
                    resourceKey="ic_goods_picker_svg"
                    width={30}
                    height={30}
                />
            </Button>
        </div>
    )
}

export default AttachControls
