import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isDateInThisWeek, dateTimeFormat, plural } from 'utils/helpers'

enum TimeNowParams {
    justNow = 'justNow',
    time = 'time'
}

enum TimeMinuteParams {
    ago = 'ago',
    time = 'time'
}

enum TimeHourParams {
    ago = 'ago',
    time = 'time'
}

enum TimeTodayParams {
    ago = 'ago',
    time = 'time',
    today = 'today'
}

enum TimeYesterdayParams {
    ago = 'ago',
    time = 'time',
    yesterday = 'yesterday'
}

enum TimeWeekdayParams {
    ago = 'ago',
    weekday = 'weekday'
}

enum TimeWeekParams {
    ago = 'ago',
    date = 'date'
}

enum TimeMonthParams {
    ago = 'ago',
    date = 'date'
}

type TTimeAgoProps = {
    now?: keyof typeof TimeNowParams
    minute?: keyof typeof TimeMinuteParams
    hour?: keyof typeof TimeHourParams
    today?: keyof typeof TimeTodayParams
    yesterday?: keyof typeof TimeYesterdayParams
    weekday?: keyof typeof TimeWeekdayParams
    week?: keyof typeof TimeWeekParams
    month?: keyof typeof TimeMonthParams
    default?: Intl.DateTimeFormatOptions
}

const TRANSLATION_WEEKDAY_OFFSET = 6

export default function useTimeAgo(params: TTimeAgoProps = {}, language?: string) {
    const { t } = useTranslation()

    const dayNames = useMemo(() => {
        return t('mo, tu, we, th, fr, sa, su').split(',')
    }, [])

    const getTimeAgo = (time: string | number | Date) => {
        if (!time) {
            return null
        }

        const date: Date = typeof time === 'object' ? time : new Date(time)
        const dateWeekday = date.getDay()
        const today: Date = new Date()

        const seconds = Math.floor((today.getTime() - date.getTime()) / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)

        // if (seconds < 60) {
        //     return 'about a minute ago'
        // }
        if (minutes === 0) {
            switch (params.now) {
                case TimeNowParams.time:
                    return dateTimeFormat(date, language, { hour: '2-digit', minute: '2-digit' })
                case TimeNowParams.justNow:
                default:
                    return t('just_now')
            }
        }
        if (minutes <= 50) {
            switch (params.minute) {
                case TimeMinuteParams.time:
                    return dateTimeFormat(date, language, { hour: '2-digit', minute: '2-digit' })
                case TimeMinuteParams.ago:
                default:
                    return `${minutes} ${plural(minutes, [
                        t('minute_one'),
                        t('minute_some'),
                        t('minute_many'),
                    ])} ${t('ago')}`
            }
        }
        // 1 hour
        if (minutes <= 60) {
            switch (params.hour) {
                case TimeHourParams.time:
                    return dateTimeFormat(date, language, { hour: '2-digit', minute: '2-digit' })
                case TimeHourParams.ago:
                default:
                    return `1 ${t('target_level_hour_one')} ${t('ago')}`
            }
        }
        // today
        if (date.getFullYear() === today.getFullYear()
            && date.getMonth() === today.getMonth()
            && date.getDate() === today.getDate()
        ) {
            switch (params.today) {
                case TimeTodayParams.time:
                    return dateTimeFormat(date, language, { hour: '2-digit', minute: '2-digit' })
                case TimeTodayParams.ago:
                    return `${hours} ${plural(hours, [
                        t('target_level_hour_one'),
                        t('target_level_hour_some'),
                        t('target_level_hour_many'),
                    ])} ${t('ago')}`
                case TimeTodayParams.today:
                default:
                    return t('today')
            }
        }
        // yesterday
        if (date.getFullYear() === today.getFullYear()
            && date.getMonth() === today.getMonth()
            && date.getDate() + 1 === today.getDate()) {
            switch (params.yesterday) {
                case TimeYesterdayParams.time:
                    return dateTimeFormat(date, language, { hour: '2-digit', minute: '2-digit' })
                case TimeYesterdayParams.ago:
                    return `${hours} ${plural(hours, [
                        t('target_level_hour_one'),
                        t('target_level_hour_some'),
                        t('target_level_hour_many'),
                    ])} ${t('ago')}`
                default:
                    return t('yesterday')
            }
        }
        // current week
        if (isDateInThisWeek(date)) {
            switch (params.weekday) {
                case TimeWeekdayParams.weekday:
                    return dayNames[(dateWeekday + TRANSLATION_WEEKDAY_OFFSET) % 7]
                case TimeWeekdayParams.ago:
                default:
                    return `${days} ${plural(days, [t('days_1x'), t('days_2x'), t('days_5x')])} ${t('ago')}`
            }
        }
        // week
        if (days <= 5) { // 7
            switch (params.week) {
                case TimeWeekParams.date:
                    return dateTimeFormat(date, language, params.default)
                case TimeWeekParams.ago:
                default:
                    return `${days} ${plural(days, [t('days_1x'), t('days_2x'), t('days_5x')])} ${t('ago')}`
            }
        }
        // month
        if (days <= (new Date(date.getFullYear(), date.getMonth() + 1, 0)).getDate()) {
            switch (params.month) {
                case TimeMonthParams.ago:
                    return `${days} ${plural(days, [t('days_1x'), t('days_2x'), t('days_5x')])} ${t('ago')}`
                case TimeMonthParams.date:
                default:
                    // like month more
                    dateTimeFormat(date, language, params.default)
            }
        }

        return dateTimeFormat(date, language, params.default)
    }

    return {
        getTimeAgo,
    }
}
