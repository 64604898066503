import React from 'react'
import cn from 'classnames'

import { IReport } from '../../../../interfaces'
import { ItemString } from '../index'
import style from './ItemFilter.module.css'

type ItemFilterPropType = {
    data: IReport
    classes?: string
    url?: string
    target?: '_blank'
    onClick?: () => void
}

const ItemFilter: React.FC<ItemFilterPropType> = ({
    data,
    classes,
    url,
    target,
    onClick = () => {},
}) => {
    return (
        <ItemString
            classes={cn(style.filter, classes, { [style.filter_clickable]: data?.filterId })}
            data={data}
            url={url}
            target={target}
            onClick={onClick}
        />
    )
}

export default ItemFilter
