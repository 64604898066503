import React from 'react'
import cn from 'classnames'

import style from './PopoverTriggerDots.module.css'

export type PopoverTriggerDotsPropType = {
    position?: 'horizontal' | 'vertical'
}

const PopoverTriggerDots: React.FC<PopoverTriggerDotsPropType> = ({ position = 'horizontal' }) => {
    return (
        <span className={cn(style.trigger, style[`trigger_${position}`])}>
            <span className={style.dot} />
            <span className={style.dot} />
            <span className={style.dot} />
        </span>
    )
}

export default PopoverTriggerDots
