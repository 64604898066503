import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IOrder } from 'interfaces'
import { TOrderProps } from 'services/MarketService'
import {
    APP_URL,
    MARKET_ORDER_STATUS_READY_PAYMENT,
    MARKET_ORDER_STATUS_WAITING_PAYMENT,
    MARKET_ORDER_STATUS_PAID,
    MARKET_ORDER_STATUS_3,
    MARKET_ORDER_STATUS_11,
    MARKET_ORDER_STATUS_15,
    MARKET_ORDER_STATUS_16,
    MARKET_ORDER_STATUS_200,
    EVENT_TYPE_ORDER_PAYMENT_SUCCESS,
    EVENT_TYPE_ORDER_PAYMENT_FAIL,
} from 'config/app'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    Iframe,
    ErrorMsg,
} from 'components'
import { useMutationBasket } from 'containers/Market/hooks'
import { useUserIsActive } from 'hooks'
import { MarketService } from 'services'
import {
    showAlertNotify,
    parseTpl,
    scrollTop,
    getRequestError,
} from 'utils/helpers'
import eventBus from 'utils/EventBus'

const PAYMENT_ACTIVE_USER_INTERVAL = 2 * 1000 // 2s
const PAYMENT_IN_ACTIVE_USER_INTERVAL = 5 * 1000 // 5s
const PAYMENT_TIMEOUT = 600 * 1000 // 10m

const StoreOrderPayment: React.FC = () => {
    const { id: storeId, orderId } = useParams<{ id: string, orderId: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const userIsActive = useUserIsActive()

    const [order, setOrder] = useState<IOrder>()
    const [errorOrder, setErrorOrder] = useState('')
    const [errorPayment, setErrorPayment] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [isRunCheckOrderPayment, setIsRunCheckOrderPayment] = useState(false)
    const [isLoadingCheckOrderPayment, setIsLoadingCheckOrderPayment] = useState(false)

    const { clear: clearBasket } = useMutationBasket()

    const storeUrl = useMemo(() => {
        return parseTpl(APP_URL.store, { ':id': storeId }, { prefix: '', suffix: '' })
    }, [])

    const basketUrl = useMemo(() => {
        return parseTpl(APP_URL.basket, { ':id': storeId }, { prefix: '', suffix: '' })
    }, [])

    const ordersUrl = useMemo(() => {
        return parseTpl(APP_URL.orders, { ':id': storeId }, { prefix: '', suffix: '' })
    }, [])

    const breadcrumbs: IBreadcrumb[] = useMemo(() => [
        { id: 1, name: t('orders'), url: ordersUrl },
        { id: 2, name: t('Order payment') },
    ], [ordersUrl])

    const handlerLoadOrderPayment = () => {
        // startCheckOrderPayment()
    }

    const handlerEventOrderPaymentSuccess = () => {
        clearBasketAction()
            .catch(() => {})
            .finally(() => {
                history.push(storeUrl)
            })
    }

    const handlerEventOrderPaymentFail = () => {
        //
    }

    // function startCheckOrderPayment() {
    //     setIsRunCheckOrderPayment(true)
    // }

    function stopCheckOrderPayment() {
        setIsRunCheckOrderPayment(false)
    }

    function orderPaymentSuccess() {
        stopCheckOrderPayment()
        showAlertNotify({
            type: 'success',
            action: EVENT_TYPE_ORDER_PAYMENT_SUCCESS,
            title: t('buy_congratulation'),
            message: t('buy_congratulation_text'),
            imageKey: 'baba_prazdnik_webp',
        })
    }

    function orderPaymentFail() {
        stopCheckOrderPayment()
        showAlertNotify({
            type: 'error',
            action: EVENT_TYPE_ORDER_PAYMENT_FAIL,
            title: '',
            message: t('buy_fail'),
        })
    }

    function orderPaymentTimeout() {
        history.push(basketUrl)
    }

    function fetchOrder() {
        setIsLoading(true)
        fetchOrderAction({ storeId: Number(storeId), orderId: Number(orderId) })
            .then(({ data }) => {
                const { payment_status: paymentStatus } = data || {}

                switch (paymentStatus) {
                    case MARKET_ORDER_STATUS_PAID:
                        setErrorPayment(t('Order has been paid successfully. You can see the status of your order in “Orders” tab.'))
                        break
                    case MARKET_ORDER_STATUS_READY_PAYMENT:
                    case MARKET_ORDER_STATUS_WAITING_PAYMENT:
                        setOrder(data)
                        break
                    case MARKET_ORDER_STATUS_3:
                    case MARKET_ORDER_STATUS_11:
                    case MARKET_ORDER_STATUS_15:
                    case MARKET_ORDER_STATUS_16:
                    case MARKET_ORDER_STATUS_200:
                        setErrorPayment(t('buy_fail'))
                        break
                    default:
                        //
                }
            })
            .catch((err) => {
                setErrorOrder(getRequestError(err))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function checkOrderPayment() {
        if (!isLoadingCheckOrderPayment && order?.id) {
            setIsLoadingCheckOrderPayment(true)
            fetchOrderAction({ storeId: Number(storeId), orderId: order.id })
                .then(({ data }) => {
                    const { payment_status: paymentStatus } = data || {}

                    switch (paymentStatus) {
                        case MARKET_ORDER_STATUS_PAID:
                            orderPaymentSuccess()
                            break
                        case MARKET_ORDER_STATUS_READY_PAYMENT: {
                            stopCheckOrderPayment()
                            break
                        }
                        case MARKET_ORDER_STATUS_WAITING_PAYMENT:
                            setOrder(data)
                            break
                        case MARKET_ORDER_STATUS_3:
                        case MARKET_ORDER_STATUS_11:
                        case MARKET_ORDER_STATUS_15:
                        case MARKET_ORDER_STATUS_16:
                        case MARKET_ORDER_STATUS_200:
                            orderPaymentFail()
                            break
                        default:
                            //
                    }
                })
                .catch(() => {})
                .finally(() => {
                    setIsLoadingCheckOrderPayment(false)
                })
        }
    }

    function fetchOrderAction(params: TOrderProps) {
        return MarketService.fetchOrder(params)
    }

    function clearBasketAction() {
        return clearBasket.mutateAsync({ storeId: Number(storeId) })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        fetchOrder()

        eventBus
            .on(EVENT_TYPE_ORDER_PAYMENT_SUCCESS, handlerEventOrderPaymentSuccess)
            .on(EVENT_TYPE_ORDER_PAYMENT_FAIL, handlerEventOrderPaymentFail)

        return () => {
            eventBus
                .off(EVENT_TYPE_ORDER_PAYMENT_SUCCESS, handlerEventOrderPaymentSuccess)
                .off(EVENT_TYPE_ORDER_PAYMENT_FAIL, handlerEventOrderPaymentFail)

            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        let intervalId = 0

        if (isRunCheckOrderPayment) {
            intervalId = window.setInterval(() => {
                checkOrderPayment()
            }, userIsActive ? PAYMENT_ACTIVE_USER_INTERVAL : PAYMENT_IN_ACTIVE_USER_INTERVAL)
        }

        return () => {
            if (intervalId) {
                window.clearInterval(intervalId)
            }
        }
    }, [isRunCheckOrderPayment, userIsActive])

    useEffect(() => {
        if (isRunCheckOrderPayment) {
            window.setTimeout(() => {
                orderPaymentTimeout()
            }, PAYMENT_TIMEOUT)
        }
    }, [isRunCheckOrderPayment])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            {!isLoading && (errorOrder || errorPayment) && (
                <ErrorMsg error={(errorOrder || errorPayment)} />
            )}

            {order?.payment_url && (
                <Iframe
                    title="payment"
                    src={order.payment_url}
                    onLoad={handlerLoadOrderPayment}
                />
            )}
        </>
    )
}

export default StoreOrderPayment
