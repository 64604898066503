import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TPayoutHistoryProps } from 'services/PayoutService'
import styleCommon from 'styles/modules/common.module.css'
import { WalletFilterField } from '../index'
import style from './WalletTransactionsFilter.module.css'

export enum FieldName {
    from = 'dateFrom',
    to = 'dateTo',
}

export type TWalletFilterProps = Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>

export type WalletFilterPropType = {
    classes?: string
    disabled?: boolean
    isOpen: boolean
    onChange: (value: TWalletFilterProps) => void
}

const WalletTransactionsFilter: React.FC<WalletFilterPropType> = ({
    classes,
    disabled,
    isOpen,
    onChange,
}) => {
    const { t } = useTranslation()

    const [filterProps, setFilterProps] = useState<TWalletFilterProps>({})

    const handlerChangeField = (name: keyof TWalletFilterProps, value?: string) => {
        if (filterProps[name] !== value) {
            setFilterProps((prevState) => ({ ...prevState, [name]: value }))
            onChange({ ...filterProps, [name]: value })
        }
    }

    return (
        <div className={cn(style.filter, classes, { [styleCommon.disabled]: disabled })}>
            <Collapse isOpened={isOpen}>
                <div className={style.fields}>
                    <WalletFilterField
                        classes={style.field}
                        placeholder={t('ui_posts_spinner_date_from_hint')}
                        onChange={(value) => handlerChangeField(FieldName.from, value)}
                    />
                    <WalletFilterField
                        classes={style.field}
                        placeholder={t('ui_posts_spinner_date_to_hint')}
                        onChange={(value) => handlerChangeField(FieldName.to, value)}
                    />
                </div>
            </Collapse>
        </div>
    )
}

export default WalletTransactionsFilter
