import React from 'react'
import { NavLink } from 'react-router-dom'

import { ImagePropType } from 'components/Image/Image'
import { Image } from 'components'
import style from './PostTileImage.module.css'

type PostTileImagePropType = {
    url?: string
} & ImagePropType

const PostTileImage: React.FC<PostTileImagePropType> = ({ url, ...props }) => {
    const imageTpl = (
        <Image
            isPlaceholder
            isImg={false}
            classesWrap={style.wrap}
            classesImage={style.image}
            {...props}
        />
    )

    return (
        url ? (
            <NavLink className={style.link} to={url}>
                {imageTpl}
            </NavLink>
        ) : (
            imageTpl
        )
    )
}

export default PostTileImage
