import { AxiosError } from 'axios'
import {
    UseInfiniteQueryOptions,
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
    useInfiniteQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TUserServiceError,
    TFriendsProps,
    TDeleteFriendsProps,
    TFriendsResponse,
    TDeleteFriendsResponse,
} from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TFriendsProps
export type TFetchFnResponse = TFriendsResponse
export type TFetchFnError = AxiosError<TUserServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

export type TDeleteFriendsMutationFnError = [string, AxiosError<TUserServiceError, TDeleteFriendsProps>]

const key = QueryCacheKeys.friends

/**
 * Хук API получить список друзей
 */
export default function useFetchFriends(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.fetchFriends(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteFriends(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return UserService.fetchFriends(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.list.length }
        },
        ...opts,
    })
}

export function useInvalidateFriends(
    params: TFetchFnParams, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

export function useMutationFriends() {
    const { t } = useTranslation()

    const deleteFriends = useMutation<
        TDeleteFriendsResponse,
        TDeleteFriendsMutationFnError,
        TDeleteFriendsProps,
        unknown
    >((props) => {
        return UserService.deleteFriends(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        deleteFriends,
    }
}
