import React from 'react'
import { TransitionGroup as ReactTransitionGroup } from 'react-transition-group'
// import { TransitionGroupProps as ReactTransitionGroupProps } from 'react-transition-group/TransitionGroup'

export type TransitionGroupPropType = {}

const TransitionGroup: React.FC<TransitionGroupPropType> = ({ children }) => {
    return (
        <ReactTransitionGroup>
            {children}
        </ReactTransitionGroup>
    )
}

export default TransitionGroup
