import { ThunkAction } from 'redux-thunk'

import {
    IRootState,
    IUser,
    IUserUpdateProps,
    IProfileProps,
    IGeolocation,
    IUserTag,
} from 'interfaces'
import {
    TUserTagsProps,
    TCountProps,
    TDeleteCountsProps,
    TAppLogoutProps,
    TLogoutResponse,
} from 'services/UserService'
import {
    AUTHORIZE_USER,
    FETCH_USER,
    FETCH_USER_PROFILE,
    UPDATE_USER,
    UPDATE_USER_PROFILE,
    SET_GEOLOCATION,
    FETCH_USER_TAGS,
    FETCH_COUNTS,
    FETCH_COUNT,
    RESET_COUNT,
    CLEAR_STATE,
} from 'reducers/types'
import { AppService, UserService } from 'services'
import errorLog from 'utils/errorLog'
import {
    authorizeUserActionType,
    fetchUserActionType,
    updateUserActionType,
    logoutUserActionType,
    updateProfileActionType,
    setGeolocationActionType,
    fetchProfileActionType,
    fetchTagsActionType,
    fetchCountsActionType,
    fetchCountActionType,
    deleteCountActionType,
} from './user-actions-type'

export function setGeolocation(data: IGeolocation): setGeolocationActionType {
    return {
        type: SET_GEOLOCATION,
        payload: data,
    }
}

export function authorizeUser(params: boolean): authorizeUserActionType {
    return {
        type: AUTHORIZE_USER,
        payload: params,
    }
}

export function fetchUser(): ThunkAction<Promise<IUser>, IRootState, unknown, fetchUserActionType> {
    return (dispatch) => {
        return UserService.fetchUsers()
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_USER, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function updateUser(params: IUserUpdateProps)
    : ThunkAction<Promise<IUser>, IRootState, unknown, updateUserActionType | updateProfileActionType> {
    return (dispatch) => {
        return UserService.updateUser(params)
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: UPDATE_USER, payload: data })
                    dispatch({ type: UPDATE_USER_PROFILE, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function fetchProfile(params: IProfileProps)
    : ThunkAction<Promise<void>, IRootState, unknown, fetchProfileActionType> {
    return async (dispatch) => {
        UserService.fetchProfile(params)
            .then(({ data }) => {
                dispatch({ type: FETCH_USER_PROFILE, payload: data })
            })
            .catch((err) => {
                errorLog('fetchProfile', err)
            })
    }
}

export function fetchUserTags(params: TUserTagsProps)
    : ThunkAction<Promise<IUserTag[]>, IRootState, unknown, fetchTagsActionType> {
    return (dispatch) => {
        return UserService.fetchUserTags(params)
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_USER_TAGS, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function fetchCounts()
    : ThunkAction<Promise<void>, IRootState, unknown, fetchCountsActionType> {
    return async (dispatch) => {
        UserService.fetchCounts()
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_COUNTS, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchCounts', err)
            })
    }
}

export function fetchCount(params: TCountProps)
    : ThunkAction<Promise<unknown>, IRootState, unknown, fetchCountActionType> {
    return (dispatch) => {
        return UserService.fetchCount(params)
            .then(({ data }) => {
                if (data) {
                    const { counter } = params
                    const count = AppService.getCounterCount(counter, data)

                    if (count !== undefined) {
                        dispatch({ type: FETCH_COUNT, payload: { counter, count } })
                    }
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

// TODO
export function deleteCounts() {
    //
}

export function deleteCount(params: TDeleteCountsProps)
    : ThunkAction<Promise<void>, IRootState, unknown, deleteCountActionType> {
    return async (dispatch) => {
        UserService.deleteCounts(params)
            .then(() => {
                const { counter } = params

                if (counter) {
                    dispatch({ type: RESET_COUNT, payload: { counter } })
                }
            })
            .catch((err) => {
                errorLog('deleteCount', err)
            })
    }
}

export function appLogoutUser(params?: TAppLogoutProps)
    : ThunkAction<Promise<void>, IRootState, unknown, logoutUserActionType> {
    return (dispatch, getState) => {
        const { geolocation, app } = getState()
        const {
            settings,
            languages,
            countries,
            resources,
        } = app

        return UserService.appLogout(params)
            .then(() => {
                dispatch({
                    type: CLEAR_STATE,
                    payload: {
                        // save store items after logout
                        geolocation,
                        app: {
                            settings,
                            languages,
                            countries,
                            resources,
                        },
                    },
                })
            })
            .catch((err) => {
                errorLog('appLogoutUser', err)
            })
    }
}

export function fullLogoutUser()
    : ThunkAction<Promise<TLogoutResponse>, IRootState, unknown, any> {
    return (dispatch, getState) => {
        const { geolocation, app } = getState()
        const {
            settings,
            languages,
            countries,
            resources,
        } = app

        return UserService.fullLogout()
            .then((data) => {
                if (data.success) {
                    dispatch({
                        type: CLEAR_STATE,
                        payload: {
                            // save store items after logout
                            geolocation,
                            app: {
                                settings,
                                languages,
                                countries,
                                resources,
                            },
                        },
                    })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function allLogoutUser()
    : ThunkAction<Promise<TLogoutResponse>, IRootState, unknown, any> {
    return (dispatch, getState) => {
        const { geolocation, app } = getState()
        const {
            settings,
            languages,
            countries,
            resources,
        } = app

        return UserService.allLogout()
            .then((data) => {
                if (data.success) {
                    dispatch({
                        type: CLEAR_STATE,
                        payload: {
                            // save store items after logout
                            geolocation,
                            app: {
                                settings,
                                languages,
                                countries,
                                resources,
                            },
                        },
                    })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}
