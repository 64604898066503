import React from 'react'
import cn from 'classnames'

import style from './InfoBlockFooter.module.css'

export type InfoBlockFooterPropType = {
    classes?: string
}

const InfoBlockFooter: React.FC<InfoBlockFooterPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.footer, classes)}>
            {children}
        </div>
    )
}

export default InfoBlockFooter
