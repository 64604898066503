import React, { useMemo } from 'react'
import cn from 'classnames'

import { IPostContent } from 'interfaces'
import style from './PostContentText.module.css'

export type PostContentTextPropType = {
    isTypeText?: boolean
    data: IPostContent
}

const PostContentText: React.FC<PostContentTextPropType> = ({ data, isTypeText = true }) => {
    const innerHtml = useMemo(() => {
        return isTypeText ? data.html : data.text
    }, [data])

    return (
        <>
            {innerHtml && (
                <div
                    className={cn({ [style.text]: isTypeText, [style.desc]: !isTypeText })}
                    dangerouslySetInnerHTML={{ __html: innerHtml }}
                />
            )}
        </>
    )
}

export default PostContentText
