import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { IResource } from 'interfaces'
import { IMAGE_PATH } from 'config/api'
import * as appSelectors from 'containers/App/app-selectors'

export default function useImgResource(resourceKey: string) {
    const resources = useSelector(appSelectors.resources)

    const [resource, setResource] = useState<IResource>()
    const [url, setUrl] = useState<string>()

    useEffect(() => {
        if (resources && resourceKey) {
            const res = resources.find((item) => item.key === resourceKey)

            if (res) {
                setResource(res)
                setUrl(`${IMAGE_PATH}/${res.filename}`)
            }
        }
    }, [resources, resourceKey])

    return { url, resource }
}
