import React, { useMemo } from 'react'
import cn from 'classnames'

import { Avatar } from 'components'
import style from './AvatarStack.module.css'

export type AvatarStackUserType = {
    id: number | string
    photo: string
    name?: string
    surname?: string
}

export type AvatarStackPropType = {
    classes?: string
    users: AvatarStackUserType[]
    count?: number
    size?: number
    borderSize?: number
    isKeepHeight?: boolean
}

const AvatarStack: React.FC<AvatarStackPropType> = ({
    classes,
    users = [],
    count = 3,
    size = 48,
    borderSize = 2,
    isKeepHeight = true,
}) => {
    const margin = useMemo(() => -Math.round(size * 0.4), [])

    return (
        <div className={cn(style.wrap, classes)} style={{ minHeight: isKeepHeight ? size + borderSize : 0 }}>
            {users.map((user, index) => (
                index < count && (
                    <Avatar
                        classes={style.image}
                        styles={{ marginLeft: index > 0 ? margin : 0, borderWidth: borderSize }}
                        src={user.photo}
                        name={user.name}
                        surname={user.surname}
                        width={size + borderSize}
                        height={size + borderSize}
                        key={user.id}
                    />
                )
            ))}
        </div>
    )
}

export default AvatarStack
