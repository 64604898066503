import { IUser, IProfile, IMessengerUser } from 'interfaces'

export default class UserDto {
    static getMessageUser(data: IUser | IProfile): IMessengerUser {
        const {
            id,
            name = '',
            surname = '',
            photo,
        } = data || {}

        return {
            id,
            name,
            surname,
            middleName: null,
            photoFullUrl: photo,
            lastLogin: '',
        }
    }
}
