import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'
import { MessengerItem } from 'containers/Messenger/components'
import { Avatar } from 'components'
import { useTimeAgo } from 'hooks'
import { dateTimeFormat } from 'utils/helpers'
import style from './MessengerItemUser.module.css'

type TUserData = {
    id: number
    name?: string
    surname?: string
    photo?: string
    photo_file_name?: string
    photoFullUrl?: string
    last_login_date?: string
    lastLogin?: string
}

type MessengerItemUserPropType = {
    classes?: string
    data: TUserData
    isActive?: boolean
    isHover?: boolean
    isProfileUrl?: boolean
    isChatUrl?: boolean
    isTime?: boolean
    isTimeInContent?: boolean
}

const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}

const MessengerItemUser: React.FC<MessengerItemUserPropType> = ({
    classes,
    data,
    isActive,
    isHover,
    isProfileUrl,
    isChatUrl,
    isTime,
    isTimeInContent,
}) => {
    const { i18n } = useTranslation()

    const { getTimeAgo } = useTimeAgo({
        now: 'time',
        minute: 'time',
        hour: 'time',
        today: 'time',
        yesterday: 'yesterday',
        weekday: 'weekday',
        week: 'date',
    }, i18n.language)

    const url = useMemo(() => {
        if (isProfileUrl) {
            return APP_URL.profile.replace(':id', String(data.id))
        }
        if (isChatUrl) {
            return APP_URL.messengerChat.replace(':id', String(data.id))
        }

        return undefined
    }, [data])

    const userPhoto = useMemo(() => {
        if ('photo' in data) {
            return data.photo
        }
        if ('photo_file_name' in data) {
            return data.photo_file_name
        }
        if ('photoFullUrl' in data) {
            return data.photoFullUrl
        }

        return ''
    }, [data])

    const time = useMemo(() => {
        if ('last_login_date' in data && data.last_login_date) {
            return dateTimeFormat(data.last_login_date, i18n.language, TIME_OPTIONS)
        }
        if ('lastLogin' in data && data.lastLogin) {
            return dateTimeFormat(data.lastLogin, i18n.language, TIME_OPTIONS)
        }
        return ''
    }, [data])

    const timeAgo = useMemo(() => {
        if ('last_login_date' in data && data.last_login_date) {
            return getTimeAgo(data.last_login_date)
        }
        if ('lastLogin' in data && data.lastLogin) {
            return getTimeAgo(data.lastLogin)
        }
        return ''
    }, [data])

    return (
        <MessengerItem
            classes={classes}
            classesContent={style.content}
            classesInfo={style.info}
            isActive={isActive}
            isHover={isHover}
            url={url}
            avatar={(
                <Avatar
                    name={data.name}
                    surname={data.surname}
                    src={userPhoto}
                    width={48}
                    height={48}
                />
            )}
            content={(
                <>
                    <div className={style.name}>
                        {`${data.name ?? ''} ${data.surname ?? ''}`}
                    </div>
                    {isTime && isTimeInContent && (
                        <div className={style.date} title={time}>
                            {timeAgo}
                        </div>
                    )}
                </>
            )}
            info={(
                <>
                    {isTime && !isTimeInContent && (
                        <div className={style.date} title={time}>
                            {timeAgo}
                        </div>
                    )}
                </>
            )}
        />
    )
}

export default MessengerItemUser
