import {
    IMessengerChat,
    IMessengerChannel,
    IHistoryMessage,
    IHistoryMessageData,
    IHistoryMessageGroup,
    IMessengerLastMessage,
    IMessengerUser,
    IMessengerWsConversationStatusMessageProps,
    IMessengerWsChannelMessageProps,
    IMessengerWsChatMessageProps,
    IMessengerWsEventMessageError,
    IMessengerWsForwardMessageProps,
    IMessengerWsReplyMessageProps,
    IMessengerWsStatusMessageProps,
} from 'interfaces'
import { MessengerMessageStatus, MessengerMessageType } from 'enums'
import { defaultDateFormat, getDateValues, getId } from 'utils/helpers'

type TReadConversationParams = { toUserId: number } | { channelId: string }

type TRetryChatMessageParams = Pick<IMessengerWsChatMessageProps['payload'], 'toUserId' | 'randomId' | 'text'>

type TRetryChannelMessageParam = Pick<IMessengerWsChannelMessageProps['payload'], 'toChannelId' | 'randomId' | 'text'>

type TMessage = {
    randomId: number
    text: string
    senderUser: IMessengerUser
} & ({ messageId: string } | { toUserId: number } | { toChannelId: string })

type TMessageErrorParams = {
    randomId: number
    text: string
} & ({ messageId: string } | { toUserId: number } | { toChannelId: string })

type TMessageProps = {
    text: string
} & ({ toUserId: number } | { toChannelId: string })

type TUnreadMessageParams = {
    messageIds: string[]
}

type TDeleteMessageParams = {
    messageId: string
}

type TForwardMessageParams = {
    forwardMessageId: string
    text?: string
} & ({ toUserId: number } | { toChannelId: string })

type TReplyMessageParams = {
    toMessageId: string
    text: string
}

/**
 * Search chat in conversation list by chat user receiver id
 */
function findConversationChat(chatUserId: number, conversationList: (IMessengerChat | IMessengerChannel)[] = []) {
    return conversationList.find((item): item is IMessengerChat => {
        return ('starterUserId' in item && item.starterUserId === chatUserId)
            || ('followerUserId' in item && item.followerUserId === chatUserId)
    })
}

/**
 * Search channel in conversation list by channel id
 */
function findConversationChannel(channelId: string, conversationList: (IMessengerChat | IMessengerChannel)[] = []) {
    return conversationList.find((item): item is IMessengerChannel => {
        return 'channelId' in item && item.channelId === channelId
    })
}

function updateLastMessageConversationList(
    data: IMessengerChat | IMessengerChannel,
    entityId: string | number | null, // chat id || channel id
    message: IMessengerLastMessage,
    senderUser?: IMessengerUser,
) {
    /** is channel */
    if (typeof entityId === 'string' && 'channelId' in data && data.channelId === entityId && senderUser) {
        return { ...data, lastMessage: { ...message, senderUser } }
    }

    /** is chat */
    if (typeof entityId === 'number' && 'starterUserId' in data && (data.starterUserId === entityId || data.followerUserId === entityId)) {
        return { ...data, lastMessage: message }
    }

    return data
}

function removeLastMessageConversationList(data: IMessengerChat | IMessengerChannel, messageId: string) {
    if (data.lastMessage?.id === messageId) {
        return { ...data, lastMessage: undefined }
    }
    return data
}

function getReadConversationProps(params: TReadConversationParams): IMessengerWsConversationStatusMessageProps {
    return {
        messageType: MessengerMessageType.chatStatus,
        payload: { status: MessengerMessageStatus.read, ...params },
    }
}

function getUnReadConversationProps(params: TReadConversationParams): IMessengerWsConversationStatusMessageProps {
    return {
        messageType: MessengerMessageType.chatStatus,
        payload: { status: MessengerMessageStatus.unread, ...params },
    }
}

/**
 * Доставленность определяем косвенно - если сообщение пришло с бэка, значит было доставлено
 */
function isReceivedLastMessage(senderUserId: number, lastMessage?: IMessengerLastMessage) {
    return !!lastMessage && lastMessage.senderUserId === senderUserId
}

/**
 * viewsCount > 0 - сообщение прочитано хотя бы 1 человеком (кроме автора)
 */
function isReadLastMessage(senderUserId: number, lastMessage?: IMessengerLastMessage) {
    if (lastMessage) {
        return lastMessage.senderUserId === senderUserId && !!lastMessage.viewsCount
    }
    return false
}

function isUnreadMessage(data: IHistoryMessage, userId: number) {
    return data.senderUserId !== userId && !data.viewsCount
}

/**
 * Получить объект сообщения с заполненными основными параметрами
 */
function getMessage({
    randomId,
    text,
    senderUser,
    ...params
}: TMessage): IHistoryMessage {
    const localId = getId(false)
    const date = defaultDateFormat(Date.now(), true)

    return {
        id: localId,
        _id: localId,
        randomId,
        text,
        senderUserId: senderUser.id,
        senderUser,
        receiverUserId: 'toUserId' in params ? params.toUserId : null,
        channelId: 'toChannelId' in params ? params.toChannelId : null,
        forwardedFromUserId: null,
        forwardedMessageId: null,
        forwardedMessage: null,
        inResponseToUserId: null,
        inResponseToMessageId: null,
        inResponseToMessage: null,
        viewsCount: 0,
        isDeleted: false,
        deletedAt: null,
        updatedAt: date,
        createdAt: date,
    }
}

function getMessageData(value: IHistoryMessage, isReceived: boolean = false, isError: boolean = false)
    : IHistoryMessageData {
    return {
        data: value,
        meta: { isReceived, isRead: !!value.viewsCount, isError },
    }
}

/**
 * Группировка сообщений по дате
 */
const getMessageGroups = (messages: IHistoryMessageData[]): IHistoryMessageGroup[] => {
    return messages.reduce<IHistoryMessageGroup[]>((acc, item, index, items) => {
        const date = new Date(item.data.createdAt)

        if (item.data.isDeleted) {
            return acc
        }
        if (acc.length && acc[0].messages.length) {
            const prevItem = acc[0].messages[0]
            const {
                year: prevYear,
                month: prevMonth,
                day: prevDay,
            } = getDateValues(new Date(prevItem.data.createdAt))
            const { year: currentYear, month: currentMonth, day: currentDay } = getDateValues(date)
            const isMatchDate = currentYear === prevYear && currentMonth === prevMonth && currentDay === prevDay

            // add message to first group
            if (isMatchDate) {
                return acc.map((groupItem, groupIndex) => {
                    if (groupIndex === 0) {
                        return { ...groupItem, messages: [item, ...groupItem.messages] }
                    }
                    return groupItem
                })
            }
        }
        // add message to new group
        return [{ id: getId(false), date: defaultDateFormat(date), messages: [item] }, ...acc]
    }, [])
}

function getMessageError(params: TMessageErrorParams): IMessengerWsEventMessageError {
    return {
        messageType: MessengerMessageType.error,
        payload: {
            errorCode: 0,
            errorMsg: '',
            requestData: params,
        },
    }
}

function getMessageProps(params: TMessageProps): IMessengerWsChatMessageProps | IMessengerWsChannelMessageProps {
    if ('toUserId' in params) {
        return {
            messageType: MessengerMessageType.chat,
            payload: { randomId: getId(true), ...params },
        }
    }

    return {
        messageType: MessengerMessageType.channel,
        payload: { randomId: getId(true), ...params },
    }
}

function getUnreadMessageProps({ messageIds }: TUnreadMessageParams): IMessengerWsStatusMessageProps {
    if (messageIds.length > 1) {
        return {
            messageType: MessengerMessageType.massStatus,
            payload: { messagesIds: messageIds, status: MessengerMessageStatus.read },
        }
    }

    return {
        messageType: MessengerMessageType.status,
        payload: { messageId: messageIds[0], status: MessengerMessageStatus.read },
    }
}

function getDeleteMessageProps({ messageId }: TDeleteMessageParams): IMessengerWsStatusMessageProps {
    return {
        messageType: MessengerMessageType.status,
        payload: { messageId, status: MessengerMessageStatus.deleted },
    }
}

function getRetryMessageProps(params: TRetryChatMessageParams | TRetryChannelMessageParam)
    : IMessengerWsChatMessageProps | IMessengerWsChannelMessageProps {
    if ('toUserId' in params) {
        return {
            messageType: MessengerMessageType.chat,
            payload: params,
        }
    }

    return {
        messageType: MessengerMessageType.channel,
        payload: params,
    }
}

function getForwardMessageProps(params: TForwardMessageParams): IMessengerWsForwardMessageProps {
    if ('toUserId' in params) {
        return {
            messageType: MessengerMessageType.forward,
            payload: { ...params, randomId: getId(true) },
        }
    }

    return {
        messageType: MessengerMessageType.forward,
        payload: { ...params, randomId: getId(true) },
    }
}

function getReplyMessageProps(params: TReplyMessageParams): IMessengerWsReplyMessageProps {
    return {
        messageType: MessengerMessageType.reply,
        payload: { ...params, randomId: getId(true) },
    }
}

/**
 * Get user id chat receiver
 */
function getChatUserId(userId: number, data: IMessengerChat) {
    return data.starterUserId === userId ? data.followerUserId : data.starterUserId
}

/**
 * Messenger helper hook
 */
export default function useMessenger() {
    return {
        findConversationChat,
        findConversationChannel,
        updateLastMessageConversationList,
        removeLastMessageConversationList,
        getReadConversationProps,
        getUnReadConversationProps,
        isReceivedLastMessage,
        isReadLastMessage,
        isUnreadMessage,
        getMessage,
        getMessageGroups,
        getMessageData,
        getMessageError,
        getMessageProps,
        getUnreadMessageProps,
        getDeleteMessageProps,
        getRetryMessageProps,
        getForwardMessageProps,
        getReplyMessageProps,
        getChatUserId,
    }
}
