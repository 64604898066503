import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IChannel, IFriend, IFriendProfile } from 'interfaces'
import { TAddChannelMemberProps } from 'services/ChatMicroService'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, Block } from 'layout'
import { Tag } from 'components'
import { useMutationChannelMembers } from 'containers/Messenger/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { useWindowResize } from 'hooks'
import { ChannelAction } from 'form-actions'
import { MessageForm } from 'forms'
import { showAlertNotify } from 'utils/helpers'
import {
    MessengerContainer,
    MessengerBody,
    MessengerFooter,
    MessengerMenu,
    MessengerFriends,
    MessengerSearch,
    MessengerSearchList,
    MessengerItemCheckBox,
    MessengerItemUser,
} from '../components'
import style from './MessengerChannelNew.module.css'

const MessengerChannelNew: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)

    const [channelMembers, setChannelMembers] = useState<Record<string, IFriend | IFriendProfile>>({})
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { add: addChannelMembers } = useMutationChannelMembers()

    const handlerChangeMember = (data: { id: number, name?: string, surname?: string }, value: boolean) => {
        setChannelMembers((prevState) => {
            if (!value) {
                const { [data.id]: memberId, ...state } = prevState
                return state
            }

            return { ...prevState, [data.id]: data }
        })
    }

    const handlerDeleteMember = (value: number) => {
        setChannelMembers((prevState) => {
            const { [value]: memberId, ...state } = prevState
            return state
        })
    }

    const handlerSearchFriend = (value: string) => {
        setSearchText(value)
    }

    const handlerCompleteChannel = ({ id }: IChannel) => {
        const members = Object
            .entries(channelMembers)
            .map(([key, value]) => {
                return { channelId: id, userId: value.id, isAdmin: false }
            })

        const request = members.map((item) => addChannelMembersAction(item))

        setIsLoading(true)
        Promise.all(request)
            .then(() => {
                history.push(APP_URL.messengerChannel.replace(':id', id))
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err?.length ? err[0] : t('update_error') })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handlerErrorChannel = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    function addChannelMembersAction(params: TAddChannelMemberProps) {
        return addChannelMembers.mutateAsync(params)
    }

    return (
        <>
            <ContentContainer size="half">
                <MessengerContainer>
                    <ChannelAction
                        isSetMembers={!!Object.keys(channelMembers).length}
                        isDisabled={isLoading}
                        onComplete={handlerCompleteChannel}
                        onError={handlerErrorChannel}
                    />
                    <div className={style.splitter} />
                    {!!Object.keys(channelMembers).length && (
                        <div className={cn(style.tags, { [style.tags_updating]: isLoading })}>
                            {Object.entries(channelMembers).map(([key, value]) => (
                                <Tag
                                    classes={style.tag}
                                    key={key}
                                    onDelete={() => handlerDeleteMember(value.id)}
                                >
                                    {`${value.name ?? ''} ${value.surname ?? ''}`}
                                </Tag>
                            ))}
                        </div>
                    )}
                    <MessengerBody
                        classes={cn({ [style.blockContent_updating]: isLoading })}
                    />
                    <MessengerFooter>
                        <Form
                            onSubmit={() => {}}
                            render={() => (
                                <MessageForm
                                    isDisabled
                                    isFocus={false}
                                    onSubmit={() => {}}
                                />
                            )}
                        />
                    </MessengerFooter>
                </MessengerContainer>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer classes={style.sidebar} position="left">
                    <Block classes={cn(style.blockSideBar, { [style.blockSideBar_updating]: isLoading })}>
                        <MessengerSearch
                            isCompact
                            classes={style.searchSideBar}
                            onSearch={handlerSearchFriend}
                        >
                            <MessengerMenu isSetMenuAddChannel={false} />
                        </MessengerSearch>
                        {!!searchText && (
                            <MessengerSearchList
                                classesTitle={style.searchListTitle}
                                classesItem={style.searchListItem}
                                user={user}
                                searchText={searchText}
                            />
                        )}
                        {!searchText && (
                            <MessengerFriends
                                render={(data) => (
                                    <MessengerItemCheckBox
                                        name={String(data.id)}
                                        checked={!!channelMembers[data.id]}
                                        key={data.id}
                                        onChange={(name, value) => handlerChangeMember(data, value)}
                                    >
                                        <MessengerItemUser
                                            data={data}
                                        />
                                    </MessengerItemCheckBox>
                                )}
                            />
                        )}
                    </Block>
                </SideBarContainer>
            )}
        </>
    )
}

export default MessengerChannelNew
