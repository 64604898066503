import React from 'react'
import { useTranslation } from 'react-i18next'

import { Chevron } from 'components'
import style from './MenuNavMore.module.css'

type MenuItemMorePropType = {
    onClick: () => void
}

const MenuNavMore: React.FC<MenuItemMorePropType> = ({ onClick }) => {
    const { t } = useTranslation()

    const handlerClick = () => {
        onClick()
    }

    return (
        <button className={style.more} type="button" onClick={handlerClick}>
            <span className={style.icon}>
                <Chevron classes={style.chevron} type="down" size={12} />
            </span>
            <span className={style.text}>
                {t('tab_more')}
            </span>
        </button>
    )
}

export default MenuNavMore
