import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'

import { APP_URL, STORE_TYPE_CPA_NO_GOODS, STORE_ID_STICKERS } from 'config/app'
import {
    useFetchStoreProfile,
    useFetchStoreCategories,
    useFetchStoreCatalog,
    useFetchStoreShowcase,
} from 'containers/Store/hooks'
import { useFetchBasket } from 'containers/Market/hooks'
import { Catalog } from 'containers'
import {
    Loader,
    PageTitle,
    ErrorMsg,
    StoreBreadcrumbs,
    StoreBannerGift,
} from 'components'
import { scrollTop } from 'utils/helpers'
import style from './StoreCatalog.module.css'

/**
 * StoreCatalog - user store catalog
 */
const StoreCatalog: React.FC = () => {
    const { id, catalogId } = useParams<{ id: string, catalogId?: string }>()
    const history = useHistory()

    const storeId = useMemo(() => Number(id), [id])
    const isStoreShowcase = useMemo(() => storeId === STORE_ID_STICKERS, [id])

    const {
        isInitialLoading: isLoadingStoreProfile,
        data: dataStoreProfile,
        error: errorStoreProfile,
    } = useFetchStoreProfile({
        id: storeId,
    }, {
        enabled: !Number.isNaN(storeId),
    })

    const {
        isInitialLoading: isLoadingStoreCatalog,
        data: dataStoreCatalog,
        error: errorStoreCatalog,
    } = useFetchStoreCatalog({
        storeId,
        node: catalogId ? Number(catalogId) : undefined,
        view: 'extra-plain',
    }, {
        enabled: !Number.isNaN(storeId) && !isStoreShowcase,
    })

    const {
        isInitialLoading: isLoadingStoreShowcase,
        data: dataStoreShowcase,
        error: errorStoreShowcase,
    } = useFetchStoreShowcase({
        storeId,
    }, {
        enabled: !!dataStoreProfile && isStoreShowcase,
    })

    const {
        data: dataStoreCategories,
    } = useFetchStoreCategories({
        storeId,
    }, {
        enabled: !!dataStoreProfile && !isStoreShowcase,
    })

    const {
        data: dataBasket,
    } = useFetchBasket({
        storeId,
    }, {
        enabled: !!dataStoreProfile,
    })

    function goToProfile() {
        history.push(generatePath(APP_URL.storeProfile, { id: storeId }))
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [catalogId])

    useEffect(() => {
        if (dataStoreProfile?.gateway_type === STORE_TYPE_CPA_NO_GOODS) {
            goToProfile()
        }
    }, [dataStoreProfile])

    return (
        <div className={style.catalog}>
            {isLoadingStoreProfile && (
                <Loader />
            )}

            {!isLoadingStoreProfile && errorStoreProfile && (
                <ErrorMsg error={errorStoreProfile[0]} />
            )}

            {!errorStoreProfile && (errorStoreCatalog || errorStoreShowcase) && (
                <ErrorMsg error={(errorStoreCatalog && errorStoreCatalog[0])
                    || (errorStoreShowcase && errorStoreShowcase[0])
                    || ''}
                />
            )}

            {!!catalogId && (
                <PageTitle classes={style.breadcrumbs}>
                    <StoreBreadcrumbs
                        storeId={storeId}
                        catalogId={Number(catalogId)}
                        categories={dataStoreCategories}
                    />
                </PageTitle>
            )}

            <StoreBannerGift basket={dataBasket} />

            {dataStoreProfile && (
                <Catalog
                    isSetItemLink
                    isShowBuyButton
                    isLoading={isLoadingStoreCatalog || isLoadingStoreShowcase}
                    storeProfile={dataStoreProfile}
                    data={dataStoreCatalog || dataStoreShowcase}
                />
            )}
        </div>
    )
}

export default StoreCatalog
