import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IOrder } from 'interfaces'
import { TOrdersProps } from 'services/MarketService'
import { MarketService } from 'services'

const key = 'storeOrders'

export type TFetchFnParams = TOrdersProps
export type TError = string
export type TQueryKey = [typeof key, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<IOrder[], TError, IOrder[], TQueryKey>

/**
 * Хук API получение списка заказов в магазине
 */
export default function useFetchStoreOrders(params: TFetchFnParams, {
    cacheTime = 30 * 1000, // 30s
    staleTime = 30 * 1000, // 30s
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return MarketService.fetchOrders(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                const [error] = err?.response?.data || []

                if (error && typeof error === 'string') {
                    return Promise.reject(error)
                }

                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
