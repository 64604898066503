import React from 'react'
import style from './SuggestField.module.css'

export type SuggestFieldPartType = {
    id: string
    highlight: boolean
    value: string
}

export type SuggestFieldItemType = {
    id: number
    parts: SuggestFieldPartType[]
}

export type SuggestFieldPropType = {
    suggest: SuggestFieldItemType
    onSelect: (id: number) => void
}

const SuggestField: React.FC<SuggestFieldPropType> = ({ suggest, onSelect }) => {
    const handlerSelect = () => {
        onSelect(suggest.id)
    }

    return (
        // eslint-disable-next-line
        <div className={style.item} onClick={handlerSelect}>
            {suggest?.parts.map((item) => (
                <span
                    className={item.highlight ? style.highlight : ''}
                    key={item.id}
                >
                    {item.value}
                </span>
            ))}
        </div>
    )
}

export default SuggestField
