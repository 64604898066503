export default function errorLog(
    str: string = '',
    data: any = null,
    mode: 'log' | 'error' = 'log', env: string = 'development',
) {
    if (process.env.NODE_ENV === env) {
        if (data) {
            // eslint-disable-next-line no-console
            console[mode](str, data)
        } else {
            // eslint-disable-next-line no-console
            console[mode](str)
        }
    }
}
