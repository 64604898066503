import React, { useMemo } from 'react'
import cn from 'classnames'

import { IChannelMember } from 'interfaces'
import { MS_CHAT_BASE_URL } from 'config/api'
import { Avatar } from 'components'
import style from './MessengerChannelAvatar.module.css'

type MessengerChannelAvatarPropType = {
    userId: number
    title: string
    image?: string | null
    members?: IChannelMember[]
}

const AVATAR_USER_COUNT = 2
// const MEMBER_PLACEHOLDER_DATA = {
//     _id: '',
//     id: '0',
//     userId: 0,
//     channelId: '',
//     joinDate: '',
//     lastMessageIdRead: null,
//     isAdmin: false,
//     isArchived: false,
//     createdAt: '',
//     updatedAt: '',
//     user: {
//         id: 0,
//         name: '',
//         surname: '',
//         middleName: null,
//         lastLogin: '',
//         photoFullUrl: undefined,
//     },
// }

// const getFakeMembers = (count: number) => {
//     return Array(count)
//         .fill({})
//         .map((item, index) => ({ ...MEMBER_PLACEHOLDER_DATA, id: String(index) }))
// }

const MessengerChannelAvatar: React.FC<MessengerChannelAvatarPropType> = ({
    userId,
    title,
    image,
    members,
}) => {
    const avatarUsers = useMemo(() => {
        if (!image && members) {
            return members.length > AVATAR_USER_COUNT
                ? members.filter((item) => item.userId !== userId && item.user)
                : members
            // const users = members.filter((item) => item.userId !== userId && item.user)
            //
            // return users.length < AVATAR_USER_COUNT
            //     ? [...users, ...getFakeMembers(AVATAR_USER_COUNT - users.length)]
            //     : users
        }

        return []
    }, [image, members])

    return (
        <>
            {!image && (!members || !avatarUsers.length) && (
                <Avatar
                    classes={style.image}
                    name={title}
                    surname=""
                    src=""
                    width={48}
                    height={48}
                />
            )}
            {image && (
                <Avatar
                    classes={style.image}
                    name={title}
                    surname=""
                    src={`${MS_CHAT_BASE_URL}/images/channels/${image}`}
                    width={48}
                    height={48}
                    isResize={false}
                />
            )}
            {!image && !!avatarUsers.length && avatarUsers.map((item, index) => (
                index < AVATAR_USER_COUNT && item.user && (
                    <Avatar
                        classes={cn(style.avatar, style[`avatar_${index + 1}`])}
                        name={item.user.name}
                        surname={item.user.surname}
                        src={item.user.photoFullUrl}
                        width={32}
                        height={32}
                        key={item.id}
                    />
                )
            ))}
        </>
    )
}

export default MessengerChannelAvatar
