import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store'

/**
 * Pre-typed versions of the useDispatch
 *
 * @see https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
 * @example
 * const thunkDispatch = useThunkDispatch()
 *
 * thunkDispatch(Action())
 *  .then(() => {})
 *  .catch(() => {})
 *  .finally(() => {})
 */
export default function useThunkDispatch() {
    return useDispatch<AppDispatch>()
}
