import { useState, useEffect } from 'react'

/**
 * Hook check internet connection
 */
export default function useOnline() {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    const handlerOnline = () => {
        setIsOnline(true)
    }

    const handlerOffline = () => {
        setIsOnline(false)
    }

    useEffect(() => {
        window.addEventListener('online', handlerOnline)
        window.addEventListener('offline', handlerOffline)

        return () => {
            window.removeEventListener('online', handlerOnline)
            window.removeEventListener('offline', handlerOffline)
        }
    }, [])

    return [isOnline]
}
