import React, { useMemo } from 'react'
import cn from 'classnames'

import { IPostFile } from 'interfaces'
import { EVENT_TYPE_MODAL_CONTENT_OPEN } from 'config/app'
import { Image } from 'components'
import eventBus from 'utils/EventBus'
import { PostAttachItem } from './components'
import style from './PostAttaches.module.css'

export type PostAttachesPropType = {
    files: IPostFile[]
    classes?: string
    isShowDesc?: boolean
}

/**
 * Component show post attached files
 * @param files
 * @param [classes]
 * @param [isShowDesc]
 */
const PostAttaches: React.FC<PostAttachesPropType> = ({ files = [], classes, isShowDesc = false }) => {
    const attaches = useMemo(() => {
        return files.filter((item) => !item.group)
    }, [files])

    const handlerClickAttach = (file: IPostFile, type: string) => {
        const { title } = file
        const contentData = {
            title,
            size: 'medium',
            contentView: getTpl(file, type),
        }

        eventBus.emit(EVENT_TYPE_MODAL_CONTENT_OPEN, contentData)
    }

    /**
     * Template attach content
     * @param file
     * @param type
     */
    function getTpl(file: IPostFile, type: string) {
        if (type === 'image') {
            return (
                <Image
                    src={file?.preview_src || file?.src}
                    width={file?.width}
                    height={file?.height}
                    backgroundColor={file?.placeholder_color}
                />
            )
        }
        if (type === 'video') {
            return (
                /* TODO ? Video component */
                <video controls className={style.video}>
                    <source src={file?.src} type={file?.type} />
                </video>
            )
        }

        return (
            <></>
        )
    }

    return (
        <>
            {!!attaches.length && (
                <div className={cn(style.attaches, isShowDesc ? style.attaches_column : style.attaches_row, classes)}>
                    {attaches.map((item) => (
                        <PostAttachItem
                            file={item}
                            isShowDesc={isShowDesc}
                            key={item.id}
                            onClick={handlerClickAttach}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default PostAttaches
