import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TStoreServiceError,
    TStoresProps,
    TStoresResponse,
} from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoresProps
export type TFetchFnError = AxiosError<TStoreServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TStoresResponse, TError, TStoresResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TStoresResponse,
    TError,
    TStoresResponse,
    TStoresResponse,
    TQueryKey
>

const key = QueryCacheKeys.storesProfiles

/**
 * Хук API получить список магазинов
 */
export default function useFetchStores(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStores(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteStores(initParams: TFetchFnParams = {}, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return StoreService.fetchStores(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}
