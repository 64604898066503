import React from 'react'
import cn from 'classnames'

import { BadgePropType } from 'components/Badge/Badge'
import { SvgResourcePropType } from 'components/SvgResource/SvgResource'
import { Badge, SvgResource } from 'components'
import style from './Icon.module.css'

type IconPropType = SvgResourcePropType & {
    classesWrap?: string
    count?: number
    notify?: boolean
    badge?: Pick<BadgePropType, 'backgroundColor' | 'color'>
}

const Icon: React.FC<IconPropType> = ({
    classesWrap,
    count,
    notify,
    badge,
    ...props
}) => {
    return (
        <div className={cn(style.wrap, classesWrap)}>
            <SvgResource
                {...props}
            />
            {!!count && (
                <Badge
                    animChanged
                    classes={style.badge}
                    size="small"
                    {...badge}
                >
                    {count}
                </Badge>
            )}
            {notify && (
                <div className={style.notify} />
            )}
        </div>
    )
}

export default Icon
