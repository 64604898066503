import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IUser } from 'interfaces'
import { Spinner } from 'components'
import {
    MessengerSearchItemChat,
    MessengerSearchItemChannel,
    MessengerSearchItemChatMessage,
    MessengerSearchItemChannelMessage,
    MessengerNothing,
} from 'containers/Messenger/components'
import {
    useFetchUserBelongsChannels,
    useFetchUserBelongsConversations,
    useFetchUserBelongsMessages,
} from 'containers/Messenger/hooks'
import style from './MessengerSearchList.module.css'

type SearchListPropType = {
    classes?: string
    classesTitle?: string
    classesItem?: string
    user: IUser
    searchText: string
}

const MessengerSearchList: React.FC<SearchListPropType> = ({
    classes,
    classesTitle,
    classesItem,
    user,
    searchText,
}) => {
    const { t } = useTranslation()

    const {
        isInitialLoading: isLoadingUserBelongsConversations,
        data: dataUserBelongsConversations,
    } = useFetchUserBelongsConversations({
        userId: user.id,
        withUsersInfo: true,
        searchText,
    }, {
        enabled: !!searchText,
    })

    const {
        isInitialLoading: isLoadingUserBelongsChannels,
        data: dataUserBelongsChannels,
    } = useFetchUserBelongsChannels({
        userId: user.id,
        searchText,
    }, {
        enabled: !!searchText,
    })

    const {
        isInitialLoading: isLoadingUserBelongsMessages,
        data: dataUserBelongsMessages,
    } = useFetchUserBelongsMessages({
        userId: user.id,
        withUsersInfo: true,
        withChannelsInfo: true,
        searchText,
    }, {
        enabled: !!searchText,
    })

    const isLoadingSearch = useMemo(() => {
        return isLoadingUserBelongsConversations || isLoadingUserBelongsChannels || isLoadingUserBelongsMessages
    }, [isLoadingUserBelongsConversations, isLoadingUserBelongsChannels, isLoadingUserBelongsMessages])

    return (
        <div className={cn(style.list, { [style.list_updating]: isLoadingSearch }, classes)}>
            {isLoadingSearch && (
                <Spinner position="top" size="medium" />
            )}

            {!isLoadingSearch
                && (dataUserBelongsChannels && !dataUserBelongsChannels.length)
                && (dataUserBelongsConversations && !dataUserBelongsConversations.length)
                && (dataUserBelongsMessages && !dataUserBelongsMessages.length)
            && (
                <MessengerNothing
                    nothingText={`${t('nothing_found')}\n${t('Please try again')}`}
                />
            )}

            {!!dataUserBelongsConversations?.length && (
                <div className={style.row}>
                    <div className={cn(style.rowTitle, classesTitle)}>
                        {t('chat_search_users')}
                    </div>
                    {dataUserBelongsConversations.map((item) => (
                        <MessengerSearchItemChat
                            classes={cn(style.listItem, classesItem)}
                            data={item}
                            user={user}
                            key={item.id}
                        />
                    ))}
                </div>
            )}
            {!!dataUserBelongsChannels?.length && (
                <div className={style.row}>
                    <div className={cn(style.rowTitle, classesTitle)}>
                        {t('chat_search_groups')}
                    </div>
                    {dataUserBelongsChannels.map((item) => (
                        <MessengerSearchItemChannel
                            classes={cn(style.listItem, classesItem)}
                            data={item}
                            user={user}
                            key={item.id}
                        />
                    ))}
                </div>
            )}
            {!!dataUserBelongsMessages?.length && (
                <div className={style.row}>
                    <div className={cn(style.rowTitle, classesTitle)}>
                        {t('chat_search_messages')}
                    </div>
                    {dataUserBelongsMessages.map((item) => (
                        <React.Fragment key={item.id}>
                            {'channelId' in item && item.channelId ? (
                                <MessengerSearchItemChannelMessage
                                    classes={cn(style.listItem, classesItem)}
                                    data={item}
                                    user={user}
                                    searchText={searchText}
                                />
                            ) : (
                                <MessengerSearchItemChatMessage
                                    classes={cn(style.listItem, classesItem)}
                                    data={item}
                                    user={user}
                                    searchText={searchText}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MessengerSearchList
