import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { APP_URL } from 'config/app'
import { Registration } from 'containers'
import { Footer } from 'components'
import style from './HomePage.module.css'

/**
 * Страница регистрации данных
 *
 * @name HomePage
 * @memberOf module:Layouts
 */
const HomePage: React.FC = () => {
    return (
        <>
            <div className={style.page}>
                <div className={style.content}>
                    <Switch>
                        <Route
                            exact
                            path={APP_URL.registration}
                            component={Registration}
                        />
                        <Redirect from="*" to={APP_URL.registration} />
                    </Switch>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default HomePage
