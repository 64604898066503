import React, {
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { DatePeriod } from 'enums'
import { REPORTS_DATE_MIN } from 'config/app'
import { Button, SvgResource, DatePickerNative } from 'components/index'
import {
    getWeekDateRange,
    getMonthDateRange,
    getYearDateRange,
    defaultDateFormat,
    dateTimeFormat,
} from 'utils/helpers'
import { DateRangeFilterPeriodAction, DateRangeFilterPreviewSelect } from './components'
import style from './DateRangeFilter.module.css'

export type DatePairType = {
    dateFrom: string
    dateTo: string
}

export type DateRangeFilterPropType = {
    classes?: string
    periods?: DatePeriod[]
    defaultPeriod?: DatePeriod
    defaultDateWeek?: Date
    defaultDateMonth?: Date
    defaultDateYear?: Date
    activeColor?: string
    minDate?: string
    onChange: ({ dateFrom, dateTo }: DatePairType, period: DatePeriod) => void
}

type PeriodsType = Record<string, DatePairType>

type ActionType = { type: DatePeriod, payload: DatePairType }

const PERIOD_TRANSLATION_KEYS = {
    [DatePeriod.week]: 'ui_charts_period_week',
    [DatePeriod.month]: 'ui_charts_period_month',
    [DatePeriod.year]: 'ui_charts_period_year',
    [DatePeriod.all]: 'ui_charts_period_all_time',
    [DatePeriod.custom]: 'ui_charts_period_week',
}

const getAllPeriodDatePair = (startDate?: string, endDate?: string): DatePairType => {
    return {
        dateFrom: defaultDateFormat(startDate),
        dateTo: defaultDateFormat(endDate),
    }
}

const getWeekList = () => {
    const DAYS_IN_WEEK = 7
    const WEEK_TIME = DAYS_IN_WEEK * 24 * 60 * 60 * 1000
    const dateEnd = new Date()
    const dateEndTime = dateEnd.getTime()
    const dateStart = new Date(dateEnd.getFullYear(), dateEnd.getMonth() - 6, dateEnd.getDate())
    const dateStartYear = dateStart.getFullYear()
    const dateStartMonth = dateStart.getMonth()
    const dateStartDayNumber = (dateStart.getDay() + 6) % DAYS_IN_WEEK
    const weeks = []

    let dateFirstDayWeek = new Date(dateStartYear, dateStartMonth, dateStart.getDate() - dateStartDayNumber)
    let nextDay = dateFirstDayWeek.getDate()

    const dateCorrectYear = dateFirstDayWeek.getFullYear()
    const dateCorrectMonth = dateFirstDayWeek.getMonth()

    while ((dateEndTime - dateFirstDayWeek.getTime()) > WEEK_TIME) {
        nextDay += DAYS_IN_WEEK
        dateFirstDayWeek = new Date(dateCorrectYear, dateCorrectMonth, nextDay)
        weeks.push(dateFirstDayWeek)
    }

    return weeks.reverse()
}

const getMonthList = (minDate: string) => {
    const MONTH_IN_YEAR = 12
    const date = new Date()
    const dateCurrentYear = date.getFullYear()
    const dateCurrentMonth = date.getMonth()
    const dateMin = new Date(minDate)
    const dateMinYear = dateMin.getFullYear()
    const dateMinMonth = dateMin.getMonth()
    const difYears = dateCurrentYear - dateMinYear
    const years = [...Array(difYears + 1).keys()].map((item) => dateMinYear + item)

    return years
        .reduce((acc, year, index) => {
            const months: number[] = []

            if (year === dateMinYear) {
                months.push(...[...Array(MONTH_IN_YEAR - dateMinMonth)].map((item, idx) => dateMinMonth + idx))
            } else if (year === dateCurrentYear) {
                months.push(...[...Array(dateCurrentMonth + 1)].map((item, idx) => idx))
            } else {
                months.push(...Array(MONTH_IN_YEAR).keys())
            }

            return [...acc, ...months.map((month) => new Date(year, month))]
        }, [] as Date[])
        .reverse()
}

const getYearList = (minDate: string) => {
    const date = new Date()
    const dateCurrentYear = date.getFullYear()
    const dateMin = new Date(minDate)
    const dateMinYear = dateMin.getFullYear()
    const difYears = dateCurrentYear - dateMinYear

    return [...Array(difYears + 1).keys()]
        .map((item) => new Date(dateMinYear + item, 0))
        .reverse()
}

const reducer = (state: PeriodsType, action: ActionType): PeriodsType => {
    switch (action.type) {
        case DatePeriod.week:
            return { ...state, [DatePeriod.week]: action.payload }
        case DatePeriod.month:
            return { ...state, [DatePeriod.month]: action.payload }
        case DatePeriod.year:
            return { ...state, [DatePeriod.year]: action.payload }
        case DatePeriod.custom:
            return { ...state, [DatePeriod.custom]: action.payload }
        case DatePeriod.all:
        default:
            return state
    }
}

const DateRangeFilter: React.FC<DateRangeFilterPropType> = ({
    classes,
    periods = [
        DatePeriod.week,
        DatePeriod.month,
        DatePeriod.year,
        DatePeriod.all,
        DatePeriod.custom,
    ],
    defaultPeriod = DatePeriod.month,
    defaultDateWeek,
    defaultDateMonth,
    defaultDateYear,
    activeColor,
    minDate = REPORTS_DATE_MIN,
    onChange,
}) => {
    const { t, i18n } = useTranslation()

    const [periodsState, dispatch] = useReducer(reducer, {
        [DatePeriod.week]: getWeekDateRange(defaultDateWeek),
        [DatePeriod.month]: getMonthDateRange(defaultDateMonth),
        [DatePeriod.year]: getYearDateRange(defaultDateYear),
        [DatePeriod.all]: getAllPeriodDatePair(minDate),
        [DatePeriod.custom]: { dateFrom: '', dateTo: '' },
    }, (state) => ({
        ...state,
        [DatePeriod.custom]: state[defaultPeriod],
    }))

    const [dateCurrent] = useState(new Date())
    const [activePeriod, setActivePeriod] = useState(defaultPeriod)

    const weekList = useMemo(() => {
        return getWeekList().map((date, index) => {
            const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
            const ext = { sliceRusYearStr: true }
            const dateTimeCurrent = dateCurrent.getTime()
            const dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6)
            const from = dateTimeFormat(date, i18n.language, options, ext)
            const to = dateTimeFormat(dateTo, i18n.language, options, ext)
            const dateTimeFrom = date.getTime()
            const dateTimeTo = dateTo.getTime()
            let value: string

            if (dateTimeCurrent >= dateTimeFrom && dateTimeCurrent <= dateTimeTo) {
                value = t('ui_charts_period_current_week')
            } else {
                value = `${from} - ${to}`
            }

            return { id: index, date, value }
        })
    }, [])

    const monthList = useMemo(() => {
        return getMonthList(minDate).map((date, index) => ({
            id: index,
            date,
            value: dateTimeFormat(date, i18n.language, {
                month: 'long',
                year: 'numeric',
            }, {
                sliceRusYearStr: true,
            }),
        }))
    }, [])

    const yearList = useMemo(() => {
        return getYearList(minDate).map((date, index) => {
            const dateCurrentYear = dateCurrent.getFullYear()
            const dateYear = date.getFullYear()
            let value: string

            if (dateCurrentYear === dateYear) {
                value = t('ui_charts_period_current_year')
            } else if (dateCurrentYear - 1 === dateYear) {
                value = t('ui_charts_period_previous_year')
            } else {
                value = dateTimeFormat(date, i18n.language, { year: 'numeric' })
            }

            return { id: index, date, value }
        })
    }, [])

    const dateWeek = useMemo(() => {
        const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
        const ext = { sliceRusYearStr: true }
        const dateTimeCurrent = dateCurrent.getTime()
        const { dateFrom, dateTo } = periodsState[DatePeriod.week]
        const from = dateTimeFormat(dateFrom, i18n.language, options, ext)
        const to = dateTimeFormat(dateTo, i18n.language, options, ext)
        const dateTimeFrom = Date.parse(dateFrom)
        const dateTimeTo = Date.parse(dateTo)

        if (dateTimeCurrent >= dateTimeFrom && dateTimeCurrent <= dateTimeTo) {
            return t('ui_charts_period_current_week')
        }

        return `${from} - ${to}`
    }, [periodsState[DatePeriod.week]])

    const dateMonth = useMemo(() => {
        const from = periodsState[DatePeriod.month].dateFrom
        return dateTimeFormat(from, i18n.language, { month: 'long', year: 'numeric' }, { sliceRusYearStr: true })
    }, [periodsState[DatePeriod.month]])

    const dateYear = useMemo(() => {
        const dateCurrentYear = dateCurrent.getFullYear()
        const from = periodsState[DatePeriod.year].dateFrom
        const year = new Date(from).getFullYear()

        if (dateCurrentYear === year) {
            return t('ui_charts_period_current_year')
        }
        if (dateCurrentYear - 1 === year) {
            return t('ui_charts_period_previous_year')
        }

        return dateTimeFormat(from, i18n.language, { year: 'numeric' })
    }, [periodsState[DatePeriod.year]])

    const dateAll = useMemo(() => {
        const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
        const ext = { sliceRusYearStr: true }
        const from = dateTimeFormat(periodsState[DatePeriod.all].dateFrom, i18n.language, options, ext)
        const to = dateTimeFormat(periodsState[DatePeriod.all].dateTo, i18n.language, options, ext)

        return `${from} - ${to}`
    }, [periodsState[DatePeriod.all]])

    const isDisabledPrevDate = useMemo(() => {
        const { dateFrom } = periodsState[activePeriod]
        const date = new Date(dateFrom)
        const dateReportsMinDate = new Date(minDate)

        switch (activePeriod) {
            case DatePeriod.week: {
                date.setDate(date.getDate() - 7)
                return date.getTime() < dateReportsMinDate.getTime()
            }
            case DatePeriod.month: {
                date.setMonth(date.getMonth() - 1)
                return date.getTime() < dateReportsMinDate.getTime()
            }
            case DatePeriod.year: {
                date.setFullYear(date.getFullYear() - 1)
                return date.getFullYear() < dateReportsMinDate.getFullYear()
            }
            default:
                return true
        }
    }, [periodsState])

    const isDisabledNextDate = useMemo(() => {
        const { dateFrom } = periodsState[activePeriod]
        const date = new Date(dateFrom)

        switch (activePeriod) {
            case DatePeriod.week: {
                date.setDate(date.getDate() + 7)
                return date.getTime() > dateCurrent.getTime()
            }
            case DatePeriod.month: {
                date.setMonth(date.getMonth() + 1)
                return date.getTime() > dateCurrent.getTime()
            }
            case DatePeriod.year: {
                date.setFullYear(date.getFullYear() + 1)
                return date.getFullYear() > dateCurrent.getFullYear()
            }
            default:
                return true
        }
    }, [periodsState])

    const handlerClickPeriod = (period: DatePeriod) => {
        // const periodName = e.currentTarget.name

        // toggle custom dates and set default periods
        if (activePeriod === DatePeriod.custom && activePeriod === period) {
            setActivePeriod(defaultPeriod)
            dispatch({ type: defaultPeriod, payload: periodsState[defaultPeriod] })
        } else {
            setActivePeriod(period as DatePeriod)
        }
        // set custom dates active periods
        dispatch({ type: DatePeriod.custom, payload: periodsState[period] })
    }

    const handlerClickWeek = (id: number) => {
        const week = weekList.find((item) => item.id === id)

        if (week) {
            dispatch({ type: DatePeriod.week, payload: getWeekDateRange(week.date) })
        }
    }

    const handlerClickMonth = (id: number) => {
        const month = monthList.find((item) => item.id === id)

        if (month) {
            dispatch({ type: DatePeriod.month, payload: getMonthDateRange(month.date) })
        }
    }

    const handlerClickYear = (id: number) => {
        const year = yearList.find((item) => item.id === id)

        if (year) {
            dispatch({ type: DatePeriod.year, payload: getYearDateRange(year.date) })
        }
    }

    const handlerClickPrevDate = () => {
        const { dateFrom } = periodsState[activePeriod]
        const date = new Date(dateFrom)

        if (activePeriod === DatePeriod.week) {
            date.setDate(date.getDate() - 7)
            dispatch({ type: DatePeriod.week, payload: getWeekDateRange(date) })
        }
        if (activePeriod === DatePeriod.month) {
            date.setMonth(date.getMonth() - 1)
            dispatch({ type: DatePeriod.month, payload: getMonthDateRange(date) })
        }
        if (activePeriod === DatePeriod.year) {
            date.setFullYear(date.getFullYear() - 1)
            dispatch({ type: DatePeriod.year, payload: getYearDateRange(date) })
        }
    }

    const handlerClickNextDate = () => {
        const { dateFrom } = periodsState[activePeriod]
        const date = new Date(dateFrom)

        if (activePeriod === DatePeriod.week) {
            date.setDate(date.getDate() + 7)
            dispatch({ type: DatePeriod.week, payload: getWeekDateRange(date) })
        }
        if (activePeriod === DatePeriod.month) {
            date.setMonth(date.getMonth() + 1)
            dispatch({ type: DatePeriod.month, payload: getMonthDateRange(date) })
        }
        if (activePeriod === DatePeriod.year) {
            date.setFullYear(date.getFullYear() + 1)
            dispatch({ type: DatePeriod.year, payload: getYearDateRange(date) })
        }
    }

    const handlerClickCustomDateFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(e.currentTarget.value)

        dispatch({
            type: DatePeriod.custom,
            payload: {
                dateFrom: defaultDateFormat(date),
                dateTo: periodsState[DatePeriod.custom].dateTo,
            },
        })
    }

    const handlerClickCustomDateTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(e.currentTarget.value)

        dispatch({
            type: DatePeriod.custom,
            payload: {
                dateFrom: periodsState[DatePeriod.custom].dateFrom,
                dateTo: defaultDateFormat(date),
            },
        })
    }

    const previewPeriod = useMemo(() => [{
        id: DatePeriod.week,
        list: weekList,
        value: dateWeek,
        handler: handlerClickWeek,
    }, {
        id: DatePeriod.month,
        list: monthList,
        value: dateMonth,
        handler: handlerClickMonth,
    }, {
        id: DatePeriod.year,
        list: yearList,
        value: dateYear,
        handler: handlerClickYear,
    }], [periodsState])

    useEffect(() => {
        onChange(periodsState[activePeriod], activePeriod)
    }, [periodsState, activePeriod])

    return (
        <div className={classes}>
            <div className={style.periods}>
                {periods.map((period) => (
                    <DateRangeFilterPeriodAction
                        isIcon={period === DatePeriod.custom}
                        isActive={period === activePeriod}
                        activeColor={activeColor}
                        name={period}
                        key={period}
                        onClick={handlerClickPeriod}
                    >
                        {period === DatePeriod.custom ? (
                            <SvgResource
                                classes={style.periodsCalendarIcon}
                                resourceKey="ic_kicks_calendar_svg"
                                width={20}
                                height={20}
                            />
                        ) : (
                            <>
                                {t(PERIOD_TRANSLATION_KEYS[period])}
                            </>
                        )}
                    </DateRangeFilterPeriodAction>
                ))}
            </div>
            <div className={style.date}>
                {activePeriod === DatePeriod.custom ? (
                    <div className={style.datePreviewGroup}>
                        <DatePickerNative
                            classesWrap={style.datePreviewGroupItem}
                            classes={style.datePreviewGroupAction}
                            min={minDate}
                            max={periodsState[DatePeriod.custom].dateTo}
                            value={periodsState[DatePeriod.custom].dateFrom}
                            onChange={handlerClickCustomDateFrom}
                        >
                            {periodsState[DatePeriod.custom].dateFrom}
                        </DatePickerNative>
                        <div className={style.datePreviewGroupDelimiter}>
                            -
                        </div>
                        <DatePickerNative
                            classesWrap={style.datePreviewGroupItem}
                            classes={style.datePreviewGroupAction}
                            min={periodsState[DatePeriod.custom].dateFrom}
                            max={defaultDateFormat(dateCurrent)}
                            value={periodsState[DatePeriod.custom].dateTo}
                            onChange={handlerClickCustomDateTo}
                        >
                            {periodsState[DatePeriod.custom].dateTo}
                        </DatePickerNative>
                    </div>
                ) : (
                    <>
                        <Button
                            classes={style.dateButtonArrow}
                            styleType="transparent"
                            disabled={isDisabledPrevDate}
                            onClick={handlerClickPrevDate}
                        >
                            <SvgResource
                                classes={style.dateArrowLeft}
                                resourceKey="business_wallet_details_arrow_right_black_svg"
                                width={18}
                                height={14}
                            />
                        </Button>
                        <div className={style.datePreview}>
                            {previewPeriod
                                .filter((period) => period.id === activePeriod)
                                .map((period) => (
                                    <DateRangeFilterPreviewSelect
                                        value={period.value}
                                        options={period.list}
                                        activeColor={activeColor}
                                        key={period.id}
                                        onChange={period.handler}
                                    />
                                ))}
                            {activePeriod === DatePeriod.all && dateAll}
                        </div>
                        <Button
                            classes={style.dateButtonArrow}
                            styleType="transparent"
                            disabled={isDisabledNextDate}
                            onClick={handlerClickNextDate}
                        >
                            <SvgResource
                                classes={style.dateArrowRight}
                                resourceKey="business_wallet_details_arrow_right_black_svg"
                                width={18}
                                height={14}
                            />
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

export default DateRangeFilter
