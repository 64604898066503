import {
    ICalendarDay,
    ICalendarHour,
    ICalendarPost,
    IDateValues,
} from 'interfaces'
import { DatePeriod } from 'enums'
import { defaultDateFormat } from 'utils/helpers'

const getDays = ({ year, month }: Omit<IDateValues, 'day' | 'hour' | 'minutes'>): ICalendarDay[] => {
    const dateCurrent = new Date()
    const dateStart = new Date(year, month, 1)
    const dateEnd = new Date(year, month + 1, 0)
    const dateStartIndex = dateStart.getDay()
    const daysCount = dateEnd.getDate()
    const startIndex = dateStartIndex || 7

    return Array(daysCount + startIndex - 1)
        .fill('')
        .map((item, index) => {
            const dateDayIndex = (index + 1) - (startIndex - 1)
            const dateDate = new Date(year, month, dateDayIndex)

            const dateYear = dateDate.getFullYear()
            const dateMonth = dateDate.getMonth()
            const dateDay = dateDate.getDate()

            const isCurrentYear = dateYear === dateCurrent.getFullYear()
            const isCurrentMonth = dateMonth === dateCurrent.getMonth()
            const isCurrentDay = isCurrentYear && isCurrentMonth && dateDay === dateCurrent.getDate()

            return {
                id: defaultDateFormat(dateDate),
                activeMonth: dateYear === year && dateMonth === month,
                currentMonth: isCurrentMonth,
                currentDay: isCurrentDay,
                date: dateDate,
                posts: [] as ICalendarPost[], // TODO ?
            }
        })
}

const getPosts = (dateValues: IDateValues, posts: ICalendarPost[], period: DatePeriod) => {
    const {
        year,
        month,
        day,
        hour,
    } = dateValues

    let timeStart: number
    let timeEnd: number

    switch (period) {
        case DatePeriod.hour: {
            const dateStart = new Date(year, month, day, hour, 0, 0)
            const dateStartHours = dateStart.getHours()

            return posts.filter((itemPost) => {
                const dateStartPost = new Date(itemPost.calendar_date_from)
                return dateStartHours === dateStartPost.getHours()
            })
        }
        case DatePeriod.day: {
            timeStart = (new Date(year, month, day, 0, 0, 0)).getTime()
            timeEnd = (new Date(year, month, day, 23, 59, 59)).getTime()

            return posts.filter((itemPost) => {
                const timeStartPost = (new Date(itemPost.calendar_date_from)).getTime()
                const timeEndPost = (new Date(itemPost.calendar_date_to)).getTime()

                return timeStartPost <= timeEnd && timeEndPost >= timeStart
            })
        }
        default:
            return []
    }
}

const sortPosts = (
    { year, month, day }: Omit<IDateValues, 'hour' | 'minutes'>,
    posts: ICalendarPost[],
    period: DatePeriod,
) => {
    switch (period) {
        case DatePeriod.hour: {
            return posts.sort((itemPostA, itemPostB) => {
                const dateEndItemPostA = new Date(itemPostA.calendar_date_to)
                const dateEndItemPostB = new Date(itemPostB.calendar_date_to)

                const timeEndItemPostA = (new Date(
                    year,
                    month,
                    day,
                    dateEndItemPostA.getHours(),
                    dateEndItemPostA.getMinutes(),
                    dateEndItemPostA.getSeconds(),
                )).getTime()

                const timeEndItemPostB = (new Date(
                    year,
                    month,
                    day,
                    dateEndItemPostB.getHours(),
                    dateEndItemPostB.getMinutes(),
                    dateEndItemPostB.getSeconds(),
                )).getTime()

                return timeEndItemPostA > timeEndItemPostB ? 1 : -1
            })
        }
        case DatePeriod.day:
            return posts.sort((itemPostA, itemPostB) => {
                const dateStartItemPostA = new Date(itemPostA.calendar_date_from)
                const dateStartItemPostB = new Date(itemPostB.calendar_date_from)

                const timeStartItemPostA = (new Date(
                    year,
                    month,
                    day,
                    dateStartItemPostA.getHours(),
                    dateStartItemPostA.getMinutes(),
                    dateStartItemPostA.getSeconds(),
                )).getTime()

                const timeStartItemPostB = (new Date(
                    year,
                    month,
                    day,
                    dateStartItemPostB.getHours(),
                    dateStartItemPostB.getMinutes(),
                    dateStartItemPostB.getSeconds(),
                )).getTime()

                return timeStartItemPostA > timeStartItemPostB ? 1 : -1
            })
        default:
            return posts
    }
}

export default function useCalendar() {
    const getMonthDays = (date: Date) => {
        const dateValues = { year: date.getFullYear(), month: date.getMonth() }
        return getDays(dateValues)
    }

    const getDayPosts = (date: Date, posts: ICalendarPost[], isSort: boolean = true): ICalendarPost[] => {
        const dateValues = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }
        const dayPosts = getPosts({ ...dateValues, hour: 0, minutes: 0 }, posts, DatePeriod.day)

        return isSort ? sortPosts(dateValues, dayPosts, DatePeriod.day) : dayPosts
    }

    const getHourPosts = (date: Date, posts: ICalendarPost[], isSort: boolean = true): ICalendarPost[] => {
        const dateValues = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate(),
            hour: date.getHours(),
            minutes: 0,
        }
        const hourPosts = getPosts(dateValues, posts, DatePeriod.hour)

        return isSort ? sortPosts(dateValues, hourPosts, DatePeriod.hour) : hourPosts
    }

    const getDaysPosts = (
        days: ICalendarDay[],
        posts: ICalendarPost[],
        isSort?: boolean,
    ): Record<string, ICalendarPost[]> => {
        return days.reduce((acc, item) => {
            const key = defaultDateFormat(item.date)
            const value = getDayPosts(item.date, posts, isSort)

            return value.length ? { ...acc, [key]: value } : acc
        }, {})
    }

    const getHoursPosts = (
        hours: ICalendarHour[],
        posts: ICalendarPost[],
        isSort?: boolean,
    ): Record<string, ICalendarPost[]> => {
        return hours.reduce((acc, item) => {
            const key = defaultDateFormat(item.date, true)
            const value = getHourPosts(item.date, posts, isSort)

            return value.length ? { ...acc, [key]: value } : acc
        }, {})
    }

    return {
        getMonthDays,
        getDaysPosts,
        getHoursPosts,
        getDayPosts,
    }
}
