import React, { useEffect } from 'react'

type callbackClickOutsideType = (target: null | EventTarget) => void

/**
 * Handle callback by click outside ref element
 */
export default function useOutsideClick(ref: React.RefObject<any>, callbackClickOutside: callbackClickOutsideType) {
    const handleClickOutside = (e: MouseEvent): void => {
        const { current } = ref || {}
        const { target } = e

        if (current && !current.contains(target)) {
            callbackClickOutside(target)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref.current])
}
