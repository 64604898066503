import React, { useMemo } from 'react'

import { IPost } from 'interfaces'
import { CardPropType } from 'components/Card/CardV2'
import { CardSize } from 'enums'
import { APP_URL, POST_FILE_GROUP_ID_1, POST_FILE_GROUP_ID_2 } from 'config/app'
import { Avatar, CardV2 } from 'components'
import { usePost } from 'hooks'
import { numberFormat } from 'utils/helpers'
import { PostTileFile, PostTileImage } from './components'
import style from './PostTile.module.css'

type PostTilePropType = {
    post: IPost
    classes?: string
    size?: CardPropType['size']
    isShowAvatar?: boolean
}

const IMAGE_SIZE = {
    width: 400,
    height: 400,
}

const IMAGE_RESIZE_OPTIONS = {
    width: 400,
    height: 400,
    quality: 90,
    fit: 'cover' as 'cover',
}

/**
 * Карточка поста - плитка
 */
const PostTile: React.FC<PostTilePropType> = ({
    classes,
    post,
    size = CardSize.thirdWidth,
    isShowAvatar = false,
}) => {
    const { getMainFiles } = usePost()

    const url = useMemo(() => {
        return post ? APP_URL.post.replace(':id', String(post.id)) : ''
    }, [post])

    const mainFile = useMemo(() => {
        const [file] = getMainFiles(post.files, [POST_FILE_GROUP_ID_1, POST_FILE_GROUP_ID_2])
        return file
    }, [post])

    const isShowAuthor = useMemo(() => {
        const { is_show_author } = post
        return is_show_author === undefined || is_show_author === null || is_show_author
    }, [post])

    const orderSum = useMemo(() => {
        const { order } = post

        if (order) {
            return order.left_currency_symbol_placement
                ? `${order.currency} ${numberFormat(order.order_sum)}`
                : `${numberFormat(order.order_sum)} ${order.currency}`
        }

        return ''
    }, [post])

    return (
        <CardV2
            classes={style.post}
            size={size}
        >
            {isShowAvatar && (isShowAuthor ? post.user.photo : post.community.logo) && (
                <Avatar
                    classes={style.avatar}
                    src={isShowAuthor ? post.user.photo : post.community.logo}
                    width={56}
                    height={56}
                    name={isShowAuthor ? post.user.name : post.community.title}
                    surname={isShowAuthor ? post.user.surname : undefined}
                    bgColor={!isShowAuthor ? post.community.color : undefined}
                />
            )}
            {post.order && (
                <div className={style.order}>
                    <span className={style.orderSum}>
                        {orderSum}
                    </span>
                </div>
            )}
            {mainFile && (
                <PostTileFile
                    file={mainFile}
                    url={url}
                    imgWidth={IMAGE_SIZE.width}
                    imgHeight={IMAGE_SIZE.height}
                    resize={IMAGE_RESIZE_OPTIONS}
                />
            )}
            {!mainFile && post.placeholder_picture && (
                <PostTileImage
                    url={url}
                    src={post.placeholder_picture}
                    width={IMAGE_SIZE.width}
                    height={IMAGE_SIZE.height}
                    resize={IMAGE_RESIZE_OPTIONS}
                />
            )}
        </CardV2>
    )
}

export default PostTile
