import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { IProfile } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/ReportForm/ReportForm'
import { TAddTicketProps } from 'services/SupportService'
import { APP_ID, APP_VERSION, SUPPORT_REPORT_ID } from 'config/app'
import { useMutationTicket } from 'containers/Support/hooks'
import { ReportForm } from 'forms'
import { useUserGUID } from 'hooks'

type ReportActionPropType = {
    profile?: IProfile
    onSuccess?: () => void
    onError?: (err: string) => void
}

const FORM_TEXT_LIMIT = 5000

const ReportAction: React.FC<ReportActionPropType> = ({
    profile,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()
    const guid = useUserGUID()

    const { add: addTicket } = useMutationTicket()

    const [initialValues] = useState<FormDataType>({
        ...formDataInitial,
        category: SUPPORT_REPORT_ID,
        member_id: profile?.account.member_id ?? '',
        member_email: profile?.email ?? '',
        member_name: `${profile?.name ?? ''} ${profile?.surname ?? ''}`,
        member_phone: profile?.user_phones?.length ? profile.user_phones[0].phone_number : '',
    })

    const [isSubmitting, setIsSubmitting] = useState(false)

    const userInfo = useMemo(() => {
        let info = ''

        if (profile) {
            const { id, name, surname } = profile
            info = `${name ?? ''} ${surname ?? ''} ID: ${id}`
        }

        return info
    }, [profile])

    const handlerSubmit = (params: FormDataType) => {
        const { subject, message_text, ...props } = params
        addTicketAction({
            ...props,
            app_version: `${APP_ID} ${APP_VERSION}`,
            os_version: window.navigator.userAgent,
            phone_model: window.navigator.userAgent,
            generated_user_id: guid,
            message_text: `${subject} ${userInfo}\n${message_text}`,
        })
    }

    function addTicketAction(params: TAddTicketProps) {
        setIsSubmitting(true)
        addTicket.mutate(params, {
            onSuccess: () => {
                onSuccess()
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
            onSettled: () => {
                setIsSubmitting(false)
            },
        })
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            mutators={{
                [FieldName.subject]: (
                    [key, data]: [FieldName.subject, { id: number, value: string }],
                    state,
                    { changeValue },
                ) => {
                    if ('value' in data) {
                        changeValue(state, key, () => data.value)
                    }
                },
            }}
            validate={(values) => {
                const errors = {}
                if (values[FieldName.subject] === undefined || values[FieldName.subject] === '') {
                    errors[FieldName.subject] = true
                }
                if (values[FieldName.message_text]) {
                    if (values[FieldName.message_text].length >= FORM_TEXT_LIMIT) {
                        errors[FieldName.message_text] = t('form_validation_length_limit').replace('%d', String(FORM_TEXT_LIMIT))
                    }
                }

                return errors
            }}
            render={({ handleSubmit }) => (
                <ReportForm
                    isSubmitting={isSubmitting}
                    textInfo={`${t('support_screen_complaint_info_text')} ${userInfo}`}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ReportAction
