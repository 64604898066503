import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { appLogoutUser } from 'containers/User/user-actions'

const Logout: React.FC = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(appLogoutUser())
    }, [])

    return (
        <div />
    )
}

export default Logout
