import React, {
    Children,
    useContext,
    useMemo,
    useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Context } from '../Context'
import CustomSelectSearch from '../CustomSelectSearch/CustomSelectSearch'
import style from './CustomSelectOptions.module.css'

export type CustomSelectOptionsPropType = {
    search?: string
    classes?: string
    classesList?: string
    searchPlaceholder?: string
    onOpen?: (open: boolean) => void
    onSearch?: (value: string) => void
}

const CustomSelectOptions: React.FC<CustomSelectOptionsPropType> = ({
    children,
    search,
    classes,
    classesList,
    searchPlaceholder,
    onOpen = () => {},
    onSearch,
}) => {
    const { t } = useTranslation()

    const { isOpen } = useContext(Context)

    const isShowList = useMemo(() => children && Children.count(children), [children])

    useEffect(() => {
        onOpen(isOpen)
    }, [isOpen])

    return (
        <div className={cn(style.options, classes, { [style.options_visible]: isOpen })}>
            {onSearch && (
                <CustomSelectSearch
                    search={search}
                    placeholder={searchPlaceholder}
                    onChange={onSearch}
                />
            )}
            {isShowList ? (
                <ul className={cn(style.list, classesList)}>
                    {children}
                </ul>
            ) : (
                <div className={style.noResult}>
                    {t('no_data')}
                </div>
            )}
        </div>
    )
}

export default CustomSelectOptions
