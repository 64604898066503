import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { dateTimeFormat, plural } from 'utils/helpers'

type TimeAgoPropType = {
    time: string | number | Date
    classes?: string
    options?: Intl.DateTimeFormatOptions
}

/**
 * @deprecated
 * useTimeAgo
 */
const TimeAgo: React.FC<TimeAgoPropType> = ({ time, classes, options }) => {
    const { t, i18n } = useTranslation()

    const timeAgo = useMemo(() => getTimeAgo(time), [time])

    function getTimeAgo(dateParam: string | number | Date) {
        if (!dateParam) {
            return null
        }

        const date: Date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
        // const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
        const today: Date = new Date()
        // const yesterday = new Date(today - DAY_IN_MS)
        const seconds = Math.floor((today.getTime() - date.getTime()) / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)
        // const isToday = today.toDateString() === date.toDateString()
        // const isYesterday = yesterday.toDateString() === date.toDateString()
        // const isThisYear = today.getFullYear() === date.getFullYear()

        // if (seconds < 5) {
        //     return 'now'
        // }
        // if (seconds < 60) {
        //     return `${seconds} seconds ago`
        // }
        // if (seconds < 90) {
        //     return 'about a minute ago'
        // }
        if (minutes === 0) {
            return t('just_now')
        }
        if (minutes <= 50) {
            return `${minutes} ${plural(minutes, [
                t('minute_one'),
                t('minute_some'),
                t('minute_many'),
            ])} ${t('ago')}`
        }
        if (minutes <= 60) {
            return `1 ${t('target_level_hour_one')} ${t('ago')}`
        }
        if (hours <= 23) {
            return `${hours} ${plural(hours, [
                t('target_level_hour_one'),
                t('target_level_hour_some'),
                t('target_level_hour_many'),
            ])} ${t('ago')}`
        }
        if (days <= 7) {
            return `${days} ${plural(days, [t('days_1x'), t('days_2x'), t('days_5x')])} ${t('ago')}`
        }
        // if (isToday) {
        //     return getFormattedDate(date, 'Today') // Today at 10:20
        // }
        // if (isYesterday) {
        //     return getFormattedDate(date, 'Yesterday') // Yesterday at 10:20
        // }
        // if (isThisYear) {
        //     return getFormattedDate(date, false, true) // 10. January at 10:20
        // }

        return dateTimeFormat(date, i18n.language, options)
    }

    return (
        <span className={classes}>
            {timeAgo}
        </span>
    )
}

export default TimeAgo
