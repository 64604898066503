import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IReport } from 'interfaces'
import { fetchReportsPropType } from 'services/ReportService'
import { QueryCacheKeys } from 'enums'
import { ReportService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = fetchReportsPropType
export type TError = string
export type TQueryKey = [string, fetchReportsPropType] | string[]
export type TQueryFnOpts = UseQueryOptions<IReport[], TError, IReport[], TQueryKey>

const key = QueryCacheKeys.reports

/**
 * Хук API получить отчеты
 */
export default function useFetchReports(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ReportService.fetchReports(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
