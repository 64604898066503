import React, { useMemo } from 'react'
import cn from 'classnames'

import { splitStr } from '../../utils/helpers'
import style from './ErrorMsg.module.css'

export type ErrorMsgPropType = {
    classes?: string
    classesItem?: string
    error: string
}

/**
 * Page error message
 * @param {string} [classes]
 * @param {string} [classesItem]
 * @param {string} [error]
 */
const ErrorMsg: React.FC<ErrorMsgPropType> = ({
    children,
    classes,
    classesItem,
    error = '',
}) => {
    const err = useMemo(() => splitStr(error), [error])

    return (
        <>
            {(!!err.length || children) && (
                <div className={cn(style.error, classes)}>
                    {err.map((item) => (
                        <div className={classesItem} key={item.id}>
                            {item.text}
                        </div>
                    ))}

                    {children}
                </div>
            )}
        </>
    )
}

export default ErrorMsg
