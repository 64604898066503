import React from 'react'
import { useTranslation } from 'react-i18next'

import { MenuList } from 'components'
// import style from './MessengerMenuConversation.module.css'

type MessengerMenuConversationPropType = {
    onMarkRead?: () => void
    onMarkUnRead?: () => void
}

const MessengerMenuConversation: React.FC<MessengerMenuConversationPropType> = ({
    onMarkRead,
    onMarkUnRead,
}) => {
    const { t } = useTranslation()

    const handlerMarkRead = () => {
        if (onMarkRead) {
            onMarkRead()
        }
    }

    const handlerMarkUnRead = () => {
        if (onMarkUnRead) {
            onMarkUnRead()
        }
    }

    return (
        <MenuList type="large">
            {onMarkRead && (
                <MenuList.Item
                    onClick={handlerMarkRead}
                >
                    {t('chat_mark_read')}
                </MenuList.Item>
            )}
            {onMarkUnRead && (
                <MenuList.Item
                    onClick={handlerMarkUnRead}
                >
                    {t('chat_mark_unread')}
                </MenuList.Item>
            )}
        </MenuList>
    )
}

export default MessengerMenuConversation
