import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Emoji, Link, TextContent } from 'components'
import { useTimeAgo } from 'hooks'
import { dateTimeFormat } from 'utils/helpers'
import { MessengerItem, MessengerStatusArrow } from 'containers/Messenger/components'
import style from './MessengerItemInner.module.css'

type MessengerItemInnerPropType = {
    classes?: string
    avatar: JSX.Element
    title: string
    message?: string | null
    messageLines?: 1 | 2
    date?: string
    senderName?: string
    url?: string
    content?: JSX.Element
    menu?: JSX.Element
    countNew?: number
    isSimpleMessage?: boolean
    isActive?: boolean
    isHover?: boolean
    isMarkedUnread?: boolean
    isReceived?: boolean
    isRead?: boolean
    isError?: boolean
}

const EMOJI_IMAGE_CODE = 128444 // &#128444;
const EMOJI_VIDEO_CODE = 127916 // &#127916;

const MessengerItemInner: React.FC<MessengerItemInnerPropType> = ({
    classes,
    title,
    message,
    messageLines = 1,
    date,
    senderName,
    url,
    avatar,
    content,
    menu,
    countNew,
    isActive,
    isHover,
    isSimpleMessage,
    isMarkedUnread,
    isReceived,
    isRead,
    isError,
}) => {
    const { i18n } = useTranslation()

    const { getTimeAgo } = useTimeAgo({
        now: 'time',
        minute: 'time',
        hour: 'time',
        today: 'time',
        yesterday: 'yesterday',
        weekday: 'weekday',
        week: 'ago',
    }, i18n.language)

    const time = useMemo(() => {
        return date ? dateTimeFormat(date, i18n.language, {
            year: '2-digit',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }) : ''
    }, [date])

    const timeAgo = useMemo(() => {
        return date ? getTimeAgo(date) : undefined
    }, [date])

    const isSimpleMessageImage = useMemo(() => {
        if (isSimpleMessage && message) {
            return message.includes('.jpg') || message.includes('.png') || message.includes('.gif')
        }
        return false
    }, [message])

    const isSimpleMessageVideo = useMemo(() => {
        if (isSimpleMessage && message) {
            return message.includes('.mp4') || message.includes('.avi') || message.includes('.mov')
        }
        return false
    }, [message])

    return (
        <MessengerItem
            isActive={isActive}
            isHover={isHover}
            classes={classes}
            avatar={avatar}
            content={(
                <>
                    <Link className={style.title} url={url}>
                        {title}
                    </Link>
                    {(senderName || message) && (
                        <Link className={style.message} url={url}>
                            {senderName && (
                                <span className={style.messageSender}>
                                    {`${senderName}:`}
                                </span>
                            )}
                            {message && !isSimpleMessageImage && !isSimpleMessageVideo && (
                                <TextContent
                                    classesContent={cn(
                                        style.messageText,
                                        style[`messageText_lines-${messageLines}`],
                                        { [style.messageText_read]: isRead },
                                    )}
                                    content={message}
                                />
                            )}
                            {isSimpleMessageImage && (
                                <Emoji
                                    classes={style.messageText}
                                    code={EMOJI_IMAGE_CODE}
                                    label="image"
                                />
                            )}
                            {isSimpleMessageVideo && (
                                <Emoji
                                    classes={style.messageText}
                                    code={EMOJI_VIDEO_CODE}
                                    label="video"
                                />
                            )}
                        </Link>
                    )}
                    {content && (
                        <div className={style.content}>
                            {content}
                        </div>
                    )}
                    {(isMarkedUnread || !!countNew) && (
                        <div className={style.count}>
                            {isMarkedUnread && (
                                <div className={style.countInformer} />
                            )}
                            {!isMarkedUnread && !!countNew && (
                                <div className={style.countInformer}>
                                    {countNew}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            info={(
                <>
                    {timeAgo && (
                        <div className={style.date} title={time}>
                            {timeAgo}
                        </div>
                    )}
                    {/** isRead cannot be without isReceived */}
                    {!isError && isReceived && (
                        <div className={style.status}>
                            {isReceived && (
                                <MessengerStatusArrow />
                            )}
                            {isRead && (
                                <MessengerStatusArrow />
                            )}
                        </div>
                    )}
                </>
            )}
            menu={menu}
        />
    )
}

export default MessengerItemInner
