import React, { useEffect, useState } from 'react'

import { ICommentProps } from 'interfaces'
import { CommentForm } from 'forms'
import { useMutationComments } from 'containers/Comments/hooks'
import { SimService } from 'services'
import { showAlertNotify, getFileImageElement } from 'utils/helpers'

export type CommentActionPropType = {
    classes?: string
    commentProps: ICommentProps
    onComplete?: () => void
}

export type FormDataType = ICommentProps

const CommentAction: React.FC<CommentActionPropType> = ({
    classes,
    commentProps,
    onComplete = () => {},
}) => {
    const [formData, setFormData] = useState<FormDataType>(commentProps)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { add: mutateAddComment } = useMutationComments({ postId: commentProps.postId })

    const handlerSubmit = (comment: string) => {
        addCommentAction(commentProps.postId, comment, commentProps.parent)
    }

    const handlerAddAttach = (file: File) => {
        fileUploadAction(file)
            .then(({ path }) => {
                getFileImageElement(file)
                    .then(({ width, height }) => {
                        const url = `${path}?sswidth=${width}&ssheight=${height}`
                        addCommentAction(commentProps.postId, url, commentProps.parent) // TODO ? commentProps -> formData
                    })
                    .catch(() => {
                        addCommentAction(commentProps.postId, path, commentProps.parent)
                    })
            })
            .catch(() => {
                //
            })
    }

    function addCommentAction(postId: number, comment: string, parent?: number) {
        setIsSubmitting(true)
        mutateAddComment({ postId, comment, parent }, {
            onSuccess: () => {
                onComplete()
            },
            onError: (error) => {
                showAlertNotify({ type: 'error', message: error })
            },
            onSettled: () => {
                setIsSubmitting(false)
            },
        })
    }

    function fileUploadAction(file: File) {
        setIsSubmitting(true)
        return SimService.filesUpload(file)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return Promise.reject()
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        setFormData(commentProps)
    }, [commentProps])

    return (
        <CommentForm
            classes={classes}
            data={formData}
            isSubmitting={isSubmitting}
            onAddAttach={handlerAddAttach}
            onSubmit={handlerSubmit}
        />
    )
}

export default CommentAction
