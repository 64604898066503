import React, { useEffect, useRef } from 'react'

import {
    IMessengerWsConversationStatusMessageProps,
    IMessengerWsEventMessageChatStatus,
    IMessengerWsEventMessageNew,
} from 'interfaces'
import { API_URL, WSS_CHAT_BASE_URL, WSS_CHAT_TOKEN } from 'config/api'
import { useWebSocket, useOnline } from 'hooks'

export type TEventMessage = IMessengerWsEventMessageChatStatus | IMessengerWsEventMessageNew

type TMessageProps = IMessengerWsConversationStatusMessageProps

type MessageEventActionPropType = {
    message?: IMessengerWsConversationStatusMessageProps
    onMessage?: (data: TEventMessage) => void
    onError?: (data: TMessageProps) => void
}

const MessageConversationAction: React.FC<MessageEventActionPropType> = ({
    message,
    onMessage = () => {},
    onError = () => {},
}) => {
    const isMounted = useRef(false)
    const [isOnline] = useOnline()

    const {
        send: wsSend,
        readyState: wsReadyState,
    } = useWebSocket<TEventMessage>(`${WSS_CHAT_BASE_URL}/${API_URL.wssChat}`, {
        enabled: isMounted.current,
        accessToken: WSS_CHAT_TOKEN,
        onMessage: handlerMessage,
        onError: () => {},
    })

    function handlerMessage(e: TEventMessage) {
        onMessage(e)
    }

    function sendMessageAction(params: TMessageProps, callback?: () => void) {
        if (isOnline && wsReadyState() === WebSocket.OPEN) {
            wsSend(JSON.stringify(params))

            if (callback) {
                callback()
            }
        } else {
            onError(params)
        }
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (message) {
            sendMessageAction(message)
        }
    }, [message])

    return null
}

export default MessageConversationAction
