import React from 'react'

type EmojiPropType = {
    code: number // HTML Dec Code &#128444; => 128444
    classes?: string
    label?: string
}

const Emoji: React.FC<EmojiPropType> = ({ classes, code, label = 'image' }) => {
    return (
        <span className={classes} role="img" aria-label={label}>
            {String.fromCodePoint(code)}
        </span>
    )
}

export default Emoji
