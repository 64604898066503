import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    Input,
    SvgResource,
    Stickers,
    TextContentEditable,
} from 'components'

import styleInput from 'components/Input/Input.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './CommentForm.module.css'

export type FormDataType = {
    comment: string
}

export type CommentFormPropType = {
    classes?: string
    data: FormDataType
    isSubmitting: boolean
    onAddAttach: (file: File) => void
    onSubmit: (comment: string) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

const CommentForm: React.FC<CommentFormPropType> = ({
    classes,
    data,
    isSubmitting,
    onAddAttach,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const [formData, setFormData] = useState<FormDataType>(data)

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const handlerSubmitByEnter = (comment: string) => {
        setFormData((prevState) => ({ ...prevState, comment }))
        onSubmit(comment)
    }

    const handlerChangeContent = (comment: string) => {
        setFormData((prevState) => ({ ...prevState, comment }))
    }

    const handlerClickSticker = (type: string, id: number) => {
        setFormData((prevState) => ({
            ...prevState,
            comment: `${prevState.comment}{[${type},${id}]}`,
        }))
    }

    const handlerClickAttach = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target
        const [file] = files || []

        if (file) {
            onAddAttach(file)
        }
    }

    useEffect(() => {
        setFormData(data)
    }, [data])

    return (
        <form className={cn(style.form, { [styleForm.submitting]: isSubmitting }, classes)} onSubmit={handlerSubmit}>
            <TextContentEditable
                classes={cn(style.input, styleInput.gray)}
                value={formData.comment}
                placeholder={t('Add comment')}
                onChange={handlerChangeContent}
                onSubmitByEnter={handlerSubmitByEnter}
            />

            <div className={style.actions}>
                <Stickers
                    classes={cn(style.action, style.action_stickers)}
                    onClick={handlerClickSticker}
                />

                <label
                    className={cn(style.action, styleForm.field, styleForm.field_attachIcon)}
                    title={t('add_attach')}
                >
                    <Input
                        // multiple
                        classes={styleForm.inputFile}
                        type="file"
                        styleType="clear"
                        accept={ACCEPT_IMAGE}
                        onChange={handlerClickAttach}
                    />
                    <SvgResource
                        resourceKey="ic_attach_svg"
                        width={26}
                        height={32}
                    />
                </label>
            </div>
        </form>
    )
}

export default CommentForm
