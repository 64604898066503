import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    SetDataOptions,
    InfiniteData,
    useQueryClient,
    useInfiniteQuery,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IStoreSettings } from 'interfaces'
import {
    TStoreServiceError,
    TStoreSettingsProps,
    TStoreSettingsResponse,
    TStoreSettingsUpdateProps,
    TStoreSettingsUpdateResponse,
} from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoreSettingsProps
export type TFetchFnError = AxiosError<TStoreServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TStoreSettingsResponse, TError, TStoreSettingsResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TStoreSettingsResponse,
    TError,
    TStoreSettingsResponse,
    TStoreSettingsResponse,
    TQueryKey
>

export type TStoreSettingsMutationFnError = [string, AxiosError<TStoreServiceError, TStoreSettingsUpdateProps>]

const key = QueryCacheKeys.storeSettings

/**
 * Хук API получить магазины
 */
export default function useFetchStoreSettings(params: TFetchFnParams, opts: TQueryFnOpts) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStoreSettings(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteStoreSettings(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return StoreService.fetchStoreSettings(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}

export function useMutationStoreSettings(options = {}) {
    const { t } = useTranslation()

    const update = useMutation<
        TStoreSettingsUpdateResponse,
        TStoreSettingsMutationFnError,
        TStoreSettingsUpdateProps,
        unknown
    >((props) => {
        return StoreService.updateStoreSettings(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        update,
    }
}

export function useInvalidateStoreSettings(
    params: TFetchFnParams,
    { exact = true, ...filters }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries(
            [`${key}-${user.id}`, params],
            { exact, ...filters },
            options,
        )
    }

    return {
        invalidate,
    }
}

export function useSetInfiniteQueryDataStoreSettings(params: TFetchFnParams, options?: SetDataOptions) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const setQueryData = (storeSettingsData: IStoreSettings) => {
        queryClient.setQueryData<InfiniteData<TStoreSettingsResponse> | undefined>(
            [`${key}-${user.id}`, params],
            (infiniteData) => {
                if (infiniteData) {
                    const pages = infiniteData.pages.map((page) => {
                        return page.map((store) => (store.id === storeSettingsData.id ? storeSettingsData : store))
                    })

                    return { ...infiniteData, pages }
                }

                return infiniteData
            },
            options,
        )
    }

    return {
        setQueryData,
    }
}

export function useRemoveQueriesDataStoreSettings() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const remove = (params: TFetchFnParams, { exact = true, ...filters }: InvalidateQueryFilters = {}) => {
        return queryClient.removeQueries([`${key}-${user.id}`, params], { exact, ...filters })
    }

    return {
        remove,
    }
}
