import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IUser, IChannel, IChannelMember } from 'interfaces'
import { MS_CHAT_BASE_URL } from 'config/api'
import {
    Avatar,
    Button,
    Popover,
    MenuList,
} from 'components'
import { useAlertDialog } from 'hooks'
import { plural } from 'utils/helpers'
import style from './MessengerHeaderChannel.module.css'

type MessengerChatHeaderPropType = {
    user: IUser
    channel?: IChannel
    members?: IChannelMember[]
    onLeaveChat: ({ channelId }: { channelId: string }) => void
    onAddMembers?: () => void
    onClickMembers?: () => void
}

const MessengerHeaderChannel: React.FC<MessengerChatHeaderPropType> = ({
    user,
    channel,
    members,
    onLeaveChat,
    onAddMembers = () => {},
    onClickMembers = () => {},
}) => {
    const { t } = useTranslation()

    const { showAlertDialog } = useAlertDialog()

    const isAdmin = useMemo(() => {
        return channel ? channel.userId === user.id : false
    }, [channel])

    const countMembers = useMemo(() => {
        return members?.length ?? 0
    }, [members])

    const textMembers = useMemo(() => {
        return plural(countMembers, [
            t('channel_chat_members_1x'),
            t('channel_chat_members_2x'),
            t('channel_chat_members_5x'),
        ])
    }, [countMembers])

    const handlerClickLeaveChat = () => {
        if (channel) {
            showAlertDialog({
                isSetQuestionText: false,
                message: t('chat_leave_dialog_message'),
                payload: { channelId: channel.id },
                onConfirm: onLeaveChat,
            })
        }
    }

    return (
        <div className={style.header}>
            <Avatar
                name={channel?.title}
                surname=""
                src={channel?.image ? `${MS_CHAT_BASE_URL}/images/channels/${channel.image}` : undefined}
                width={48}
                height={48}
                isResize={false}
            />
            <div className={style.content}>
                <div className={style.rowName}>
                    <span className={style.name}>
                        {channel?.title}
                    </span>
                </div>
                {channel?.description && (
                    <div className={style.rowDesc}>
                        <span className={style.desc}>
                            {channel.description}
                        </span>
                    </div>
                )}
                <div className={style.rowDate}>
                    {channel && !!countMembers && (
                        <Button
                            classes={style.date}
                            styleType="transparent"
                            onClick={onClickMembers}
                        >
                            {`${countMembers} ${textMembers}`}
                        </Button>
                    )}
                </div>
            </div>
            <div className={style.menu}>
                <Popover
                    isDisabled={!channel}
                    isShowArrow={false}
                    position="bottom"
                    side="left"
                    trigger={<Popover.TriggerDots />}
                >
                    <MenuList type="large">
                        <MenuList.Item onClick={handlerClickLeaveChat}>
                            {t('chat_leave_button')}
                        </MenuList.Item>
                        {isAdmin && (
                            <MenuList.Item onClick={onAddMembers}>
                                {t('channel_chat_add_user')}
                            </MenuList.Item>
                        )}
                    </MenuList>
                </Popover>
            </div>
        </div>
    )
}

export default MessengerHeaderChannel
