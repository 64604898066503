import React, { useMemo } from 'react'

import { IHistoryMessageGroup } from 'interfaces'
import { dateTimeFormat } from 'utils/helpers'
import style from './MessengerMessageGroup.module.css'

type MessengerMessageGroupPropType = {
    data: IHistoryMessageGroup
}

const MessengerMessageGroup: React.FC<MessengerMessageGroupPropType> = ({
    children,
    data,
}) => {
    const date = useMemo(() => {
        return data.date ? dateTimeFormat(data.date) : undefined
    }, [data])

    return (
        <div className={style.group}>
            {date && (
                <div className={style.dateWrap}>
                    <div className={style.date}>
                        {date}
                    </div>
                </div>
            )}
            {children}
        </div>
    )
}

export default MessengerMessageGroup
