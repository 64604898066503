type EventType = {
    [key: string]: Function[]
}

/**
 * Event Bus
 * Global event-driven system for communicate between independent components
 *
 * @example
 * subscribe to the event:
 * eventBus.on('onEventName', handlerEventName)
 *
 * unsubscribe to the event:
 * eventBus.off('onEventName', handlerEventName)
 */
class EventBus {
    private readonly events: EventType

    constructor() {
        this.events = {}
    }

    // First of all, the constructor needs to store event events, using key value storage
    // Then we need to publish the event, the parameters are the type of event and the parameters to be passed
    emit(type: string, ...args: any) {
        const e = this.events[type]

        if (Array.isArray(e)) {
            e.forEach((item: Function) => item(...args))
        }

        return this
    }

    // Then need to write a listener function,
    // the parameters are the event type and the callback function to be executed when triggered
    on(type: string, callback: Function) {
        this.events[type] = Array.isArray(this.events[type]) ? [...this.events[type], callback] : [callback]
        return this
    }

    // Remove listener function for the event type
    off(type: string, callback: Function) {
        this.events[type] = this.events[type]?.filter((item: Function) => item.name !== callback.name)
        return this
    }
}

export default new EventBus()
