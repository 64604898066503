import React, { useState, useEffect, useRef } from 'react'

import { TIntersectionObserverProps } from 'hooks/useIntersectionObserver'
import { useIntersectionObserver } from 'hooks'
import { Loader } from 'components'

import style from './InfinityScroll.module.css'

export type LoadDataType = {
    page: number
}

type InfiniteScrollPropType = {
    options?: TIntersectionObserverProps
    isActive?: boolean
    isHideLoader?: boolean
    isReversScroll?: boolean
    onLoad: (data: LoadDataType) => void
}

const InfiniteScroll: React.FC<InfiniteScrollPropType> = ({
    children,
    options = {},
    isActive = true,
    isHideLoader = false,
    isReversScroll = false,
    onLoad = () => {},
}) => {
    const ref = useRef(null)
    const [isIntersecting] = useIntersectionObserver(ref, options)

    const [page, setPage] = useState(0)

    const loaderTpl = (
        <div className={style.loader} ref={ref}>
            <Loader />
        </div>
    )

    useEffect(() => {
        if (isActive && isIntersecting) {
            onLoad({ page })
            setPage((prevState) => prevState + 1)
        }
    }, [isIntersecting])

    useEffect(() => {
        if (isHideLoader) {
            setPage(0)
        }
    }, [isHideLoader])

    return (
        <>
            {isReversScroll && isActive && !isHideLoader && loaderTpl}

            {children}

            {!isReversScroll && isActive && !isHideLoader && loaderTpl}
        </>
    )
}

export default InfiniteScroll
