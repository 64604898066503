import React, { useRef, useState, useEffect } from 'react'
import ReactCropper from 'react-cropper'

import { Spinner } from '../index'
import 'cropperjs/dist/cropper.css'

type CropperPropType = {
    source?: string
    responsive?: boolean
    zoomOnWheel?: boolean
    aspectRatio?: number
    viewMode?: 0 | 1 | 2 | 3
    options?: {
        minWidth?: number
        minHeight?: number
        maxWidth?: number
        maxHeight?: number
    }
    onCrop: (base64: string, type: string) => void
}

/**
 * Обрезка изображений с UI
 * @param source: url | base64
 * @param responsive
 * @param zoomOnWheel
 * @param aspectRatio
 * @param viewMode
 * @param options
 * @see https://github.com/fengyuanchen/cropperjs#options
 * @param onCrop
 * @constructor
 */
const Cropper: React.FC<CropperPropType> = ({
    source,
    responsive,
    zoomOnWheel = false,
    aspectRatio = 1,
    viewMode = 1,
    options = {},
    onCrop,
}) => {
    const DEFAULT_OPTIONS = {
        minWidth: 180,
        minHeight: 180,
        maxWidth: 1080,
        maxHeight: 1080,
    }
    const cropperRef = useRef<HTMLImageElement>(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const handlerCropAvatar = () => {
        const imageElement: any = cropperRef?.current
        const cropper: any = imageElement ? imageElement?.cropper : null

        if (cropper) {
            const imgBase64: string = cropper.getCroppedCanvas({ ...DEFAULT_OPTIONS, ...options }).toDataURL()
            const [type = ''] = imgBase64.match(/image\/\w+/) || []
            const base64 = imgBase64.replace(/^data:image\/\w+;base64,/, '')

            setIsProcessing(false)
            onCrop(base64, type)
        }
    }

    useEffect(() => {
        if (source) {
            setIsProcessing(true)
        }
    }, [source])

    return (
        <>
            {isProcessing && <Spinner size="medium" />}

            <ReactCropper
                responsive={responsive}
                zoomOnWheel={zoomOnWheel}
                aspectRatio={aspectRatio}
                viewMode={viewMode}
                src={source}
                crop={handlerCropAvatar}
                ref={cropperRef}
            />
        </>
    )
}

export default Cropper
