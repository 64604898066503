import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarDay, ICalendarHour } from 'interfaces'
import { DatePeriod } from 'enums'
import { CalendarGridMonth, CustomSelect, DateChanger } from 'components'
import { useCalendar } from 'containers/Calendar/hooks'
import { dateTimeFormat } from 'utils/helpers'
import style from './FieldDateTime.module.css'

type FieldDateTimePropType = {
    classes?: string
    name: string
    timeList: ICalendarHour[] | ((date: Date) => ICalendarHour[])
    disabled?: boolean
}

const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }

const FieldDateTime: React.FC<FieldDateTimePropType> = ({
    classes,
    name,
    timeList,
    disabled,
}) => {
    const { i18n } = useTranslation()

    const [date, setDate] = useState<Date | undefined>()
    const [dateCalendar, setDateCalendar] = useState<Date>(new Date())
    const [daysMonth, setDaysMonth] = useState<ICalendarDay[]>([])

    const { mutators } = useForm()
    const { input } = useField<string>(name)

    const { getMonthDays } = useCalendar()

    const dateFormatted = useMemo(() => {
        return date ? dateTimeFormat(date, i18n.language, { day: '2-digit', month: 'long', year: 'numeric' }) : ''
    }, [date])

    const timeFormatted = useMemo(() => {
        return date ? dateTimeFormat(date, i18n.language, TIME_OPTIONS) : ''
    }, [date])

    const hours = useMemo(() => {
        if (date && typeof timeList === 'function') {
            return timeList(date)
        }
        if (Array.isArray(timeList)) {
            return timeList
        }
        return []
    }, [timeList, date])

    const hoursList = useMemo(() => {
        return hours.map((item) => ({
            ...item,
            time: dateTimeFormat(new Date(item.date), i18n.language, TIME_OPTIONS),
        }))
    }, [hours])

    const handlerOpenCalendar = () => {
        setDaysMonth(getMonthDays(dateCalendar))
    }

    const handlerChangeCalendar = (value: Date) => {
        setDateCalendar(value)
        setDaysMonth(getMonthDays(value))
    }

    const handlerChangeDate = (value: Date) => {
        if (name in mutators) {
            mutators[name](name, value)
        }
    }

    useEffect(() => {
        if (input.value) {
            const dateValue = new Date(input.value)

            setDate(dateValue)
            setDateCalendar(dateValue)
        }
    }, [input.value])

    return (
        <div className={cn(style.field, classes)}>
            <CustomSelect.Select classes={style.selectDate}>
                <CustomSelect.Field classes={style.selectField} disabled={disabled}>
                    {dateFormatted}
                </CustomSelect.Field>
                <CustomSelect.Options onOpen={handlerOpenCalendar}>
                    <DateChanger
                        classes={style.dateChanger}
                        period={DatePeriod.month}
                        date={dateCalendar}
                        onChange={handlerChangeCalendar}
                    />
                    <CustomSelect.Option classes={style.selectOption} id={0} onClick={() => {}}>
                        <CalendarGridMonth
                            classes={style.calendar}
                            classesWeekday={style.calendarWeekday}
                            classesDate={style.calendarDate}
                            days={daysMonth}
                            daysPosts={{}}
                            dateSelected={date}
                            onClickDay={handlerChangeDate}
                        />
                    </CustomSelect.Option>
                </CustomSelect.Options>
            </CustomSelect.Select>
            <CustomSelect.Select classes={style.selectTime}>
                <CustomSelect.Field
                    classes={style.selectField}
                    disabled={disabled || !date}
                >
                    {/* TODO ручной ввод */}
                    {timeFormatted}
                </CustomSelect.Field>
                <CustomSelect.Options>
                    {hoursList.map((item) => (
                        <CustomSelect.Option
                            // isActive={} // TODO
                            id={Number(item.id)}
                            key={item.id}
                            onClick={() => handlerChangeDate(item.date)}
                        >
                            {item.time}
                        </CustomSelect.Option>
                    ))}
                </CustomSelect.Options>
            </CustomSelect.Select>
        </div>
    )
}

export default FieldDateTime
