import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IDeliveryCost, IStoreCurrency } from 'interfaces'
import { API_HOST } from 'config/api'
import { Block } from 'layout'
import { Button } from 'components'
import { plural, priceFormat, splitStr } from 'utils/helpers'

import styleColumns from 'styles/modules/columns.module.css'
import style from './StoreOrderPickupItem.module.css'

type StoreOrderPickupItemPropType = {
    data: IDeliveryCost
    activeId?: number
    currency?: IStoreCurrency
    onSelect: (id: number) => void
}

const StoreOrderPickupItem: React.FC<StoreOrderPickupItemPropType> = ({
    data,
    activeId,
    currency,
    onSelect,
}) => {
    const { t } = useTranslation()

    const comment = useMemo(() => (data?.comment ? splitStr(data.comment) : []), [data])
    const cost = useMemo(() => priceFormat(data?.cost, currency), [data, currency])
    const isActive = useMemo(() => activeId === data.id, [data, activeId])

    const days = useMemo(() => {
        const { delivery_time_from, delivery_time_to } = data || {}
        let result = ''

        if (delivery_time_from || delivery_time_from >= 0) {
            result += delivery_time_from
        }
        if (delivery_time_to) {
            result += ` - ${delivery_time_to}`
        }

        return result
    }, [data])

    const dayPlural = useMemo(() => {
        const { delivery_time_from, delivery_time_to } = data || {}
        const forms = [t('days_1x'), t('days_2x'), t('days_5x')]

        if (delivery_time_to) {
            return plural(delivery_time_to, forms)
        }
        if (delivery_time_from || delivery_time_from === 0) {
            return plural(delivery_time_from, forms)
        }

        return ''
    }, [data])

    const handlerClick = () => {
        onSelect(data.id)
    }

    return (
        <Block
            classes={cn(
                styleColumns.column,
                styleColumns.column_1_2,
                style.block,
                {
                    [style.block_active]: isActive,
                },
            )}
        >
            <div className={style.text}>
                {comment.length >= 1 && (
                    comment[0].text
                )}
            </div>
            {data?.image && (
                <div
                    className={style.image}
                    style={{ backgroundImage: `url(${API_HOST}${data.image})` }}
                />
            )}
            <div className={style.footer}>
                <div className={style.info}>
                    {comment.length > 1 && (
                        comment.map((item, index) => (
                            index > 0 && (
                                <div key={item.id}>
                                    {item.text}
                                </div>
                            )
                        ))
                    )}
                </div>
                <div className={style.cost}>
                    {t('selfdelivery')}
                    {' '}
                    {cost}
                </div>
                <div className={style.delivery}>
                    {days && (
                        <>
                            {days}
                            {' '}
                            {dayPlural}
                        </>
                    )}
                </div>
                <div className={style.controls}>
                    <Button
                        size="size44"
                        styleType={isActive ? 'default_bordered' : 'default'}
                        text={isActive ? t('selected') : t('select')}
                        onClick={handlerClick}
                    />
                </div>
            </div>
        </Block>
    )
}

export default StoreOrderPickupItem
