import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import style from './TicketStatusTag.module.css'

type TicketStatusTagPropType = {
    classes?: string
    status?: number
}
// TODO universal component tag
const TicketStatusTag: React.FC<TicketStatusTagPropType> = ({ classes, status }) => {
    const { t } = useTranslation()

    return (
        <>
            {status !== undefined && (
                <span className={cn(style.tag, style[`tag_status-${status}`], classes)}>
                    {t(`support_statuses_${status}`)}
                </span>
            )}
        </>
    )
}

export default TicketStatusTag
