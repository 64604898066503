import React from 'react'

import { IStickerCollection } from '../../../../interfaces'
import { StickersListItem } from '../index'
import style from './StickersList.module.css'

type StickersListPropType = {
    collection: IStickerCollection
    onClickItem: (id: number) => void
}

const StickersList: React.FC<StickersListPropType> = ({
    collection,
    onClickItem,
}) => {
    const { type, stickers = [] } = collection

    return (
        <ul className={style.list}>
            {stickers.map((sticker) => (
                <StickersListItem
                    data={{ id: sticker.id, type }}
                    key={sticker.id}
                    onClick={onClickItem}
                />
            ))}
        </ul>
    )
}

export default StickersList
