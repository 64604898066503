import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popover, MenuList } from 'components'
import style from './MessengerMenuError.module.css'

type MessengerMenuErrorPropType = {
    classes?: string
    onRetry: () => void
    onDelete: () => void
}

const MessengerMenuError: React.FC<MessengerMenuErrorPropType> = ({ classes, onRetry, onDelete }) => {
    const { t } = useTranslation()

    return (
        <Popover
            isShowArrow={false}
            classes={classes}
            position="top"
            side="left"
            trigger={<div className={style.errorIcon}>!</div>}
        >
            <MenuList classes={style.errorAction} type="large">
                <MenuList.Item onClick={onRetry}>
                    {t('chat_message_menu_send')}
                </MenuList.Item>
                <MenuList.Item disabled onClick={onDelete}>
                    {t('chat_message_menu_delete')}
                </MenuList.Item>
            </MenuList>
        </Popover>
    )
}

export default MessengerMenuError
