import React from 'react'
import cn from 'classnames'

import style from './Page.module.css'

type PageContainerPropType = {
    classes?: string
}

const PageContainer: React.FC<PageContainerPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.page, classes)}>
            {children}
        </div>
    )
}

export default PageContainer
