import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITransaction } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar } from 'components'
import { parseTpl, priceFormat } from 'utils/helpers'
import style from './WalletTransaction.module.css'

type WalletTransactionPropType = {
    classes?: string
    data: ITransaction
}

const WalletTransaction: React.FC<WalletTransactionPropType> = ({ classes, data }) => {
    const {
        img,
        hint,
        status,
        status_id: statusId,
        store_name: storeName,
        store_id: storeId,
        type_name: typeName,
        order_id: orderId,
    } = data

    const { t } = useTranslation()

    const storeUrl = useMemo(() => {
        return storeId ? parseTpl(APP_URL.store, { ':id': storeId }, { prefix: '', suffix: '' }) : ''
    }, [data])

    const orderDesc = useMemo(() => {
        return orderId ? parseTpl(t('business_kicks_history_order'), { '%s': orderId }, { prefix: '', suffix: '' }) : ''
    }, [data])

    const amountSum = useMemo(() => {
        const { amount, currency_object: { show_code, left_currency_symbol_placement } } = data
        const currency = { symbol: show_code, leftSymbolPlacement: left_currency_symbol_placement, isoCode: '' }
        const options: Intl.NumberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'always' }

        return priceFormat(amount, currency, options)
    }, [data])

    const avatarTpl = (
        <Avatar
            classes={style.avatar}
            name={storeName}
            src={img}
            width={54}
        />
    )

    return (
        <div className={cn(style.transaction, classes)}>
            {storeUrl ? (
                <NavLink className={style.link} to={storeUrl}>
                    {avatarTpl}
                </NavLink>
            ) : (
                <>
                    {avatarTpl}
                </>
            )}

            <div className={style.body}>
                <div className={style.title} title={hint}>
                    {typeName}
                </div>
                <div className={style.order}>
                    <div className={style.info}>
                        {orderDesc && (
                            <span className={style.desc}>
                                {orderDesc}
                                ,
                            </span>
                        )}

                        <span className={cn(style.status, style[`status_color${statusId}`])}>
                            {status}
                        </span>
                    </div>
                    <div className={style.amount}>
                        {amountSum}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletTransaction
