import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITicketMessage, IUser } from 'interfaces'
import { API_HOST } from 'config/api'
import {
    TextContent,
    ContentFile,
    Modal,
    Loader,
    Image,
    Video,
} from 'components'
import { AppService } from 'services'
import { dateTimeFormat } from 'utils/helpers'
import styleBlockComment from 'styles/modules/blockComment.module.css'
import { TicketMessageAvatar } from '../index'
import style from './TicketMessage.module.css'

enum KnownAttachFormats {
    jpeg = 'jpeg',
    jpg = 'jpg',
    png = 'png',
    bmp = 'bmp',
    gif = 'gif',
    pdf = 'pdf',
    mp3 = 'mp3',
    docx = 'docx',
    xlsx = 'xlsx',
    rtf = 'rtf',
    txt = 'txt',
}

type TicketMessagePropType = {
    data: {
        user?: IUser
        message: ITicketMessage
    }
}

type TAttach = {
    type: keyof typeof KnownAttachFormats | 'unknown'
    url: string
}

/**
 * HTML-uppercased qualified name
 */
type TContentMedia = 'IMG' | 'VIDEO'

const isImageType = (type: string) => {
    return type === KnownAttachFormats.jpeg
        || type === KnownAttachFormats.jpg
        || type === KnownAttachFormats.bmp
        || type === KnownAttachFormats.png
        || type === KnownAttachFormats.gif
}

const TicketMessage: React.FC<TicketMessagePropType> = ({ data }) => {
    const { user, message } = data

    const { t, i18n } = useTranslation()

    const [modalContent, setModalContent] = useState<{ type: TContentMedia, value: string }>()
    const [isShowModalContent, setIsShowModalContent] = useState(false)
    const [isLoadingContent, setIsLoadingContent] = useState(false)

    const isOwn = useMemo(() => !!data?.message?.is_by_ticket_owner, [data])

    const contentImage = useMemo(() => {
        if (data.message.message?.includes('http')) {
            return data.message.message
        }

        return undefined
    }, [data])

    const contentAttach = useMemo<TAttach | undefined>(() => {
        if (data.message?.image) {
            const [, ext] = data.message.image.split('.')

            return {
                type: ext in KnownAttachFormats ? ext as KnownAttachFormats : 'unknown',
                url: `${API_HOST}${message.image}`,
            }
        }

        return undefined
    }, [data])

    const isImageContentAttach = useMemo(() => {
        return !!contentAttach && isImageType(contentAttach.type)
    }, [contentAttach])

    const date = useMemo(() => {
        return dateTimeFormat(data.message.create_date, i18n.language, { timeStyle: 'short' })
    }, [data])

    const handlerClickContent = ({ currentTarget }: React.SyntheticEvent, url: string) => {
        setModalContent({ type: currentTarget.tagName as TContentMedia, value: url })
        setIsLoadingContent(true)
        setIsShowModalContent(true)
    }

    const handlerDownloadFile = () => {
        if (modalContent) {
            AppService.downloadFile(modalContent.value)
        }
    }

    return (
        <>
            <div className={style.message}>
                <div className={cn(
                    style.block,
                    {
                        [style.block_left]: !isOwn,
                        [styleBlockComment.block_left]: !isOwn,
                        [style.block_right]: isOwn,
                        [styleBlockComment.block_right]: isOwn,
                    },
                )}
                >
                    {contentAttach && (
                        <>
                            {isImageContentAttach ? (
                                <TextContent
                                    classesImg={styleBlockComment.image}
                                    content={contentAttach.url}
                                    onClick={(e) => handlerClickContent(e, contentAttach.url)}
                                />
                            ) : (
                                <ContentFile
                                    isPlayAudio
                                    type={contentAttach.type}
                                    url={contentAttach.url}
                                    desc={data.message.message}
                                />
                            )}
                        </>
                    )}

                    {contentImage && (
                        <>
                            <TextContent
                                isVideoPlayIcon
                                isVideoControls={false}
                                classesImg={styleBlockComment.image}
                                classesVideo={styleBlockComment.video}
                                content={contentImage}
                                onClick={(e) => handlerClickContent(e, contentImage)}
                            />
                            <div className={cn(styleBlockComment.date, { [styleBlockComment.date_right]: isOwn })}>
                                {date}
                            </div>
                        </>
                    )}

                    {((!contentImage && !contentAttach) || isImageContentAttach) && (
                        <div className={cn(
                            styleBlockComment.content,
                            {
                                [style.content_colorDefault]: !isOwn,
                                [style.content_colorOwn]: isOwn,
                            },
                        )}
                        >
                            <div className={styleBlockComment.text}>
                                <TextContent content={message.message} />
                            </div>
                            <div className={cn(styleBlockComment.date, { [styleBlockComment.date_right]: isOwn })}>
                                {date}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                classes={style.modalContent}
                classesOverlay={style.modalContentOverlay}
                isOpen={isShowModalContent}
                size="fullWidth"
                topBarContent={(
                    <Modal.TopBarMedia
                        avatar={<TicketMessageAvatar data={user} />}
                        name={data.message.is_by_ticket_owner ? `${user?.name ?? ''} ${user?.surname ?? ''}` : t('support_title')}
                        date={date}
                        onDownload={handlerDownloadFile}
                        onClose={() => setIsShowModalContent(false)}
                    />
                )}
                onClose={() => setIsShowModalContent(false)}
            >
                <Modal.Body classes={style.modalContentBody}>
                    {isLoadingContent && (
                        <Loader
                            classes={style.modalContentLoader}
                            position="center"
                        />
                    )}
                    {modalContent && (
                        <>
                            {modalContent.type === 'IMG' && (
                                <Image
                                    classesWrap={cn(
                                        style.modalContentImageWrap,
                                        { [style.modalContentImageWrap_loading]: isLoadingContent },
                                    )}
                                    classesImage={style.modalContentImage}
                                    src={modalContent.value}
                                    onLoad={() => setIsLoadingContent(false)}
                                />
                            )}
                            {modalContent.type === 'VIDEO' && (
                                <Video
                                    controls
                                    autoPlay
                                    classesWrap={cn(
                                        style.modalContentVideoWrap,
                                        { [style.modalContentVideoWrap_loading]: isLoadingContent },
                                    )}
                                    classes={style.modalContentVideo}
                                    isPlayIcon={false}
                                    src={modalContent.value}
                                    onCanPlay={() => setIsLoadingContent(false)}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TicketMessage
