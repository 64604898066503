import React from 'react'
import cn from 'classnames'

import { Button } from '../index'
import style from './DeleteButton.module.css'

type FormActiveItemDeleteButtonPropType = {
    classes?: string
    onClick: () => void
}

const DeleteButton: React.FC<FormActiveItemDeleteButtonPropType> = ({ classes, onClick = () => {} }) => {
    return (
        <Button
            classes={cn(style.button, classes)}
            styleType="text"
            onClick={onClick}
        >
            <span className={style.icon} />
        </Button>
    )
}

export default DeleteButton
