import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ICalendarPost } from 'interfaces'
import { Button } from 'components'
import { dateTimeFormat, getItemFromArray } from 'utils/helpers'
import style from './CalendarMonthEvent.module.css'

type CalendarMonthEventPropType = {
    data: ICalendarPost
    colors: string[]
    onClickEvent: (value: ICalendarPost) => void
}

const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }

const CalendarMonthEvent: React.FC<CalendarMonthEventPropType> = ({ data, colors, onClickEvent }) => {
    const { i18n } = useTranslation()

    const time = useMemo(() => {
        const from = dateTimeFormat(data.calendar_date_from, i18n.language, TIME_OPTIONS)
        const to = dateTimeFormat(data.calendar_date_to, i18n.language, TIME_OPTIONS)

        return `${from}-${to}`
    }, [data, i18n.language])

    const color = useMemo(() => {
        return getItemFromArray(colors, data.id)
    }, [data])

    const handlerClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onClickEvent(data)
    }

    return (
        <Button
            classes={style.event}
            title={data.title}
            styleType="transparent"
            onClick={handlerClick}
        >
            <span className={style.time}>
                {time}
            </span>
            <span className={style.title} style={{ color }}>
                {data.title}
            </span>
        </Button>
    )
}

export default CalendarMonthEvent
