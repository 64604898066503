import { useState, useEffect, useRef } from 'react'
import { AuthService } from 'services'

type TWebSocketParams<T> = {
    accessToken: string
    enabled?: boolean
    reconnect?: boolean
    onOpen?: (e: Event) => void
    onMessage?: (data: T, e: MessageEvent<T>) => void
    onClose?: (e: CloseEvent) => void
    onError?: (e: unknown) => void
}

/**
 *  Hook create WebSocket connection
 */
export default function useWebSocket<T = any>(url: string, {
    accessToken,
    enabled,
    reconnect = true,
    onOpen = () => {},
    onMessage = () => {},
    onClose = () => {},
    onError = () => {},
}: TWebSocketParams<T>) {
    const isMounted = useRef(false)

    const [socket, setSocket] = useState<WebSocket>()

    const connect = () => {
        const { access_token: appToken } = AuthService.getAuthData() || {}

        if (appToken) {
            setSocket(new WebSocket(`${url}?accessToken=${accessToken}&appToken=${appToken}`))
        } else {
            //
        }
    }

    const wsClose: WebSocket['close'] = (code, reason) => {
        if (socket) {
            socket.close(code, reason)
        }
    }

    const wsSend: WebSocket['send'] = (data) => {
        if (socket) {
            socket.send(data)
        }
    }

    const wsReadyState = () => {
        if (socket) {
            return socket.readyState
        }
        return 0
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (enabled || enabled === undefined) {
            connect()
        }
    }, [enabled])

    useEffect(() => {
        if (socket) {
            socket.onopen = onOpen
            socket.onmessage = (e) => {
                try {
                    onMessage(JSON.parse(e.data), e)
                } catch (err) {
                    onError(err)
                }
            }
            socket.onclose = (e) => {
                if (isMounted.current && reconnect) {
                    connect()
                }
                onClose(e)
            }
            socket.onerror = onError
        }

        return () => {
            if (!isMounted.current) {
                wsClose()
            }
        }
    }, [socket])

    return {
        close: wsClose,
        send: wsSend,
        readyState: wsReadyState,
    }
}
