import React, {
    Children,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { KeenSliderInstance, KeenSliderOptions, useKeenSlider } from 'keen-slider/react'
import cn from 'classnames'

import { SLIDER_ITEM_CLASS } from 'config/app'
import { Arrow, Button } from 'components'
import { useWindowResize } from 'hooks'
import SliderItem, { SliderItemPropType } from './components/SliderItem/SliderItem'
import style from './Slider.module.css'

export type SliderPreviewType = {
    id: string | number
    src: string
}

export type SliderOptions = KeenSliderOptions

type SliderPropType = {
    classes?: string
    params?: SliderOptions
    arrows?: boolean
    isHideLeftArrowOnFirstSlide?: boolean
    isHideRightArrowOnLastSlide?: boolean
    navigation?: boolean
    previews?: SliderPreviewType[]
    previewsPos?: 'left' | 'center' | 'right'
    onSlideChanged?: (e: any) => void
}

type SliderComposition = {
    Item: React.FC<SliderItemPropType>
}

const PREVIEW_WIDTH = 80
const PREVIEW_GUTTER = 10
const COUNT_NAV_DOTS_PER_SIDE = 4
const SLIDER_DEFAULT_OPTIONS = {
    initial: 0,
    loop: true,
    selector: `.${SLIDER_ITEM_CLASS}`,
}

/**
 * Slider
 * @see https://keen-slider.io/docs
 */
const Slider: React.FC<SliderPropType> & SliderComposition = ({
    children,
    classes,
    params = {},
    navigation = true,
    arrows = false,
    previews = [],
    previewsPos = 'left',
    isHideLeftArrowOnFirstSlide = false,
    isHideRightArrowOnLastSlide = false, // params.slides.perView must be set number
    onSlideChanged = () => {},
}) => {
    const options = { ...SLIDER_DEFAULT_OPTIONS, ...params }

    const [windowWidth] = useWindowResize()

    const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance>()
    const [currentSlide, setCurrentSlide] = useState<number>(options.initial)
    const [wrapWidth, setWrapWidth] = useState<number>(0)
    const [previewsWidth, setPreviewsWidth] = useState<number>(0)
    const [previewsOffset, setPreviewsOffset] = useState<number>(0)
    const [isShowPreviewsArrows, setIsShowPreviewsArrows] = useState(false)

    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        ...options,
        created: (instance) => {
            setSliderInstance(instance)
        },
        slideChanged: (instance) => {
            setCurrentSlide(instance.track.details.rel)
            onSlideChanged(instance)
        },
    })

    const count = useMemo(() => Children.toArray(children).length, [children])

    const dots = useMemo(() => {
        const startIndex = currentSlide > COUNT_NAV_DOTS_PER_SIDE ? currentSlide - COUNT_NAV_DOTS_PER_SIDE : 0
        const endIndex = currentSlide + COUNT_NAV_DOTS_PER_SIDE + 1

        return Array.from(Array(count).keys()).slice(startIndex, endIndex)
    }, [count, currentSlide])

    const handlerClickItem = (index: number) => {
        sliderInstance?.moveToIdx(index)
    }

    const handlerClickPrevSlider = () => {
        sliderInstance?.prev()

        if (isShowPreviewsArrows) {
            const position = (currentSlide - 1) * -(PREVIEW_WIDTH + PREVIEW_GUTTER)

            if (position > 0) {
                // scroll to end
                setPreviewsOffset(-previewsWidth + wrapWidth + PREVIEW_GUTTER)
            } else if (previewsWidth + position > wrapWidth) {
                // move to prev preview
                setPreviewsOffset(position)
            }
        }
    }

    const handlerClickNextSlider = () => {
        sliderInstance?.next()

        if (isShowPreviewsArrows) {
            const position = (currentSlide + 1) * -(PREVIEW_WIDTH + PREVIEW_GUTTER)

            if (Math.abs(position) === previewsWidth) {
                // scroll to start
                setPreviewsOffset(0)
            } else if (previewsWidth + position > wrapWidth) {
                // move to next preview
                setPreviewsOffset(position)
            } else {
                // scroll to end
                setPreviewsOffset(-previewsWidth + wrapWidth + PREVIEW_GUTTER)
            }
        }
    }

    const handlerClickPrevPreview = () => {
        if (previewsOffset < 0) {
            const halfView = Math.round(wrapWidth / 2)
            const translate = Math.abs(previewsOffset / halfView) > 1 ? previewsOffset + halfView : 0

            setPreviewsOffset(translate)
        }
    }

    const handlerClickNextPreview = () => {
        if (previewsWidth - previewsOffset > wrapWidth) {
            const position = previewsWidth - wrapWidth + previewsOffset
            const halfView = Math.round(wrapWidth / 2)

            const translate = position / halfView > 1
                ? previewsOffset - halfView
                : previewsOffset - position + PREVIEW_GUTTER

            setPreviewsOffset(translate)
        }
    }

    function initPreviews() {
        // @ts-ignore
        if (previews?.length && sliderRef?.current?.clientWidth) { // FIXME sliderRef?.current?.clientWidth
            // @ts-ignore
            const wWidth = sliderRef.current.clientWidth
            const pWidth = previews.length * (PREVIEW_WIDTH + PREVIEW_GUTTER)

            // preview count more than previews area
            if (wWidth / pWidth < 1) {
                setWrapWidth(wWidth)
                setPreviewsWidth(pWidth)
                setIsShowPreviewsArrows(true)
            } else if (isShowPreviewsArrows) {
                setIsShowPreviewsArrows(false)
                setPreviewsOffset(0)
            }
        }
    }

    useEffect(() => {
        if (sliderInstance) {
            initPreviews()
        }
    }, [sliderInstance, windowWidth])

    useEffect(() => {
        if (sliderInstance && sliderInstance.slides.length !== count) {
            sliderInstance.update()
        }
    }, [count])

    return (
        <div className={cn(style.slider, classes)}>
            <div className={style.items} ref={sliderRef}>
                {children}

                {arrows && sliderInstance && count > 1 && (
                    <>
                        {!(isHideLeftArrowOnFirstSlide && currentSlide === 0) && (
                            <button
                                className={cn(style.arrow, style.arrow_left)}
                                aria-label="left"
                                onClick={handlerClickPrevSlider}
                            >
                                <Arrow classes={style.arrowIcon} direction="left" />
                            </button>
                        )}
                        {!(isHideRightArrowOnLastSlide && sliderInstance?.track.details.maxIdx === currentSlide) && (
                            <button
                                className={cn(style.arrow, style.arrow_right)}
                                aria-label="right"
                                onClick={handlerClickNextSlider}
                            >
                                <Arrow classes={style.arrowIcon} direction="right" />
                            </button>
                        )}
                    </>
                )}
            </div>

            {(navigation && sliderInstance && count > 1) && (
                <div className={style.dots}>
                    {dots.map((index) => (
                        <Button
                            styleType="text"
                            classes={cn(style.dot, {
                                [style.dot_active]: index === currentSlide,
                                [style.dot_viewedFirst]: index <= currentSlide - COUNT_NAV_DOTS_PER_SIDE,
                                [style.dot_viewedLast]: index >= currentSlide + COUNT_NAV_DOTS_PER_SIDE,
                            })}
                            key={index}
                            onClick={() => handlerClickItem(index)}
                        />
                    ))}
                </div>
            )}

            {!!previews?.length && (
                <div className={style.previews}>
                    <div
                        className={cn(style.previewsItems, !isShowPreviewsArrows ? style[`previewsItems_${previewsPos}`] : '')}
                        style={{ transform: `translateX(${previewsOffset}px` }}
                    >
                        {previews.map((item, index) => (
                            <div
                                className={cn(style.preview, { [style.preview_active]: currentSlide === index })}
                                role="button"
                                aria-label="button"
                                tabIndex={0}
                                key={item.id}
                                onKeyDown={() => {}}
                                onClick={() => handlerClickItem(index)}
                            >
                                <img className={style.previewImage} src={item.src} alt="" />
                            </div>
                        ))}
                    </div>

                    {isShowPreviewsArrows && (
                        <>
                            {/* eslint-disable-next-line */}
                            <div className={cn(style.previewsArrow, style.arrow_left)} onClick={handlerClickPrevPreview}>
                                <Arrow classes={style.arrowIcon} direction="left" />
                            </div>

                            {/* eslint-disable-next-line */}
                            <div className={cn(style.previewsArrow, style.arrow_right)} onClick={handlerClickNextPreview}>
                                <Arrow classes={style.arrowIcon} direction="right" />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

Slider.Item = SliderItem

export default Slider
