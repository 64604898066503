import React from 'react'
import cn from 'classnames'

import style from './InfoBlockBody.module.css'

export type InfoBlockBodyPropType = {
    classes?: string
}

const InfoBlockBody: React.FC<InfoBlockBodyPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.body, classes)}>
            {children}
        </div>
    )
}

export default InfoBlockBody
