import React from 'react'
import cn from 'classnames'

import style from './CardContainer.module.css'

type CardContainerPropType = {
    classes?: string
}

const CardContainer: React.FC<CardContainerPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.container, classes)}>
            {children}
        </div>
    )
}

export default CardContainer
