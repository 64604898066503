import { EventDialogPropType } from 'components/AlertDialog/AlertDialog'
import { EVENT_TYPE_ALERT_DIALOG } from 'config/app'
import eventBus from 'utils/EventBus'

export default function useAlertDialog() {
    const showAlertDialog = <T = unknown>(params: EventDialogPropType<T>) => {
        eventBus.emit(EVENT_TYPE_ALERT_DIALOG, params)
    }

    return {
        showAlertDialog,
    }
}
