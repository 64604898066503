import React, { useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPost } from 'interfaces'
import { APP_URL, POST_FILE_GROUP_ID_1, POST_FILE_GROUP_ID_2 } from 'config/app'
import { useFetchStoreCustomers } from 'containers/Market/hooks'
import {
    Post,
    Button,
    Link,
    TextContent,
    UsersAvatarGroup,
} from 'components'
import { usePost } from 'hooks'
import stylePost from 'components/Post/Post.module.css'

type PostsPostBodyPropType = {
    classes?: string
    post: IPost
}

/**
 * Карточка поста - полная
 */
const PostCard: React.FC<PostsPostBodyPropType> = ({ classes, post }) => {
    const { t } = useTranslation()

    const [isIntersected, setIsIntersected] = useState(false)
    const [isReadMore, setIsReadMore] = useState(false)

    const { data: dataStoreCustomers } = useFetchStoreCustomers({
        orderId: post.order?.id!,
        limit: 5,
        with_count: true,
    }, {
        enabled: isIntersected && !!post.order?.id,
    })

    const { getMainFiles, getContent } = usePost()

    const url = useMemo(() => generatePath(APP_URL.post, { id: post.id }), [post])

    const mainFiles = useMemo(() => getMainFiles(post.files, [POST_FILE_GROUP_ID_1, POST_FILE_GROUP_ID_2]), [post])

    const content = useMemo(() => getContent(post.text, post.files), [post])

    const handlerIntersecting = () => {
        setIsIntersected(true)
    }

    const handlerClickReadMore = () => {
        setIsReadMore((prevState) => !prevState)
    }

    return (
        <Post
            classes={classes}
            post={post}
            onIntersecting={handlerIntersecting}
        >
            <Post.Header
                data={post}
            />
            <Post.MainImage
                classes={stylePost.mainImage}
                preview={post.small_preview}
                files={mainFiles}
                order={post?.order}
                url={url}
            />
            <Post.Attaches
                classes={stylePost.attaches}
                files={post.files}
            />
            <div className={stylePost.body}>
                {post.title && (
                    <Link className={stylePost.title} url={url}>
                        {post.title}
                    </Link>
                )}
                {post.kickbacks?.status_name && (
                    <div className={stylePost.status}>
                        <div className={cn(stylePost.statusBadge, stylePost[`checkStatusColor${post.kickbacks.status_id}`])}>
                            {post.kickbacks.status_name}
                        </div>
                    </div>
                )}
                <div className={stylePost.content}>
                    {isReadMore ? (
                        <>
                            {content.map((item, index) => (
                                <Post.Content
                                    content={item}
                                    key={item.id}
                                    renderContentText={() => (
                                        <Post.ContentText data={item} />
                                    )}
                                >
                                    {/* skip post main text item */}
                                    {index > 0 && (
                                        <>
                                            <Post.ContentText isTypeText={false} data={item} />
                                            <Post.ContentText data={item} />
                                        </>
                                    )}
                                </Post.Content>
                            ))}
                            <div className={stylePost.contentDescription}>
                                <Button
                                    classes={cn(stylePost.readMore, stylePost.readMore_mt)}
                                    styleType="text"
                                    text={t('Roll up')}
                                    onClick={handlerClickReadMore}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={stylePost.contentDescription}>
                            <Link className={stylePost.contentText} url={url}>
                                <TextContent content={post.description} />
                            </Link>
                            {post.description !== post.text && (
                                <Button
                                    classes={stylePost.readMore}
                                    styleType="text"
                                    text={t('more')}
                                    onClick={handlerClickReadMore}
                                />
                            )}
                        </div>
                    )}
                </div>
                {dataStoreCustomers && (
                    <div className={stylePost.customers}>
                        <UsersAvatarGroup
                            users={dataStoreCustomers.list}
                            desc={t('shop_card_n_made_order')}
                            descClasses={stylePost.customersDesc}
                        />
                    </div>
                )}
            </div>
            <Post.Footer
                data={post}
            />
        </Post>
    )
}

export default PostCard
