import { useState, useLayoutEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

/**
 * Hook изменения размера окна браузера
 */
export default function useWindowResize(debounce: number = 100): number[] {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight])

    const debounceWindowChange = useDebouncedCallback(updateSize, debounce)

    const onWindowChange = () => {
        debounceWindowChange()
    }

    function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', onWindowChange)

        return () => {
            window.removeEventListener('resize', onWindowChange)
        }
    }, [])

    return size
}
