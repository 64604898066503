import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import { IPost } from 'interfaces'
import { useIntersectionObserver } from 'hooks'
import { PostHeaderPropType } from './components/PostHeader/PostHeader'
import { PostMainImagePropType } from './components/PostMainImage/PostMainImage'
import { PostAttachesPropType } from './components/PostAttaches/PostAttaches'
import { PostContentPropType } from './components/PostContent/PostContent'
import { PostContentFilePropType } from './components/PostContentFile/PostContentFile'
import { PostContentTextPropType } from './components/PostContentText/PostContentText'
import { PostContentTextAreaPropType } from './components/PostContentTextArea/PostContentTextArea'
import { PostFooterPropType } from './components/PostFooter/PostFooter'
import {
    PostHeader,
    PostMainImage,
    PostAttaches,
    PostContent,
    PostContentFile,
    PostContentText,
    PostContentTextArea,
    PostFooter,
} from './components'
import style from './Post.module.css'

type PostPropType = {
    classes?: string
    post: IPost
    onIntersecting?: () => void
}

type PostComposition = {
    Header: React.FC<PostHeaderPropType>
    MainImage: React.FC<PostMainImagePropType>
    Attaches: React.FC<PostAttachesPropType>
    Content: React.FC<PostContentPropType>
    ContentFile: React.FC<PostContentFilePropType>
    ContentText: React.FC<PostContentTextPropType>
    ContentTextArea: React.FC<PostContentTextAreaPropType>
    Footer: React.FC<PostFooterPropType>
}

const Post: React.FC<PostPropType> & PostComposition = ({
    children,
    classes,
    post,
    onIntersecting = () => {},
}) => {
    const ref = useRef(null)
    const [isIntersecting] = useIntersectionObserver(ref, { rootMargin: '0px 0px 50% 0px', freezeOnceVisible: true })

    useEffect(() => {
        if (isIntersecting) {
            onIntersecting()
        }
    }, [isIntersecting])

    return (
        <div className={cn(style.post, classes)} ref={ref}>
            {children}
        </div>
    )
}

Post.Header = PostHeader
Post.MainImage = PostMainImage
Post.Attaches = PostAttaches
Post.Content = PostContent
Post.ContentFile = PostContentFile
Post.ContentText = PostContentText
Post.ContentTextArea = PostContentTextArea
Post.Footer = PostFooter

export default Post
