import React, {
    useRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { SvgResource, Popover, Share } from 'components'
import style from './ContentFile.module.css'

type ContentFilePropType = {
    type: unknown
    url: string
    desc?: string
    isPlayAudio?: boolean
}

const ContentFile: React.FC<ContentFilePropType> = ({
    type,
    url,
    desc = '',
    isPlayAudio,
}) => {
    const { t } = useTranslation()

    const audioRef = useRef<HTMLAudioElement>(null)

    const [isPlaying, setIsPlaying] = useState(false)

    const resourceKey = useMemo(() => {
        switch (type) {
            case 'pdf':
                return 'file_type_icon_pdf'
            case 'mp3':
                return isPlayAudio ? 'support_chat_play_icon' : 'file_type_icon_mp3'
            case 'txt':
                return 'file_type_icon_txt'
            case 'docx':
                return 'file_type_icon_docx'
            case 'xlsx':
                return 'file_type_icon_xlsx'
            case 'rtf':
                return 'file_type_icon_rtf'
            default:
                return 'file_type_icon_unknown'
        }
    }, [type])

    const handlerClick = (e: React.MouseEvent) => {
        if (isPlayAudio && type === 'mp3' && audioRef.current) {
            e.preventDefault()

            if (isPlaying) {
                audioRef.current.pause()
                setIsPlaying(false)
            } else {
                audioRef.current.play()
                setIsPlaying(true)
            }
        }
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onpause = () => {
                setIsPlaying(false)
            }
        }
    }, [audioRef.current])

    return (
        <div className={style.contentFile}>
            <a
                download
                className={cn(style.iconWrap, { [style.blinking]: isPlaying })}
                href={url}
                aria-label={t('save')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handlerClick}
            >
                <SvgResource
                    resourceKey={resourceKey}
                    width={40}
                    height={40}
                />
                {type === 'mp3' && (
                    <audio controls={false} ref={audioRef}>
                        <source src={url} type="audio/mpeg" />
                    </audio>
                )}
            </a>
            <div className={style.info}>
                <div className={style.desc}>
                    {desc}
                </div>
                <Popover
                    classes={style.popoverShare}
                    classesBody={style.popoverShareBody}
                    side="left"
                    trigger={(
                        <span className={style.textShare}>
                            {t('link_menu_share')}
                        </span>
                    )}
                >
                    <Share
                        link={url}
                        text={desc}
                    />
                </Popover>
            </div>
        </div>
    )
}

export default ContentFile
