import { useState } from 'react'

import { CLIENT_STORAGE_USER_GUID_KEY } from 'config/app'
import { StorageService } from 'services'
import { getUUID } from 'utils/helpers'

/**
 * Get or create new user GUID
 */
export default function useUserGUID() {
    const [userGUID] = useState<string>(getUserGUID)

    function getUserGUID() {
        let guid = StorageService.getItem<string>(CLIENT_STORAGE_USER_GUID_KEY)

        if (!guid) {
            guid = getUUID()
            StorageService.setItem(CLIENT_STORAGE_USER_GUID_KEY, guid)
        }

        return guid
    }

    return userGUID
}
