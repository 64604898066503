import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, generatePath } from 'react-router-dom'
import cn from 'classnames'

import { IUserCommon, IUserData } from 'interfaces'
import { UserCardPropType } from 'components/UserCard/UserCard'
import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    AvatarStack,
    UserCard,
    Button,
    SvgResource,
    Link,
} from 'components'
import { useWindowResize } from 'hooks'
import { sumFormat } from 'utils/helpers'
import styleCommon from 'styles/modules/common.module.css'
import style from './FriendsUserCard.module.css'

type FriendsItemPropType = {
    user: IUserCommon & {
        photo?: string
        photo_file_name?: string
        chat?: boolean
    }
    data?: IUserData
    isUpdating?: boolean
    isShowCounts?: boolean
    onFriendRequestConfirm?: (id: number) => void
    onFriendRequestReject?: (id: number) => void
} & Omit<UserCardPropType, 'name' | 'surname'>

const COUNT_FRIENDS = 3

const FriendsUserCard: React.FC<FriendsItemPropType> = ({
    user,
    data,
    isUpdating,
    isShowCounts = false,
    onFriendRequestConfirm,
    onFriendRequestReject,
    ...props
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const mutualFriends = useMemo(() => {
        if (data?.friends) {
            return data.friends.map((item) => ({
                id: item.id,
                name: item.name ?? '',
                surname: item.surname ?? '',
                photo: item.photo ?? '',
            }))
        }
        return []
    }, [data])

    const moreFriends = useMemo(() => {
        return !!data?.total && data.total > COUNT_FRIENDS ? sumFormat(data.total - COUNT_FRIENDS, 0) : ''
    }, [data])

    const countChecks = useMemo(() => {
        if (data?.count_checks === undefined) {
            return ''
        }
        return data.count_checks ? sumFormat(data.count_checks, 0) : '0'
    }, [data])

    const countCommunity = useMemo(() => {
        if (data?.count_subs_community === undefined) {
            return ''
        }
        return data.count_subs_community ? sumFormat(data.count_subs_community, 0) : '0'
    }, [data])

    const urlProfile = useMemo(() => {
        return user ? generatePath(APP_URL.profile, { id: user.id }) : ''
    }, [user])

    const urlMutualFriends = useMemo(() => {
        return data?.user ? generatePath(APP_URL.friendsMutual, { id: data.user.id }) : ''
    }, [data])

    const handlerClickChat = () => {
        history.push(generatePath(APP_URL.messengerChat, { id: user.id }))
    }

    const handlerClickConfirm = () => {
        if (onFriendRequestConfirm && data?.user) {
            onFriendRequestConfirm(data.user.id)
        }
    }

    const handlerClickReject = () => {
        if (onFriendRequestReject && data?.user) {
            onFriendRequestReject(data.user.id)
        }
    }

    return (
        <UserCard
            name={user.name}
            surname={user.surname}
            photo={user.photo || user.photo_file_name}
            online={user.is_online}
            url={urlProfile}
            {...props}
        >
            {isShowCounts && (
                <div className={style.info}>
                    <div className={style.infoItem}>
                        <SvgResource
                            resourceKey="ic_checks2_svg"
                            width={24}
                            height={30}
                            title={t('checks')}
                        />
                        <span className={style.infoText}>
                            {countChecks}
                        </span>
                    </div>
                    <div className={style.infoItemSeparate} />
                    <div className={style.infoItem}>
                        <SvgResource
                            resourceKey="ic_rating2_svg"
                            width={24}
                            height={30}
                            title={t('screen_communities_title')} // FIXME translate key
                        />
                        <span className={style.infoText}>
                            {countCommunity}
                        </span>
                    </div>
                </div>
            )}
            <div className={style.content}>
                <Link url={urlMutualFriends}>
                    <AvatarStack
                        isKeepHeight={false}
                        users={mutualFriends}
                        count={COUNT_FRIENDS}
                        size={windowWidth >= BREAKPOINTS.bigDesktop ? 48 : 40}
                    />
                </Link>
                {!!data?.total && data.total > COUNT_FRIENDS && (
                    <div className={cn(style.text, style.text_mLeft)}>
                        {`+${moreFriends}`}
                    </div>
                )}
                {data?.total === 0 && (
                    <div className={cn(style.text, style.text_center)}>
                        {t('no_mutual_friends')}
                    </div>
                )}
                {user.chat && (
                    <Button
                        classes={style.actionChat}
                        styleType="transparent"
                        onClick={handlerClickChat}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconChat}
                            resourceKey="ic_chat2_svg"
                            width={32}
                            height={32}
                        />
                    </Button>
                )}
            </div>
            {onFriendRequestConfirm && onFriendRequestReject && (
                <div className={cn(style.actions, { [styleCommon.updating]: isUpdating })}>
                    <Button
                        textUpper
                        classes={style.action}
                        size="size46"
                        onClick={handlerClickConfirm}
                    >
                        {t('accept')}
                    </Button>
                    <Button
                        textUpper
                        classes={style.action}
                        styleType="bordered"
                        size="size46"
                        onClick={handlerClickReject}
                    >
                        {t('hide')}
                    </Button>
                </div>
            )}
        </UserCard>
    )
}

export default FriendsUserCard
