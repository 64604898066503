import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TChatMicroServiceError, TUserBelongsChannelsProps, TUserBelongsChannelsResponse } from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TUserBelongsChannelsProps
export type TFetchFnResponse = TUserBelongsChannelsResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

const key = QueryCacheKeys.chatUserBelongsChannels

export default function useFetchUserBelongsChannels(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchUserBelongsChannels(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
