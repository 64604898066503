import React, { useState } from 'react'
import cn from 'classnames'

import { getId } from 'utils/helpers'
import style from './Textarea.module.css'

type TextareaPropType = {
    classes?: string
    name?: string
    styleType?: 'default' | 'dynamic' | 'clear' | 'transparent' | 'gray'
    placeholder?: string
    dynamicPlaceholder?: string
    value?: string
    limit?: number
    disabled?: boolean
    onChange?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

const Textarea: React.FC<TextareaPropType> = ({
    classes,
    name,
    styleType = 'default',
    placeholder,
    dynamicPlaceholder,
    value,
    limit = 1000,
    disabled,
    onChange = () => {},
    onBlur = () => {},
}) => {
    const [id] = useState(getId(false))

    const handlerChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        onChange(e)
    }

    const handlerBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        onBlur(e)
    }

    return (
        <>
            <textarea
                id={id}
                className={cn(style.textarea, style[styleType], classes)}
                name={name}
                placeholder={dynamicPlaceholder ? ' ' : placeholder}
                maxLength={limit}
                value={value}
                disabled={disabled}
                onChange={handlerChange}
                onBlur={handlerBlur}
            />

            {styleType === 'dynamic' && dynamicPlaceholder && (
                <label className={style.placeholder} htmlFor={id}>
                    {dynamicPlaceholder}
                </label>
            )}
        </>
    )
}

export default Textarea
