import React from 'react'
import cn from 'classnames'

import style from './Arrow.module.css'

type ArrowPropType = {
    classes?: string
    direction?: 'top' | 'bottom' | 'left' | 'right'
}

/**
 * Компонент вывода стрелочек
 *
 * @memberOf module:Components
 * @param {object} props
 * @property {string} classes
 * @property {string} direction - направление
 */
const Arrow: React.FC<ArrowPropType> = ({ classes, direction = 'bottom' }) => {
    return (
        <span className={cn(style.arrow, style[`arrow_${direction}`], classes)} />
    )
}

export default Arrow
