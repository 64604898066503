import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { IAgreementCompany, IAgreement, ICompanyAgreementData } from 'interfaces'
import { Input } from 'components'
import style from './CompanyAgreements.module.css'

type CompanyAgreementsPropType = {
    company: IAgreementCompany
    agreement: IAgreement
    onChange: (data: ICompanyAgreementData) => void
}

const CompanyAgreements: React.FC<CompanyAgreementsPropType> = ({ company, agreement, onChange }) => {
    const { t } = useTranslation()

    const handlerChangeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            success: e.target.checked,
            companyId: company.company_id,
            agreementId: agreement.id,
        })
    }

    return (
        <div className={style.companyAgreement}>
            <div className={style.logo}>
                <img className={style.logoImg} src={company.company_logo_url} alt="logo" />
            </div>

            <div className={style.item}>
                <div className={style.text}>
                    {agreement.text}
                </div>
                <div>
                    <a className={style.link} href={agreement.link} target="_blank" rel="noopener noreferrer">
                        {agreement.name}
                    </a>
                </div>
                <label className={style.labelCheck}>
                    <Input
                        type="checkbox"
                        name="agreement"
                        onChange={handlerChangeItem}
                    />
                    {t('agreement_checkbox_text')}
                </label>
            </div>
        </div>
    )
}

export default CompanyAgreements
