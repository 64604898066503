import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IRating } from 'interfaces'
import { SvgResource } from 'components'
import { numberFormat, plural } from 'utils/helpers'
import style from './RatingItem.module.css'

type RatingItemPropType = {
    data: IRating
}

const RatingItem: React.FC<RatingItemPropType> = ({ data }) => {
    const { rank } = data

    const { t } = useTranslation()

    const ratingNum = useMemo(() => numberFormat(data.rating), [data])
    const placeNum = useMemo(() => numberFormat(data.place), [data])

    const ratingPlural = useMemo(() => {
        return plural(data.rating, [t('points_count_1x'), t('points_count_2x'), t('points_count_5x')])
    }, [data])

    return (
        <div className={style.rating}>
            <div className={style.col}>
                <SvgResource
                    resourceKey={rank.icon}
                    width={32}
                    height={32}
                />
                <div className={style.desc}>
                    {rank.name}
                </div>
            </div>
            <div className={style.col}>
                <SvgResource
                    resourceKey="rating_ic_star_svg"
                    width={32}
                    height={32}
                />
                <div className={style.desc}>
                    {ratingNum}
                    {' '}
                    {ratingPlural}
                </div>
            </div>
            <div className={style.col}>
                <SvgResource
                    resourceKey="rating_ic_place_svg"
                    width={32}
                    height={32}
                />
                <div className={style.desc}>
                    {placeNum}
                    {' '}
                    {t('place')}
                </div>
            </div>
        </div>
    )
}

export default RatingItem
