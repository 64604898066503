import React, { useMemo } from 'react'
import cn from 'classnames'

import { IMlmRankUser, IMlmRankUserExtended } from 'interfaces'
import { APP_URL } from 'config/app'
import { API_HOST } from 'config/api'
import { Avatar, Image, UserListItem } from 'components'
import { sumFormat } from 'utils/helpers'
import style from './ReportsRankUsersListItem.module.css'

type ReportsRankUsersListItemPropType = {
    classes?: string
    classesBody?: string
    data: IMlmRankUser | IMlmRankUserExtended
}

const ReportsRankUsersListItem: React.FC<ReportsRankUsersListItemPropType> = ({ classes, classesBody, data }) => {
    const points = useMemo(() => {
        return 'points' in data ? sumFormat(data.points) : undefined
    }, [data])

    const userUrl = useMemo(() => APP_URL.profile.replace(':id', String(data.id)), [data])

    return (
        <UserListItem
            classes={cn(style.item, classes)}
            classesBody={cn(style.itemBody, classesBody)}
            name={data.name}
            surname={data.surname}
            avatarPos="middle"
            online={false}
            url={userUrl}
            avatar={(
                <div className={style.avatarWrap}>
                    <Avatar
                        isDoubleSize
                        classes={style.avatarParent}
                        src={data.parent.icon ? `${API_HOST}${data.parent.icon}` : undefined}
                        name={data.parent.name}
                        surname={data.parent.surname}
                        width={60}
                        height={60}
                    />
                    <Avatar
                        isDoubleSize
                        classes={style.avatar}
                        src={data.icon ? `${API_HOST}${data.icon}` : undefined}
                        name={data.name}
                        surname={data.surname}
                        width={100}
                        height={100}
                    />
                </div>
            )}
            content={(
                <div className={style.info}>
                    <div>
                        <div className={style.name}>
                            {`${data.name ?? ''} ${data.surname ?? ''}`}
                        </div>
                        <div>
                            <span className={style.region}>
                                {data.region.name}
                            </span>
                            {data.subtitle && (
                                <span className={style.rank}>
                                    {data.subtitle}
                                </span>
                            )}
                        </div>
                        {points !== undefined && (
                            <div className={style.points}>
                                {points}
                            </div>
                        )}
                    </div>
                    <Image
                        classesWrap={style.imageWrap}
                        src={`${API_HOST}${data.rank.icon}`}
                        isPlaceholder={false}
                        width={80}
                        height={49}
                        resize={{ width: 160, height: 97, quality: 100 }}
                    />
                </div>
            )}
        />
    )
}

export default ReportsRankUsersListItem
