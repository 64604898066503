import React, { CSSProperties, useContext } from 'react'
import cn from 'classnames'

import { Context } from '../Context'
import style from './CustomSelectOption.module.css'

export type CustomSelectOptionPropType = {
    id: number
    classes?: string
    styles?: CSSProperties
    isActive?: boolean
    onClick: (id: number) => void
}

const CustomSelectOption: React.FC<CustomSelectOptionPropType> = ({
    children,
    id,
    classes,
    styles,
    isActive,
    onClick,
}) => {
    const { setOpen } = useContext(Context)

    const handlerClick = () => {
        onClick(id)
        setOpen(false)
    }

    return (
        <li
            className={cn(style.item, classes, { [style.item_active]: isActive })}
            role="menuitem"
            style={styles}
            onClick={handlerClick}
            onKeyDown={() => {}}
        >
            {children}
        </li>
    )
}

export default CustomSelectOption
