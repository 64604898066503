import { IRootState } from 'interfaces'
import {
    FETCH_ORDERS,
    UPDATE_ORDERS,
    CLEAR_ORDERS,
} from 'reducers/types'
import {
    fetchOrdersActionType,
    updateOrdersActionType,
    clearOrdersActionType,
} from './market-actions-type'

type ordersActionsType = fetchOrdersActionType | updateOrdersActionType | clearOrdersActionType

export const ordersReducer = (state: object | IRootState['orders'] = {}, action: ordersActionsType)
    : object | IRootState['orders'] => {
    switch (action.type) {
        case FETCH_ORDERS: {
            const { storeId, data } = action.payload
            const orders = state[storeId] || []

            return { ...state, [storeId]: [...orders, ...data] }
        }

        case UPDATE_ORDERS: {
            const { storeId, data } = action.payload
            return { ...state, [storeId]: data }
        }

        case CLEAR_ORDERS: {
            const { storeId } = action.payload
            return { ...state, [storeId]: [] }
        }

        default:
            return state
    }
}
