import React from 'react'

import CustomSelectField, { CustomSelectFieldType } from './CustomSelectField/CustomSelectField'
import CustomSelectSelect, { CustomSelectSelectPropType } from './CustomSelectSelect/CustomSelectSelect'
import CustomSelectSearch, { CustomSelectSearchPropType } from './CustomSelectSearch/CustomSelectSearch'
import CustomSelectOptions, { CustomSelectOptionsPropType } from './CustomSelectOptions/CustomSelectOptions'
import CustomSelectOption, { CustomSelectOptionPropType } from './CustomSelectOption/CustomSelectOption'

type CustomSelectType = {
    Select: React.FC<CustomSelectSelectPropType>
    Field: React.FC<CustomSelectFieldType>
    Search: React.FC<CustomSelectSearchPropType>
    Options: React.FC<CustomSelectOptionsPropType>
    Option: React.FC<CustomSelectOptionPropType>
}

/**
 * @example
 * <CustomSelect.Select>
 *  <CustomSelect.Field>
 *     text
 *  </CustomSelect.Field>
 *  <CustomSelect.Options>
 *   {date.map((item) => (
 *     <CustomSelect.Option
 *         key={item.index}
 *         onClick={handlerClick}
 *     >
 *         {item.value}
 *     </CustomSelect.Option>
 *   </CustomSelect.Options>
 * </CustomSelect.Select>
 */
const CustomSelect: CustomSelectType = {
    Select: (props) => <CustomSelectSelect {...props} />,
    Field: (props) => <CustomSelectField {...props} />,
    Search: (props) => <CustomSelectSearch {...props} />,
    Options: (props) => <CustomSelectOptions {...props} />,
    Option: (props) => <CustomSelectOption {...props} />,
}

export default CustomSelect
