import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { IPostLike } from 'interfaces'
import { UserCardPropType } from 'components/UserCard/UserCard'
import { APP_URL } from 'config/app'
import { UserCard } from 'components'
// import style from './PostLikeUserCard.module.css'

export type PostLikesPropType = {
    data: IPostLike
} & Omit<UserCardPropType, 'name' | 'surname'>

const PostLikeUserCard: React.FC<PostLikesPropType> = ({ data, ...props }) => {
    const { user } = data

    const url = useMemo(() => {
        return generatePath(APP_URL.profile, { id: user.id })
    }, [user])

    return (
        <UserCard
            name={user.name}
            surname={user.surname}
            photo={user.photo}
            online={user.is_online}
            url={url}
            {...props}
        />
    )
}

export default PostLikeUserCard
