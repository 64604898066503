import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { Link, MenuContext } from 'components'
import { useContextMenu } from 'hooks'
import style from './MessengerItem.module.css'

type MessengerItemPropType = {
    classes?: string
    classesContent?: string
    classesInfo?: string
    isActive?: boolean
    isHover?: boolean
    url?: string
    avatar: JSX.Element
    content: JSX.Element | string
    info?: JSX.Element | string
    menu?: JSX.Element
}

/**
 * Common chat/channel item layout
 */
const MessengerItem: React.FC<MessengerItemPropType> = ({
    classes,
    classesContent,
    classesInfo,
    isActive,
    isHover,
    url,
    avatar,
    content,
    info,
    menu,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const { coords } = useContextMenu({ ref })

    const [isShowMenu, setIsShowMenu] = useState(false)

    const handlerCloseMenu = () => {
        setIsShowMenu(false)
    }

    useEffect(() => {
        if (coords.x && coords.y) {
            // FIXME in KW-184 KW-185
            // setIsShowMenu(true)
        }
    }, [coords])

    return (
        <>
            <Link
                className={cn(
                    style.item,
                    {
                        [style.item_active]: isActive,
                        [style.item_hover]: isHover,
                    },
                    classes,
                )}
                url={url}
                ref={ref}
            >
                <div className={cn(style.avatar)}>
                    {avatar}
                </div>
                <div className={cn(style.content, classesContent)}>
                    {content}
                </div>
                {info && (
                    <div className={cn(style.info, classesInfo)}>
                        {info}
                    </div>
                )}
            </Link>

            <MenuContext
                isOpen={isShowMenu}
                x={coords.x ?? 0}
                y={coords.y ?? 0}
                onClose={handlerCloseMenu}
            >
                {menu}
            </MenuContext>
        </>
    )
}

export default MessengerItem
