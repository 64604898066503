import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { useFetchStoreCpaLink } from 'containers/Store/hooks'
import { parseTpl, strPartsHighlight } from 'utils/helpers'

import styleButton from 'components/Button/Button.module.css'
import styleStoreProfile from 'containers/StoreProfile/StoreProfile.module.css'
import style from './StoreProfileCpaContent.module.css'

type StoreCpaContentPropType = {
    profile: IStoreProfile
}

const StoreProfileCpaContent: React.FC<StoreCpaContentPropType> = ({ profile }) => {
    const { t } = useTranslation()

    const { data: dataStoreCpaLink } = useFetchStoreCpaLink({ storeId: profile.id }, { enabled: !!profile })

    const textParts = useMemo(() => {
        if (profile?.kick_loyalty_settings_user) {
            const parseTplConfig = { prefix: '', suffix: '' }
            const cashBackText = parseTpl(t('kick_loyalty_settings_cpa_text_inject'), {
                '%s': profile.kick_loyalty_settings_user,
            }, parseTplConfig)
            const fullText = parseTpl(t('kick_loyalty_settings_cpa_text'), {
                '%s': cashBackText,
            }, parseTplConfig)

            return strPartsHighlight(fullText, cashBackText)
        }
        return []
    }, [profile])

    return (
        <div className={style.content}>
            {!!textParts.length && (
                <div className={style.blockText}>
                    {textParts.map((item) => (
                        <span
                            className={cn(style.text, { [style.text_highlight]: item.highlight })}
                            key={item.id}
                        >
                            {item.value}
                        </span>
                    ))}
                </div>
            )}

            <div className={style.desc}>
                {t('kick_loyalty_settings_cpa_alert_text')}
            </div>

            {dataStoreCpaLink && (
                <div className={style.controls}>
                    <a
                        className={cn(
                            styleButton.primary,
                            styleButton.size44,
                            styleStoreProfile.control,
                        )}
                        href={dataStoreCpaLink.link}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {t('button_cpa_store')}
                    </a>
                </div>
            )}
        </div>
    )
}

export default StoreProfileCpaContent
