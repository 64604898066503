import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ICalendarPost } from 'interfaces'
import { APP_URL } from 'config/app'
import { dateTimeFormat, getItemFromArray } from 'utils/helpers'
import style from './CalendarMonthPost.module.css'

type CalendarMonthPostPropType = {
    data: ICalendarPost
    colors: string[]
}

const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }

const CalendarMonthPost: React.FC<CalendarMonthPostPropType> = ({ data, colors }) => {
    const { i18n } = useTranslation()

    const time = useMemo(() => {
        const from = dateTimeFormat(data.calendar_date_from, i18n.language, TIME_OPTIONS)
        const to = dateTimeFormat(data.calendar_date_to, i18n.language, TIME_OPTIONS)

        return `${from} - ${to}`
    }, [data, i18n.language])

    const url = useMemo(() => {
        return APP_URL.post.replace(':id', String(data.id))
    }, [data])

    const color = useMemo(() => {
        return getItemFromArray(colors, data.id)
    }, [data])

    return (
        <div className={style.post}>
            <div className={style.time}>
                {time}
            </div>
            <NavLink className={style.title} to={url} style={{ color }}>
                {data.title}
            </NavLink>
        </div>
    )
}

export default CalendarMonthPost
