import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { Popover, MenuList, SvgResource } from 'components'
import style from './MessengerMenu.module.css'

type MessengerMenuPropType = {
    isSetMenuAddChat?: boolean
    isSetMenuAddChannel?: boolean
}

const MessengerMenu: React.FC<MessengerMenuPropType> = ({ isSetMenuAddChat = true, isSetMenuAddChannel = true }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const handlerClickAddChat = () => {
        history.push(APP_URL.messengerChatNew)
    }

    const handlerClickAddChannel = () => {
        history.push(APP_URL.messengerChannelNew)
    }

    return (
        <Popover
            isShowArrow={false}
            position="bottom"
            side="left"
            trigger={(
                <SvgResource
                    isImgTag={false}
                    classes={style.iconAdd}
                    resourceKey="ic_chat_add_v3_svg"
                    width={30}
                />
            )}
        >
            <MenuList>
                <MenuList.Item
                    classes={cn(style.item, { [style.item_hidden]: !isSetMenuAddChat })}
                    onClick={handlerClickAddChat}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconChat}
                        resourceKey="ic_chat2_svg"
                        width={24}
                        height={24}
                    />
                    <div className={style.itemText}>
                        {t('chat_add')}
                    </div>
                </MenuList.Item>
                <MenuList.Item
                    classes={cn(style.item, { [style.item_hidden]: !isSetMenuAddChannel })}
                    onClick={handlerClickAddChannel}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconChannel}
                        resourceKey="ic_tab_friends2_svg"
                        width={24}
                        height={24}
                    />
                    <div className={style.itemText}>
                        {t('create_group')}
                    </div>
                </MenuList.Item>
            </MenuList>
        </Popover>
    )
}

export default MessengerMenu
