import React, { useMemo } from 'react'

import { IPostFile, IPostFileCommon } from 'interfaces'
import { ImagePropType } from 'components/Image/Image'
import { PostTileImage, PostTileVideo } from '../index'

type PostTileFilePropType = {
    file: IPostFile | IPostFileCommon
    url: string
    imgWidth: number
    imgHeight: number
    resize?: ImagePropType['resize']
}

const PostTileFile: React.FC<PostTileFilePropType> = ({
    file,
    url,
    imgWidth,
    imgHeight,
    resize,
}) => {
    const { placeholderColor } = useMemo(() => ({
        // width: file.width,
        // height: file.height,
        placeholderColor: 'placeholder_color' in file ? file.placeholder_color : undefined,
    }), [file])

    const src = useMemo(() => {
        if ('preview_src' in file && file.preview_src) {
            return file.preview_src
        }
        if (file.src) {
            return file.src
        }
        return ''
    }, [file])

    return (
        <>
            {file.type?.includes('video') ? (
                <PostTileVideo
                    url={url}
                    src={src}
                    poster={'preview_src' in file ? file.preview_src : undefined}
                    backgroundColor={placeholderColor}
                    width={imgWidth}
                    height={imgHeight}
                    resize={resize}
                />
            ) : (
                <PostTileImage
                    url={url}
                    src={src}
                    backgroundColor={placeholderColor}
                    width={imgWidth}
                    height={imgHeight}
                    resize={resize}
                />
            )}
        </>
    )
}

export default PostTileFile
