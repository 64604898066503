import React, {
    useEffect,
    useMemo,
    useState,
    useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarPost, ICalendarDay } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
import { CalendarGridMonth, Spinner } from 'components'
import { useWindowResize } from 'hooks'
import { useCalendar } from '../../hooks'
import {
    CalendarMonthCell,
    CalendarMonthDay,
    CalendarMonthEvent,
    CalendarMonthMore,
} from '../index'
import style from './CalendarMonth.module.css'

type CalendarMonthPropType = {
    date: Date
    posts?: ICalendarPost[]
    colors?: string[]
    onClickDay: (value: Date) => void
    onClickEvent: (value: Date, events: ICalendarPost[]) => void
    onAddEvent: (value: Date) => void
}

const GRID_ITEM_POSTS = 2

const CalendarMonth: React.FC<CalendarMonthPropType> = ({
    date,
    posts,
    colors = [],
    onClickDay,
    onClickEvent,
    onAddEvent,
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()
    const postsRef = useRef<HTMLDivElement>(null)

    const [days, setDays] = useState<ICalendarDay[]>([])
    const [daysPosts, setDaysPosts] = useState<Record<string, ICalendarPost[]>>({})

    const { getMonthDays, getDaysPosts } = useCalendar()

    const dayNames = useMemo(() => {
        return t('mo, tu, we, th, fr, sa, su').split(',')
    }, [])

    const isMobile = useMemo(() => windowWidth < BREAKPOINTS.tabletLandscape, [windowWidth])

    const handlerClickEvent = (value: Date, events: ICalendarPost[]) => {
        onClickEvent(value, events)
    }

    useEffect(() => {
        setDays(getMonthDays(date))
        setDaysPosts({})
    }, [date])

    useEffect(() => {
        if (posts) {
            setDaysPosts(getDaysPosts(days, posts))
        }
    }, [days, posts])

    useEffect(() => {
        if (isMobile && Object.keys(daysPosts).length) {
            // FIXME refactoring: scroll after render children list
            setTimeout(() => {
                if (postsRef.current?.children.length) {
                    const childIndex = days
                        .filter((itemDay) => itemDay.activeMonth)
                        .findIndex((itemDay) => itemDay.currentDay)

                    if (childIndex >= 0 && postsRef.current.children[childIndex]) {
                        const child = postsRef.current.children[childIndex] as unknown as HTMLCollectionOf<HTMLElement>
                        postsRef.current.scrollTo({
                            top: (child as unknown as HTMLElement).offsetTop - postsRef.current.offsetTop,
                            behavior: 'smooth',
                        })
                    }
                }
            }, 300)
        }
    }, [daysPosts])

    return (
        <div className={style.wrap}>
            {!posts && <Spinner position="center" size="medium" />}
            {!isMobile && (
                <div className={style.days}>
                    {dayNames.map((item) => (
                        <div className={cn(style.cell, style.daysItem)} key={item}>
                            {item}
                        </div>
                    ))}
                </div>
            )}
            <div className={cn(style.list, { [style.list_loading]: !posts })}>
                {isMobile ? (
                    <CalendarGridMonth
                        classes={style.greedMonth}
                        classesHeader={style.greedHeader}
                        classesBody={style.greedBody}
                        classesDate={style.greedDate}
                        days={days}
                        daysPosts={daysPosts}
                        onClickDay={onClickDay}
                    />
                ) : (
                    days.map((item) => (
                        <CalendarMonthCell
                            classes={style.cell}
                            data={item}
                            key={item.id}
                            onClickDay={onClickDay}
                            onClickAddEvent={onAddEvent}
                        >
                            {daysPosts[item.id]?.map((post, index) => (
                                index < GRID_ITEM_POSTS && (
                                    <CalendarMonthEvent
                                        data={post}
                                        colors={colors}
                                        key={post.id}
                                        onClickEvent={() => handlerClickEvent(item.date, daysPosts[item.id])}
                                    />
                                )
                            ))}
                            {daysPosts[item.id] && daysPosts[item.id].length > GRID_ITEM_POSTS && (
                                <CalendarMonthMore
                                    count={daysPosts[item.id].length}
                                    max={GRID_ITEM_POSTS}
                                />
                            )}
                        </CalendarMonthCell>
                    ))
                )}
            </div>
            {isMobile && (
                <div className={style.posts} ref={postsRef}>
                    {days
                        .filter((item) => item.activeMonth && !!daysPosts[item.id]?.length)
                        .map((item) => (
                            <CalendarMonthDay
                                data={item}
                                posts={daysPosts[item.id]}
                                colors={colors}
                                key={item.id}
                            />
                        ))}
                </div>
            )}
        </div>
    )
}

export default CalendarMonth
