import React, { useState, useEffect } from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    Avatar,
    Input,
    Button,
    SvgResource,
} from 'components'
import { FieldFile } from 'forms/fields'
import styleForm from 'styles/modules/form.module.css'
import style from './ChannelForm.module.css'

export enum FieldName {
    title = 'title',
    description = 'description',
    image = 'image',
}

export type FormDataType = {
    [FieldName.title]: string
    [FieldName.description]?: string
    [FieldName.image]?: File
}

type ChannelFormPropType = {
    classes?: string
    isSubmitting: boolean
    isDisabled: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void

}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'
const AVATAR_IMAGE_MAX_SIDE = 400

const ChannelForm: React.FC<ChannelFormPropType> = ({
    classes,
    isSubmitting,
    isDisabled,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const [imageSrc, setImageSrc] = useState('')

    const { values, hasValidationErrors } = useFormState<FormDataType>()

    useEffect(() => {
        return () => {
            if (imageSrc) {
                window.URL.revokeObjectURL(imageSrc)
            }
        }
    }, [imageSrc])

    useEffect(() => {
        const file = values[FieldName.image]

        if (file) {
            setImageSrc(window.URL.createObjectURL(file))
        }
    }, [values[FieldName.image]])

    return (
        <form
            className={cn(
                style.form,
                { [styleForm.submitting]: isDisabled || isSubmitting },
                classes,
            )}
            onSubmit={onSubmit}
        >
            <div className={style.row}>
                <FieldFile
                    classes={style.imageWrap}
                    name={FieldName.image}
                    accept={ACCEPT_IMAGE}
                    imageMaxSide={AVATAR_IMAGE_MAX_SIDE}
                    isUpload={false}
                >
                    <Avatar
                        isResize={false}
                        src={imageSrc}
                        width={48}
                        height={48}
                    />
                    {!values[FieldName.image] && (
                        <SvgResource
                            classes={style.imageIcon}
                            resourceKey="add_photo_svg"
                            width={48}
                            height={48}
                        />
                    )}
                </FieldFile>
                <Field
                    name={FieldName.title}
                    render={({ input, meta }) => (
                        <Input
                            classes={cn(style.input, style.input_margin)}
                            placeholder={t('chat_group_name')}
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                        />
                    )}
                />
                <Button
                    textUpper
                    classes={style.action}
                    type="submit"
                    size="size40"
                    disabled={hasValidationErrors}
                >
                    {t('chat_group_create')}
                </Button>
            </div>
            <div className={style.row}>
                <Field
                    name={FieldName.description}
                    render={({ input, meta }) => (
                        <Input
                            classes={style.input}
                            placeholder={t('channel_chat_description')}
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                        />
                    )}
                />
            </div>
        </form>
    )
}

export default ChannelForm
