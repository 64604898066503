import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
} from 'react'
import cn from 'classnames'

import { Button } from 'components'
import style from './Counter.module.css'

type CounterPropType = {
    classes?: string
    changeByRemote?: boolean // change current count by changed value in remote state
    value?: number
    minCount?: number
    maxCount?: number
    onChange: (value: number) => void
}

const Counter: React.FC<CounterPropType> = ({
    classes,
    changeByRemote = true,
    value = 1,
    minCount = 0,
    maxCount = 1000,
    onChange,
}) => {
    const ref = useRef<HTMLInputElement>(null)
    const [count, setCount] = useState(value)

    const isDisabledMinus = useMemo(() => count === minCount, [count])
    const isDisabledPlus = useMemo(() => count + 1 > maxCount, [count])

    const handlerClickMinus = () => {
        const newCount = count - 1

        if (newCount >= minCount) {
            if (!changeByRemote) {
                setCount(newCount)
            }
            onChange(newCount)
        }
    }

    const handlerClickPlus = () => {
        const newCount = count + 1

        if (newCount <= maxCount) {
            if (!changeByRemote) {
                setCount(newCount)
            }
            onChange(newCount)
        }
    }

    const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: val } = e.target
        const newCount = Number(val)

        if (!Number.isNaN(newCount) && newCount <= maxCount) {
            setCount(newCount)
        }
    }

    const handlerChangeKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && ref.current) {
            ref.current.blur()
        }
    }

    const handlerBlur = () => {
        onChange(count)
    }

    useEffect(() => {
        if (changeByRemote && value !== count) {
            setCount(value)
        }
    }, [value])

    return (
        <div className={cn(style.counter, classes)}>
            <Button
                classes={cn(style.control, style.controlLeft)}
                disabled={isDisabledMinus}
                styleType="text"
                text="-"
                onClick={handlerClickMinus}
            />
            <input
                className={style.input}
                inputMode="numeric"
                value={String(count)}
                ref={ref}
                onChange={handlerChange}
                onKeyUp={handlerChangeKeyUp}
                onBlur={handlerBlur}
            />
            <Button
                classes={cn(style.control, style.controlRight)}
                disabled={isDisabledPlus}
                styleType="text"
                text="+"
                onClick={handlerClickPlus}
            />
        </div>
    )
}

export default Counter
