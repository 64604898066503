import React, { useEffect, useRef, useState } from 'react'
import {
    Chart as ChartJs,
    ChartConfiguration,
    ChartType,
    ChartOptions,
    PluginChartOptions,
    PluginOptionsByType,
    InteractionItem,
    registerables,
} from 'chart.js'

import style from './Chart.module.css'

export type ChartClickEvent = InteractionItem

export type {
    ChartType,
    ChartConfiguration,
    ChartOptions,
    PluginChartOptions,
    PluginOptionsByType,
}

export type ChartPropType = {
    config?: ChartConfiguration
    options?: React.CanvasHTMLAttributes<HTMLCanvasElement>
    onClick?: (e: ChartClickEvent) => void
}

/**
 * @see https://www.chartjs.org/docs/3.9.1/
 * plugins:
 *  @see ./plugins
 *  @see chartjs-plugin-datalabels https://v2_2_0--chartjs-plugin-datalabels.netlify.app/guide/
 */
const Chart: React.FC<ChartPropType> = ({
    config,
    options = {},
    onClick,
}) => {
    const ref = useRef<HTMLCanvasElement>(null)
    const [chart, setChart] = useState<ChartJs>()

    const handlerClick = (e: Event) => {
        if (chart && onClick) {
            const [point] = chart.getElementsAtEventForMode(e, 'point', { intersect: true }, true)

            if (point) {
                onClick(point)
            }
        }
    }

    function destroyChart() {
        if (chart) {
            chart.destroy()
        }
    }

    useEffect(() => {
        ChartJs.register(...registerables)
    }, [])

    useEffect(() => {
        if (ref?.current && config) {
            destroyChart()
            setChart(new ChartJs(ref.current, config))
        }

        return () => {
            destroyChart()
        }
    }, [config])

    useEffect(() => {
        if (ref?.current && chart && onClick) {
            ref.current.onclick = handlerClick
        }

        return () => {
            if (ref?.current?.onclick) {
                ref.current.onclick = null
            }
        }
    }, [chart])

    return (
        <canvas
            className={style.canvas}
            ref={ref}
            {...options}
        />
    )
}

export default Chart
