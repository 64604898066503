import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import style from './RegAgreement.module.css'

const RegAgreement: React.FC = () => {
    const { t, i18n } = useTranslation()

    const cookieLink = useMemo(() => {
        switch (i18n.language) {
            case 'ru':
                return 'https://www.sessia.com/lang/ru/cookie.html'
            case 'vn':
                return 'https://www.sessia.com/lang/vn/cookie.html'
            case 'de':
                return 'https://www.sessia.com/lang/de/cookie.html'
            case 'cn':
                return 'https://www.sessia.com/lang/cn/cookie.html'
            case 'en':
            default:
                return 'https://www.sessia.com/cookie.html'
        }
    }, [i18n.language])

    return (
        <>
            {t('By registering, you consent with')}
            &nbsp;
            <a className={style.link} href={t('user_agreement_link')} target="_blank" rel="noopener noreferrer">
                {t('the Terms of Use')}
            </a>
            ,
            &nbsp;
            <a className={style.link} href={t('privacy_policy_link')} target="_blank" rel="noopener noreferrer">
                {t('Use of Data Policy')}
            </a>
            &nbsp;
            {t('and')}
            &nbsp;
            <a className={style.link} href={cookieLink} target="_blank" rel="noopener noreferrer">
                {t('Cookie Policy')}
            </a>
        </>
    )
}

export default RegAgreement
