import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IShopFilter } from 'interfaces'
import style from './ShopFilterItem.module.css'

type ShopFilterItemPropType = {
    filter: IShopFilter
    onClick: (id: number) => void
}

const ShopFilterItem: React.FC<ShopFilterItemPropType> = ({ filter, onClick }) => {
    const { t } = useTranslation()

    const handlerClick = () => {
        onClick(filter.id)
    }

    return (
        // eslint-disable-next-line
        <li
            className={cn(style.item, { [style.active]: filter.active, [style.center]: !filter.marker })}
            key={filter.id}
            onClick={handlerClick}
        >
            {filter.marker && (
                <span className={cn(style.marker, style[filter.marker])} />
            )}
            <span className={style.text}>
                {t(filter.translateKey)}
            </span>
        </li>
    )
}

export default ShopFilterItem
