import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Transition } from 'layout'
import { Button } from 'components'
import { splitStr } from 'utils/helpers'
import style from './SmsTimer.module.css'

type SmsTimerPropType = {
    timer: number // ms
    onTimeEnd: () => void
    onClickAction: () => void
}

const SmsTimer: React.FC<SmsTimerPropType> = ({ timer, onTimeEnd, onClickAction }) => {
    const { t, i18n } = useTranslation()

    const [timeLeft, setTimeLeft] = useState(timer / 1000)
    const [isCallActive, setIsCallActive] = useState(false)

    const textSmsSendInfo = useMemo(() => {
        return splitStr(t('phone_code_info_part2'))
            .map((item) => item.text)
            .join(' ')
    }, [i18n.language])

    const textCallSendInfo = useMemo(() => {
        return splitStr(t('phone_code_info_part3'))
            .map((item) => item.text)
            .join(' ')
    }, [i18n.language])

    const handlerClickCallAuth = () => {
        setIsCallActive(true)
        onClickAction()
    }

    useEffect(() => {
        const timerIntervalId = setInterval(() => {
            setTimeLeft((prevState) => prevState - 1)
        }, 1000)

        const setTimeoutId = setTimeout(() => {
            clearInterval(timerIntervalId)
            setTimeLeft(0)
            onTimeEnd()
        }, timer)

        return () => {
            clearInterval(timerIntervalId)
            clearTimeout(setTimeoutId)
        }
    }, [])

    return (
        <>
            <Transition.Group>
                {isCallActive && (
                    <Transition.CSSTransition
                        in
                        classes={style.text}
                        classNames="fade-in"
                        timeout={600}
                    >
                        {textCallSendInfo}
                    </Transition.CSSTransition>
                )}
            </Transition.Group>

            {!isCallActive && (
                <>
                    <div className={style.text}>
                        {textSmsSendInfo}
                    </div>

                    {timeLeft > 0 && (
                        <div className={style.timer}>
                            {timeLeft}
                        </div>
                    )}

                    {timeLeft === 0 && (
                        <div className={style.action}>
                            <Button
                                classes={style.actionLink}
                                text={t('reg_sms_not_received')}
                                styleType="text"
                                onClick={handlerClickCallAuth}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default SmsTimer
