import React from 'react'
import cn from 'classnames'

import style from './Radio.module.css'

type RadioPropType = {
    name: string
    classes?: string
    classesText?: string
    text?: string
    size?: 'small' | 'medium'
    value?: string
    checked?: boolean
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Radio: React.FC<RadioPropType> = ({
    name,
    classes,
    classesText,
    text,
    size = 'medium',
    value = '',
    checked,
    disabled,
    onChange = () => {},
}) => {
    return (
        <label className={cn(style.label, classes)}>
            <input
                className={style.radio}
                type="radio"
                name={name}
                value={value}
                checked={!!checked}
                disabled={!!disabled}
                onChange={onChange}
            />
            <span className={cn(style.circle, style[`circle_${size}`])} />
            {text && (
                <span className={cn(style.text, classesText)}>
                    {text}
                </span>
            )}
        </label>
    )
}

export default Radio
