import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { InputPropType } from 'components/Input/Input'
import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { Input } from 'components'
import style from './Search.module.css'

type SearchPropType = {
    classes?: string
    classesInput?: string
    placeholder?: string
    styleType?: InputPropType['styleType']
    focus?: boolean
    debounce?: number
    defaultValue?: string
    disabled?: boolean
    onSubmit?: (value: string) => void
    onSearch: (value: string) => void
}

const Search: React.FC<SearchPropType> = ({
    classes,
    classesInput,
    placeholder,
    styleType,
    focus,
    defaultValue,
    debounce = INPUT_DEBOUNCE_TIME,
    disabled,
    onSubmit,
    onSearch,
}) => {
    const { t } = useTranslation()

    const searchDebounced = useDebouncedCallback(searchCallback, debounce)

    const handlerChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        searchDebounced(target.value)
    }

    const handlerKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            search(e.currentTarget.value)

            if (onSubmit) {
                onSubmit(e.currentTarget.value)
            }
        }
    }

    function searchCallback(text: string) {
        search(text)
    }

    function search(text: string) {
        onSearch(text.trim())
    }

    return (
        <div className={cn(style.search, classes)}>
            <Input
                classes={classesInput}
                styleType={styleType}
                placeholder={placeholder || t('search')}
                focus={focus}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={handlerChange}
                onKeyUp={handlerKeyUp}
            />
        </div>
    )
}

export default Search
