import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICurrency, IMlmIncome, IMlmIncomeType } from 'interfaces'
import { Chevron } from 'components'
import { priceFormat } from 'utils/helpers'
import style from './ReportsFreedomIncomeListItemContent.module.css'

type ReportsFreedomIncomeListItemContentPropType = {
    data: IMlmIncome
    orderTitle: string
    isLink?: boolean
    incomeType?: IMlmIncomeType
    currencyData?: ICurrency
}

const ReportsFreedomIncomeListItemContent: React.FC<ReportsFreedomIncomeListItemContentPropType> = ({
    data,
    orderTitle,
    isLink,
    incomeType,
    currencyData,
}) => {
    const {
        approved,
        order,
    } = data

    const { t } = useTranslation()

    const amountValue = useMemo(() => {
        const {
            showCode: symbol = '',
            iso: isoCode = '',
            left_currency_symbol_placement: leftSymbolPlacement = false,
            roundScale,
        } = currencyData || {}

        const options: Intl.NumberFormatOptions = {
            minimumFractionDigits: roundScale,
            maximumFractionDigits: roundScale,
        }

        return data
            ? priceFormat(data.amount, { symbol, leftSymbolPlacement, isoCode }, options)
            : undefined
    }, [data])

    const amountSign = useMemo(() => {
        return data ? Math.sign(data.amount) : 0
    }, [data])

    return (
        <div className={style.content}>
            <div className={style.info}>
                <div className={style.title}>
                    {incomeType?.name}
                </div>
                <div className={style.subtitle}>
                    {orderTitle}
                    {order && (
                        <>
                            {' '}
                            {order}
                        </>
                    )}
                </div>
            </div>
            <div className={style.data}>
                <div className={cn(style.amount, {
                    [style.amount_positive]: amountSign === 1,
                    [style.amount_negative]: amountSign === -1,
                })}
                >
                    {amountSign === 1 && (
                        <>
                            +
                        </>
                    )}
                    {amountValue}
                </div>
                <div className={style.approve}>
                    {approved ? (
                        t('approved')
                    ) : (
                        t('not_approved')
                    )}
                </div>
            </div>
            {isLink && (
                <div className={style.arrow}>
                    <Chevron
                        classes={style.chevron}
                        size={15}
                        type="right"
                    />
                </div>
            )}
        </div>
    )
}

export default ReportsFreedomIncomeListItemContent
