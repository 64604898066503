import { AxiosError } from 'axios'
import {
    UseInfiniteQueryOptions,
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
    useInfiniteQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TUserServiceError,
    TFriendsRequestProps,
    TFriendsRequestResponse,
    TAddFriendsRequestProps,
    TAddFriendsRequestResponse,
    TCancelFriendsRequestProps,
    TCancelFriendsRequestResponse,
    TConfirmFriendsRequestProps,
    TConfirmFriendsRequestResponse,
    TRejectFriendsRequestProps,
    TRejectFriendsRequestResponse,
} from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TFriendsRequestProps
export type TFetchFnError = AxiosError<TUserServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFriendsRequestResponse, TError, TFriendsRequestResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFriendsRequestResponse,
    TError,
    TFriendsRequestResponse,
    TFriendsRequestResponse,
    TQueryKey
>

export type TAddMutationFnError = [string, AxiosError<TUserServiceError, TAddFriendsRequestProps>]
export type TCancelMutationFnError = [string, AxiosError<TUserServiceError, TCancelFriendsRequestProps>]
export type TConfirmMutationFnError = [string, AxiosError<TUserServiceError, TConfirmFriendsRequestProps>]
export type TRejectMutationFnError = [string, AxiosError<TUserServiceError, TRejectFriendsRequestProps>]

const key = QueryCacheKeys.friendsRequest

/**
 * Хук API получить список запросы в друзья
 */
export default function useFetchFriendsRequest(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.fetchFriendsRequest(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, opts)
}

export function useFetchInfiniteFriendsRequest(initParams: TFetchFnParams = {}, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return UserService.fetchFriendsRequest(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.list.length }
        },
        ...opts,
    })
}

export function useInvalidateFriendsRequest(
    params: TFetchFnParams = {}, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

export function useMutationFriendsRequest() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddFriendsRequestResponse,
        TAddMutationFnError,
        TAddFriendsRequestProps,
        unknown
    >((props) => {
        return UserService.addFriendsRequest(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const cancel = useMutation<
        TCancelFriendsRequestResponse,
        TCancelMutationFnError,
        TCancelFriendsRequestProps,
        unknown
    >((props) => {
        return UserService.cancelFriendsRequest(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const confirm = useMutation<
        TConfirmFriendsRequestResponse,
        TConfirmMutationFnError,
        TConfirmFriendsRequestProps,
        unknown
    >((props) => {
        return UserService.confirmFriendsRequest(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const reject = useMutation<
        TRejectFriendsRequestResponse,
        TRejectMutationFnError,
        TRejectFriendsRequestProps,
        unknown
    >((props) => {
        return UserService.rejectFriendsRequest(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        cancel,
        confirm,
        reject,
    }
}
