import React from 'react'
import cn from 'classnames'

import style from './ModalFooter.module.css'

export type ModalFooterPropType = {
    classes?: string
}

const ModalFooter: React.FC<ModalFooterPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.footer, classes)}>
            {children}
        </div>
    )
}

export default ModalFooter
