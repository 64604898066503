import React, { useMemo } from 'react'

import { IMlmProduct } from 'interfaces'
import { numberFormat } from 'utils/helpers'
import style from './ReportsFreedomTurnoverListItemContent.module.css'

type ReportsFreedomTurnoverListItemContentPropType = {
    data: IMlmProduct
}

const ReportsFreedomTurnoverListItemContent: React.FC<ReportsFreedomTurnoverListItemContentPropType> = ({
    data,
}) => {
    const count = useMemo(() => {
        return data.count !== undefined ? numberFormat(data.count) : undefined
    }, [data])

    return (
        <div className={style.content}>
            <div className={style.info}>
                {data.title}
            </div>
            <div className={style.data}>
                {count}
            </div>
        </div>
    )
}

export default ReportsFreedomTurnoverListItemContent
