import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { SvgResource } from 'components'
import style from './PopoverTriggerMore.module.css'

export type PopoverTriggerMorePropType = {

}

const PopoverTriggerMore: React.FC<PopoverTriggerMorePropType> = () => {
    const { t } = useTranslation()

    return (
        <div className={cn(style.trigger)}>
            <div className={style.text}>
                {t('tab_more')}
            </div>
            <SvgResource
                isImgTag={false}
                classes={style.iconDropDown}
                resourceKey="dropdpwn_daw_svg"
                width={15}
                height={13}
            />
        </div>
    )
}

export default PopoverTriggerMore
