export default class WebWorkerEnabler {
    constructor(worker: () => void) {
        let code = worker.toString()
        code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))

        const blob = new Blob([code], { type: 'application/javascript' })

        return new window.Worker(URL.createObjectURL(blob))
    }
}
