import React, { useEffect, useRef, useState } from 'react'

export type TIntersectionObserverProps = IntersectionObserverInit & {
    freezeOnceVisible?: boolean
}

export type TIntersectionObserverResponse = [
    boolean,
]

/**
 * Hook асинхронного наблюдения за элементом на странице
 * Intersection Observer API
 *
 * @example
 * const [isIntersecting] = useIntersectionObserver(ref, { root, rootMargin, threshold, freezeOnceVisible })
 *
 * useEffect(() => {
 *    if (isIntersecting) {
 *        // Do something
 *    }
 *}, [isIntersecting])
 */
export default function useIntersectionObserver<T extends HTMLElement = HTMLElement>(
    ref: React.RefObject<T>,
    { freezeOnceVisible, ...params }: TIntersectionObserverProps,
): TIntersectionObserverResponse {
    const observer = useRef<IntersectionObserver | null>(null)

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        return () => {
            if (observer?.current) {
                observer.current.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        if (typeof window.IntersectionObserver === 'undefined') {
            setIsVisible(true)
            return undefined
        }
        // Delete the old observer before creating a new one
        if (observer?.current) {
            observer.current.disconnect()
        }

        observer.current = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting && observer?.current && freezeOnceVisible) {
                observer.current.disconnect()
            }

            setIsVisible(entry.isIntersecting)
        }, params)

        if (ref.current) {
            observer.current.observe(ref.current)
        }

        return () => {
            if (observer?.current) {
                observer.current.disconnect()
            }
        }
    }, [ref.current, params])

    return [isVisible]
}
