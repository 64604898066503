import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_URL } from 'config/app'
import MessengerConversationList from './MessengerConversationList/MessengerConversationList'
import MessengerChatNew from './MessengerChatNew/MessengerChatNew'
import MessengerChannelNew from './MessengerChannelNew/MessengerChannelNew'
import MessengerChat from './MessengerChat/MessengerChat'
import MessengerChannel from './MessengerChannel/MessengerChannel'

const Messenger: React.FC = () => {
    return (
        <Switch>
            <Route exact path={APP_URL.messenger} component={MessengerConversationList} />
            <Route exact path={APP_URL.messengerChatNew} component={MessengerChatNew} />
            <Route exact path={APP_URL.messengerChannelNew} component={MessengerChannelNew} />
            <Route path={APP_URL.messengerChat} component={MessengerChat} />
            <Route path={APP_URL.messengerChannel} component={MessengerChannel} />
        </Switch>
    )
}

export default Messenger
