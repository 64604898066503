import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateOptions,
    InvalidateQueryFilters,
    InfiniteData,
    SetDataOptions,
    useQueryClient,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IPost } from 'interfaces'
import { fetchPostsUserPropType } from 'services/CommunityService'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = fetchPostsUserPropType
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IPost[], TError, IPost[], TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<IPost[], TError, IPost[], IPost[], TQueryKey>

const key = 'posts-user'

/**
 * Хук API получение списка постов юзера
 */
export default function useFetchPostsUser(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchPostsUser(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useFetchInfinitePostsUser(initParams: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return CommunityService.fetchPostsUser(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useInvalidatePostsUser(
    params: TFetchFnParams, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries(
            [`${key}-${user.id}`, params],
            { exact, ...filters },
            options,
        )
    }

    return {
        invalidate,
    }
}

export function useSetInfiniteQueryDataPostsUser(params: TFetchFnParams, options?: SetDataOptions) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const setQueryData = (postData: IPost) => {
        queryClient.setQueryData<InfiniteData<IPost[]> | undefined>(
            [`${key}-${user.id}`, params],
            (infiniteData) => {
                if (infiniteData) {
                    const pages = infiniteData.pages.map((page) => {
                        return page.map((post) => (post.id === postData.id ? postData : post))
                    })

                    return { ...infiniteData, pages }
                }

                return infiniteData
            },
            options,
        )
    }

    return {
        setQueryData,
    }
}
