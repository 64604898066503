import React from 'react'
import cn from 'classnames'

import { Checkbox } from 'components'
import style from './MessengerItemCheckBox.module.css'

type MessengerItemCheckBoxPropType = {
    classes?: string
    name: string
    checked: boolean
    disabled?: boolean
    onChange: (name: string, value: boolean) => void
}

const MessengerItemCheckBox: React.FC<MessengerItemCheckBoxPropType> = ({
    children,
    classes,
    name,
    checked,
    disabled,
    onChange,
}) => {
    const handlerChange = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        onChange(name, currentTarget.checked)
    }

    return (
        <div className={cn(style.box, classes)}>
            <Checkbox
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={handlerChange}
            />
            {children}
        </div>
    )
}

export default MessengerItemCheckBox
