import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarPost } from 'interfaces'
import { TDeleteCalendarPostProps } from 'services/CommunityService'
import { DatePeriod } from 'enums'
import { APP_URL, REGEXP_DATE_DEFAULT } from 'config/app'
import { ContentContainer, Block, Transition } from 'layout'
import { Button, Modal } from 'components'
import {
    useFetchCalendarPosts,
    useFetchCommunity,
    useInvalidateCalendarPosts,
    useMutationPost,
} from 'containers/Community/hooks'
import { CalendarPostAction } from 'form-actions'
import { formDataInitial } from 'forms/CalendarPostForm/CalendarPostForm'
import { useAlertDialog } from 'hooks'
import {
    defaultDateFormat,
    dateTimeFormat,
    parseTpl,
    scrollTop,
    showAlertNotify,
} from 'utils/helpers'
import { AppService } from 'services'
import { PostDto } from 'dto'
import {
    vBlue6,
    vBlue7,
    vViolet6,
    vYellow5,
    vYellow7,
    vGreen4,
    vGreen5,
    vRed6,
    vRed7,
} from 'styles/modules/variables.module.css'
import {
    CalendarHeader,
    CalendarYear,
    CalendarMonth,
    CalendarDay,
    CalendarDateEventList,
} from './components'
// eslint-disable-next-line
import styleOverlay from 'styles/modules/overlay.module.css'
import style from './Calendar.module.css'

const DEFAULT_PERIOD = DatePeriod.month
const DEFAULT_DATE = new Date()

const CALENDAR_COLORS_SET = [
    vBlue7,
    vYellow5,
    vBlue6,
    vViolet6,
    vGreen4,
    vGreen5,
    vRed6,
    vRed7,
    vYellow7,
]

const getPeriod = (value?: string) => {
    switch (value) {
        case DatePeriod.day:
            return DatePeriod.day
        case DatePeriod.month:
            return DatePeriod.month
        case DatePeriod.year:
            return DatePeriod.year
        default:
            return undefined
    }
}

const getDate = (value: string = '') => {
    if (REGEXP_DATE_DEFAULT.test(value)) {
        const date = new Date(value)
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    }
    return undefined
}

const getCalendarPostsDateParams = (period?: DatePeriod, date?: Date) => {
    if (!period || !date) {
        return undefined
    }

    const dateYear = date.getFullYear()
    const dateMonth = date.getMonth()
    const dateDay = date.getDate()

    switch (period) {
        case DatePeriod.day:
            return {
                from: defaultDateFormat(new Date(dateYear, dateMonth, dateDay, 0, 0, 0), true),
                to: defaultDateFormat(new Date(dateYear, dateMonth, dateDay, 23, 59, 59), true),
            }
        case DatePeriod.month:
            return {
                from: defaultDateFormat(new Date(dateYear, dateMonth, 1), true),
                to: defaultDateFormat(new Date(dateYear, dateMonth + 1, 0, 23, 59, 59), true),
            }
        case DatePeriod.year:
            return {
                from: defaultDateFormat(new Date(dateYear, 0, 1, 0, 0, 0), true),
                to: defaultDateFormat(new Date(dateYear, 12, 0, 23, 59, 59), true),
            }
        default:
            return undefined
    }
}

const Calendar: React.FC = () => {
    const { period: periodParam, date: dateParam } = useParams<{ period?: string, date?: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const { showAlertDialog } = useAlertDialog()

    const [period, setPeriod] = useState(getPeriod(periodParam))
    const [date, setDate] = useState(getDate(dateParam))
    const [dateEvent, setDateEvent] = useState<Date | undefined>()
    const [calendarPostsDateParams, setCalendarPostsDateParams] = useState(getCalendarPostsDateParams(period, date))
    const [dayEvents, setDayEvents] = useState<{ date: Date, events: ICalendarPost[]} | undefined>()
    const [postProps, setPostProps] = useState(formDataInitial)

    const [isOpenModalEvents, setIsOpenModalEvents] = useState(false)
    const [isOpenModalAddEvent, setIsOpenModalAddEvent] = useState(false)
    const [isOpenModalEditEvent, setIsOpenModalEditEvent] = useState(false)

    const { data: dataCalendarPosts } = useFetchCalendarPosts({
        comment_limit: 0,
        ...{ ...calendarPostsDateParams || {} },
    }, {
        enabled: !!calendarPostsDateParams,
    })

    const { data: dataCommunity } = useFetchCommunity({
        can_publish: true,
    }, {
        enabled: isOpenModalAddEvent || isOpenModalEditEvent,
    })

    const { invalidate: invalidateCalendarPosts } = useInvalidateCalendarPosts({}, {
        exact: false,
        refetchType: 'all',
    })

    const { deleteCalendarPost } = useMutationPost()

    const communities = useMemo(() => {
        return dataCommunity ? AppService.getCommunities(dataCommunity) : []
    }, [dataCommunity])

    const handlerChangePeriod = (value: DatePeriod) => {
        setCalendarParams(value, new Date())
    }

    const handlerChangeDate = (value: Date) => {
        setCalendarParams(period || DEFAULT_PERIOD, value)
    }

    const handlerClickDay = (value: Date) => {
        setCalendarParams(DatePeriod.day, value)
    }

    const handlerClickDayEvents = (value: Date, events: ICalendarPost[]) => {
        setDayEvents({ date: value, events })
        setIsOpenModalEvents(true)
    }

    const handlerAddEvent = () => {
        setIsOpenModalAddEvent(true)
    }

    const handlerClickEditEvent = (value: ICalendarPost) => {
        setPostProps({ ...formDataInitial, ...PostDto.getCalendarPostProps(value) })
        setIsOpenModalEditEvent(true)
    }

    const handlerClickDeleteEvent = (value: ICalendarPost) => {
        showAlertDialog({
            isSetQuestionText: false,
            message: t('ui_screen_post_delete_post_warning'),
            payload: { post: value },
            onConfirm: deletePost,
        })
    }

    const handlerModalAddEventOpen = (value?: Date) => {
        setDateEvent(value)
        setIsOpenModalAddEvent(true)
    }

    const handlerModalEventsClose = () => {
        setIsOpenModalEvents(false)
    }

    const handlerModalAddEventClose = () => {
        closeModalAddEvent()
    }

    const handlerModalEditEventClose = () => {
        setIsOpenModalEditEvent(false)
    }

    const handlerCompleteAddEvent = () => {
        invalidateCalendarPosts()
        closeModalAddEvent()
    }

    const handlerCompleteEditEvent = (value: ICalendarPost) => {
        invalidateCalendarPosts()
        updateEventDay(value)
        setIsOpenModalEditEvent(false)
    }

    function deletePost({ post }: { post: ICalendarPost }) {
        const { id, ...props } = PostDto.getCalendarPostProps(post)

        if (id) {
            deletePostAction({ id, ...props })
        }
    }

    function setCalendarParams(periodValue: DatePeriod, dateValue: Date, method: 'push' | 'replace' = 'push') {
        history[method](parseTpl(APP_URL.calendarDate, {
            ':period': periodValue,
            ':date': defaultDateFormat(dateValue),
        }, {
            prefix: '',
            suffix: '',
        }))

        setPeriod(periodValue)
        setDate(dateValue)
        setCalendarPostsDateParams(getCalendarPostsDateParams(periodValue, dateValue))
    }

    function closeModalAddEvent() {
        setDateEvent(undefined)
        setIsOpenModalAddEvent(false)
    }

    function updateEventDay(value: ICalendarPost) {
        setDayEvents((prevState) => {
            if (!prevState) {
                return undefined
            }
            const events = prevState.events.map((item) => {
                if (item.id === value.id) {
                    return { ...item, ...value }
                }
                return item
            })

            return { ...prevState, events }
        })
    }

    function removeEventDay(value: ICalendarPost) {
        setDayEvents((prevState) => {
            if (!prevState) {
                return undefined
            }
            const events = prevState.events.filter((item) => item.id !== value.id)

            return { ...prevState, events }
        })
    }

    function deletePostAction(params: TDeleteCalendarPostProps) {
        deleteCalendarPost.mutate({ ...params, isPublished: false }, {
            onSuccess: (res) => {
                if (res) {
                    invalidateCalendarPosts().then(() => removeEventDay(res))
                }
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (!period || !date) {
            setCalendarParams(period || DEFAULT_PERIOD, date || DEFAULT_DATE, 'replace')
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <div className={style.title}>
                    {t('calendar')}
                </div>

                <Block classes={style.block}>
                    <CalendarHeader
                        classes={cn(style.header, { [style.header_bordered]: period === DatePeriod.day })}
                        period={period}
                        date={date}
                        onChangePeriod={handlerChangePeriod}
                        onChangeDate={handlerChangeDate}
                        onAddEvent={handlerAddEvent}
                    />

                    <Transition.Group>
                        {date && period === DatePeriod.day && (
                            <Transition.CSSTransition in classNames="fade-in" timeout={300}>
                                <CalendarDay
                                    date={date}
                                    posts={dataCalendarPosts}
                                    colors={CALENDAR_COLORS_SET}
                                    onClickEvent={handlerClickDayEvents}
                                    onAddEvent={handlerModalAddEventOpen}
                                />
                            </Transition.CSSTransition>
                        )}
                        {date && period === DatePeriod.month && (
                            <Transition.CSSTransition in classNames="fade-in" timeout={300}>
                                <CalendarMonth
                                    date={date}
                                    posts={dataCalendarPosts}
                                    colors={CALENDAR_COLORS_SET}
                                    onClickDay={handlerClickDay}
                                    onClickEvent={handlerClickDayEvents}
                                    onAddEvent={handlerModalAddEventOpen}
                                />
                            </Transition.CSSTransition>
                        )}
                        {date && period === DatePeriod.year && (
                            <Transition.CSSTransition in classNames="fade-in" timeout={300}>
                                <CalendarYear
                                    date={date}
                                    posts={dataCalendarPosts}
                                    onClickDay={handlerClickDayEvents}
                                />
                            </Transition.CSSTransition>
                        )}
                    </Transition.Group>
                </Block>
            </ContentContainer>

            <Modal
                isOpen={isOpenModalEvents}
                classesOverlay={styleOverlay.overlay_medium}
                size="smallCenter"
                onClose={handlerModalEventsClose}
            >
                <Modal.Header isCloseButton titlePos="left">
                    {dayEvents?.date && (
                        <Button
                            styleType="text"
                            onClick={() => {
                                handlerClickDay(dayEvents.date)
                                setIsOpenModalEvents(false)
                            }}
                        >
                            {dateTimeFormat(dayEvents.date)}
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {dayEvents?.events ? (
                        <CalendarDateEventList
                            data={dayEvents.events}
                            colors={CALENDAR_COLORS_SET}
                            onClickEdit={handlerClickEditEvent}
                            onClickDelete={handlerClickDeleteEvent}
                        />
                    ) : (
                        <div>
                            {t('calendar_no_events')}
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                mobileFullScreen
                isOpen={isOpenModalAddEvent}
                classesOverlay={styleOverlay.overlay_medium}
                size="small2"
                onClose={handlerModalAddEventClose}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalAddEventHeader}
                    title={t('ui_posts_create_event_title')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalAddEventBody}>
                    <CalendarPostAction
                        postProps={formDataInitial}
                        date={dateEvent}
                        communities={communities}
                        onComplete={handlerCompleteAddEvent}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                mobileFullScreen
                isOpen={isOpenModalEditEvent}
                classesOverlay={styleOverlay.overlay_medium}
                size="small2"
                onClose={handlerModalEditEventClose}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalEditEventHeader}
                    title={t('calendar_edit_event')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalEditEventBody}>
                    <CalendarPostAction
                        postProps={postProps}
                        date={dateEvent}
                        communities={communities}
                        onComplete={handlerCompleteEditEvent}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Calendar
