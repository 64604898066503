import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ITicketMessageProps } from 'interfaces'
import { APP_URL } from 'config/app'
import { TicketMessageForm } from 'forms'
import { SupportService, SimService } from 'services'
import { showAlertNotify, getFileImageElement } from 'utils/helpers'

export type TicketMessageActionPropType = {
    ticketMessageProps: ITicketMessageProps
    classes?: string
}

export type FormDataType = ITicketMessageProps

const TicketMessageAction: React.FC<TicketMessageActionPropType> = ({
    classes,
    ticketMessageProps,
}) => {
    const isMounted = useRef(false)
    const history = useHistory()
    const { t } = useTranslation()

    const [formData, setFormData] = useState<FormDataType>(ticketMessageProps)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handlerSubmit = (message: string) => {
        addTicketMessageRequest([addTicketMessageAction(message)])
    }

    const handlerChange = (message: string) => {
        setFormData((prevState) => ({ ...prevState, message }))
    }

    const handlerAddAttach = (file: File) => {
        setIsSubmitting(true)
        fileUploadAction(file)
            .then((url) => {
                if (formData.message) {
                    addTicketMessageRequest([addTicketMessageAction(formData.message), addTicketMessageAction(url)])
                } else {
                    addTicketMessageRequest([addTicketMessageAction(url)])
                }
            })
            .catch(() => {})
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    function addTicketMessageRequest(values: Promise<any>[]) {
        const request = Promise.all(values)

        setIsSubmitting(true)

        request
            .then(() => {
                setFormData((prevState) => ({ ...prevState, message: '' }))
                history.push(APP_URL.support)
            })
            .catch((err) => {
                const { error } = err?.response?.data || {}

                showAlertNotify({
                    type: 'error',
                    message: error && typeof error === 'string' ? error : t('update_error'),
                })
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    function addTicketMessageAction(message: string) {
        return SupportService.addTicketMessage({ ...formData, message })
    }

    function fileUploadAction(file: File): Promise<string> {
        return SimService.filesUpload(file)
            .then(({ data }) => {
                const { path } = data || {}

                if (path) {
                    return getFileImageElement(file)
                        .then(({ width, height }) => {
                            return `${path}?sswidth=${width}&ssheight=${height}`
                        })
                        .catch(() => {
                            return path
                        })
                }

                return ''
            })
            .catch(() => {
                return Promise.reject()
            })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    })

    return (
        <TicketMessageForm
            classes={classes}
            data={{ message: formData.message }}
            isSubmitting={isSubmitting}
            onChange={handlerChange}
            onAddAttach={handlerAddAttach}
            onSubmit={handlerSubmit}
        />
    )
}

export default TicketMessageAction
