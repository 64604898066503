import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'layout'
import { SvgResource } from 'components'
import style from './Footer.module.css'

export const Footer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <footer className={style.footer}>
            <Container>
                <div className={style.copyright}>
                    <div className={style.copyrightText}>
                        {t('powered_by_sessia')}
                    </div>
                    <SvgResource
                        isImgTag={false}
                        classes={style.copyrightIcon}
                        resourceKey="logo_sessia_svg"
                        width={48}
                        height={62}
                    />
                </div>
            </Container>
        </footer>
    )
}

export default Footer
