import React, { useMemo } from 'react'
import cn from 'classnames'

import { Avatar } from '../index'
import style from './UsersAvatarGroup.module.css'

export type UsersAvatarGroupUserType = {
    id: number | string
    photo?: string
    name?: string
    surname?: string
}

export type UsersAvatarGroupPropType = {
    users: UsersAvatarGroupUserType[]
    desc?: string
    size?: number
    containerClasses?: string
    descClasses?: string
}

/**
 * @deprecated
 * use AvatarStack
 */
const UsersAvatarGroup: React.FC<UsersAvatarGroupPropType> = ({
    users = [],
    desc,
    size = 48,
    containerClasses,
    descClasses,
}) => {
    const BORDERS_SIZE = 8

    const avatarMarginLeft = useMemo(() => -((size + BORDERS_SIZE) / 2), [])

    return (
        <>
            {users.length > 0 && (
                <div className={cn(style.container, containerClasses)}>
                    <div className={style.avatars}>
                        {users.map((user, index) => (
                            <Avatar
                                classes={style.image}
                                styles={{ marginLeft: index > 0 ? avatarMarginLeft : 0 }}
                                src={user.photo}
                                name={user.name}
                                surname={user.surname}
                                width={size + BORDERS_SIZE}
                                height={size + BORDERS_SIZE}
                                key={user.id}
                            />
                        ))}
                    </div>
                    {desc && (
                        <div className={cn(style.text, descClasses)}>
                            {desc}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default UsersAvatarGroup
