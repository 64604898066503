import React from 'react'

import { SvgResource } from 'components'
import { vTopNavHeight } from 'styles/modules/variables.module.css'
import style from './Logo.module.css'

type LogoPropType = {
    classes?: string
}

const LOGO_HEIGHT = Number.parseInt(vTopNavHeight, 10)

const Logo: React.FC<LogoPropType> = ({ classes }) => {
    return (
        <SvgResource
            isImgTag={false}
            classes={style.logoImage}
            resourceKey="kickback_violet_logo_svg"
            height={LOGO_HEIGHT}
        />
    )
}

export default Logo
