import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'

import { BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, Block } from 'layout'
import * as userSelectors from 'containers/User/user-selectors'
import { useWindowResize } from 'hooks'
import { MessageForm } from 'forms'
import {
    MessengerContainer,
    MessengerBody,
    MessengerFooter,
    MessengerMenu,
    MessengerSearch,
    MessengerSearchList,
    MessengerFriends,
    MessengerItemUser,
} from '../components'
import style from './MessengerChatNew.module.css'

const MessengerChatNew: React.FC = () => {
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)

    const [searchText, setSearchText] = useState('')

    const handlerSearch = (value: string) => {
        setSearchText(value)
    }

    return (
        <>
            {windowWidth >= BREAKPOINTS.desktop && (
                <ContentContainer size="half">
                    <MessengerContainer>
                        <MessengerBody />
                        <MessengerFooter>
                            <Form
                                onSubmit={() => {}}
                                render={() => (
                                    <MessageForm
                                        isDisabled
                                        isFocus={false}
                                        onSubmit={() => {}}
                                    />
                                )}
                            />
                        </MessengerFooter>
                    </MessengerContainer>
                </ContentContainer>
            )}

            <SideBarContainer classes={style.sidebar} position="left">
                <Block classes={style.blockSideBar}>
                    <MessengerSearch
                        isCompact
                        classes={style.searchSideBar}
                        onSearch={handlerSearch}
                    >
                        <MessengerMenu isSetMenuAddChat={false} />
                    </MessengerSearch>
                    {!!searchText && (
                        <MessengerSearchList
                            classesTitle={style.searchListTitle}
                            classesItem={style.searchListItem}
                            user={user}
                            searchText={searchText}
                        />
                    )}
                    {!searchText && (
                        <MessengerFriends
                            render={(data) => (
                                <MessengerItemUser
                                    isTime
                                    isHover
                                    isChatUrl
                                    data={data}
                                    key={data.id}
                                />
                            )}
                        />
                    )}
                </Block>
            </SideBarContainer>
        </>
    )
}

export default MessengerChatNew
