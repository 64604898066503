import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'

type MenuStaticPropType = {
    isPublic?: boolean
    classesNav?: string
    classesItem?: string
    classesLink?: string
}

const MenuStatic: React.FC<MenuStaticPropType> = ({
    isPublic = true,
    classesNav,
    classesItem,
    classesLink,
}) => {
    const { t, i18n } = useTranslation()

    const list = useMemo(() => {
        return [{
            url: t('privacy_policy_link'),
            title: t('privacy_policy_title'),
        }, {
            url: t('user_agreement_link'),
            title: t('user_agreement_title'),
        }]
    }, [i18n.language])

    return (
        <ul className={classesNav}>
            {list.map(({ url, title }) => (
                <li className={classesItem} key={title}>
                    <a
                        className={classesLink}
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {title}
                    </a>
                </li>
            ))}
            {isPublic && (
                <li className={classesItem}>
                    <NavLink className={classesLink} to={APP_URL.support}>
                        {t('support')}
                    </NavLink>
                </li>
            )}
        </ul>
    )
}

export default MenuStatic
