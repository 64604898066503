import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentContainer, Block } from 'layout'
import { PageTitle } from 'components'
import { UserAction } from 'form-actions'
import { scrollTop } from 'utils/helpers'
import style from './UserSettings.module.css'

const UserSettings: React.FC = () => {
    const { t } = useTranslation()

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer classes={style.content} size="half">
            <PageTitle title={t('edit_profile')} />

            <Block classes={style.block}>
                <UserAction />
            </Block>
        </ContentContainer>
    )
}

export default UserSettings
