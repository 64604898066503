import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Input } from 'components'
import style from './CustomSelectSearch.module.css'

export type CustomSelectSearchPropType = {
    search?: string
    classes?: string
    placeholder?: string
    onChange: (value: string) => void
}

const CustomSelectSearch: React.FC<CustomSelectSearchPropType> = ({
    search = '',
    classes,
    placeholder,
    onChange,
}) => {
    const { t } = useTranslation()

    const handlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
    }

    return (
        <div className={cn(style.search, classes)}>
            <Input
                placeholder={placeholder || t('search')}
                value={search}
                onChange={handlerSearch}
            />
        </div>
    )
}

export default CustomSelectSearch
