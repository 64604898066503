import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import { IStoreSettings } from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL } from 'config/app'
import { CardV2, Button, SvgResource } from 'components'
import { parseTpl } from 'utils/helpers'
import styleCommon from 'styles/modules/common.module.css'
import style from './MarketStoreItem.module.css'

type TShopItemProps = {
    data: IStoreSettings
    size?: CardSize
    onSubscribe?: (id: number, isSubscribe: boolean) => void
}

const MarketStoreItem: React.FC<TShopItemProps> = ({ data, size, onSubscribe = () => {} }) => {
    const [isUpdating, setIsUpdating] = useState(false)

    const url = useMemo(() => {
        return parseTpl(APP_URL.store, { ':id': data.id }, { prefix: '', suffix: '' })
    }, [data])

    const handlerClickSubscribe = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsUpdating(true)
        onSubscribe(data.id, !data.is_subscribe)
    }

    useEffect(() => {
        if (isUpdating) {
            setIsUpdating(false)
        }
    }, [data])

    return (
        <CardV2 classes={style.card} size={size} url={url}>
            <CardV2.Image
                src={data.logo_image}
                width={400}
            >
                <Button
                    classes={cn(style.action, {
                        [styleCommon.updating]: isUpdating,
                    })}
                    styleType="text"
                    onClick={handlerClickSubscribe}
                >
                    {data.is_subscribe ? (
                        <SvgResource
                            classes={cn(style.footerItemIcon, style.iconLikeOn)}
                            resourceKey="ic_post_likeon2_svg"
                            width={32}
                            height={32}
                        />
                    ) : (
                        <SvgResource
                            isImgTag={false}
                            classes={cn(style.footerItemIcon, style.iconLikeOff)}
                            resourceKey="ic_post_likeoff2_svg"
                            width={32}
                            height={32}
                        />
                    )}
                </Button>
            </CardV2.Image>
            <CardV2.Body
                classes={style.body}
                title={data.name}
                desc={data.sub_title}
            />
        </CardV2>
    )
}

export default MarketStoreItem
