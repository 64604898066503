import { ThunkAction } from 'redux-thunk'

import { IRootState } from 'interfaces'
import {
    TTicketsProps,
    TTicketsResponse,
    TTicketProps,
    TTicketResponse,
    TAddTicketProps,
    TAddTicketResponse,
} from 'services/SupportService'
import { FETCH_TICKETS, FETCH_TICKET, ADD_TICKET } from 'reducers/types'
import { SupportService } from 'services'
import {
    fetchTicketsActionType,
    fetchTicketActionType,
    addTicketActionType,
} from './support-actions-type'

/**
 * @deprecated
 * use useFetchTickets
 */
export function fetchTickets(params: TTicketsProps)
    : ThunkAction<Promise<TTicketsResponse>, IRootState, unknown, fetchTicketsActionType> {
    return (dispatch) => {
        return SupportService.fetchTickets(params)
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_TICKETS, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

/**
 * @deprecated
 * use useFetchTicket
 */
export function fetchTicket(params: TTicketProps)
    : ThunkAction<Promise<TTicketResponse>, IRootState, unknown, fetchTicketActionType> {
    return (dispatch) => {
        return SupportService.fetchTicket(params)
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_TICKET, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

/**
 * @deprecated
 * use useMutationTicket
 */
export function addTicket(params: TAddTicketProps)
    : ThunkAction<Promise<TAddTicketResponse>, IRootState, unknown, addTicketActionType> {
    return (dispatch) => {
        return SupportService.addTicket(params)
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: ADD_TICKET, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}
