import React from 'react'

import { IReport } from 'interfaces'
import { API_HOST } from 'config/api'
import { Image } from 'components'

type ItemPictureStringPropType = {
    classes?: string
    data: IReport
}

const ItemPictureString: React.FC<ItemPictureStringPropType> = ({ classes, data }) => {
    const {
        picture,
        backgroundColor,
        width,
        height,
    } = data

    return (
        <Image
            classesWrap={classes}
            src={`${API_HOST}${picture}`}
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            resize={{ width: 700, quality: 90 }}
        />
    )
}

export default ItemPictureString
