import { IObjectLiteral } from '../interfaces'

export type formFieldType = {
    name: string
    value: string | number | boolean | IObjectLiteral
    type?: string
    checked?: boolean
}

export type setDataFieldType = formFieldType

export type formErrorsType = { [key: string]: boolean | string }

/**
 * FIXME
 * Работа с данными формы
 * @deprecated
 */
export class Form {
    /**
     * Сохранение в state изменения полей формы
     * @param {setDataFieldType} field
     */
    static setData(field: setDataFieldType) {
        return (state: any) => {
            const {
                name,
                type,
                value,
                checked,
            } = field

            switch (type) {
                case 'checkbox':
                    return { ...state, [name]: checked }
                default:
                    return { ...state, [name]: value }
            }
        }
    }

    /**
     * Проверка отсутсвия ошибок валидации полей формы
     * @param {formErrorsType} errors
     * @return {boolean}
     */
    static isSuccess(errors: formErrorsType) {
        return !Object.keys(errors).length
    }

    /**
     * Сброс ошибок полей в state ошибок
     * @param {setDataFieldType} fields
     * @return {formErrorsType}
     */
    static resetErrors(fields: IObjectLiteral) {
        return (state: formErrorsType) => {
            return Object.keys(fields).reduce((errors, key) => {
                return errors[key] ? { ...errors, [key]: false } : errors
            }, state)
        }
    }
}
