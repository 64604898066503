import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dataLabels from 'chartjs-plugin-datalabels'
import cn from 'classnames'

import { IMlmRank } from 'interfaces'
import { ChartConfiguration, ChartClickEvent } from 'components/Chart/Chart'
import { AppScheme } from 'enums'
import { CLIENT_STORAGE_REPORTS_MEMBERS_KEY } from 'config/app'
import { useStorageCount } from 'containers/App/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { Chart, Badge, Button } from 'components'
import { numberFormat, sumFormat } from 'utils/helpers'
import { vBlue9, vRed4, vGray14 } from 'styles/modules/variables.module.css'
import style from './ReportsMlmMembers.module.css'

type ReportsMlmMembersPropType = {
    type: keyof typeof AppScheme
    data: IMlmRank[]
    options?: Partial<ChartConfiguration>
    onClickMembers?: (data: IMlmRank) => void
    onClickAllMembers?: () => void
}

const ReportsMlmMembers: React.FC<ReportsMlmMembersPropType> = ({
    type,
    data,
    options = {},
    onClickMembers,
    onClickAllMembers,
}) => {
    const defaults = {
        color: type === 'pv' ? vBlue9 : vRed4,
        barThickness: 25,
    }

    const { t } = useTranslation()

    const user = useSelector(userSelectors.user)

    const { getCount, saveCount } = useStorageCount()

    const [count, setCount] = useState(0)

    const items = useMemo(() => data.filter((item) => !!item.count), [data])
    const labels = useMemo(() => items.map((item) => item.name), [items])
    const values = useMemo(() => items.map((item) => item.count), [items])
    const backgrounds = useMemo(() => items.map((item) => item.color), [items])
    const canvasHeight = useMemo(() => {
        return items.length > 1
            ? items.length * (defaults.barThickness + 8) + 30
            : items.length * (defaults.barThickness + 8) + 40
    }, [items]) // fix chart canvas height
    const totalValue = useMemo(() => values.reduce((acc, value) => acc + value, 0), [values])
    const totalValueFormatted = useMemo(() => numberFormat(totalValue), [totalValue])

    const chartConfig: ChartConfiguration = useMemo(() => {
        return {
            type: 'bar',
            data: {
                labels,
                datasets: [{
                    backgroundColor: backgrounds,
                    hoverBackgroundColor: backgrounds,
                    borderWidth: 0,
                    borderRadius: 6,
                    data: values.map((item) => Number((item ** 0.2).toFixed(2))),
                }],
            },
            options: {
                layout: {
                    padding: {
                        right: 20,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                barThickness: defaults.barThickness,
                maxBarThickness: defaults.barThickness,
                aspectRatio: options?.options?.aspectRatio, // TODO merge options
                scales: {
                    x: {
                        grid: {
                            borderDash: [4, 3],
                        },
                        ticks: {
                            callback(value, index, ticks) {
                                if (typeof value === 'number') {
                                    return index === 0 || index + 1 === ticks.length
                                        ? sumFormat(value ** (1 / 0.2))
                                        : ''
                                }
                                return undefined
                            },
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            crossAlign: 'far',
                            padding: 0,
                            font: {
                                size: 10,
                            },
                            color: '#000',
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        offset: 0,
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                        formatter(value, ctx) {
                            return sumFormat(values[ctx.dataIndex])
                        },
                        listeners: {
                            click(ctx, { x, y }) {
                                const isClickByDatalabel = !Number.isNaN(x) && !Number.isNaN(y)

                                if (isClickByDatalabel && onClickMembers) {
                                    onClickMembers(items[ctx.dataIndex])
                                }
                            },
                        },
                    },
                },
                onHover(e, elements, chart) {
                    if (e.native?.target) {
                        (e.native.target as HTMLElement).style.cursor = elements.length === 1 ? 'pointer' : 'default'
                    }
                },
            },
            plugins: [dataLabels],
        }
    }, [])

    const handlerClickAllMembers = () => {
        if (onClickAllMembers) {
            onClickAllMembers()
        }
    }

    const handlerClickMembers = ({ index }: ChartClickEvent) => {
        if (onClickMembers) {
            onClickMembers(items[index])
        }
    }

    useEffect(() => {
        saveCount(`${CLIENT_STORAGE_REPORTS_MEMBERS_KEY}.${user.id}`, totalValue)
        setCount(getCount(`${CLIENT_STORAGE_REPORTS_MEMBERS_KEY}.${user.id}`, totalValue))
    }, [totalValue])

    return (
        <>
            <div className={style.title}>
                {t('mlm_privilege_program_member')}
            </div>
            <div className={style.info}>
                <Button
                    classes={cn(
                        style.infoButtonMembers,
                        { [style.infoButtonMembers_disabled]: !totalValue },
                    )}
                    styleType="transparent"
                    onClick={handlerClickAllMembers}
                >
                    <span style={{ color: defaults.color }}>
                        {t('mlm_privilege_program_number_member')}
                        {': '}
                        {totalValueFormatted}
                    </span>
                </Button>
                <Badge
                    size="small"
                    backgroundColor={count ? defaults.color : vGray14}
                >
                    {count}
                </Badge>
            </div>
            {!!values.length && (
                <div className={style.chart}>
                    <Chart
                        config={chartConfig}
                        options={{ height: canvasHeight }}
                        onClick={handlerClickMembers}
                    />
                </div>
            )}
        </>
    )
}

export default ReportsMlmMembers
