import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IDeliveryCost, IStoreCurrency } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
import { useWindowResize } from 'hooks'
import { plural, priceFormat } from 'utils/helpers'

import styleColumns from 'styles/modules/columns.module.css'
import style from './StoreOrderTariffsItem.module.css'

export type StoreOrderTariffsItemPropType = {
    data: IDeliveryCost
    activeId?: number
    currency?: IStoreCurrency
    onSelect: (id: number) => void
}

const StoreOrderTariffsItem: React.FC<StoreOrderTariffsItemPropType> = ({
    data,
    activeId,
    currency,
    onSelect,
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const dayPlural = useMemo(() => {
        const forms = [t('days_1x'), t('days_2x'), t('days_5x')]
        return plural(data.delivery_time_to || data.delivery_time_from, forms)
    }, [data])

    const cost = useMemo(() => priceFormat(data?.cost, currency), [data, currency])

    const handlerSelect = () => {
        onSelect(data.id)
    }

    return (
        <div
            className={cn(
                styleColumns.column,
                style.block,
                {
                    [styleColumns.column_1_2]: windowWidth >= BREAKPOINTS.tablet,
                    [style.block_active]: data.id === activeId,
                },
            )}
            role="button"
            tabIndex={0}
            onKeyDown={handlerSelect}
            onClick={handlerSelect}
        >
            <div className={style.title}>
                {data.delivery_type.name}
            </div>
            {data.comment && (
                <div className={style.comment}>
                    {data.comment}
                </div>
            )}
            <div className={style.info}>
                <div className={style.time}>
                    {(data.delivery_time_from || data.delivery_time_to) && (
                        <>
                            {data.delivery_time_from}
                            {data.delivery_time_to ? ` - ${data.delivery_time_to} ` : ' '}
                            {dayPlural}
                        </>
                    )}
                </div>
                <div className={style.cost}>
                    {cost}
                </div>
            </div>
        </div>
    )
}

export default StoreOrderTariffsItem
