import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EVENT_TYPE_ALERT_NOTIFY } from 'config/app'
import { Button, Modal, ImgResource } from 'components'
import { splitStr } from 'utils/helpers'
import eventBus from 'utils/EventBus'

import styleAlert from 'styles/modules/alert.module.css'
import style from './AlertNotify.module.css'

export type EventNotifyPropType = {
    type?: 'success' | 'error'
    action?: string
    title?: string
    message?: string
    imageKey?: string
}

type EventNotifyMessageType = {
    id: string
    text: string
}

type EventNotifyStateType = {
    action: string
    title: string
    message: EventNotifyMessageType[]
    imageKey?: string
}

const initialEventDataState: EventNotifyStateType = {
    action: '',
    title: '',
    message: [],
}

/**
 * Модальное окно уведомлений
 *
 * @memberOf module:Components
 * @listens EVENT_TYPE_ALERT_NOTIFY
 * @emits {string}
 */
const AlertNotify: React.FC = () => {
    const { t } = useTranslation()

    const [eventData, setEventData] = useState<EventNotifyStateType>(initialEventDataState)
    const [isOpen, setIsOpen] = useState(false)

    const handlerEventAlertNotify = (params: EventNotifyPropType) => {
        const {
            type,
            action = '',
            title = '',
            message = '',
            imageKey,
        } = params

        setEventData({
            action,
            title: title || title === '' ? title : getDefaultTitle(type),
            message: message ? splitStr(message) : [],
            imageKey,
        })
        setIsOpen(true)
    }

    const handlerDone = () => {
        const { action } = eventData

        if (action) {
            eventBus.emit(action)
        }

        setIsOpen(false)
    }

    const handlerClose = () => {
        setIsOpen(false)
    }

    function getDefaultTitle(type?: 'success' | 'error') {
        switch (type) {
            case 'success':
                return t('dialog_success_title')
            case 'error':
                return t('warning')
            default:
                return ''
        }
    }

    useEffect(() => {
        eventBus.on(EVENT_TYPE_ALERT_NOTIFY, handlerEventAlertNotify)

        return () => {
            eventBus.off(EVENT_TYPE_ALERT_NOTIFY, handlerEventAlertNotify)
        }
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            size="small"
            classes={styleAlert.content}
            classesOverlay={style.overlay}
            onClose={handlerClose}
        >
            <Modal.Header title={eventData.title} />
            <Modal.Body>
                <div className={styleAlert.imageWrap}>
                    <ImgResource
                        classes={styleAlert.image}
                        resourceKey={eventData.imageKey ?? 'baba_lucky_png'}
                        isShowLoader={false}
                        width={320}
                        height={292}
                    />
                </div>
                <div className={styleAlert.textBlock}>
                    {eventData.message.map((item) => (
                        <div key={item.id}>
                            {item.text}
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    textUpper
                    classes={styleAlert.control}
                    size="size40"
                    text={t('Ok')}
                    onClick={handlerDone}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default AlertNotify
