import React, { useMemo } from 'react'

import { IHistoryMessage, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Link } from 'components'
import { useFetchChannelMembers } from 'containers/Messenger/hooks'
import { MessengerChannelAvatar, MessengerSearchItem } from 'containers/Messenger/components'

type MessengerSearchChatPropType = {
    classes?: string
    data: IHistoryMessage
    user: IUser
    searchText: string
}

const MessengerSearchItemChannelMessage: React.FC<MessengerSearchChatPropType> = ({
    classes,
    data,
    user,
    searchText,
}) => {
    const { data: dataChannelMembers } = useFetchChannelMembers({
        channelId: data.channelId ?? '',
        withUsersInfo: true,
    }, {
        enabled: !!data.channelId,
    })

    const urlChannel = useMemo(() => {
        return data.channelId ? APP_URL.messengerChannel.replace(':id', data.channelId) : undefined
    }, [data])

    const avatarTpl = (
        <Link url={urlChannel}>
            <MessengerChannelAvatar
                userId={user.id}
                title={data.channel?.title ?? ''}
                image={data.channel?.image}
                members={dataChannelMembers}
            />
        </Link>
    )

    return (
        <MessengerSearchItem
            classes={classes}
            title={data.channel?.title ?? ''}
            avatar={avatarTpl}
            message={data.text || data.forwardedMessage}
            searchText={searchText}
            date={data.createdAt}
            url={urlChannel}
        />
    )
}

export default MessengerSearchItemChannelMessage
