import React, { useMemo } from 'react'

import { IChannel, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Link } from 'components'
import { useFetchChannelMembers, useFetchHistoryChannel } from 'containers/Messenger/hooks'
import { MessengerChannelAvatar, MessengerItemInner } from 'containers/Messenger/components'

type MessengerSearchChatPropType = {
    classes?: string
    data: IChannel
    user: IUser
}

const MessengerSearchItemChannel: React.FC<MessengerSearchChatPropType> = ({ classes, data, user }) => {
    const { data: dataChannelMembers } = useFetchChannelMembers({
        channelId: data.id,
        withUsersInfo: true,
    })

    const { data: dataHistoryChannel } = useFetchHistoryChannel({
        channelId: data.id,
        withUsersInfo: true,
    })

    const urlChannel = useMemo(() => {
        return APP_URL.messengerChannel.replace(':id', data.id)
    }, [data])

    const lastMessage = useMemo(() => {
        return dataHistoryChannel?.length ? (dataHistoryChannel[0].text || dataHistoryChannel[0].forwardedMessage) : ''
    }, [dataHistoryChannel])

    const senderMessage = useMemo(() => {
        const [message] = dataHistoryChannel || []

        if (message?.senderUser) {
            return `${message.senderUser.name ?? ''} ${message.senderUser.surname ?? ''}`
        }

        return ''
    }, [dataHistoryChannel])

    const avatarTpl = (
        <Link url={urlChannel}>
            <MessengerChannelAvatar
                userId={user.id}
                title={data.title}
                image={data.image}
                members={dataChannelMembers}
            />
        </Link>
    )

    return (
        <MessengerItemInner
            isSimpleMessage
            classes={classes}
            title={data.title}
            avatar={avatarTpl}
            message={lastMessage}
            senderName={senderMessage}
            date={data.createdAt}
            url={urlChannel}
        />
    )
}

export default MessengerSearchItemChannel
