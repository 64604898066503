import React, { forwardRef } from 'react'
import cn from 'classnames'

import { useImgResource } from 'hooks'
import style from './MessengerBody.module.css'

type MessengerBodyPropType = {
    children?: React.ReactElement
    classes?: string
}

const MessengerBody = forwardRef<HTMLDivElement, MessengerBodyPropType>(({
    children,
    classes,
}, ref) => {
    const { url: urlBackground } = useImgResource('chat_background_png')

    return (
        <div
            className={cn(style.body, classes)}
            style={{ backgroundImage: urlBackground ? `url(${urlBackground})` : undefined }}
            ref={ref}
        >
            {children}
        </div>
    )
})

export default MessengerBody
