import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IUserAccountFilter } from 'interfaces'
import { TFilterAccountsProps } from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'

export type TFetchFnParams = TFilterAccountsProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IUserAccountFilter[], TError, IUserAccountFilter[], TQueryKey>

const key = QueryCacheKeys.filterAccounts

/**
 * Хук API получить аккаунты по фильтру
 */
export default function useFetchFilterAccounts(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.filterAccounts(params)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                // TODO get error
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
