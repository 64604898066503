import {
    IUser,
    IUserAccount,
    IUserAccountSale,
    IUserAccountFilter,
    IUserTag,
    IUserUpdateProps,
    IFriend,
    IFriends,
    IFriendsRequest,
    IMutualFriends,
    IAuthData,
    IUserRegData,
    IUserData,
    IGuarantor,
    IProfile,
    IProfileProps,
    IStoreFriends,
    IPhoneData,
    IChartData,
    ICounts,
    IRating,
    IRatingSum,
    IReportMlm,
    IMlmRankUser,
    IReportMlmRank,
    IMlmBusinessPack,
    IMlmRank,
    IMlmRankUserExtended,
    IMlmReportsGraph,
    IMlmIncome,
    IMlmIncomeType,
    IMlmProduct,
    IMlmPrivilegeData,
    IMlmPrivilegeSum,
    IMlmPrivilegeHistory,
    ISubscription,
} from 'interfaces'
import { AppScheme, ChartPeriod, FriendFilterSortBy } from 'enums'
import { API_URL } from 'config/api'
import {
    COMPANY_ACCOUNT_ID_5,
    CLIENT_STORAGE_AUTH_PHONE_KEY,
    CLIENT_STORAGE_AUTH_DATA_KEY,
    CLIENT_STORAGE_REG_DATA_KEY,
    CLIENT_STORAGE_USER_SESSION_KEY,
} from 'config/app'
import { AuthService, StorageService } from 'services'
import requestClient from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TUserServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type fetchFriendsStorePropType = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TUserProfileProps = IProfileProps

export type TUserTagsProps = {
    accountId: number
}

export type TFriendsStoreProps = {
    storeId: number
    limit?: number
    offset?: number
    with_count?: boolean
}

export type TFriendsSearchProps = {
    limit?: number
    offset?: number
    name?: string
    memberId?: string
    age_from?: number
    age_to?: number
    country?: number
    city?: number
    gender?: 1 | 0
    is_friends_only?: 1 | 0
    is_with_photo_only?: 1 | 0
    sort_by?: keyof typeof FriendFilterSortBy
    q?: string // по фамилии, имени, member_ID и телефону
}

export type TFriendsChatSearchProps = {
    name?: string
    age_from?: number
    age_to?: number
    country?: number
    city?: number
    gender?: 0 | 1
    memberId?: string
    is_friends_only?: 0 | 1
    is_with_photo_only?: 0 | 1
    sort_by?: string
    q?: string
    limit?: number
    offset?: number
}

export type TAppLogoutProps = {
    isRemoveAuthData?: boolean
}

export type TFilterAccountsProps = {
    filterId: number
    reportId: string
    page: number
}

export type TFilterAccountsForSalesProps = {
    limit?: number
    offset?: number
    q: string
}

export type TCounterHistoryProps = {
    counter?: number
    report?: string
    account?: number
    period?: keyof typeof ChartPeriod
    delta?: boolean
    group?: 'month' | 'week' | 'day'
    dateFrom?: string
    dateTo?: string
}

export type TRemoveAuthDataProps = {
    countryId: number
    phone: string
} | {
    key: string
    value: any
}

export type TDeleteUserProps = {
    dryRun?: true // for test only
}

export type TCountProps = {
    counter: keyof ICounts
}

export type TDeleteCountsProps = {
    counter?: string
}

export type TUsersDataProps = {
    users: string // ids
    is_checks?: 0 | 1
    is_subs_community?: 0 | 1
    is_mutual_friends?: 0 | 1
    all?: 0 | 1
    limit_friends?: number
}

export type TMlmReportsProps = {
    type?: keyof typeof AppScheme
}

export type TMlmReportsUsersProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    countryId?: number
    cityId?: number
    line?: number
    limit?: number
    offset?: number
}

export type TMlmReportsRanksCurrentProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    countryId?: number
    cityId?: number
    line?: string
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsRanksCurrentUsersProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    countryId?: number
    cityId?: number
    line?: string
    dateFrom?: string
    dateTo?: string
    limit?: number
    offset?: number
}

export type TMlmReportsRanksMaxProps = {
    type?: keyof typeof AppScheme
    userId?: number
    line?: number
}

export type TMlmReportsRanksMaxUsersProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    dateFrom?: string
    dateTo?: string
    limit?: number
    offset?: number
}

export type TMlmReportsRanksNewProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    cityId?: number
    countryId?: number
    line?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsRanksNewUsersProps = {
    type?: keyof typeof AppScheme
    userId?: number
    rankId?: number
    dateFrom?: string
    dateTo?: string
    limit?: number
    offset?: number
}

export type TMlmReportsClientsGraphProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsActivePartnersGraphProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsLoginGraphProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsIncomeGraphProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsTurnoverGraphProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmReportsIncomeListProps = {
    type?: keyof typeof AppScheme
    typeId?: number
    dateFrom?: string
    dateTo?: string
    limit?: number
    offset?: number
}

export type TMlmTurnoverProductsProps = {
    type?: keyof typeof AppScheme
    userId?: number
    dateFrom?: string
    dateTo?: string
    limit?: number
    offset?: number
}

export type fetchMlmRankUsersPropType = {
    type?: keyof typeof AppScheme
    rankId?: number
    showAll?: boolean
    limit?: number
    offset?: number
}

export type fetchMlmPrivilegeHistoryPropType = {
    type: number
    limit?: number
    offset?: number
    dateFrom?: string
    dateTo?: string
}

export type TMlmRankStatsProps = {
    userId?: number
    showAll?: boolean
}

export type TProfileResponse = IProfile

export type TFriendsStoreResponse = IStoreFriends

export type TFriendsSearchResponse = {
    count: number
    results: IFriend[]
}

export type TFriendsChatSearchResponse = {
    count: number
    results: any[] // FIXME
}

export type TCounterHistoryResponse = IChartData

export type fetchCountResponseType = string[]
    | { [key: string]: string[] }
    | { [key: string]: { [key: string]: string[] } }

export type TDeleteCountsResponse = '' | any // TODO counter ? data='' : ...

export type TRatingListResponse = IRating[]

export type TRatingTypeSumResponse = IRatingSum[]

export type TUsersDataResponse = IUserData[]

export type TLogoutResponse = {
    success: boolean
}

export type fetchMlmRankUsersResponseType = {
    count: number
    data: IMlmRankUserExtended[]
}

export type fetchMlmReportsUsersResponseType = {
    count: number
    data: IMlmRankUser[]
}

export type fetchMlmReportsRanksCurrentResponseType = IReportMlmRank[]

export type fetchMlmReportsRanksCurrentUsersResponseType = {
    count: number
    data: IMlmRankUser[]
}

export type fetchMlmReportsRanksMaxResponseType = IReportMlmRank[]

export type TMlmReportsRanksMaxUsersResponse = {
    count: number
    data: IMlmRankUser[]
}

export type fetchMlmReportsRanksNewResponseType = IReportMlmRank[]

export type TMlmReportsRanksNewUsersResponse = {
    count: number
    data: IMlmRankUser[]
}

export type fetchMlmReportsClientsGraphResponseType = IMlmReportsGraph

export type fetchMlmReportsActivePartnersGraphResponseType = IMlmReportsGraph

export type fetchMlmReportsLoginGraphResponseType = IMlmReportsGraph

export type fetchMlmReportsIncomeGraphResponseType = IMlmReportsGraph

export type fetchMlmReportsTurnoverGraphResponseType = IMlmReportsGraph

export type fetchMlmIncomeListResponseType = {
    count: number
    types: IMlmIncomeType[]
    data: IMlmIncome[]
}

export type fetchMlmTurnoverProductsResponseType = {
    title: string
    count: number
    params: Omit<TMlmTurnoverProductsProps, 'type'>
    data: IMlmProduct[]
}

export type TMlmPvBusinessPackResponse = IMlmBusinessPack

export type TProfileFriendsProps = {
    userId: number
    limit?: number
    offset?: number
    q?: string
}

export type TFriendsProps = {
    limit?: number
    offset?: number
    q?: string
}

export type TDeleteFriendsProps = {
    userId: number
}

export type TFriendsRequestProps = {
    limit?: number
    offset?: number
}

export type TFriendsRequestRejectProps = {
    userId: number
}

export type TFriendsRequestConfirmProps = {
    userId?: number
}

export type TMutualFriendsProps = {
    users: string // ids
    limit?: number
    offset?: number
    short_view?: 0 | 1
}

export type TAddFriendsRequestProps = {
    id: number
    member_id: string
    phone?: string // +79101234567
}

export type TCancelFriendsRequestProps = {
    userId: number
}

export type TConfirmFriendsRequestProps = {
    userId?: number
}

export type TRejectFriendsRequestProps = {
    userId: number
}

export type TSubscriptionResponse = ISubscription

export type TProfileFriendsResponse = IFriends

export type TFriendsResponse = IFriends

export type TDeleteFriendsResponse = string // Friend deleted

export type TFriendsRequestResponse = IFriendsRequest

export type TAddFriendsRequestResponse = string // ... добавлен(а) в друзья

export type TCancelFriendsRequestResponse = string

export type TConfirmFriendsRequestResponse = string

export type TRejectFriendsRequestResponse = string

/**
 * API user
 */
class UserService {
    static isSetAccounts(user: IUser): boolean {
        return !!user?.accounts?.length
    }

    /**
     * Получить MLM user account
     */
    static getCompanyAccountId5(user: IUser): IUserAccount | undefined {
        return user?.accounts.find((item) => item.company_id === COMPANY_ACCOUNT_ID_5)
    }

    /**
     * Сохранить телефон пользователя при авторизации
     */
    static savePhoneData(data: IPhoneData): void {
        StorageService.setItem(CLIENT_STORAGE_AUTH_PHONE_KEY, data)
    }

    /**
     * Получить сохроненный телефон пользователя предыдущей авторизации
     */
    static getPhoneData(): null | IPhoneData {
        return StorageService.getItem<IPhoneData>(CLIENT_STORAGE_AUTH_PHONE_KEY)
    }

    /**
     * Сохранить данные пользователя при регистрации
     */
    static saveRegData(data: IUserRegData): void {
        StorageService.setItem(CLIENT_STORAGE_REG_DATA_KEY, data)
    }

    /**
     * Получить регистрационные данные пользователя
     */
    static getRegData(): null | IUserRegData {
        return StorageService.getItem(CLIENT_STORAGE_REG_DATA_KEY)
    }

    /**
     * Удалить регистрационные данные пользователя
     */
    static clearRegData(): void {
        StorageService.removeItem(CLIENT_STORAGE_REG_DATA_KEY)
    }

    /**
     * Сохранить данные авторизации пользователя
     */
    static saveAuthData(data: IAuthData): void {
        const prevData = UserService.getAuthData() || []
        const newData = prevData.filter((item) => !(item.countryId === data.countryId && item.phone === data.phone))

        StorageService.setItem(CLIENT_STORAGE_AUTH_DATA_KEY, [...newData, data])
    }

    /**
     * Получить данные авторизации пользователя
     */
    static getAuthData(): null | IAuthData[] {
        return StorageService.getItem(CLIENT_STORAGE_AUTH_DATA_KEY)
    }

    /**
     * Удалить данные авторизации пользователя
     */
    static removeAuthData(params: TRemoveAuthDataProps): void {
        const prevData = UserService.getAuthData() || []
        const newData = prevData.filter((item) => {
            if ('key' in params) {
                return item[params.key] !== params.value
            }
            return !(item.countryId === params.countryId && item.phone === params.phone)
        })

        StorageService.setItem(CLIENT_STORAGE_AUTH_DATA_KEY, newData)
    }

    /**
     * Сохранить sessia cookie token
     */
    static saveSessionData(data: string): void {
        StorageService.setItem(CLIENT_STORAGE_USER_SESSION_KEY, data)
    }

    /**
     * Получить данные сессии пользователя
     */
    static getSessionData(): null | string {
        return StorageService.getItem(CLIENT_STORAGE_USER_SESSION_KEY)
    }

    /**
     * Удалить данные сенссии пользователя
     */
    static clearSessionData(): void {
        StorageService.removeItem(CLIENT_STORAGE_USER_SESSION_KEY)
    }

    /**
     * Получить данные пользователя
     */
    static fetchUsers() {
        return requestClient<IUser>(API_URL.users)
    }

    /**
     * Обновить данные пользователя
     */
    static updateUser(params: IUserUpdateProps) {
        return requestClient<IUser>(API_URL.users, { method: 'put', data: { user: params } })
    }

    /**
     * Удалить аккаунт пользователя
     */
    static deleteUser(params?: TDeleteUserProps) {
        return requestClient(API_URL.userDelete, { method: 'delete', params })
    }

    /**
     * Получить профиль пользователя
     */
    static fetchProfile({ userId, account_id }: TUserProfileProps) {
        const url = parseTpl(API_URL.profile, { userId })
        return requestClient<TProfileResponse>(url, { params: { account_id } })
    }

    /**
     * Получить теги пользователя
     */
    static fetchUserTags({ accountId }: TUserTagsProps) {
        const url = parseTpl(API_URL.tags, { accountId })
        return requestClient<IUserTag[]>(url)
    }

    /**
     * Получить друзей пользователя для данного магазина
     */
    static fetchFriendsStore({
        storeId,
        limit = 10,
        offset = 0,
        with_count = false,
    }: TFriendsStoreProps) {
        const url = parseTpl(API_URL.friendsStore, { id: storeId })
        return requestClient<TFriendsStoreResponse>(url, { params: { limit, offset, with_count } })
    }

    static searchFriends(params: TFriendsSearchProps) {
        return requestClient<TFriendsSearchResponse>(API_URL.friendsSearch, { method: 'post', data: params })
    }

    static searchFriendsChat({ limit, offset, ...data }: TFriendsChatSearchProps) {
        return requestClient<TFriendsChatSearchResponse>(API_URL.friendsChatSearch, { method: 'post', data, params: { limit, offset } })
    }

    static getGuarantor() {
        return requestClient(API_URL.guarantor)
    }

    static setGuarantor(params: IGuarantor) {
        return requestClient<{ status: string }>(API_URL.guarantor, { method: 'put', data: params })
    }

    /**
     * Разлогинивание пользователя в приложении
     */
    static appLogout({ isRemoveAuthData }: TAppLogoutProps = {}): Promise<void> {
        return new Promise((resolve) => {
            if (isRemoveAuthData) {
                const { access_token: accessToken } = AuthService.getAuthData() || {}
                UserService.removeAuthData({ key: 'access_token', value: accessToken })
            }

            UserService.clearSessionData()
            AuthService.clearAuthData()

            return resolve()
        })
    }

    /**
     * Разлогинивание пользователя в приложении и на сервере
     */
    static fullLogout() {
        return requestClient<TLogoutResponse>(API_URL.logout)
            .then(({ data }) => {
                if (data.success) {
                    const { access_token: accessToken } = AuthService.getAuthData() || {}

                    UserService.removeAuthData({ key: 'access_token', value: accessToken })
                    UserService.clearSessionData()
                    AuthService.clearAuthData()

                    return Promise.resolve(data)
                }

                return Promise.reject()
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    /**
     * Разлогинивание пользователя на всех устройствах
     */
    static allLogout() {
        return requestClient<TLogoutResponse>(API_URL.allLogout)
            .then(({ data }) => {
                if (data.success) {
                    const { access_token: accessToken } = AuthService.getAuthData() || {}

                    UserService.removeAuthData({ key: 'access_token', value: accessToken })
                    UserService.clearSessionData()
                    AuthService.clearAuthData()

                    return Promise.resolve(data)
                }

                return Promise.reject()
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    /**
     * Смена текущего акаунта пользователя
     */
    static changeAccount(id: number) {
        const url = parseTpl(API_URL.changeAccount, { id })
        return requestClient<[]>(url, { method: 'post' })
    }

    static filterAccounts({ filterId, reportId, page = 0 }: TFilterAccountsProps) {
        return requestClient<IUserAccountFilter[]>(`${API_URL.filterAccounts}/${filterId}/${page}`, {
            params: { reportId },
        })
    }

    /**
     * Поиск аккаунтов пользователя
     */
    static filterAccountsForSales({ limit = 0, offset = 10, q = '' }: TFilterAccountsForSalesProps) {
        return requestClient<IUserAccountSale[]>(API_URL.filterAccountsForSales, { params: { limit, offset, q } })
    }

    static fetchCounterHistory(params: TCounterHistoryProps) {
        return requestClient<TCounterHistoryResponse>(API_URL.counterHistory, { params })
    }

    /**
     * User event counts
     */
    static fetchCounts() {
        return requestClient<ICounts>(API_URL.counts)
    }

    static fetchCount({ counter }: TCountProps) {
        return requestClient<fetchCountResponseType>(`${API_URL.counts}${counter}`)
    }

    static deleteCounts({ counter }: TDeleteCountsProps) {
        const url = counter ? `${API_URL.countsDelete}/${counter}` : API_URL.countsDelete
        return requestClient<TDeleteCountsResponse>(url, { method: 'delete' })
    }

    static fetchRatingList() {
        return requestClient<TRatingListResponse>(API_URL.ratingList)
    }

    static fetchRatingTypeSum() {
        return requestClient<TRatingTypeSumResponse>(API_URL.ratingTypeSum)
    }

    static fetchUsersData(params: TUsersDataProps) {
        return requestClient<TUsersDataResponse>(API_URL.userHodgepodge, { params })
    }

    static fetchMlmReports({ type = AppScheme.pv }: TMlmReportsProps = {}) {
        return requestClient<IReportMlm>(`${API_URL.userMlmReports}/${type}/`)
    }

    static fetchMlmReportsUsers({ type = AppScheme.pv, ...params }: TMlmReportsUsersProps = {}) {
        const url = parseTpl(API_URL.userMlmReportsUsers, { scheme: type })
        return requestClient<fetchMlmReportsUsersResponseType>(url, { params })
    }

    static fetchMlmReportsRanksCurrent({ type = AppScheme.pv, ...params }: TMlmReportsRanksCurrentProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksCurrent, { scheme: type })
        return requestClient<fetchMlmReportsRanksCurrentResponseType>(url, { params })
    }

    static fetchMlmReportsRanksCurrentUsers({ type = AppScheme.pv, ...params }: TMlmReportsRanksCurrentUsersProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksCurrentUsers, { scheme: type })
        return requestClient<fetchMlmReportsRanksCurrentUsersResponseType>(url, { params })
    }

    static fetchMlmReportsRanksMax({ type = AppScheme.pv, ...params }: TMlmReportsRanksMaxProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksMax, { scheme: type })
        return requestClient<fetchMlmReportsRanksMaxResponseType>(url, { params })
    }

    static fetchMlmReportsRanksMaxUsers({ type = AppScheme.pv, ...params }: TMlmReportsRanksMaxUsersProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksMaxUsers, { scheme: type })
        return requestClient<TMlmReportsRanksMaxUsersResponse>(url, { params })
    }

    static fetchMlmReportsRanksNew({ type = AppScheme.pv, ...params }: TMlmReportsRanksNewProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksNew, { scheme: type })
        return requestClient<fetchMlmReportsRanksNewResponseType>(url, { params })
    }

    static fetchMlmReportsRanksNewUsers({ type = AppScheme.pv, ...params }: TMlmReportsRanksNewUsersProps) {
        const url = parseTpl(API_URL.userMlmReportsRanksNewUsers, { scheme: type })
        return requestClient<TMlmReportsRanksNewUsersResponse>(url, { params })
    }

    static fetchMlmReportsClientsGraph({ type = AppScheme.pv, ...params }: TMlmReportsClientsGraphProps) {
        const url = parseTpl(API_URL.userMlmReportsClientsGraph, { scheme: type })
        return requestClient<fetchMlmReportsClientsGraphResponseType>(url, { params })
    }

    static fetchMlmReportsActivePartnersGraph({ type = AppScheme.pv, ...params }: TMlmReportsActivePartnersGraphProps) {
        const url = parseTpl(API_URL.userMlmReportsActivePartnersGraph, { scheme: type })
        return requestClient<fetchMlmReportsActivePartnersGraphResponseType>(url, { params })
    }

    static fetchMlmReportsLoginGraph({ type = AppScheme.pv, ...params }: TMlmReportsLoginGraphProps) {
        const url = parseTpl(API_URL.userMlmReportsLoginGraph, { scheme: type })
        return requestClient<fetchMlmReportsLoginGraphResponseType>(url, { params })
    }

    static fetchMlmReportsIncomeGraph({ type = AppScheme.pv, ...params }: TMlmReportsIncomeGraphProps) {
        const url = parseTpl(API_URL.userMlmReportsIncomeGraph, { scheme: type })
        return requestClient<fetchMlmReportsIncomeGraphResponseType>(url, { params })
    }

    static fetchMlmReportsTurnoverGraph({ type = AppScheme.pv, ...params }: TMlmReportsTurnoverGraphProps) {
        const url = parseTpl(API_URL.userMlmReportsTurnoverGraph, { scheme: type })
        return requestClient<fetchMlmReportsTurnoverGraphResponseType>(url, { params })
    }

    static fetchMlmReportsIncomeList({ type = AppScheme.pv, ...params }: TMlmReportsIncomeListProps) {
        const url = parseTpl(API_URL.userMlmReportsIncomeList, { scheme: type })
        return requestClient<fetchMlmIncomeListResponseType>(url, { params })
    }

    static fetchMlmTurnoverProducts({ type = AppScheme.pv, ...params }: TMlmTurnoverProductsProps) {
        const url = parseTpl(API_URL.userMlmReportsTurnoverProducts, { scheme: type })
        return requestClient<fetchMlmTurnoverProductsResponseType>(url, { params })
    }

    static fetchMlmPvBusinessPack() {
        return requestClient<TMlmPvBusinessPackResponse>(API_URL.userMlmPvBusinessPack)
    }

    static fetchMlmRankStats(params: TMlmRankStatsProps = {}) {
        return requestClient<IMlmRank[]>(API_URL.userMlmRankStats, { params })
    }

    static fetchMlmRankUsers({ type = AppScheme.pv, ...params }: fetchMlmRankUsersPropType) {
        return requestClient<fetchMlmRankUsersResponseType>(`${API_URL.userMlmRankUsers}/${type}`, { params })
    }

    static fetchMlmPrivilege() {
        return requestClient<IMlmPrivilegeData>(API_URL.userMlmPrivilege)
    }

    static fetchMlmPrivilegeSum() {
        return requestClient<IMlmPrivilegeSum[]>(API_URL.userMlmPrivilegeSum)
    }

    static fetchMlmPrivilegeHistory(params: fetchMlmPrivilegeHistoryPropType) {
        return requestClient<IMlmPrivilegeHistory[]>(API_URL.userMlmPrivilegeHistory, { params })
    }

    static fetchSubscription() {
        return requestClient<TSubscriptionResponse>(API_URL.subscription)
    }

    static fetchProfileFriends({ userId, ...params }: TProfileFriendsProps) {
        const url = parseTpl(API_URL.profileFriends, { userId })
        return requestClient<TProfileFriendsResponse>(url, { params })
    }

    static fetchFriends(params?: TFriendsProps) {
        return requestClient<TFriendsResponse>(API_URL.friends, { params })
    }

    static deleteFriends({ userId }: TDeleteFriendsProps) {
        return requestClient<TDeleteFriendsResponse>(`${API_URL.friendsDelete}/${userId}`, { method: 'delete' })
    }

    static fetchFriendsRequest(params?: TFriendsRequestProps) {
        return requestClient<TFriendsRequestResponse>(API_URL.friendsRequest, { params })
    }

    static addFriendsRequest(params: TAddFriendsRequestProps) {
        return requestClient<TAddFriendsRequestResponse>(API_URL.friendsRequest, { method: 'post', data: params })
    }

    static cancelFriendsRequest({ userId }: TCancelFriendsRequestProps) {
        return requestClient<TCancelFriendsRequestResponse>(`${API_URL.friendsRequest}/${userId}`, { method: 'delete' })
    }

    static confirmFriendsRequest({ userId }: TConfirmFriendsRequestProps = {}) {
        const url = userId ? `${API_URL.friendsRequestConfirm}/${userId}` : API_URL.friendsRequestConfirm
        return requestClient<TConfirmFriendsRequestResponse>(url, { method: 'put' })
    }

    static rejectFriendsRequest({ userId }: TRejectFriendsRequestProps) {
        return requestClient<TRejectFriendsRequestResponse>(`${API_URL.friendsRequestReject}/${userId}`, { method: 'delete' })
    }

    static fetchMutualFriends(params: TMutualFriendsProps) {
        return requestClient<IMutualFriends[]>(API_URL.friendsMutual, { params })
    }
}

export default UserService
