import React, { useMemo, useState } from 'react'

import { IHistoryMessage, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Link } from 'components'
import { MessengerSearchItem } from 'containers/Messenger/components'
import style from './MessengerSearchItemChatMessage.module.css'

type MessengerSearchChatPropType = {
    classes?: string
    data: IHistoryMessage
    user: IUser
    searchText: string
}

const MessengerSearchItemChatMessage: React.FC<MessengerSearchChatPropType> = ({
    classes,
    data,
    user,
    searchText,
}) => {
    const [chatUser] = useState(user.id === data.senderUserId ? data.receiverUser : data.senderUser)

    const urlChatUser = useMemo(() => {
        return chatUser ? APP_URL.profile.replace(':id', String(chatUser.id)) : ''
    }, [chatUser])

    const urlChat = useMemo(() => {
        return chatUser ? APP_URL.messengerChat.replace(':id', String(chatUser.id)) : ''
    }, [chatUser])

    const avatarTpl = (
        <Link className={style.user} url={urlChatUser}>
            <Avatar
                name={chatUser?.name}
                surname={chatUser?.surname}
                src={chatUser?.photoFullUrl}
                width={48}
                height={48}
            />
        </Link>
    )

    return (
        <MessengerSearchItem
            classes={classes}
            title={`${chatUser?.name ?? ''} ${chatUser?.surname ?? ''}`}
            avatar={avatarTpl}
            message={data.text || data.forwardedMessage}
            searchText={searchText}
            date={data.createdAt}
            url={urlChat}
        />
    )
}

export default MessengerSearchItemChatMessage
