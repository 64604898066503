import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IUser, IMessengerChat, IMessengerChannel } from 'interfaces'
import { Spinner } from 'components'
import { useFetchConversationList } from 'containers/Messenger/hooks'
import { MessengerNothing } from 'containers/Messenger/components'

import style from '../MessengerSearchList/MessengerSearchList.module.css'

type MessengerSearchConversationListPropType = {
    classes?: string
    user: IUser
    searchText: string
    render: (data: IMessengerChat | IMessengerChannel) => void
}

const MessengerSearchConversationList: React.FC<MessengerSearchConversationListPropType> = ({
    classes,
    user,
    searchText,
    render,
}) => {
    const { t } = useTranslation()

    const {
        isInitialLoading: isLoadingConversationList,
        data: dataConversationList,
    } = useFetchConversationList({
        userId: user.id,
        searchText,
    }, {
        enabled: !!searchText,
    })

    return (
        <div className={cn(style.list, { [style.list_updating]: isLoadingConversationList }, classes)}>
            {isLoadingConversationList && (
                <Spinner position="top" size="medium" />
            )}

            {!isLoadingConversationList && dataConversationList && !dataConversationList.data.length && (
                <MessengerNothing
                    nothingText={`${t('nothing_found')}\n${t('Please try again')}`}
                />
            )}

            {dataConversationList?.data.map((item) => render(item))}
        </div>
    )
}

export default MessengerSearchConversationList
