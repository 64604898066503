import React from 'react'
import cn from 'classnames'

import BlockHeader, { BlockHeaderPropType } from './BlockHeader/BlockHeader'
import style from './Block.module.css'

type BlockPropType = {
    classes?: string
    styles?: React.CSSProperties
}

type BlockComposition = {
    Header: React.FC<BlockHeaderPropType>
}

const Block: React.FC<BlockPropType> & BlockComposition = ({
    children,
    classes,
    styles,
}) => {
    return (
        <div className={cn(style.block, classes)} style={styles}>
            {children}
        </div>
    )
}

Block.Header = BlockHeader

export default Block
