import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    SetDataOptions,
    Updater,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TMarketServiceError,
    TGoodsProps,
    TGoodsResponse,
    TAddGoodsProps,
    TAddGoodsResponse,
    TUpdateGoodsProps,
    TUpdateGoodsResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TGoodsProps
export type TFetchFnError = AxiosError<TMarketServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TGoodsResponse[0], TError, TGoodsResponse[0], TQueryKey>

export type TAddGoodsMutationFnError = [string, AxiosError<TMarketServiceError, TAddGoodsProps>]
export type TUpdateGoodsMutationFnError = [string, AxiosError<TMarketServiceError, TUpdateGoodsProps>]

const key = QueryCacheKeys.storeGoods

/**
 * Хук API получить товар
 */
export default function useFetchGoods(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchGoods(params)
            .then(({ data }) => {
                return data[0]
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationGoods() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddGoodsResponse,
        TAddGoodsMutationFnError,
        TAddGoodsProps
    >((props) => {
        return MarketService.addGoods(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const update = useMutation<
        TUpdateGoodsResponse,
        TUpdateGoodsMutationFnError,
        TUpdateGoodsProps
    >((props) => {
        return MarketService.updateGoods(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        update,
    }
}

/**
 * Operations with query cached data goods
 */
export function useQueryDataGoods() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    /**
     * Update cached data of multiple queries
     */
    const setQueryData = (
        params: TFetchFnParams,
        updater: Updater<TGoodsResponse[0] | undefined, TGoodsResponse[0] | undefined>,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueriesData([`${key}-${user.id}`, params], updater, options)
    }

    return {
        setQueryData,
    }
}
