import React from 'react'
import { useHistory } from 'react-router-dom'

import { APP_URL } from 'config/app'
import { PageHeader } from 'components'
import style from './MessengerHeader.module.css'

const MessengerHeader: React.FC = ({ children }) => {
    const history = useHistory()

    const handlerClickBack = () => {
        history.push(APP_URL.messenger)
    }

    return (
        <div className={style.header}>
            <PageHeader
                classes={style.headerTop}
                onClickBack={handlerClickBack}
            />
            <div className={style.headerBottom}>
                {children}
            </div>
        </div>
    )
}

export default MessengerHeader
