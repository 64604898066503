import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { ICalendarMonth, ICalendarPost, IDateValues } from 'interfaces'
import { Spinner } from 'components'
import { CalendarYearMonth } from '../index'
import style from './CalendarYear.module.css'

type CalendarYearPropType = {
    date: Date
    posts?: ICalendarPost[]
    onClickDay: (value: Date, events: ICalendarPost[]) => void
}

const GRID_ITEMS_LENGTH = 12

const getMonths = ({ year }: Omit<IDateValues, 'month' | 'day' | 'hour' | 'minutes'>): ICalendarMonth[] => {
    return Array(GRID_ITEMS_LENGTH)
        .fill('')
        .map((item, index) => {
            const dateDate = new Date(year, index, 1)
            return {
                id: String(index),
                date: dateDate,
            }
        })
}

const CalendarYear: React.FC<CalendarYearPropType> = ({
    date,
    posts,
    onClickDay,
}) => {
    const [months, setMonths] = useState<ICalendarMonth[]>([])

    useEffect(() => {
        setMonths(getMonths({ year: date.getFullYear() }))
    }, [date])

    return (
        <div className={style.wrap}>
            {!posts && <Spinner position="center" size="medium" />}
            <div className={cn(style.list, { [style.list_loading]: !posts })}>
                {months.map((item) => (
                    <div className={style.item} key={item.id}>
                        <CalendarYearMonth
                            classes={style.month}
                            data={item}
                            posts={posts}
                            onClickDay={onClickDay}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CalendarYear
