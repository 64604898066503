import { useState, useEffect } from 'react'

/**
 * Check user activity on page
 * @param {number} interval - check interval
 * @param {number} timeout - timeout activity
 * @return boolean
 */
export default function useUserIsActive(interval: number = 1000, timeout: number = 3000) {
    const [lastActive, setLastActive] = useState(0)
    const [userIsActive, setUserIsActive] = useState(false)

    const handlerActive = () => {
        setLastActive(Date.now())
        setUserIsActive(true)
    }

    useEffect(() => {
        setLastActive(Date.now())

        window.addEventListener('mousemove', handlerActive)
        window.addEventListener('keypress', handlerActive)
        window.addEventListener('click', handlerActive)

        return () => {
            window.removeEventListener('mousemove', handlerActive)
            window.removeEventListener('keypress', handlerActive)
            window.removeEventListener('click', handlerActive)
        }
    }, [])

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            if (Date.now() - lastActive > timeout) {
                setUserIsActive(false)
                window.clearInterval(intervalId)
            }
        }, interval)

        return () => {
            window.clearInterval(intervalId)
        }
    }, [lastActive])

    return userIsActive
}
