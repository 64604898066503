declare const self: DedicatedWorkerGlobalScope

/**
 * Web worker
 * Normalization translations
 */
export default function TranslationNormalize() {
    self.onmessage = (e: MessageEvent) => {
        self.postMessage(normalizeTranslations(e.data))
    }

    function normalizeTranslations(data: any) {
        const excludeKeys = ['android_key', 'ios_key', 'webApp_key']
        const translations: { [key: string]: any } = {}

        if (Array.isArray(data)) {
            for (let i = 0, len = data.length; i < len; i += 1) {
                const langs = data[i]
                const webAppKey = langs.webApp_key

                if (webAppKey) {
                    // eslint-disable-next-line
                    for (let key in langs) {
                        if (Object.prototype.hasOwnProperty.call(langs, key) && !excludeKeys.includes(key)) {
                            if (!translations[key] || !translations[key].translation) {
                                translations[key] = { translation: {} }
                            }

                            translations[key].translation[webAppKey] = langs[key]
                        }
                    }
                }
            }
        }

        return translations
    }
}
