import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// import {  } from 'interfaces'
import { TContactsProps } from 'services/SimService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { SimService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TContactsProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<any, TError, any, TQueryKey>

const key = QueryCacheKeys.contacts

/**
 * Хук API поиск контактов для чата
 */
export default function useFetchContacts(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return SimService.fetchContacts(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
