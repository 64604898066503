import {
    IPost,
    ICalendarPost,
    IPostProps,
    ICalendarPostProps,
} from 'interfaces'

export default class PostDto {
    static getPostProps(data: IPost): { id: number } & IPostProps {
        const {
            id,
            type,
            title,
            text,
            community,
            files,
        } = data || {}

        return {
            id,
            type,
            title,
            text,
            community: community.id,
            files,
        }
    }

    static getCalendarPostProps(data: ICalendarPost): { id: number } & ICalendarPostProps {
        const {
            id,
            type,
            title,
            text,
            community,
            files,
            calendar_date_from: calendarDateFrom,
            calendar_date_to: calendarDateTo,
        } = data || {}

        return {
            id,
            type,
            title,
            text,
            calendarDateFrom,
            calendarDateTo,
            community: community.id,
            files,
        }
    }
}
