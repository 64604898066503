import { useMemo } from 'react'

import { IBasket } from 'interfaces'

export type BasketGoodsPropType = {
    basket?: IBasket
    goodsId?: number
    customCurrency?: string
}

export default function useBasketGoods({ basket, goodsId, customCurrency }: BasketGoodsPropType) {
    const goodsInBasketDefaultPrice = useMemo(() => {
        if (!goodsId) {
            return undefined
        }
        return basket?.content.find(({ goods, custom_currency }) => {
            return goodsId === goods.id && !custom_currency
        })
    }, [basket, goodsId])

    const goodsInBasketCustomPrice = useMemo(() => {
        if (!goodsId) {
            return undefined
        }
        return basket?.content.find(({ goods, custom_currency }) => {
            return goodsId === goods.id && customCurrency === custom_currency
        })
    }, [basket, goodsId])

    return {
        goodsInBasketDefaultPrice,
        goodsInBasketCustomPrice,
    }
}
