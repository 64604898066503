import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TMarketplaceServiceError, TShopsProps, TShopsResponse } from 'services/MarketplaceService'
import { QueryCacheKeys } from 'enums'
import { MarketplaceService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TShopsProps
export type TFetchFnError = AxiosError<TMarketplaceServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TShopsResponse, TError, TShopsResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TShopsResponse,
    TError,
    TShopsResponse,
    TShopsResponse,
    TQueryKey
>

const key = QueryCacheKeys.shops

/**
 * Hook API shops
 */
export default function useFetchShops(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], ({ signal }) => {
        return MarketplaceService.fetchShops(params, { signal })
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteShops(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams, signal }) => {
        return MarketplaceService.fetchShops(pageParam, { signal })
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}
