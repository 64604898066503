import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ICalendarDay, ICalendarPost } from 'interfaces'
import { Block } from 'layout'
import { dateTimeFormat } from 'utils/helpers'
import { CalendarMonthPost } from '../index'
import style from './CalendarMonthDay.module.css'

type CalendarMonthDayPropType = {
    data: ICalendarDay
    posts: ICalendarPost[]
    colors: string[]
}

const CalendarMonthDay: React.FC<CalendarMonthDayPropType> = ({ data, posts, colors }) => {
    const { i18n } = useTranslation()

    const date = useMemo(() => {
        return dateTimeFormat(data.date, i18n.language, { weekday: 'long', day: 'numeric', month: 'long' })
    }, [data, i18n.language])

    return (
        <div className={style.day}>
            <div className={style.date}>
                {date}
            </div>
            <Block>
                {posts.map((item) => (
                    <CalendarMonthPost
                        data={item}
                        colors={colors}
                        key={item.id}
                    />
                ))}
            </Block>
        </div>
    )
}

export default CalendarMonthDay
