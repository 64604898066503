import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IRatingRank } from 'interfaces'
import * as userSelectors from 'containers/User/user-selectors'
import { DirectoryService } from 'services'

const key = 'rating-ranks'

export type TError = string
export type TQueryKey = string[]
export type TQueryFnOpts = UseQueryOptions<IRatingRank[], TError, IRatingRank[], TQueryKey>

export default function useFetchRatingRanks({
    cacheTime = 3600 * 1000, // 1h
    staleTime = 3600 * 1000, // 1h
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return DirectoryService.fetchRatingRanks()
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
