import {
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ICalendarPost } from 'interfaces'
import { TCalendarPostsProps } from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TCalendarPostsProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<ICalendarPost[], TError, ICalendarPost[], TQueryKey>

const key = QueryCacheKeys.calendar

/**
 * Хук API получение постов для календаря
 */
export default function useFetchCalendarPosts(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCalendarPosts(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useInvalidateCalendarPosts(
    params: TFetchFnParams, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries(
            [`${key}-${user.id}`, params],
            { exact, ...filters },
            options,
        )
    }

    return {
        invalidate,
    }
}
