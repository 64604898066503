import React, { useState, useMemo, useEffect } from 'react'
import { useParams, NavLink, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { APP_URL } from 'config/app'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    ErrorMsg,
} from 'components'
import { useFetchBasket, useFetchPayoutMarketAvailableSum } from 'containers/Market/hooks'
import { OrderMakeAction } from 'form-actions'
import { scrollTop } from 'utils/helpers'
import { MarketService } from 'services'
import { useAppSelector } from 'store'

const StoreOrderMake: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()

    const darkDoorData = useAppSelector((state) => state.app.darkDoor)

    const [storeId] = useState(Number(id))
    const [isSetStoreId] = useState(!Number.isNaN(storeId))

    const { data: dataBasket } = useFetchBasket({ storeId }, { enabled: isSetStoreId })

    const {
        data: dataPayoutMarketAvailableSum,
    } = useFetchPayoutMarketAvailableSum({ store_id: storeId }, { enabled: !!dataBasket })

    const storeUrl = useMemo(() => {
        return isSetStoreId ? generatePath(APP_URL.store, { id: storeId }) : ''
    }, [isSetStoreId])

    const basketUrl = useMemo(() => {
        return isSetStoreId ? generatePath(APP_URL.basket, { id: storeId }) : ''
    }, [isSetStoreId])

    const breadcrumbs: IBreadcrumb[] = useMemo(() => [
        { id: 1, name: t('basket'), url: basketUrl },
        { id: 2, name: t('market_checkout_title') },
    ], [basketUrl])

    const orderProducts = useMemo(() => {
        if (dataBasket?.content.length) {
            return dataBasket.content
                .filter((item) => item.quantity)
                .map((item) => {
                    const { goods, quantity, custom_currency: payment_by } = item
                    return payment_by ? { goods: goods.id, quantity, payment_by } : { goods: goods.id, quantity }
                })
        }

        return undefined
    }, [dataBasket])

    const sumPaymentByBonus = useMemo(() => {
        return MarketService.getBonusAvailableSum(dataBasket?.market_wallet_list)
    }, [dataBasket])

    const isDelivery = useMemo(() => {
        return !!dataBasket?.content?.some((item) => item.goods.is_deliverable)
    }, [dataBasket])

    const isDarkDoorMode = useMemo(() => !!(darkDoorData && Object.keys(darkDoorData).length), [darkDoorData])

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isSetStoreId && (
                <>
                    {!dataBasket && (
                        <Loader />
                    )}

                    {dataBasket && !dataBasket.content.length && (
                        <ErrorMsg error={t('market_empty_basket')}>
                            <NavLink to={storeUrl || APP_URL.market}>
                                {t('button_store_catalogue')}
                            </NavLink>
                        </ErrorMsg>
                    )}

                    {orderProducts && (
                        <OrderMakeAction
                            storeId={storeId}
                            storeUrl={storeUrl}
                            orderProducts={orderProducts}
                            sumPaymentByBonus={sumPaymentByBonus}
                            sumPaymentByPayout={dataPayoutMarketAvailableSum?.canPayout ?? 0}
                            payDates={dataBasket?.pay_dates ?? []}
                            isDelivery={isDelivery}
                            isDarkDoorMode={isDarkDoorMode}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default StoreOrderMake
