import {
    getDigitCountFromMask,
    getDigitCountFromString,
} from './helpers'

// export type TValidationFields = TValidationFormRules[] // FIXME remove

export type validationFormFieldsType = {
    [key: string]: any
}

export type validationFormErrorsType = { // TODO TValidationError
    [key: string]: string | boolean
}

export type TValidationFieldRule = {
    field: string
    rules: TValidationRule[]
}

type TValidationRuleName = 'name' | 'surname' | 'required' | 'min' | 'max' | 'isNumber' | 'phone' | 'email' | 'codeTest'

type TValidationOptions = {
    mask?: string
    min?: number
    max?: number
}

type TValidationRule = {
    rule: TValidationRuleName
    error?: string
    options?: TValidationOptions
}

/**
 * Form validation
 * @param formRules
 * @param formFields
 * @return {object}
 */
export function validation(formRules: TValidationFieldRule[] = [], formFields: validationFormFieldsType)
    : validationFormErrorsType {
    return formRules.reduce((acc, { field, rules }) => {
        for (let i = 0, len = rules.length; i < len; i += 1) {
            const { rule, options, error } = rules[i]
            const isValid = validate(rule, formFields[field], options)

            if (!isValid) {
                acc[field] = error || true
                break
            }
        }

        return acc
    }, {})
}

/**
 * Form field validation
 */
export function validate(type: TValidationRuleName, value: string, options: TValidationOptions = {}): boolean {
    const FIX_PHONE_MASK_LENGTH = 1

    switch (type) {
        case 'name': // FIXME in forms to required
        case 'surname': // FIXME in forms to required
        case 'required': {
            return !!value && !!String(value).trim()
        }
        case 'min': {
            const { min } = options
            const val = value && value.trim() ? value.trim() : null

            return val && (min || min === 0) ? val.length >= min : false
        }
        case 'max': {
            const { max } = options
            const val = value && value.trim() ? value.trim() : null

            return val && max ? val.length <= max : false
        }
        case 'isNumber': {
            const val = value && value.trim() ? Number(value.trim()) : null
            return typeof val === 'number' && Number.isFinite(val)
        }
        case 'phone': {
            if (options.mask && value) {
                const numbersWillCount = getDigitCountFromMask(options.mask)
                const numbersDidCount = getDigitCountFromString(value)

                return numbersWillCount === numbersDidCount
                    || numbersWillCount - FIX_PHONE_MASK_LENGTH === numbersDidCount
            }
            return !('mask' in options && !!value)
        }
        // TODO remove
        case 'codeTest': {
            if (options.mask && value) {
                const numbersWillCount = getDigitCountFromMask(options.mask)
                const numbersDidCount = getDigitCountFromString(value)

                return numbersWillCount === numbersDidCount
            }
            return !('mask' in options && !!value)
        }
        case 'email': {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const val = value ? value.trim() : null

            return val ? re.test(String(val).toLowerCase()) : true
        }
        default:
            return false
    }
}
