import React from 'react'
import cn from 'classnames'

import TabsItem, { TabsItemType } from './TabsItem/TabsItem'
import style from './Tabs.module.css'

export type TabsPropType = {
    items: TabsItemType[]
    classes?: string
    classesItem?: string
    classesActive?: string
    classesText?: string
    active?: number
    onChange?: (id: number, extra?: any) => void
}

/**
 * Tabs
 */
const Tabs: React.FC<TabsPropType> = ({
    items = [],
    classes,
    classesItem,
    classesActive,
    classesText,
    active = 1,
    onChange = () => {},
}) => {
    const handlerClickItem = (id: number, extra?: any) => {
        onChange(id, extra)
    }

    return (
        <>
            {!!items.length && (
                <ul className={cn(style.tabs, classes)}>
                    {items.map((item) => (
                        <TabsItem
                            classes={classesItem}
                            classesActive={classesActive}
                            classesText={classesText}
                            item={item}
                            active={active === item.id}
                            key={item.id}
                            onClick={handlerClickItem}
                        />
                    ))}
                </ul>
            )}
        </>
    )
}

export default Tabs
