import React from 'react'
import { generatePath } from 'react-router-dom'
import cn from 'classnames'

import { IHistoryMessage } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Link } from 'components'
import style from './MessengerMessageAvatar.module.css'

type MessengerMessageAvatarPropType = {
    isOwn: boolean
    data: IHistoryMessage
}

const MessengerMessageAvatar: React.FC<MessengerMessageAvatarPropType> = ({ isOwn, data }) => {
    return (
        <Link
            className={cn(
                style.avatarLink,
                { [style.avatarLink_right]: isOwn },
            )}
            url={generatePath(APP_URL.profile, { id: data.senderUserId })}
        >
            <Avatar
                name={data.senderUser?.name}
                // name={isOwn ? user.name ?? '' : item.data.senderUser?.name}
                surname={data.senderUser?.surname}
                // surname={isOwn ? user.surname ?? '' : item.data.senderUser?.surname}
                src={data.senderUser?.photoFullUrl}
                // src={isOwn ? user.photo : item.data.senderUser?.photoFullUrl}
                width={48}
                height={48}
            />
            {!isOwn && data.channelId && (
                <div className={style.avatarUser}>
                    {`${data.senderUser?.name ?? ''} ${data.senderUser?.surname ?? ''}`}
                </div>
            )}
        </Link>
    )
}

export default MessengerMessageAvatar
