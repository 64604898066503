import { useState } from 'react'
import {
    validation,
    validationFormFieldsType,
    validationFormErrorsType,
    TValidationFieldRule,
} from 'utils/validators'

export type FormValueType = validationFormFieldsType

export type FormErrorType = validationFormErrorsType

export type FormRuleType = TValidationFieldRule

/**
 * Хук управления состоянием формы
 *
 * @deprecated
 * use react-final-form
 */
export default function useForm<T extends FormValueType>(data: T, staticRules: FormRuleType[] = []) {
    const [values, setValues] = useState<T>(data)
    const [errors, setError] = useState<FormErrorType>({})

    function setFormValue(name: string, value: any) {
        setValues((prevState) => ({ ...prevState, [name]: value }))
    }

    function setFormError(name: string, value: boolean | string) {
        setError((prevState) => ({ ...prevState, [name]: value }))
    }

    function validateForm(field?: FormValueType, isSetError: boolean = true, dynamicRules: FormRuleType[] = []) {
        const rules = dynamicRules.length ? dynamicRules : staticRules
        let err = {}

        if (!rules?.length) {
            return false
        }
        if (field) { // validate current field
            const [entry] = Object.entries(field)
            const [name] = entry || []
            const rule = rules.find((item) => item.field === name)

            if (name && rule) {
                err = validation([rule], field)

                if (isSetError) {
                    setError((prevState) => {
                        const fieldErr = name in err ? err : { [name]: false }
                        return { ...prevState, ...fieldErr }
                    })
                }
            }
        } else { // validate all fields
            err = validation(rules, values)

            if (isSetError) {
                setError(err)
            }
        }

        return !Object.keys(err).length
    }

    function isValidForm() {
        return validateForm(undefined, false)
    }

    return {
        formData: values,
        setFormValue,
        formErrors: errors,
        setFormError,
        validateForm,
        isValidForm,
    }
}
