import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITicketCategory } from 'interfaces'
import { TValidationFieldRule } from 'utils/validators'
import {
    Input,
    Button,
    Textarea,
    CustomSelect,
    SvgResource,
    Image,
} from 'components'
import { useForm } from 'hooks'

import styleForm from 'styles/modules/form.module.css'
import style from './SupportForm.module.css'

export type FormDataType = {
    category: number
    name: string
    email: string
    phone: string
    message: string
}

export type SupportFormPropType = {
    isAuthorized: boolean
    data: {
        name: FormDataType['name']
        phone: FormDataType['phone']
        email?: FormDataType['email']
    }
    images: string[]
    categories: ITicketCategory[]
    // errors?: formErrorsType
    classesContent?: string
    classesControls?: string
    isSubmitting: boolean
    onAddAttach: (file: File) => void
    onRemoveAttach: () => void
    onSubmit: (data: any) => void
}

const SupportForm: React.FC<SupportFormPropType> = ({
    isAuthorized,
    data,
    images,
    categories = [],
    // errors = {},
    classesContent,
    classesControls,
    isSubmitting,
    onAddAttach,
    onRemoveAttach,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'category',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'email',
        rules: isAuthorized
            ? [{ rule: 'email', error: t('invalid_email') }]
            : [{ rule: 'required', error: t('error_field_is_empty') }, { rule: 'email', error: t('invalid_email') }],
    }, {
        field: 'phone',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'message',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }]

    const {
        formData,
        setFormValue,
        formErrors,
        validateForm,
        isValidForm,
    } = useForm({
        category: 0,
        name: data?.name ? data.name : '',
        email: data?.email ? data.email : '',
        phone: data?.phone ? data.phone : '',
        message: '',
    }, FORM_VALIDATION_RULES)

    const categoryList = useMemo(() => {
        if (isAuthorized) {
            return categories.filter((item) => item.available_after_authorization)
        }

        return categories.filter((item) => item.available_before_authorization)
    }, [categories])

    const categoryName = useMemo(() => {
        const category = categories.find((item) => item.id === formData.category)
        return category?.name
    }, [formData.category])

    const isDisabled = useMemo(() => !isValidForm(), [formData]) // TODO by FORM_VALIDATION_RULES

    const handlerSubmitForm = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (validateForm()) {
            onSubmit(formData)
        }
    }

    const handlerChangeFormData = (e: React.SyntheticEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget
        setFormValue(name, value)
    }

    const handlerChangeCategory = (categoryId: number) => {
        setFormValue('category', categoryId)
    }

    const handlerAddAttach = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target
        const [file] = files || []

        if (file) {
            onAddAttach(file)
        }
    }

    const handlerRemoveAttach = () => {
        onRemoveAttach()
    }

    const handlerValidateFormData = (e: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget
        validateForm({ [name]: value })
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmitForm}>
            <div className={cn(style.body, classesContent)}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <CustomSelect.Select classes={style.select}>
                        <CustomSelect.Field classes={!categoryName ? style.selectField : ''}>
                            {categoryName || t('ui_support_create_new_ticket_spinner_title')}
                        </CustomSelect.Field>
                        <CustomSelect.Options classesList={style.selectList}>
                            {categoryList.map((item) => (
                                <CustomSelect.Option id={item.id} key={item.id} onClick={handlerChangeCategory}>
                                    {item.name}
                                </CustomSelect.Option>
                            ))}
                        </CustomSelect.Options>
                    </CustomSelect.Select>
                </div>

                {!data.name && (
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Input
                            classes={formErrors.name ? styleForm.invalid : ''}
                            styleType="dynamic"
                            name="name"
                            dynamicPlaceholder={t('firstname')}
                            value={formData.name}
                            onChange={handlerChangeFormData}
                            onBlur={handlerValidateFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors.name}
                        </div>
                    </div>
                )}

                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Input
                        classes={formErrors.email ? styleForm.invalid : ''}
                        styleType="dynamic"
                        name="email"
                        dynamicPlaceholder={t('email')}
                        value={formData.email}
                        onChange={handlerChangeFormData}
                        onBlur={handlerValidateFormData}
                    />
                    <div className={styleForm.error}>
                        {formErrors.email}
                    </div>
                </div>

                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Input
                        classes={formErrors.phone ? styleForm.invalid : ''}
                        styleType="dynamic"
                        name="phone"
                        dynamicPlaceholder={t('phone')}
                        value={formData.phone}
                        onChange={handlerChangeFormData}
                        onBlur={handlerValidateFormData}
                    />
                    <div className={styleForm.error}>
                        {formErrors.phone}
                    </div>
                </div>

                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Textarea
                        classes={formErrors.message ? styleForm.invalid : ''}
                        styleType="dynamic"
                        dynamicPlaceholder={t('message')}
                        name="message"
                        value={formData.message}
                        onChange={handlerChangeFormData}
                        onBlur={handlerValidateFormData}
                    />
                    <div className={styleForm.error}>
                        {formErrors.message}
                    </div>
                </div>

                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('add_attach')}
                    </div>
                    {images.length ? (
                        <Image
                            classesWrap={style.attach}
                            classesImage={style.attachImage}
                            src={images[0]}
                            resize={{ quality: 90, width: 96, height: 96 }}
                        >
                            <Button
                                classes={style.attachRemove}
                                styleType="text"
                                text="-"
                                onClick={handlerRemoveAttach}
                            />
                        </Image>
                    ) : (
                        <label className={cn(styleForm.field, styleForm.field_attachBtn)}>
                            <Input
                                classes={styleForm.inputFile}
                                type="file"
                                styleType="clear"
                                accept={ACCEPT_IMAGE}
                                onChange={handlerAddAttach}
                            />
                            <SvgResource
                                resourceKey="ic_attach_svg"
                                width={30}
                                height={30}
                            />
                        </label>
                    )}
                </div>
            </div>

            <div className={cn(style.footer, classesControls)}>
                <Button
                    classes={style.modalControl}
                    type="submit"
                    textUpper
                    size="size40"
                    text={t('send')}
                    disabled={isDisabled}
                />
            </div>
        </form>
    )
}

export default SupportForm
