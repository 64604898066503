import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBasket, IGoodsData, IStoreCurrency } from 'interfaces'
import { CardSize } from 'enums'
import { PRODUCT_TYPE_PROMOTION_DESCRIPTION } from 'config/app'
import { useBasketGoods } from 'containers/StoreBasket/hooks'
import {
    CardV2,
    Button,
    Badge,
    SvgResource,
    Counter,
} from 'components'
import { MarketService } from 'services'
import { numberFormat, priceFormat } from 'utils/helpers'
import style from './CatalogShowcaseGoods.module.css'
import styleCatalog from '../../CatalogShowcase.module.css'

export type CatalogShowcaseGoodsPropType = {
    classes?: string
    data: IGoodsData
    size: keyof typeof CardSize
    url?: string
    basket?: IBasket
    storeCurrency?: IStoreCurrency
    payoutCurrency?: string
    onClick?: (value: IGoodsData) => void
    onAddToBasket?: (value: IGoodsData, count: number, currency?: string) => void
}

const CatalogShowcaseGoods: React.FC<CatalogShowcaseGoodsPropType> = ({
    children,
    classes,
    data,
    size,
    url,
    basket,
    storeCurrency,
    payoutCurrency,
    onClick,
    onAddToBasket,
}) => {
    const { t } = useTranslation()

    const { goodsInBasketDefaultPrice, goodsInBasketCustomPrice } = useBasketGoods({
        basket,
        goodsId: data.id,
        customCurrency: payoutCurrency,
    })

    const image = useMemo(() => {
        const { images } = data

        if (images) {
            const image1 = images.find((img) => img.format === 1)
            const image2 = images.find((img) => img.format === 2)

            return image1?.path || image2?.path || ''
        }

        return ''
    }, [data])

    const wallets = useMemo(() => basket?.market_wallet_list, [basket])

    const isGoodsTypePromotion = useMemo(() => {
        return data?.product_type?.id === PRODUCT_TYPE_PROMOTION_DESCRIPTION
    }, [data])

    const isShowBuy = useMemo(() => {
        return data.is_purchasable && onAddToBasket !== undefined
    }, [data])

    const isShowBuyByPayout = useMemo(() => {
        if (wallets && onAddToBasket !== undefined) { // && onAddToBasketCV && isShowBuyButton
            const { is_purchasable, is_applying_cv } = data || {}
            return is_purchasable && is_applying_cv && !!MarketService.getPayoutAvailableSum(wallets)
        }
        return false
    }, [data, wallets])

    const cost = useMemo(() => {
        if (!isGoodsTypePromotion && storeCurrency && data?.cost) {
            return priceFormat(data.cost, storeCurrency)
        }
        return ''
    }, [data, storeCurrency])

    const retailPrice = useMemo(() => {
        if (!isGoodsTypePromotion && storeCurrency && data?.retail_price > data?.cost) {
            return priceFormat(data.retail_price, storeCurrency)
        }
        return ''
    }, [data, storeCurrency])

    const priceCV = useMemo(() => {
        return !isGoodsTypePromotion && data?.price_cv ? numberFormat(data.price_cv) : ''
    }, [data])

    const kicksPrice = useMemo(() => {
        return !isGoodsTypePromotion && data?.kicks_price ? numberFormat(data.kicks_price) : ''
    }, [data])

    const handlerClick = () => {
        if (onClick) {
            onClick(data)
        }
    }

    const handlerAddToBasket = () => {
        if (onAddToBasket) {
            onAddToBasket(data, 1)
        }
    }

    const handlerAddToBasketCV = () => {
        if (onAddToBasket && !goodsInBasketCustomPrice?.quantity) {
            onAddToBasket(data, 1, payoutCurrency)
        }
    }

    const handlerChangeCount = (value: number) => {
        if (onAddToBasket) {
            onAddToBasket(data, value)
        }
    }

    return (
        <CardV2
            classes={classes}
            size={size}
        >
            <CardV2.Image
                src={image}
                width={400}
                url={url}
                onClick={onClick && handlerClick}
            />
            <CardV2.Body
                classes={styleCatalog.body}
                classesTitle={style.title}
                classesDesc={style.desc}
                title={data.name}
                desc={data?.subtitle}
                url={url}
                onClick={onClick && handlerClick}
            >
                {!isGoodsTypePromotion && (
                    <>
                        <div className={style.prices}>
                            <div className={style.pricesItem}>
                                <span className={style.price}>
                                    {cost}
                                </span>
                                {retailPrice && (
                                    <span className={cn(style.price, style.price_old)}>
                                        {retailPrice}
                                    </span>
                                )}
                            </div>
                            <div className={cn(style.pricesItem, style.pricesItem_between)}>
                                <span className={cn(style.price, style.price_cv)}>
                                    {priceCV && `${priceCV} ${t('market_cv')}`}
                                </span>
                                <span className={cn(style.price, style.price_kicks)}>
                                    {kicksPrice && (
                                        <>
                                            {kicksPrice}
                                            {' '}
                                            <SvgResource
                                                classes={style.iconPrice}
                                                resourceKey="kicks_currency_svg"
                                                width={15}
                                                height={17}
                                            />
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                        {(isShowBuy || isShowBuyByPayout) && (
                            <div className={style.actions}>
                                {isShowBuy && (
                                    <>
                                        {goodsInBasketDefaultPrice?.quantity ? (
                                            <Counter
                                                changeByRemote
                                                classes={style.actionCounter}
                                                value={goodsInBasketDefaultPrice.quantity}
                                                onChange={handlerChangeCount}
                                            />
                                        ) : (
                                            <Button
                                                classes={style.action}
                                                styleType="text"
                                                onClick={handlerAddToBasket}
                                            >
                                                <SvgResource
                                                    classes={style.iconBuy}
                                                    resourceKey="ic_post_cart2_svg"
                                                    width={30}
                                                    height={30}
                                                />
                                                {goodsInBasketDefaultPrice && (
                                                    <Badge classes={style.actionBadge} size="small" animChanged>
                                                        {goodsInBasketDefaultPrice.quantity}
                                                    </Badge>
                                                )}
                                            </Button>
                                        )}
                                    </>
                                )}
                                {isShowBuyByPayout && (
                                    <Button
                                        classes={style.action}
                                        styleType="text"
                                        type="button"
                                        onClick={handlerAddToBasketCV}
                                    >
                                        <SvgResource
                                            classes={style.iconPresent}
                                            resourceKey="ic_shop_present_svg"
                                            width={30}
                                            height={33}
                                        />
                                        {goodsInBasketCustomPrice && (
                                            <Badge classes={style.actionBadge} size="small" animChanged>
                                                {goodsInBasketCustomPrice.quantity}
                                            </Badge>
                                        )}
                                    </Button>
                                )}
                            </div>
                        )}
                    </>
                )}
            </CardV2.Body>
            {children}
        </CardV2>
    )
}

export default CatalogShowcaseGoods
