import React, { useEffect, useState } from 'react'

import { ICommunityDefaultImage } from 'interfaces'
import { CardContainer } from 'layout'
import { useFetchSubscription } from 'containers/User/hooks'
import { useFetchInfiniteCommunityDefaultImages } from 'containers/Community/hooks'
import { InfiniteScroll } from 'components'
import { PhotoBankImageSelectorImage } from './components'

type PhotoBankImageSelectorPropType = {
    classes?: string
    onSelectImage: (data: ICommunityDefaultImage) => void
}

const LIMIT = 12
const OFFSET = 0

const PhotoBankImageSelector: React.FC<PhotoBankImageSelectorPropType> = ({ classes, onSelectImage }) => {
    const [isHideLoaderImages, setIsHideLoaderStores] = useState(false)

    const { data: dataSubscription } = useFetchSubscription()

    const {
        isLoading: isLoadingImages,
        data: dataDefaultImages,
        fetchNextPage: fetchNextPageImages,
    } = useFetchInfiniteCommunityDefaultImages({
        limit: LIMIT,
        offset: OFFSET,
    }, {
        enabled: !!dataSubscription?.subscription.daysleft,
    })

    const handlerLoadImages = () => {
        if (!isLoadingImages) {
            fetchNextPageImages()
        }
    }

    useEffect(() => {
        if (dataDefaultImages) {
            const { pages } = dataDefaultImages
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoaderStores(true)
            }
        }
    }, [dataDefaultImages])

    return (
        <div className={classes}>
            <InfiniteScroll
                isActive={!!dataSubscription?.subscription.daysleft}
                isHideLoader={isHideLoaderImages}
                onLoad={handlerLoadImages}
            >
                <CardContainer>
                    {dataDefaultImages?.pages.map((page) => (
                        <React.Fragment key={page[0]?.id}>
                            {page.map((item) => (
                                <PhotoBankImageSelectorImage
                                    data={item}
                                    key={item.id}
                                    onClick={onSelectImage}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </CardContainer>
            </InfiniteScroll>
        </div>
    )
}

export default PhotoBankImageSelector
