import React from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { API_HOST } from 'config/api'
import { Card } from 'components'
import style from './ItemReward.module.css'

type ItemRewardPropType = {
    data: IReport
    classes?: string
}

const ItemReward: React.FC<ItemRewardPropType> = ({ data, classes }) => {
    return (
        <Card.Item classes={cn(style.reward, classes)}>
            <Card.Image
                classes={style.image}
                backgroundColor={data.backgroundColor}
                src={`${API_HOST}${data.icon}`}
            />
            <Card.Body styles={{ backgroundColor: data.backgroundColor }}>
                <div className={style.title} style={{ color: data.textColor }}>
                    <div>
                        {data.name}
                    </div>
                    <div>
                        {data.value}
                    </div>
                </div>
            </Card.Body>
        </Card.Item>
    )
}

export default ItemReward
