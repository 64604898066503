import React from 'react'

import CardItem, { CardItemPropType } from './CardItem/CardItem'
import CardImage, { CardImagePropType } from './CardImage/CardImage'
import CardBody, { CardBodyPropType } from './CardBody/CardBody'
import CardFooter, { CardFooterPropType } from './CardFooter/CardFooter'

type CardType = {
    Item: React.FC<CardItemPropType>
    Image: React.FC<CardImagePropType>
    Body: React.FC<CardBodyPropType>
    Footer: React.FC<CardFooterPropType>
}

const Card: CardType = {
    Item: (props) => <CardItem {...props} />,
    Image: (props) => <CardImage {...props} />,
    Body: (props) => <CardBody {...props} />,
    Footer: (props) => <CardFooter {...props} />,
}

export default Card
