import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IHistoryMessage } from 'interfaces'
import { TextContent } from 'components'
import style from './MessengerMessageContent.module.css'

type MessengerMessageContentPropType = {
    classes?: string
    data: IHistoryMessage
    isOwn: boolean
    onClickResponse?: (messageId: string) => void
    onClick?: (e: React.SyntheticEvent, value: string) => void
}

// FIXME remove after fix sswidth and ssheight on backend
const mediaSizeParams = 'sswidth=420&ssheight=420'

const MessengerMessageContent: React.FC<MessengerMessageContentPropType> = ({
    classes,
    data,
    isOwn,
    onClickResponse,
    onClick,
}) => {
    const { t } = useTranslation()

    const name = useMemo(() => {
        if (data.forwardedFromUser) {
            return `${data.forwardedFromUser.name ?? ''} ${data.forwardedFromUser.surname ?? ''}`
        }
        if (data.inResponseToUser) {
            return `${data.inResponseToUser?.name ?? ''} ${data.inResponseToUser?.surname ?? ''}`
        }

        return ''
    }, [data])

    const content = useMemo(() => {
        if (data.forwardedMessage) {
            return data.forwardedMessage.includes('http') ? `${data.forwardedMessage}?${mediaSizeParams}` : data.forwardedMessage
        }
        if (data.inResponseToMessage) {
            return data.inResponseToMessage.includes('http') ? `${data.inResponseToMessage}?${mediaSizeParams}` : data.inResponseToMessage
        }

        return ''
    }, [data])

    const handlerClickResponse = () => {
        if (onClickResponse && data.inResponseToMessageId) {
            onClickResponse(data.inResponseToMessageId)
        }
    }

    return (
        <div className={cn(style.content, classes)}>
            {(data.forwardedMessageId || data.inResponseToMessageId) && (
                <>
                    {data.forwardedMessageId && (
                        <div className={style.title}>
                            {t('chat_message_forward')}
                        </div>
                    )}
                    {/* eslint-disable-next-line */}
                    <div
                        className={cn(style.header, {
                            [style.header_sender]: isOwn,
                            [style.header_clickable]: data.inResponseToMessageId,
                        })}
                        onClick={handlerClickResponse}
                    >
                        <div className={cn(style.name, { [style.name_sender]: isOwn })}>
                            {name}
                        </div>
                        <TextContent
                            classesContent={style.headerMessage}
                            classesImg={style.headerImage}
                            classesVideo={style.headerVideo}
                            classesSticker={style.headerSticker}
                            content={content}
                            isVideoControls={false}
                        />
                    </div>
                </>
            )}
            {data.text && (
                <TextContent
                    isVideoPlayIcon
                    isVideoControls={false}
                    classesContent={style.contentMessage}
                    classesImg={style.contentImage}
                    classesVideo={style.contentVideo}
                    classesSticker={style.contentSticker}
                    stickerSize={{ width: 210, height: 210 }}
                    content={data.text?.includes('http') && !data.text.includes('?') ? `${data.text}?${mediaSizeParams}` : data.text}
                    onClick={(e) => data.text && onClick && onClick(e, data.text)}
                />
            )}
        </div>
    )
}

export default MessengerMessageContent
