import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    Avatar,
    MenuList,
    SvgResource,
    Popover,
    Icon,
} from 'components'
import { useWindowResize } from 'hooks'
import { useAppSelector } from 'store'
import style from './MenuNavMain.module.css'

const MenuNavMain: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const user = useAppSelector((state) => state.user)
    const {
        chat_count: chatCount,
        support_count: supportCount,
    } = useAppSelector((state) => state.counts)

    const handlerClickSettings = () => {
        history.push(APP_URL.settings)
    }

    const handlerClickExit = () => {
        history.push(APP_URL.logout)
    }

    return (
        <ul className={style.nav}>
            {windowWidth >= BREAKPOINTS.tabletLandscape && (
                <>
                    <li className={style.navItem}>
                        <NavLink className={style.link} to={APP_URL.messenger} title={t('chat')}>
                            <Icon
                                isImgTag={false}
                                classes={style.icon}
                                resourceKey="ic_chat_v2_svg"
                                width={32}
                                height={32}
                                count={chatCount}
                                badge={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                }}
                            />
                        </NavLink>
                    </li>
                    <li className={style.navItem}>
                        <NavLink className={style.link} to={APP_URL.support} title={t('ui_support_create_ticket_title')}>
                            <Icon
                                isImgTag={false}
                                classes={style.icon}
                                resourceKey="ic_support_v2_svg"
                                width={32}
                                height={32}
                                notify={!!supportCount}
                            />
                        </NavLink>
                    </li>
                </>
            )}
            {/* <li className={style.navItem}>
                <SvgIcon
                    classes={style.icon}
                    resourceKey="ic_notification_svg_white"
                    width={32}
                    height={32}
                />
                {!!notifications_count && (
                    <Badge classes={style.badge} animChanged size="small">
                        {notifications_count}
                    </Badge>
                )}
            </li> */}
            <li className={style.navItem}>
                <Popover
                    isShowArrow={false}
                    position="bottom"
                    side="left"
                    trigger={(
                        <Avatar
                            classes={style.avatar}
                            name={user?.name}
                            surname={user?.surname}
                            src={user?.photo}
                            width={32}
                            height={32}
                        />
                    )}
                >
                    <MenuList>
                        <MenuList.Item onClick={handlerClickSettings}>
                            <SvgResource
                                isImgTag={false}
                                classes={style.iconMenuItem}
                                resourceKey="ic_settings_v2_svg"
                                width={25}
                                height={23}
                            />
                            {t('settings')}
                        </MenuList.Item>
                        <MenuList.Item onClick={handlerClickExit}>
                            <SvgResource
                                isImgTag={false}
                                classes={style.iconMenuItem}
                                resourceKey="ic_appexit_svg"
                                width={25}
                                height={23}
                            />
                            {t('exit')}
                        </MenuList.Item>
                    </MenuList>
                </Popover>
            </li>
        </ul>
    )
}

export default MenuNavMain
