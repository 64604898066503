import React from 'react'
import { useSelector } from 'react-redux'

import { ICalendarPost } from 'interfaces'
import * as userSelectors from 'containers/User/user-selectors'
import { CalendarDateEventListItem } from '../index'
// import style from './CalendarDateEventList.module.css'

type CalendarDateEventListPropType = {
    classes?: string
    data: ICalendarPost[]
    colors: string[]
    onClickEdit: (data: ICalendarPost) => void
    onClickDelete: (data: ICalendarPost) => void
}

const CalendarDateEventList: React.FC<CalendarDateEventListPropType> = ({
    classes,
    data,
    colors,
    onClickEdit,
    onClickDelete,
}) => {
    const user = useSelector(userSelectors.user)

    return (
        <div className={classes}>
            {data.map((item) => (
                <CalendarDateEventListItem
                    data={item}
                    colors={colors}
                    isOwn={item.user?.id === user.id}
                    key={item.id}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            ))}
        </div>
    )
}

export default CalendarDateEventList
