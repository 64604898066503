import {
    useQuery,
    useQueryClient,
    UseQueryOptions,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IPostComments, IPostCommentResponse } from 'interfaces'
import {
    fetchCommentsPropType,
    addCommentPropType,
    updateCommentPropType,
    deleteCommentPropType,
    restoreCommentPropType,
} from 'services/CommunityService'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

const key = 'comments'

export type TFetchFnParams = fetchCommentsPropType
export type TError = string
export type TQueryKey = [typeof key, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IPostComments, TError, IPostComments, TQueryKey>

/**
 * Хук API получение списка комментариев поста
 */
export default function useFetchComments(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return CommunityService.fetchComments(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useInvalidateComments(params: TFetchFnParams) {
    const queryClient = useQueryClient()

    const invalidate = () => {
        return queryClient.invalidateQueries([key, params], { exact: true })
    }

    return {
        invalidate,
    }
}

export function useMutationComments(params: TFetchFnParams, invalidate: boolean = true) {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const mutationAdd = useMutation<IPostCommentResponse, string, addCommentPropType, unknown>((props) => {
        return CommunityService.addComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: onSuccessMutate,
    })

    const mutationUpdate = useMutation<[], string, updateCommentPropType, unknown>((props) => {
        return CommunityService.updateComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: onSuccessMutate,
    })

    const mutationDelete = useMutation<'', string, deleteCommentPropType, unknown>((props) => {
        return CommunityService.deleteComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: onSuccessMutate,
    })

    const mutationRestore = useMutation<'', string, restoreCommentPropType, unknown>((props) => {
        return CommunityService.restoreComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: onSuccessMutate,
    })

    function onSuccessMutate() {
        if (invalidate) {
            invalidateQueries()
        }
    }

    function invalidateQueries() {
        queryClient.invalidateQueries([key, params], { exact: true })
    }

    return {
        add: mutationAdd.mutate,
        update: mutationUpdate.mutate,
        deleteComment: mutationDelete.mutate,
        restoreComment: mutationRestore.mutate,
    }
}
