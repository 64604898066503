import React, { useMemo } from 'react'
import { NavLink, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ITicketItem } from 'interfaces'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { TicketStatusTag, TextContent } from 'components'
import { useTimeAgo } from 'hooks'
import style from './SupportItem.module.css'

type SupportItemPropType = {
    data: ITicketItem
}

const SupportItem: React.FC<SupportItemPropType> = ({ data }) => {
    const { i18n } = useTranslation()

    const { getTimeAgo } = useTimeAgo(undefined, i18n.language)

    const url = useMemo(() => generatePath(APP_URL.ticket, {
        id: data.id,
    }), [data])

    const date = useMemo(() => {
        return getTimeAgo(data.create_date)
    }, [data])

    return (
        <Block classes={style.item}>
            <div className={style.body}>
                <div className={style.title}>
                    <NavLink className={style.titleLink} to={url}>
                        <span className={style.textId}>
                            ID
                            {data.id}
                            {' '}
                        </span>
                        <span className={style.textTitle}>
                            {data?.category.name}
                        </span>
                    </NavLink>
                </div>
                <div className={style.content}>
                    <NavLink className={style.contentLink} to={url}>
                        <TextContent
                            isVideoControls={false}
                            classesImg={style.contentImage}
                            classesVideo={style.contentVideo}
                            content={data?.last_message.message}
                            imgResize={{ width: 100 }}
                        />
                    </NavLink>
                </div>
            </div>
            <div className={style.info}>
                <div className={style.date}>
                    {date}
                </div>
                <div className={style.status}>
                    <TicketStatusTag status={data.status_id} />
                </div>
            </div>
        </Block>
    )
}

export default SupportItem
