import React, { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Collapse } from 'react-collapse'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { Link } from 'components'
import { getURLSearchParams } from 'utils/helpers'
import style from './MenuNavLink.module.css'
import { NavLinkContent } from '../MenuNavContent/MenuNavContent'
import { MenuNavContent } from '../index'

type MenuNavLinkPropType = {
    isMobile?: boolean
    data: IMenuNavItemData
    isOpen: boolean
    onChangeSubMenu: (itemId: number) => void
}

const MenuNavLink: React.FC<MenuNavLinkPropType> = ({
    data,
    isOpen,
    isMobile,
    onChangeSubMenu,
}) => {
    const {
        id,
        url,
        children,
        disabled,
    } = data

    const { search, pathname } = useLocation()
    const { tabId } = getURLSearchParams(search)

    const isActivePath = useMemo(() => {
        if (url) {
            return !!matchPath(pathname, { path: url, exact: false })
        }
        return false
    }, [url, pathname, tabId])

    const isActiveChild = (child: Omit<IMenuNavItemData, 'children' | 'icon' | 'childType'>) => {
        return `${pathname}${search}` === child.url
    }

    const handlerClickSubMenu = () => {
        onChangeSubMenu(id)
    }

    return (
        <>
            {disabled ? (
                <div className={cn(style.link, { [style.link_disabled]: disabled })}>
                    <MenuNavContent
                        data={data}
                        type={NavLinkContent.parent}
                    />
                </div>
            ) : (
                <>
                    {(isMobile || (!isMobile && !children)) && (
                        <Link
                            className={cn(style.link, { [style.link_active]: isActivePath })}
                            url={url}
                            onClick={handlerClickSubMenu}
                        >
                            <MenuNavContent
                                data={data}
                                type={NavLinkContent.parent}
                                isActive={isActivePath}
                            />
                        </Link>
                    )}
                    {!isMobile && children && (
                        <>
                            {/* eslint-disable-next-line */}
                            <div
                                className={style.link}
                                onClick={handlerClickSubMenu}
                            >
                                <MenuNavContent
                                    data={data}
                                    type={NavLinkContent.parentWithChildren}
                                    isItemOpen={isOpen}
                                    isActive={isActivePath}
                                />
                            </div>
                            <Collapse isOpened={isOpen}>
                                {Object.values(children).map((child) => (
                                    <React.Fragment key={child.id}>
                                        {child.disabled ? (
                                            <div className={cn(style.link, style.linkChild)}>
                                                <MenuNavContent
                                                    data={child}
                                                    type={NavLinkContent.child}
                                                />
                                            </div>
                                        ) : (
                                            <Link
                                                className={cn(
                                                    style.link,
                                                    style.linkChild,
                                                    { [style.link_active]: isActiveChild(child) },
                                                )}
                                                url={child.url}
                                            >
                                                <MenuNavContent
                                                    data={child}
                                                    type={NavLinkContent.child}
                                                    isActive={isActiveChild(child)}
                                                />
                                            </Link>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Collapse>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default MenuNavLink
