import {
    Store,
    Reducer,
    createStore,
    applyMiddleware,
    AnyAction,
} from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { IRootState } from 'interfaces'
import rootReducer from 'reducers/root-reducer'
import { thunk } from 'middleware'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any
        __REDUX_DEVTOOLS_EXTENSION__?: any
    }
}

/** Type from the store that includes the thunk middleware */
export type AppDispatch = ThunkDispatch<IRootState, any, AnyAction>

const initialState = {}

const configureStore = (): Store<IRootState> => {
    const create = '__REDUX_DEVTOOLS_EXTENSION__' in window ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore) : createStore
    const createStoreWithMiddleware = applyMiddleware(thunk)(create)

    return createStoreWithMiddleware((rootReducer as Reducer), initialState)
}

/**
 * App store instance
 */
const store = configureStore()

/**
 * App selector with typed store's root state
 */
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector

export default store
