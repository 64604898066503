import { IShop } from 'interfaces'
import { AppScheme } from 'enums'
import { API_URL } from 'config/api'
import requestClient, { RequestOptions } from 'utils/requestClient'

export type TMarketplaceServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TShopsProps = {
    category_id?: number | string
    limit?: number
    offset?: number
    lat?: number
    lng?: number
    sort_by?: 'dist' | 'cashback' | 'kickback'
    friends_only?: boolean
    schema?: keyof typeof AppScheme
}

export type TShopsResponse = IShop[]

class MarketplaceService {
    /**
     * Получить магазины обычной категории
     */
    static fetchShops(params: TShopsProps, options?: RequestOptions) {
        return requestClient<TShopsResponse>(API_URL.marketplace, { params, ...options })
    }
}

export default MarketplaceService
