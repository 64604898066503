import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { plural } from 'utils/helpers'
import style from './CalendarMonthMore.module.css'

type CalendarMonthMorePropType = {
    count: number
    max: number
}

const CalendarMonthMore: React.FC<CalendarMonthMorePropType> = ({ count, max }) => {
    const { t } = useTranslation()

    const moreCountText = useMemo(() => {
        const moreCount = count - max
        const text = plural(moreCount, [t('event_count_1x'), t('event_count_2x'), t('event_count_5x')])
        return `+${moreCount} ${text}`
    }, [])

    return (
        <div className={style.more}>
            {moreCountText}
        </div>
    )
}

export default CalendarMonthMore
