import React, { useMemo } from 'react'

import { IHistoryMessage } from 'interfaces'
import { Button, SvgResource, TextContent } from 'components'
import style from './MessengerMessageReply.module.css'

type MessengerMessageReplyPropType = {
    data: IHistoryMessage
    onClose: () => void
}

const MessengerMessageReply: React.FC<MessengerMessageReplyPropType> = ({ data, onClose }) => {
    const content = useMemo(() => {
        return data.forwardedMessage || data.text || ''
    }, [data])

    return (
        <div className={style.reply}>
            <Button
                classes={style.close}
                styleType="text"
                onClick={onClose}
            />
            <SvgResource
                isImgTag={false}
                classes={style.iconReply}
                resourceKey="ic_chat_reply_svg"
                width={19}
                height={16}
            />
            <div className={style.content}>
                <div className={style.name}>
                    {`${data.senderUser?.name ?? ''} ${data.senderUser?.surname ?? ''}`}
                </div>
                <div className={style.body}>
                    <TextContent
                        classesContent={style.message}
                        classesImg={style.messageImg}
                        classesVideo={style.messageVideo}
                        content={content}
                        isVideoControls={false}
                        imgResize={{ width: 60, height: 60 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default MessengerMessageReply
