import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IDeliveryCost, IStoreCurrency } from 'interfaces'
import { Modal, Button } from 'components'
import styleColumns from 'styles/modules/columns.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './StoreOrderPickup.module.css'
import { StoreOrderPickupItem, StoreOrderPickupListItem } from '../index'

type StoreOrderPickupPropType = {
    items: IDeliveryCost[]
    currency?: IStoreCurrency
    activeId?: number
    error?: string | boolean
    onSelect: (id: number) => void
}

const MAX_ITEMS_SHOW = 2

const StoreOrderPickup: React.FC<StoreOrderPickupPropType> = ({
    items,
    currency,
    activeId,
    error,
    onSelect,
}) => {
    const { t } = useTranslation()

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [checkedId, setCheckedId] = useState<number | undefined>()

    const filteredItems = useMemo(() => {
        if (items.length) {
            const activeItemIndex = activeId ? items.findIndex((item) => item.id === activeId) : -1

            if (activeItemIndex >= 0 && activeItemIndex >= MAX_ITEMS_SHOW) {
                const secondItem = items.find((item) => item.id !== activeId)
                return secondItem ? [items[activeItemIndex], secondItem] : [items[activeItemIndex]]
            }

            return items.slice(0, MAX_ITEMS_SHOW)
        }

        return []
    }, [items, activeId])

    const handlerOpenModal = () => {
        setIsOpenModal(true)
    }

    const handlerCloseModal = () => {
        setIsOpenModal(false)
    }

    const handlerSelect = (id: number) => {
        setCheckedId(id)
        onSelect(id)
    }

    const handlerChange = (id: number) => {
        setCheckedId(id)
    }

    const handlerChooseChecked = () => {
        if (checkedId) {
            onSelect(checkedId)
        }

        setIsOpenModal(false)
    }

    return (
        <>
            <div className={styleForm.dangerText}>
                {error}
            </div>
            <div className={styleColumns.columns}>
                {filteredItems.slice(0, MAX_ITEMS_SHOW).map((item) => (
                    <StoreOrderPickupItem
                        data={item}
                        activeId={activeId}
                        currency={currency}
                        key={`item${item.id}`}
                        onSelect={handlerSelect}
                    />
                ))}
            </div>

            {items?.length > MAX_ITEMS_SHOW && (
                <Button
                    classes={style.showAll}
                    styleType="text"
                    text={t('pickup_points')}
                    onClick={handlerOpenModal}
                />
            )}

            <Modal
                isOpen={isOpenModal}
                size="medium"
                onClose={handlerCloseModal}
            >
                <Modal.Header
                    title={t('select_pickup')}
                    titlePos="left"
                    isCloseButton
                />
                <Modal.Body classes={style.modalBody}>
                    {items?.map((item) => (
                        <StoreOrderPickupListItem
                            data={item}
                            currency={currency}
                            checked={checkedId === item.id}
                            key={`list${item.id}`}
                            onChange={handlerChange}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        classes={style.modalControl}
                        textUpper
                        size="size40"
                        text={t('select')}
                        disabled={!checkedId}
                        onClick={handlerChooseChecked}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StoreOrderPickup
