import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IPayout,
    ICurrency,
    IReport,
    ITransaction,
} from 'interfaces'
import { TPayoutHistoryProps } from 'services/PayoutService'
import { BREAKPOINTS, CLIENT_STORAGE_WALLET_CURRENCY_KEY } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    ContentBackgroundBottom,
    Block,
} from 'layout'
import {
    Button,
    Loader,
    NoDataInfo,
    SvgResource,
} from 'components'
import { useWindowResize } from 'hooks'
import { StorageService } from 'services'
import { priceFormat, scrollTop, getCountFractionDigits } from 'utils/helpers'
import { useAppSelector } from 'store'
import {
    WalletNav,
    WalletTransactionsFilter,
    WalletTransactions,
    WalletItem,
    WalletPayoutCurrent,
    WalletReports,
    WalletWallet,
} from './components'
import { TabItems } from './components/WalletNav/WalletNav'
import { useFetchPayoutHistory, useFetchPayoutOptions } from './hooks'
import style from './Wallet.module.css'

const LIMIT_TRANSACTIONS = 20

const storageCurrency = StorageService.getItem<ICurrency>(CLIENT_STORAGE_WALLET_CURRENCY_KEY)

const Wallet: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const {
        id: userCurrencyId,
        show_code: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    } = useAppSelector((state) => {
        return state.user?.country?.default_currency
            ? state.user.country.default_currency
            : { id: 0, show_code: '', left_currency_symbol_placement: false }
    })

    const [activeTabId, setActiveTabId] = useState(TabItems.transactions)
    const [activePayout, setActivePayout] = useState<IPayout>()
    const [transactions, setTransactions] = useState<ITransaction[]>()
    const [transactionsParamsProps, setTransactionsParamsProps] = useState<Omit<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>>({
        limit: LIMIT_TRANSACTIONS,
        offset: 0,
    })
    const [transactionsFilterProps, setTransactionsFilterProps] = useState<Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>>({})
    const [currentCurrency, setCurrentCurrency] = useState<Partial<ICurrency>>(storageCurrency || {
        id: userCurrencyId,
        showCode: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    })

    const [isOpenTransactionsFilter, setIsOpenTransactionsFilter] = useState(false)
    const [isShowWalletItemTotal, setIsShowWalletItemTotal] = useState(false)

    const {
        isFetching: isFetchingPayoutOptions,
        data: dataPayouts,
    } = useFetchPayoutOptions({
        converted_balance_currency_id: currentCurrency.id,
    }, {
        enabled: !!currentCurrency,
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingTransactions,
        isFetching: isFetchingTransactions,
        data: dataTransactions,
    } = useFetchPayoutHistory({
        ...transactionsParamsProps,
        ...transactionsFilterProps,
    }, {
        keepPreviousData: true,
    })

    const totalSumCurrency = useMemo(() => {
        const { showCode = '', left_currency_symbol_placement: leftSymbolPlacement = false, roundScale } = currentCurrency
        const fractionDigits = getCountFractionDigits(roundScale)
        const priceCurrency = { symbol: showCode, leftSymbolPlacement, isoCode: '' }
        const priceOptions = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
        let sum = 0

        if (dataPayouts) {
            sum = dataPayouts.reduce((acc, item) => {
                return item.convertedBalanceAmount ? acc + item.convertedBalanceAmount : acc
            }, 0)
        }

        return priceFormat(sum, priceCurrency, priceOptions)
    }, [dataPayouts])

    const handlerChangeTab = (id: number) => {
        setActiveTabId(id)
        setIsShowWalletItemTotal(id === TabItems.reports)
    }

    const handlerChangeTransactionsFilter = (values: Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>) => {
        setTransactionsFilterProps(values)
        setTransactions(undefined)
    }

    const handlerChangeCurrency = (data: ICurrency) => {
        setCurrentCurrency(data)
        StorageService.setItem(CLIENT_STORAGE_WALLET_CURRENCY_KEY, data)
    }

    const handlerClickWalletItemTotal = () => {
        if (activeTabId === TabItems.transactions) {
            setActivePayout(undefined)
            setTransactionsParamsProps((prevState) => ({
                ...prevState,
                rewardCurrencyId: undefined,
                offset: 0,
            }))
            setIsShowWalletItemTotal(false)
        }
    }
    /**
     * @deprecated
     */
    const handlerClickReport = (report?: IReport) => {
        // if (report && (report.type === 'folder' || report.type === 'folder_narrow')) {
        //     setReportFolder(report)
        // } else {
        //     setReportFolder(undefined)
        // }
    }

    const handlerClickPayout = (data: IPayout) => {
        setActivePayout(data)
        setTransactionsParamsProps((prevState) => ({
            ...prevState,
            rewardCurrencyId: data.currencyId,
            offset: 0,
        }))
        setTransactions(undefined)
        setIsShowWalletItemTotal(true)
        scrollTop()
    }

    const handlerOnLoadTransactions = ({ page }: { page: number }) => {
        setTransactionsParamsProps((prevState) => ({ ...prevState, offset: LIMIT_TRANSACTIONS * (page + 1) }))
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataTransactions) {
            setTransactions((prevState) => {
                return prevState ? [...prevState, ...dataTransactions] : dataTransactions
            })
        }
    }, [dataTransactions])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                {activePayout ? (
                    <WalletPayoutCurrent
                        classes={style.walletCurrent}
                        data={activePayout}
                    />
                ) : (
                    <WalletNav
                        classes={style.nav}
                        activeTabId={activeTabId}
                        currencyId={currentCurrency.id}
                        onChangeCurrency={handlerChangeCurrency}
                        onChangeTab={handlerChangeTab}
                    >
                        <div className={style.amountSum}>
                            {totalSumCurrency}
                        </div>
                        <div className={style.amountDesc}>
                            {t('payout_total_funds')}
                        </div>
                    </WalletNav>
                )}

                {windowWidth < BREAKPOINTS.desktop && (
                    <div className={style.mobileBar}>
                        {activeTabId === TabItems.transactions && dataPayouts && (
                            <div className={style.payoutsMobile}>
                                {dataPayouts.map((item, index) => (
                                    (!activePayout || item.currencyCode !== activePayout.currencyCode) && (
                                        <WalletItem
                                            classes={style.listItem}
                                            data={item}
                                            convertedCurrency={currentCurrency}
                                            index={index}
                                            key={item.currencyCode}
                                            onClick={handlerClickPayout}
                                        />
                                    )
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {activeTabId === TabItems.transactions && (
                    <div className={style.contentInner}>
                        <ContentBackgroundBottom isWidthGutter />

                        {isLoadingTransactions && (
                            <Loader />
                        )}

                        {dataTransactions && (
                            <Block classes={style.block}>
                                <Block.Header classes={style.blockHeader}>
                                    <div className={style.blockTitle}>
                                        {t('wallet_total_income')}
                                    </div>
                                    <div className={style.blockActions}>
                                        <Button
                                            classes={style.action}
                                            styleType="text"
                                            onClick={() => setIsOpenTransactionsFilter((prevState) => !prevState)}
                                        >
                                            <SvgResource
                                                isImgTag={false}
                                                classes={style.iconCalendar}
                                                resourceKey="wallet_icon_calendar_svg"
                                                width={20}
                                                height={20}
                                            />
                                        </Button>
                                    </div>
                                </Block.Header>
                                <WalletTransactionsFilter
                                    classes={cn(
                                        style.filter,
                                        { [style.filter_updating]: isFetchingTransactions },
                                    )}
                                    isOpen={isOpenTransactionsFilter}
                                    onChange={handlerChangeTransactionsFilter}
                                />

                                {isFetchingTransactions && !isLoadingTransactions && (
                                    <Loader classes={style.loaderTransactions} />
                                )}

                                {transactions && !transactions.length && (
                                    <NoDataInfo classes={style.noDataInfo} text={t('no_data')} />
                                )}

                                <WalletTransactions
                                    isActive={!!transactions}
                                    isHideLoader={dataTransactions && dataTransactions.length < LIMIT_TRANSACTIONS}
                                    classes={style.transactions}
                                    data={transactions}
                                    onLoad={handlerOnLoadTransactions}
                                />
                            </Block>
                        )}
                    </div>
                )}

                {activeTabId === TabItems.reports && (
                    <WalletReports classes={style.reports} onClick={handlerClickReport} />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    <div className={style.list}>
                        {isShowWalletItemTotal && (
                            <WalletWallet
                                classes={style.listItem}
                                amount={totalSumCurrency}
                                onClick={handlerClickWalletItemTotal}
                            />
                        )}

                        {activeTabId === TabItems.transactions && dataPayouts?.map((item, index) => (
                            (!activePayout || item.currencyCode !== activePayout.currencyCode) && (
                                <WalletItem
                                    classes={cn(style.listItem, { [style.listItem_updating]: isFetchingPayoutOptions })}
                                    data={item}
                                    convertedCurrency={currentCurrency}
                                    index={index}
                                    key={item.currencyCode}
                                    onClick={handlerClickPayout}
                                />
                            )
                        ))}
                    </div>
                </SideBarContainer>
            )}
        </>
    )
}

export default Wallet
