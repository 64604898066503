import React from 'react'
import cn from 'classnames'

import { Container } from 'layout'
import style from './NavBar.module.css'

type NavBarPropType = {
    classes?: string
}

const NavBar: React.FC<NavBarPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.navBar, classes)}>
            <Container>
                <nav className={style.nav}>
                    {children}
                </nav>
            </Container>
        </div>
    )
}

export default NavBar
