import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IGoods, IGoodsImage } from 'interfaces'
import { SliderPreviewType } from 'components/Slider/Slider'
import { PRODUCT_TYPE_PROMOTION_DESCRIPTION, SLIDER_ITEM_CLASS } from 'config/app'
import { Block } from 'layout'
import { Button, Slider, SvgResource } from 'components'
import {
    showAlertNotify,
    getImageResizeSrc,
    getStoreCurrency,
    setFirstCharUpper,
    numberFormat,
    priceFormat,
    copyTextClipboard,
} from 'utils/helpers'
import { GoodsImage } from '../index'
import style from './GoodsContent.module.css'

type GoodsContentPropType = {
    data: IGoods
    inBasket: boolean
    onAddToBasket: () => void
}

const DESC_TEXT_CHARS_FOR_HIDE = 390 // ~ 5 rows

const getSliderPreviewImage = (image: IGoodsImage): SliderPreviewType => {
    return {
        id: image.id,
        src: getImageResizeSrc({
            src: image.path,
            width: 80,
            height: 80,
            quality: 90,
        }),
    }
}

const GoodsContent: React.FC<GoodsContentPropType> = ({ data, inBasket, onAddToBasket }) => {
    const { t } = useTranslation()

    const isTypePromotion = useMemo(() => data?.product_type?.id === PRODUCT_TYPE_PROMOTION_DESCRIPTION, [data])
    const currency = useMemo(() => getStoreCurrency(data.store), [data])

    const price = useMemo(() => {
        if (!isTypePromotion && currency?.symbol) {
            return priceFormat(data.price, currency)
        }
        return ''
    }, [data, currency])

    const retailPrice = useMemo(() => {
        if (!isTypePromotion && currency && data?.retail_price && data.retail_price > data.price) {
            return priceFormat(data.retail_price, currency)
        }
        return ''
    }, [data, currency])

    const kicksPrice = useMemo(() => {
        return !isTypePromotion && data?.kicks_price ? numberFormat(data.kicks_price) : ''
    }, [data])

    const priceCV = useMemo(() => {
        return !isTypePromotion && data.price_cv ? numberFormat(data.price_cv) : ''
    }, [data])

    const addToCartControlText = useMemo(() => {
        return inBasket ? setFirstCharUpper(t('market_in_basket')) : t('Add to cart')
    }, [inBasket])

    const [isShowDescControl, setIsShowDescControl] = useState(false)
    const [isShowAllDesc, setIsShowAllDesc] = useState(false)
    const [images, setImages] = useState<IGoodsImage[]>([])
    const [sliderPreviews, setSliderPreviews] = useState<SliderPreviewType[]>([])

    const handlerClickAddToBasket = () => {
        onAddToBasket()
    }

    const handlerClickShowDesc = () => {
        setIsShowDescControl(false)
        setIsShowAllDesc((prevState) => !prevState)
    }

    const handlerClickShare = () => {
        const { sharing_link: link, sharing_text: text } = data.store || {}

        copyTextClipboard(`${link}\n${text}`)
            .then(() => {
                showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
    }

    useEffect(() => {
        if (data?.images?.length) {
            if (data.images.length > 1) {
                const imagesFormat2 = data.images.filter((item) => item.format === 2)

                if (imagesFormat2.length) {
                    setImages(imagesFormat2)
                    setSliderPreviews(imagesFormat2.map((item) => getSliderPreviewImage(item)))
                } else {
                    setImages(data.images)
                    setSliderPreviews(data.images.map((item) => getSliderPreviewImage(item)))
                }
            } else {
                setImages(data.images)
            }
        }
        if (data?.description?.length > DESC_TEXT_CHARS_FOR_HIDE) {
            setIsShowDescControl(true)
        }
    }, [data])

    return (
        <>
            {!!Object.keys(data).length && (
                <Block classes={style.goods}>
                    <div className={style.head}>
                        <div className={style.name}>
                            {data.name}
                        </div>
                        {data.subtitle && (
                            <div className={style.subtitle}>
                                {data.subtitle}
                            </div>
                        )}
                        <div className={style.headControls}>
                            {data?.store?.sharing_link && (
                                <Button
                                    styleType="text"
                                    classes={style.buttonShare}
                                    onClick={handlerClickShare}
                                >
                                    <SvgResource
                                        classes={style.iconShare}
                                        resourceKey="ic_post_share_svg"
                                        width={30}
                                        height={30}
                                    />
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className={style.imageWrap}>
                        {images.length && (
                            images.length > 1 ? (
                                <Slider
                                    previews={sliderPreviews}
                                    previewsPos="center"
                                    navigation={false}
                                    arrows
                                >
                                    {images.map((item, index) => (
                                        <div className={SLIDER_ITEM_CLASS} key={item.id}>
                                            <GoodsImage image={item} setHeight={index === 0} />
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <GoodsImage image={images[0]} />
                            )
                        )}
                    </div>

                    <div className={style.body}>
                        {!isTypePromotion && (
                            <div className={style.prices}>
                                <div className={style.pricesItem}>
                                    <div>
                                        <span className={style.price}>
                                            {/* TODO ? cost */}
                                            {price}
                                        </span>
                                        {retailPrice && (
                                            <span className={style.priceOld}>
                                                {retailPrice}
                                            </span>
                                        )}
                                    </div>
                                    {kicksPrice && (
                                        <div className={cn(style.price, style.price_kicks)}>
                                            {kicksPrice}
                                            {' '}
                                            <SvgResource
                                                classes={style.iconPrice}
                                                resourceKey="kicks_currency_svg"
                                                width={15}
                                                height={17}
                                            />
                                        </div>
                                    )}
                                </div>
                                {priceCV && (
                                    <div className={style.pricesItem}>
                                        <div className={cn(style.price, style.price_cv)}>
                                            {priceCV}
                                            {' '}
                                            {t('market_cv')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {!isTypePromotion && data.is_purchasable && (
                            <div className={style.controls}>
                                <Button
                                    classes={style.addToCartControl}
                                    size="size44"
                                    text={addToCartControlText}
                                    onClick={handlerClickAddToBasket}
                                />
                            </div>
                        )}
                        {!!data.description && (
                            <>
                                <div className={style.descTitle}>
                                    {t('screen_pm_description_title')}
                                </div>
                                <div className={style.desc}>
                                    <div className={cn(style.descText, isShowAllDesc ? style.descText_show : '')}>
                                        {data.description}
                                    </div>
                                    {isShowDescControl && (
                                        <Button
                                            classes={style.descControl}
                                            styleType="text"
                                            text={t('expand')}
                                            onClick={handlerClickShowDesc}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </Block>
            )}
        </>
    )
}

export default GoodsContent
