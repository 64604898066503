import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IUser, IHistoryMessage, IHistoryMessageData } from 'interfaces'
import { REGEXP_STICKER_CODE } from 'config/app'
import { dateTimeFormat } from 'utils/helpers'
import {
    MessengerStatusArrow,
    MessengerMessageContent,
    MessengerMenuMessage,
    MessengerMenuError,
} from 'containers/Messenger/components'
import {
    Loader,
    Avatar,
    Modal,
    Image,
    Video,
} from 'components'
import { useContextMenu } from 'hooks'
import style from './MessengerMessage.module.css'

type MessengerMessagePropType = {
    user: IUser
    data: IHistoryMessageData
    isOwn: boolean
    isAdmin?: boolean // for channel only
    isFocused?: boolean
    onForward: (messageId: string, isSingleForward: boolean) => void
    onReply: (data: IHistoryMessage) => void
    onRetry: (randomId: number) => void
    onDelete: (messageId: string) => void
    onResponse: (messageId: string) => void
}

type TContentMedia = 'IMG' | 'VIDEO'

const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }
const TIME_EXT_OPTIONS: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}

const MessengerMessage = forwardRef<HTMLDivElement, MessengerMessagePropType>(({
    user,
    data,
    isOwn,
    isAdmin,
    isFocused,
    onForward,
    onReply,
    onRetry,
    onDelete,
    onResponse,
}, ref) => {
    const { i18n } = useTranslation()
    const messageRef = useRef<HTMLDivElement>(null)

    const { coords } = useContextMenu({ ref: messageRef })

    const [modalContent, setModalContent] = useState<{ type: TContentMedia, value: string }>()
    const [isShowMenu, setIsShowMenu] = useState(false)
    const [isShowModalContent, setIsShowModalContent] = useState(false)
    const [isLoadingContent, setIsLoadingContent] = useState(false)

    const message = useMemo(() => data.data, [data])
    const meta = useMemo(() => data.meta, [data])

    const timeCreatedAt = useMemo(() => {
        return message.createdAt ? dateTimeFormat(data.data.createdAt, i18n.language, TIME_OPTIONS) : ''
    }, [message])

    const time = useMemo(() => {
        const createdAt = message.createdAt && dateTimeFormat(message.createdAt, i18n.language, TIME_EXT_OPTIONS)
        return `${createdAt}`
    }, [message])

    const isSticker = useMemo(() => !!data.data.text && REGEXP_STICKER_CODE.test(data.data.text), [data])
    const isError = useMemo(() => meta.isError, [meta])
    const isReceived = useMemo(() => (isOwn ? meta.isReceived : false), [meta])
    const isRead = useMemo(() => (isOwn ? meta.isRead : false), [meta])

    const handlerForward = (isSingleForward: boolean = false) => {
        /** forwardedMessageId - fix для корректной отработки на бэке при повторной пересылке сообщения */
        onForward(data.data.forwardedMessageId || data.data.id, isSingleForward)
    }

    const handlerReply = () => {
        onReply(data.data)
    }

    const handlerRetry = () => {
        onRetry(data.data.randomId)
    }

    const handlerDelete = () => {
        onDelete(data.data.id) // TODO ? forwarded
    }

    const handlerCloseMenu = () => {
        setIsShowMenu(false)
    }

    const handlerClickContent = ({ currentTarget }: React.SyntheticEvent, value: string) => {
        setModalContent({ type: currentTarget.tagName as TContentMedia, value })
        setIsLoadingContent(true)
        setIsShowModalContent(true)
    }

    const handlerDownload = () => {
        if (data.data.text) {
            window.fetch(data.data.text)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob)
                    const anchor = document.createElement('a')

                    anchor.setAttribute('download', '')
                    anchor.href = url
                    anchor.click()
                    setTimeout(() => URL.revokeObjectURL(url))
                })
                .catch(() => {
                    //
                })
        }
    }

    useImperativeHandle(ref, () => messageRef.current!, [])

    useEffect(() => {
        if (coords.x && coords.y) {
            setIsShowMenu(true)
        }
    }, [coords])

    return (
        <>
            <div
                className={cn(
                    style.message,
                    {
                        [style.message_sender]: isOwn,
                        [style.message_sender_bg]: isOwn && !isSticker,
                        [style.message_receiver]: !isOwn,
                        [style.message_receiver_bg]: !isOwn && !isSticker,
                        [style.message_focused]: isFocused,
                    },
                )}
                ref={messageRef}
            >
                <MessengerMessageContent
                    classes={style.content}
                    data={message}
                    isOwn={isOwn}
                    onClickResponse={onResponse}
                    onClick={handlerClickContent}
                />
                <div className={style.info}>
                    <div className={style.date} title={time}>
                        {timeCreatedAt}
                    </div>
                    {isError && (
                        <MessengerMenuError
                            classes={style.error}
                            onRetry={handlerRetry}
                            onDelete={handlerDelete}
                        />
                    )}
                    {!isError && (isReceived || isRead) && (
                        <div className={style.status}>
                            {isReceived && (
                                <MessengerStatusArrow />
                            )}
                            {isRead && (
                                <MessengerStatusArrow />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <MessengerMenuMessage
                isOpen={isShowMenu}
                x={coords.x ?? 0}
                y={coords.y ?? 0}
                onForward={handlerForward}
                onReply={handlerReply}
                onDelete={isOwn || isAdmin ? handlerDelete : undefined}
                onClose={handlerCloseMenu}
            />

            <Modal
                classes={style.modalContent}
                classesOverlay={style.modalContentOverlay}
                isOpen={isShowModalContent}
                size="fullWidth"
                topBarContent={(
                    <Modal.TopBarMedia
                        avatar={(
                            <Avatar
                                name={isOwn ? user.name : data.data.senderUser?.name}
                                surname={isOwn ? user.surname : data.data.senderUser?.surname}
                                src={isOwn ? user.photo : data.data.senderUser?.photoFullUrl}
                                width={48}
                                height={48}
                            />
                        )}
                        name={isOwn ? user.name : data.data.senderUser?.name}
                        date={time}
                        onShare={() => {
                            setIsShowModalContent(false)
                            handlerForward(true)
                        }}
                        onDownload={handlerDownload}
                        onClose={() => setIsShowModalContent(false)}
                    />
                )}
                onClose={() => setIsShowModalContent(false)}
            >
                <Modal.Body classes={style.modalContentBody}>
                    {isLoadingContent && (
                        <Loader
                            classes={style.modalContentLoader}
                            position="center"
                        />
                    )}
                    {modalContent && (
                        <>
                            {modalContent.type === 'IMG' && (
                                <Image
                                    classesWrap={cn(
                                        style.modalContentImageWrap,
                                        { [style.modalContentImageWrap_loading]: isLoadingContent },
                                    )}
                                    classesImage={style.modalContentImage}
                                    src={modalContent.value}
                                    onLoad={() => setIsLoadingContent(false)}
                                />
                            )}
                            {modalContent.type === 'VIDEO' && (
                                <Video
                                    controls
                                    autoPlay
                                    classesWrap={cn(
                                        style.modalContentVideoWrap,
                                        { [style.modalContentVideoWrap_loading]: isLoadingContent },
                                    )}
                                    classes={style.modalContentVideo}
                                    isPlayIcon={false}
                                    src={modalContent.value}
                                    onCanPlay={() => setIsLoadingContent(false)}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
})

export default MessengerMessage
