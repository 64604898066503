import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { IMessengerWsForwardMessageProps } from 'interfaces'
import { Block } from 'layout'
import * as userSelectors from 'containers/User/user-selectors'
import { MessengerModalForward } from '../index'
import style from './MessengerContainer.module.css'

type MessengerContainerPropType = {
    isSingleForward?: boolean
    forwardMessageId?: string
    onSendForward?: (value: IMessengerWsForwardMessageProps[]) => void
}

const MessengerContainer: React.FC<MessengerContainerPropType> = ({
    children,
    isSingleForward = false,
    forwardMessageId = '',
    onSendForward,
}) => {
    const user = useSelector(userSelectors.user)

    const [isOpenModalForward, setIsOpenModalForward] = useState(false)

    const handlerSendForward = (value: IMessengerWsForwardMessageProps[]) => {
        if (onSendForward) {
            onSendForward(value)
        }
        setIsOpenModalForward(false)
    }

    useEffect(() => {
        setIsOpenModalForward(!!forwardMessageId)
    }, [forwardMessageId])

    return (
        <>
            <Block classes={style.container}>
                {children}
            </Block>

            {onSendForward && (
                <MessengerModalForward
                    isOpen={isOpenModalForward}
                    isSingleForward={isSingleForward}
                    forwardMessageId={forwardMessageId}
                    user={user}
                    onClose={() => setIsOpenModalForward(false)}
                    onSend={handlerSendForward}
                />
            )}
        </>
    )
}

export default MessengerContainer
