import { ICountryIdentify, IGeolocation } from 'interfaces'
import requestClient from 'utils/requestClient'
import { API_URL } from 'config/api'

type TCountryProps = IGeolocation

type TCountryResponse = ICountryIdentify

/**
 * API country
 */
class CountryService {
    /**
     * Определение страны юзера
     */
    static fetchCountry(params?: TCountryProps) {
        return requestClient<TCountryResponse>(API_URL.country, { params: { lat: params?.lat, lng: params?.lon } })
    }
}

export default CountryService
