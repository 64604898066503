import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import style from './Badge.module.css'

export type BadgePropType = {
    classes?: string
    size?: 'small' | 'medium' | 'large'
    backgroundColor?: string
    color?: string
    animChanged?: boolean
}

const Badge: React.FC<BadgePropType> = ({
    children,
    classes,
    size = 'medium',
    backgroundColor,
    color,
    animChanged,
}) => {
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if (animChanged) {
            setIsChanged(true)
            setTimeout(() => {
                setIsChanged(false)
            }, 1000)
        }
    }, [children])

    return (
        <span
            className={cn(
                style.badge,
                style[`badge_${size}`],
                { [style.badge_changed]: isChanged },
                classes,
            )}
            style={{ color, backgroundColor }}
        >
            {children}
        </span>
    )
}

export default Badge
