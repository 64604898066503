import React, { useEffect, useState } from 'react'

type TCoords = {
    x?: number
    y?: number
}

type TRightClickParams = {
    ref: React.RefObject<any>
}

export default function useContextMenu({ ref }: TRightClickParams) {
    const [coords, setCoords] = useState<TCoords>({ x: undefined, y: undefined })

    const onContextMenu = (e: MouseEvent) => {
        e.preventDefault()
        setCoords({ x: e.pageX, y: e.pageY })
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('contextmenu', onContextMenu)
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('contextmenu', onContextMenu)
            }
        }
    }, [])

    return {
        coords,
    }
}
