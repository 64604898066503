import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ICommunityDefaultImage } from 'interfaces'
import { fetchCommunityDefaultImagesPropType } from 'services/CommunityService'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = fetchCommunityDefaultImagesPropType
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<ICommunityDefaultImage[], TError, ICommunityDefaultImage[], TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    ICommunityDefaultImage[],
    TError,
    ICommunityDefaultImage[],
    ICommunityDefaultImage[],
    TQueryKey
>

const key = 'communityDefaultImages'

/**
 * Хук API получение списка сообществ
 */
export default function useFetchCommunityDefaultImages(params: TFetchFnParams = {}, {
    cacheTime = 3600 * 1000, // 1h
    staleTime = 3600 * 1000, // 1h
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunityDefaultImages(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useFetchInfiniteCommunityDefaultImages(initParams: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return CommunityService.fetchCommunityDefaultImages(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        cacheTime,
        staleTime,
        ...opts,
    })
}
