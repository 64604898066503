import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type TBudget = number

/**
 * API payout
 */
class BudgetService {
    static fetchBudget(): Promise<{ data: TBudget }> {
        return requestClient(API_URL.budget)
    }
}

export default BudgetService
