import {
    IUploadedFileData,
} from 'interfaces'
import { VIDEO_FILE_UPLOAD_LOCATION, OTHER_FILE_UPLOAD_LOCATION } from 'config/app'
import { API_URL } from 'config/api'
import { getId, getFileTypeByMimeType } from 'utils/helpers'
import requestClient from 'utils/requestClient'

export type TChannelMemberListProps = {
    channelId: number
}

export type TChatHistoryProps = {
    userId: number
}

export type TChannelHistoryProps = {
    channelId: number
}

export type TContactsProps = {

}

class SimService {
    static filesUpload(file: File) {
        const fileData = new FormData()
        const { type } = getFileTypeByMimeType(file.type)
        const location = type === 'video' ? VIDEO_FILE_UPLOAD_LOCATION : OTHER_FILE_UPLOAD_LOCATION

        fileData.append('file_id', String(getId(true)))
        fileData.append('location', String(location))
        fileData.append('file_total_parts', '1')
        fileData.append('file_part', '0')
        fileData.append('file_data', file)

        return requestClient<IUploadedFileData>(API_URL.filesUpload, { method: 'post', data: fileData })
    }

    static fetchContacts(params: TContactsProps) {
        return requestClient<any>(API_URL.contactSearch, params)
    }
}

export default SimService
