import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICountry, IPhoneData } from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import { PhoneDataFields, SmsCodeField } from 'enums'
import {
    SMS_TIMER,
    MASK_PLACEHOLDER_CHAR,
    USER_SMS_CODE_MASK_CHARS_COUNT,
    ADMIN_SMS_CODE_MASK_CHARS_COUNT,
} from 'config/app'
import {
    CountryDataSelect,
    Input,
    Button,
    SmsTimer,
} from 'components'
import { maskNormalizer } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './AuthPhoneForm.module.css'

export type FormDataType = IPhoneData & { [SmsCodeField.smsCode]?: string }

export type AuthPhoneFormPropType = {
    classes?: string
    data: FormDataType
    errors: validationFormErrorsType
    country?: ICountry
    countries: ICountry[]
    buttonText: string
    isDisabledPhone: boolean
    isShowSmsConfirm: boolean
    isDisabledSmsConfirm: boolean
    isDisabled: boolean
    onEndSmsTimer: () => void
    onCallAuth: (data: IPhoneData) => void
    onChangeCountry: (data: ICountry) => void
    onChangePhone: (data: string) => void
    onChangeCode: (data: string) => void
    onSubmit: () => void
}

const USER_SMS_CODE_MASK = Array(USER_SMS_CODE_MASK_CHARS_COUNT)
    .fill(MASK_PLACEHOLDER_CHAR)
    .join('')
const ADMIN_SMS_CODE_MASK = Array(ADMIN_SMS_CODE_MASK_CHARS_COUNT - USER_SMS_CODE_MASK_CHARS_COUNT)
    .fill(MASK_PLACEHOLDER_CHAR)
    .join('')

const CODE_MASK = `${maskNormalizer(USER_SMS_CODE_MASK)}[${maskNormalizer(ADMIN_SMS_CODE_MASK)}]`

const AuthPhoneForm: React.FC<AuthPhoneFormPropType> = ({
    classes,
    data,
    errors,
    country,
    countries,
    buttonText,
    isShowSmsConfirm,
    isDisabledPhone,
    isDisabledSmsConfirm,
    isDisabled,
    onEndSmsTimer,
    onCallAuth,
    onChangeCountry,
    onChangePhone,
    onChangeCode,
    onSubmit,
}) => {
    const { [PhoneDataFields.phone]: phone, [SmsCodeField.smsCode]: code } = data

    const { t } = useTranslation()

    const [isEmptyPhoneValue, setIsEmptyPhoneValue] = useState(true)

    const phoneMask = useMemo(() => {
        return country?.mask ? maskNormalizer(country.mask) : undefined
    }, [country])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerAcceptPhone = ({ value }: HTMLInputElement, unmaskedValue: string) => {
        setIsEmptyPhoneValue(!unmaskedValue)
        onChangePhone(value)
    }

    const handlerChangePhone = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        onChangePhone(currentTarget.value)
    }

    const handlerAcceptCode = (el: HTMLInputElement, unmaskedValue: string) => {
        onChangeCode(unmaskedValue)
    }

    const handlerEnterCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handlerClickCall = () => {
        onCallAuth(data)
    }

    return (
        <form className={classes} onSubmit={handlerSubmit}>
            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label className={style.label}>
                    {t('Country code')}
                </label>
                <CountryDataSelect
                    isShowSelectArrow
                    classes={style.field}
                    classesField={style.selectField}
                    countries={countries}
                    selected={country}
                    disabled={!country || isDisabledPhone}
                    onChange={onChangeCountry}
                >
                    {country && (
                        <span className={style.selectText}>
                            {country?.name}
                            {' '}
                            {`(${country?.code})`}
                        </span>
                    )}
                </CountryDataSelect>
            </div>
            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label className={style.label}>
                    {t('Phone number')}
                </label>
                {phoneMask ? (
                    <Input
                        focus
                        classes={cn(style.field, style.inputField, { [style.inputField_filled]: !isEmptyPhoneValue })}
                        styleType="clear"
                        type="tel"
                        name={PhoneDataFields.phone}
                        mask={phoneMask}
                        value={phone}
                        disabled={isDisabledPhone}
                        onAccept={handlerAcceptPhone}
                    />
                ) : (
                    <Input
                        classes={cn(style.field, style.inputField, { [style.inputField_filled]: !isEmptyPhoneValue })}
                        styleType="clear"
                        type="tel"
                        maxLength={30}
                        name={PhoneDataFields.phone}
                        value={phone}
                        disabled={isDisabledPhone}
                        onChange={handlerChangePhone}
                    />
                )}
                {errors?.[PhoneDataFields.phone] && (
                    <div className={styleForm.fieldError}>
                        {errors[PhoneDataFields.phone]}
                    </div>
                )}
            </div>
            {isShowSmsConfirm && (
                <div className={cn(styleForm.row, styleForm.row_32)}>
                    <SmsTimer
                        timer={SMS_TIMER}
                        onTimeEnd={onEndSmsTimer}
                        onClickAction={handlerClickCall}
                    />
                    <div className={style.confirm}>
                        <Input
                            focus
                            classes={cn(
                                style.input,
                                style.inputField,
                                {
                                    [style.inputField_filled]: !!code,
                                    [styleForm.invalid]: errors?.[SmsCodeField.smsCode],
                                },
                            )}
                            name={SmsCodeField.smsCode}
                            inputMode="numeric"
                            autoComplete="off"
                            placeholder={t('SMS-code')}
                            mask={CODE_MASK}
                            value={code}
                            disabled={isDisabledSmsConfirm}
                            onAccept={handlerAcceptCode}
                            onKeyDown={handlerEnterCode}
                        />
                        {errors?.[SmsCodeField.smsCode] && (
                            <div className={cn(style.fieldError_center, styleForm.fieldError)}>
                                {errors[SmsCodeField.smsCode]}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isShowSmsConfirm && (
                <div className={style.privacy}>
                    <div className={style.privacyRow}>
                        {t('privacy_info')}
                    </div>
                    <div className={style.privacyRow}>
                        <a
                            className={style.privacyLink}
                            href={t('privacy_policy_link')}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('privacy_policy_title')}
                        </a>
                    </div>
                    <div className={style.privacyRow}>
                        <a
                            className={style.privacyLink}
                            href={t('user_agreement_link')}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('user_agreement_title')}
                        </a>
                    </div>
                </div>
            )}
            {(!isShowSmsConfirm || !isDisabled) && (
                <div className={style.controls}>
                    <Button
                        textUpper
                        type="submit"
                        size="size44"
                        text={buttonText}
                        disabled={isDisabled}
                    />
                </div>
            )}
        </form>
    )
}

export default AuthPhoneForm
