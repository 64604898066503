import { Chart, ChartTypeRegistry, Plugin } from 'chart.js'

export type NoDataTextOptions = {
    enabled: boolean
    text: string
    font?: string
    fontColor?: string
}

const defaults = {
    text: 'No data',
    font: 'bolder 20px sans-serif',
    fontColor: '#000',
}

const noDataText: Plugin = {
    id: 'noDataText',
    afterDraw(chart: Chart<keyof ChartTypeRegistry>, args: {}, options: NoDataTextOptions) {
        const { ctx, chartArea } = chart
        const { width, height } = chartArea

        if (options.enabled) {
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.font = options.font ?? defaults.font
            ctx.fillStyle = options.fontColor ?? defaults.fontColor
            ctx.fillText(options.text ?? defaults.text, width / 2, height / 2)
            ctx.restore()
        }
    },
}

export default noDataText
