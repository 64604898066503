import React from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorMsg } from 'components'
import style from './MessengerNothing.module.css'

type MessengerNothingPropType = {
    nothingText?: string
}

const MessengerNothing: React.FC<MessengerNothingPropType> = ({ nothingText }) => {
    const { t } = useTranslation()

    return (
        <ErrorMsg
            classes={style.nothing}
            error={nothingText ?? t('nothing_found')}
        />
    )
}

export default MessengerNothing
