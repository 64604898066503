import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarPost } from 'interfaces'
import { Button } from 'components'
import { dateTimeFormat, getItemFromArray } from 'utils/helpers'
import style from './CalendarDayEvent.module.css'

type CalendarDayEventPropType = {
    styles?: React.CSSProperties
    date: Date
    data: ICalendarPost
    colors: string[]
    gridItemHeight: number
    onClickEvent: (value: ICalendarPost) => void
}

const GRID_ITEM_CONTENT_GAP = 1
const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }

const CalendarDayEvent: React.FC<CalendarDayEventPropType> = ({
    styles = {},
    date,
    data,
    colors,
    gridItemHeight,
    onClickEvent,
}) => {
    const { i18n } = useTranslation()

    const dateValues = useMemo(() => ({
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
    }), [date])

    const color = useMemo(() => {
        return getItemFromArray(colors, data.id)
    }, [data])

    const time = useMemo(() => {
        const from = dateTimeFormat(data.calendar_date_from, i18n.language, TIME_OPTIONS)
        const to = dateTimeFormat(data.calendar_date_to, i18n.language, TIME_OPTIONS)

        return `${from}-${to}`
    }, [data, i18n.language])

    const gridItemContentHeight = useMemo(() => {
        return gridItemHeight - (GRID_ITEM_CONTENT_GAP * 2)
    }, [gridItemHeight])

    const height = useMemo(() => {
        const { year, month, day } = dateValues
        const dateFrom = new Date(data.calendar_date_from)
        const dateTo = new Date(data.calendar_date_to)
        const dateStart = new Date(year, month, day, dateFrom.getHours(), dateFrom.getMinutes())
        const dateEnd = new Date(year, month, day, dateTo.getHours(), dateTo.getMinutes())
        const dateDiff = dateEnd.getTime() - dateStart.getTime()
        const duration = dateDiff / (60 * 1000)
        const factor = duration / 60

        if (factor < 0.5) {
            return 0.5 * gridItemContentHeight
        }
        if (factor > 1) {
            return factor * gridItemContentHeight + factor * GRID_ITEM_CONTENT_GAP
        }

        return factor * gridItemContentHeight + GRID_ITEM_CONTENT_GAP
    }, [gridItemContentHeight])

    const handlerClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onClickEvent(data)
    }

    return (
        <Button
            classes={style.event}
            style={{ ...styles, height, color }}
            title={time}
            styleType="transparent"
            onClick={handlerClick}
        >
            <span className={cn(style.text, { [style.text_oneLine]: height <= 0.5 * gridItemHeight })}>
                {data.title}
            </span>
        </Button>
    )
}

export default CalendarDayEvent
