import { ThunkAction } from 'redux-thunk'

import {
    IRootState,
    ISettings,
    ICityProps,
    IFlashMessage,
    ICountryIdentify,
    ITranslation,
    ICountry,
    ICurrency,
    IGeolocation,
    IDarkDoorProps,
} from 'interfaces'
import { AppSettings } from 'enums'
// eslint-disable-next-line
import {
    addModalActionType,
    removeModalActionType,
    setSettingsActionType,
    updateSettingsActionType,
    setDarkDoorActionType,
    setGeolocationActionType,
    fetchLanguagesActionType,
    fetchCountriesActionType,
    fetchResourcesActionType,
    fetchTranslationsActionType,
    setTranslationsActionType,
    fetchCitiesActionType,
    fetchStickersActionType,
    fetchCurrenciesActionType,
    fetchCountryIdentifyActionType,
    addMessageActionType,
    removeMessageActionType,
    removeAllMessagesActionType,
} from './app-actions-type'
import { COUNTRY_ID_DEFAULT } from 'config/app'
import {
    ADD_MODAL,
    REMOVE_MODAL,
    SET_SETTINGS,
    UPDATE_SETTINGS,
    SET_DARK_DOOR,
    FETCH_LANGUAGES,
    FETCH_COUNTRIES,
    FETCH_RESOURCES,
    FETCH_TRANSLATIONS,
    SET_TRANSLATIONS,
    FETCH_CITIES,
    FETCH_STICKERS,
    FETCH_CURRENCIES,
    SET_COUNTRY_IDENTIFY,
    ADD_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_MESSAGES,
    SET_GEOLOCATION,
} from 'reducers/types'
import {
    AppService,
    AuthService,
    DirectoryService,
    TranslationService,
    CountryService,
    UserService,
} from 'services'
import { showAlertNotify } from 'utils/helpers'
import errorLog from 'utils/errorLog'
import i18n from 'i18n'

export function addModal(id: number): addModalActionType {
    return {
        type: ADD_MODAL,
        payload: id,
    }
}

export function removeModal(id: number): removeModalActionType {
    return {
        type: REMOVE_MODAL,
        payload: id,
    }
}

export function setSettings(settings: ISettings): setSettingsActionType {
    return {
        type: SET_SETTINGS,
        payload: settings,
    }
}

export function updateSettings(id: keyof typeof AppSettings, value: any): updateSettingsActionType {
    return {
        type: UPDATE_SETTINGS,
        payload: value,
        meta: { id },
    }
}

/** AlertFlash show */
export function addMessage(message: IFlashMessage): addMessageActionType {
    return {
        type: ADD_MESSAGE,
        payload: message,
    }
}

/** AlertFlash hide */
export function removeMessage(id: string): removeMessageActionType {
    return {
        type: REMOVE_MESSAGE,
        payload: id,
    }
}

/** AlertFlash hide all */
export function removeAllMessages(): removeAllMessagesActionType {
    return {
        type: REMOVE_MESSAGES,
    }
}

/**
 * Detected user country
 * @param data
 */
export function setCountryIdentify(data: ICountryIdentify) {
    return {
        type: SET_COUNTRY_IDENTIFY,
        payload: data,
    }
}

export function setTranslations(data: ITranslation): setTranslationsActionType {
    return {
        type: SET_TRANSLATIONS,
        payload: data,
    }
}

export function setDarkDoor({ token, company_id }: IDarkDoorProps): setDarkDoorActionType {
    AuthService.saveDarkDoorAuthData({ access_token: token })

    return {
        type: SET_DARK_DOOR,
        payload: { company_id },
    }
}

export function getCurrentPosition(): ThunkAction<Promise<void>, IRootState, unknown, setGeolocationActionType> {
    return async (dispatch) => {
        AppService.getCurrentPosition()
            .then((data) => {
                if (data) {
                    dispatch({ type: SET_GEOLOCATION, payload: data })
                }
            })
            .catch((err) => {
                errorLog('getCurrentPosition', err)
            })
    }
}

export function fetchLanguages(): ThunkAction<Promise<void>, IRootState, unknown, fetchLanguagesActionType> {
    return async (dispatch) => {
        DirectoryService.fetchLanguages()
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_LANGUAGES, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchLanguages', err)
            })
    }
}

export function fetchCountries(): ThunkAction<Promise<void>, IRootState, unknown, fetchCountriesActionType> {
    return async (dispatch) => {
        DirectoryService.fetchCountries()
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_COUNTRIES, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchCountries', err)
            })
    }
}

export function fetchResources(): ThunkAction<Promise<void>, IRootState, unknown, fetchResourcesActionType> {
    return async (dispatch) => {
        DirectoryService.fetchResources()
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_RESOURCES, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchResources', err)
            })
    }
}

export function fetchTranslations({ date }: { date?: number })
    : ThunkAction<Promise<void>, IRootState, unknown, fetchTranslationsActionType> {
    return async (dispatch) => {
        return TranslationService.fetchTranslations({ date })
            .then(({ data }) => {
                const { date: newDate, translations } = data || {}

                if (newDate && translations && Object.keys(translations).length) {
                    dispatch({
                        type: FETCH_TRANSLATIONS,
                        payload: {
                            date: newDate,
                            translations,
                        },
                    })
                }
            })
            .catch((err) => {
                errorLog('fetchTranslations', err)
            })
    }
}

export function fetchCities(params: ICityProps)
    : ThunkAction<Promise<void>, IRootState, unknown, fetchCitiesActionType> {
    return async (dispatch) => {
        DirectoryService.fetchCities(params)
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_CITIES, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchCities', err)
            })
    }
}

export function fetchStickers(): ThunkAction<Promise<void>, IRootState, unknown, fetchStickersActionType> {
    return async (dispatch) => {
        AppService.fetchStickers()
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_STICKERS, payload: data })
                }
            })
            .catch((err) => {
                errorLog('fetchStickers', err)
            })
    }
}

export function fetchCurrencies(): ThunkAction<Promise<ICurrency[]>, IRootState, unknown, fetchCurrenciesActionType> {
    return (dispatch) => {
        return DirectoryService.fetchCurrencies()
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    dispatch({ type: FETCH_CURRENCIES, payload: data })
                }
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

/**
 * Получение страны неавторизованного юзера
 */
export function fetchCountryIdentify()
    : ThunkAction<Promise<void>, IRootState, unknown, fetchCountryIdentifyActionType> {
    return async (dispatch, getState: () => IRootState) => {
        const state = getState()

        const getCountryByPosition = (coords: IGeolocation) => {
            CountryService.fetchCountry(coords)
                .then(successCallback)
                .catch(errorCallback)
        }

        const getCountryDefault = (): Promise<ICountry> => {
            const { app: { countries } } = state
            const findCountry = (data: ICountry[] = []) => {
                const phoneCountryId = UserService.getPhoneData()?.id
                const countryId = phoneCountryId || COUNTRY_ID_DEFAULT

                return data.find((item) => item.id === countryId) || data[0]
            }

            return new Promise((resolve, reject) => {
                if (countries.length) {
                    resolve(findCountry(countries))
                } else {
                    DirectoryService.fetchCountries()
                        .then(({ data }) => {
                            if (data?.length) {
                                resolve(findCountry(data))
                            } else {
                                reject()
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        })
                }
            })
        }

        function successCallback({ data }: { data: ICountryIdentify }) {
            if (data?.country) {
                TranslationService.changeLanguage(data.country.lang)
                dispatch({ type: SET_COUNTRY_IDENTIFY, payload: data })
            } else {
                errorCallback()
            }
        }

        function errorCallback() {
            getCountryDefault()
                .then((country) => {
                    successCallback({ data: { country, source: '' } })
                })
                .catch((err) => {
                    // TODO if !country show empty phone field
                    showAlertNotify({
                        type: 'error',
                        message: typeof err?.message === 'string' ? err.message : i18n.t('update_error'),
                    })
                })
        }

        CountryService.fetchCountry()
            .then(successCallback)
            .catch((err) => {
                // Страна не определена
                if (err?.response?.status === 404) {
                    if (state.geolocation) {
                        getCountryByPosition(state.geolocation)
                    } else {
                        AppService.getCurrentPosition()
                            .then((coords) => {
                                getCountryByPosition(coords)
                            })
                            .catch(() => {
                                errorCallback()
                            })
                    }
                } else {
                    errorCallback()
                }
            })
    }
}
