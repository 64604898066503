import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb, IMlmProduct } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod, AppScheme, AppTitle } from 'enums'
import { APP_URL } from 'config/app'
import { API_HOST } from 'config/api'
import { CardContainer, Block } from 'layout'
import { ReportsFreedomChart } from 'containers/ReportsFreedom/components'
import { useFetchMlmReportsTurnoverGraph, useFetchMlmReportsTurnoverProducts } from 'containers/ReportsFreedom/hooks'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    DateRangeFilter,
    InfiniteScroll,
    UserListItem,
    NoDataInfo,
} from 'components'
import { numberFormat } from 'utils/helpers'
import { ReportsFreedomTurnoverListItemContent } from './components'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'
import style from './ReportsFreedomTurnover.module.css'

const ReportsFreedomTurnover: React.FC = () => {
    const { state }: { state?: { type?: keyof typeof AppScheme } } = useLocation()
    const { t } = useTranslation()

    const [type] = useState<keyof typeof AppScheme>(state?.type ?? AppScheme.pv)
    const [productsList, setProductsList] = useState<IMlmProduct[]>([])
    const [turnoverListTitle, setTurnoverListTitle] = useState('')
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)

    const [datePeriodTurnover, setDatePeriodTurnover] = useState<DatePeriod>()
    const [dateFilterTurnover, setDateFilterTurnover] = useState<DatePairType>()
    const [dateFilterProducts, setDateFilterProducts] = useState<DatePairType>()

    const [isResetProductsListPrevData, setIsResetProductsListPrevData] = useState(false)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: AppTitle[type] },
        { id: 3, name: t('mlm_privilege_turnover') },
    ])

    const {
        isInitialLoading: isLoadingTurnover,
        isFetching: isFetchingTurnover,
        data: dataReportsFreedomTurnoverGraph,
    } = useFetchMlmReportsTurnoverGraph({
        type,
        dateFrom: dateFilterTurnover?.dateFrom,
        dateTo: dateFilterTurnover?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingTurnoverProducts,
        data: dataTurnoverProducts,
    } = useFetchMlmReportsTurnoverProducts({
        type,
        dateFrom: dateFilterProducts?.dateFrom,
        dateTo: dateFilterProducts?.dateTo,
        limit,
        offset,
    }, {
        enabled: !!dateFilterProducts,
    })

    const chartTotalActivePartners = useMemo(() => {
        const { total } = dataReportsFreedomTurnoverGraph || {}
        return total !== undefined ? numberFormat(total) : undefined
    }, [dataReportsFreedomTurnoverGraph])

    const handlerChangeDateFilterTurnover = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterTurnover(dates)
        setDatePeriodTurnover(period)
    }

    const handlerChangeDateFilterProducts = (dates: DatePairType) => {
        setDateFilterProducts(dates)
        resetUserList()
    }

    const handlerOnLoad = () => {
        setOffset((prevSate) => prevSate + limit)
    }

    function resetUserList() {
        setIsResetProductsListPrevData(true)
        setOffset(0)
        setIsHideInfiniteLoader(false)
    }

    useEffect(() => {
        if (dataTurnoverProducts) {
            const { title, data } = dataTurnoverProducts
            const list = isResetProductsListPrevData ? data : [...productsList, ...data]

            setTurnoverListTitle(title)
            setProductsList(list)
            setIsResetProductsListPrevData(false)

            if (data.length < limit) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataTurnoverProducts])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {dataReportsFreedomTurnoverGraph?.title}
                    </div>
                </Block.Header>
                <DateRangeFilter
                    activeColor={dataReportsFreedomTurnoverGraph?.color}
                    onChange={handlerChangeDateFilterTurnover}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingTurnover },
                    )}
                    style={{ color: dataReportsFreedomTurnoverGraph?.color }}
                >
                    {chartTotalActivePartners}
                </div>
                {isLoadingTurnover && (
                    <Loader />
                )}
                {dataReportsFreedomTurnoverGraph && (
                    <ReportsFreedomChart
                        data={dataReportsFreedomTurnoverGraph}
                        period={datePeriodTurnover}
                        isLoading={isFetchingTurnover}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {turnoverListTitle}
                    </div>
                </Block.Header>
                <DateRangeFilter
                    activeColor={dataReportsFreedomTurnoverGraph?.color}
                    onChange={handlerChangeDateFilterProducts}
                />
                <CardContainer classes={style.list}>
                    <InfiniteScroll
                        isActive={!isLoadingTurnoverProducts}
                        options={{ rootMargin: '0px 0px 50% 0px' }}
                        isHideLoader={isHideInfiniteLoader}
                        onLoad={handlerOnLoad}
                    >
                        {productsList.map((item) => (
                            <UserListItem
                                classes={style.item}
                                classesBody={style.body}
                                name=""
                                surname=""
                                photo={item.icon ? `${API_HOST}${item.icon}` : undefined}
                                online={false}
                                avatarPos="middle"
                                avatarSize={50}
                                key={item.id}
                                content={(
                                    <ReportsFreedomTurnoverListItemContent
                                        data={item}
                                        key={item.id}
                                    />
                                )}
                            />
                        ))}
                        {!isLoadingTurnoverProducts && !productsList.length && (
                            <NoDataInfo
                                classes={styleReports.listNoData}
                                text={t('no_rewards_in_period')}
                            />
                        )}
                    </InfiniteScroll>
                </CardContainer>
            </Block>
        </>
    )
}

export default ReportsFreedomTurnover
