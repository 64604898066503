import React, { useMemo } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { IStoreProfile } from 'interfaces'
import { Block } from 'layout'
import { ImgResource } from 'components'
import style from './StoreProfileBonus.module.css'

type StoreProfileBonusPropType = {
    profile?: IStoreProfile
}

const StoreProfileBonus: React.FC<StoreProfileBonusPropType> = ({ profile }) => {
    const { t } = useTranslation()

    const isShowBanner = useMemo(() => {
        return !!(profile
            && profile.rank
            && 'kick_loyalty_settings' in profile
            && 'kick_max_loyalty_settings' in profile
            && 'kick_loyalty_settings_user' in profile
            && 'kick_max_loyalty_settings_user' in profile)
    }, [profile])

    return (
        <>
            {isShowBanner && (
                <Block classes={style.block}>
                    <div className={cn(style.column, style.column_vAlignCenter, style.info)}>
                        <div className={cn(style.row, style.row_double, style.text)}>
                            {t('kick_loyalty_settings_banner_base')}
                        </div>
                        <div className={cn(style.row, style.row_double, style.text)}>
                            {t('kick_loyalty_settings_status_banner')}
                        </div>
                        <div className={cn(style.row, style.row_double, style.text)}>
                            {t('kick_loyalty_settings_banner_base_kickback')}
                        </div>
                    </div>
                    <div className={cn(style.column, style.column_vAlignCenter, style.base)}>
                        <ImgResource
                            isShowLoader={false}
                            resourceKey="kick_loyalty_settings_banner_icon"
                            classes={style.bgImage}
                        />
                        <div className={style.row}>
                            {profile && (
                                <span className={cn(style.text, style.badge, style.badge_yellow)}>
                                    {t('kicks_cpa_up_to')}
                                    {' '}
                                    {profile.kick_loyalty_settings}
                                </span>
                            )}
                        </div>
                        <div className={style.row}>
                            {profile && (
                                <span className={cn(style.text, style.badge, style.badge_blue)}>
                                    {profile.rank.bonus}
                                </span>
                            )}
                        </div>
                        <div className={style.row}>
                            {profile && (
                                <span className={style.text}>
                                    {t('kicks_cpa_up_to')}
                                    {' '}
                                    {profile.kick_max_loyalty_settings}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={cn(style.column, style.column_vAlignCenter, style.user)}>
                        <div className={cn(style.row, style.text, style.text_small)}>
                            {t('kick_loyalty_settings_user_banner')}
                        </div>
                        <div className={cn(style.row, style.text, style.text_large)}>
                            {profile && (
                                <>
                                    {t('kicks_cpa_up_to')}
                                    {' '}
                                    {profile.kick_loyalty_settings_user}
                                </>
                            )}
                        </div>
                        <div className={cn(style.row, style.text)}>
                            {profile && (
                                <>
                                    {t('kick_loyalty_settings_banner_kickback')}
                                    {' '}
                                    {profile.kick_max_loyalty_settings_user}
                                </>
                            )}
                        </div>
                    </div>
                </Block>
            )}
        </>
    )
}

export default StoreProfileBonus
