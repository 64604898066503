import React from 'react'
import cn from 'classnames'

import { CustomSelect } from 'components'
import style from './DateRangeFilterPreviewSelect.module.css'

export type DateRangeFilterOption = {
    id: number
    value: string
}

type DateRangeFilterPreviewSelectPropType = {
    value: string
    options: DateRangeFilterOption[]
    activeColor?: string
    onChange: (id: number) => void
}

const DateRangeFilterPreviewSelect: React.FC<DateRangeFilterPreviewSelectPropType> = ({
    value,
    options,
    activeColor,
    onChange,
}) => {
    return (
        <CustomSelect.Select>
            <CustomSelect.Field classes={style.field} showArrow={false}>
                <span className={style.fieldText}>
                    {value}
                </span>
            </CustomSelect.Field>
            <CustomSelect.Options>
                {options.map((item) => (
                    <CustomSelect.Option
                        classes={cn(
                            style.option,
                            { [style.option_active]: item.value === value },
                        )}
                        styles={item.value === value && activeColor ? { color: activeColor } : undefined}
                        id={item.id}
                        key={item.id}
                        onClick={onChange}
                    >
                        <span className={style.optionText}>
                            {item.value}
                        </span>
                    </CustomSelect.Option>
                ))}
            </CustomSelect.Options>
        </CustomSelect.Select>
    )
}

export default DateRangeFilterPreviewSelect
