import { IReport } from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type fetchReportsPropType = {
    displayFull?: boolean
    count?: boolean
}

export type fetchReportPropType = {
    id: string
    displayFull?: boolean
    count?: boolean
}

export type fetchReportsFilterValuePropType = {
    ids: string[]
}

class ReportService {
    static fetchReports(params: fetchReportsPropType = {}): Promise<{ data: IReport[] }> {
        return requestClient(API_URL.reports, { params })
    }

    static fetchReport({ id, ...params }: fetchReportPropType): Promise<{ data: IReport[] }> {
        return requestClient(`${API_URL.reports}/${id}`, { params })
    }

    static fetchReportsFilterValue({ ids }: fetchReportsFilterValuePropType): Promise<{ data: IReport[] }> {
        return requestClient(API_URL.reportsSelected, { params: { id: ids } })
    }
}

export default ReportService
