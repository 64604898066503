import { IStorageCount } from 'interfaces'
import { StorageService } from 'services'
import { getDateInstance } from 'utils/helpers'

const isExpiredDateFromYesterday = (date: string | number | Date): boolean => {
    const datePrev = getDateInstance(date)
    const dateCurrent = getDateInstance()

    return datePrev.getDate() !== dateCurrent.getDate()
}

const getCount = (storageKey: string, value: number) => {
    const { value: prevCount } = StorageService.getItem<IStorageCount | null>(storageKey) || {}
    return prevCount !== undefined ? value - prevCount : value
}

const saveCount = (storageKey: string, value: number) => {
    const dateCurrent = new Date()
    const dateCurrentTime = dateCurrent.getTime()
    const { date: prevDateTime, value: prevCount } = StorageService.getItem<IStorageCount | null>(storageKey) || {}

    const isSetData = !!prevDateTime && prevCount !== undefined
    const isExpired = isSetData && isExpiredDateFromYesterday(prevDateTime!)
    const isNewMonth = isSetData && (new Date(prevDateTime!)).getMonth() !== (dateCurrent.getMonth())

    if (!isSetData || isExpired) {
        StorageService.setItem(storageKey, { date: dateCurrentTime, value })
    }
    if (isNewMonth) {
        StorageService.setItem(storageKey, { date: dateCurrentTime, value: 0 })
    }
}

/**
 * Hook сохранения/получения счетчика на клиенте, обновляется при устаревании
 */
export default function useStorageCount() {
    return {
        getCount,
        saveCount,
    }
}
