import errorLog from 'utils/errorLog'

type storageType = 'local' | 'session'

/**
 * Client-side storage
 */
class StorageService {
    static setItem(key: string, value: any, storage: storageType = 'local'): void {
        try {
            window[`${storage}Storage`].setItem(key, JSON.stringify(value))
        } catch (e) {
            if (e instanceof Error && e.name === 'QUOTA_EXCEEDED_ERR') {
                errorLog('localStorage QUOTA_EXCEEDED_ERR')
            }
        }
    }

    static getItem<T>(key: string, parse: boolean = true, storage: storageType = 'local'): null | T {
        const data = window[`${storage}Storage`].getItem(key)

        if (data) {
            if (parse) {
                try {
                    return JSON.parse(data) as T
                } catch (e) {
                    errorLog('StorageService getItem', e)
                    return null
                }
            }

            return data as T
        }

        return null
    }

    static removeItem(key: string, storage: storageType = 'local'): void {
        window[`${storage}Storage`].removeItem(key)
    }

    static clear(storage: storageType = 'local') {
        window[`${storage}Storage`].clear()
    }
}

export default StorageService
