import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { CustomSelect } from '../../../../components'

import style from '../../StoreOrderMake.module.css'
import styleForm from '../../../../styles/modules/form.module.css'
import styleInput from '../../../../components/Input/Input.module.css'

export type StoreOrderMakeFormPayDatesPropType = {
    payDates?: string[]
    error?: string | boolean
    onChange: (date: string) => void
}

type datesType = {
    index: number
    value: string
}

const StoreOrderMakePayDates: React.FC<StoreOrderMakeFormPayDatesPropType> = ({
    payDates,
    error,
    onChange,
}) => {
    const { t } = useTranslation()

    const isShow = useMemo(() => !!payDates?.length && payDates.length > 1, [payDates])

    const [dates, setDates] = useState<datesType[]>()
    const [selected, setSelected] = useState<string>('')

    const handlerChange = (index: number) => {
        const date = dates?.find((item) => item.index === index)
        const { value } = date || {}

        if (value) {
            setSelected(value)
            onChange(value)
        }
    }

    useEffect(() => {
        if (payDates?.length) {
            if (payDates.length > 1) {
                setDates(payDates.map((item, index) => ({ index, value: item })))
            } else { // auto select
                onChange(payDates[0])
            }
        }
    }, [payDates])

    return (
        <>
            {isShow && (
                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <div className={cn(styleForm.fieldHead, style.fieldLabel)}>
                        {t('select_date')}
                    </div>
                    <div className={style.field}>
                        <CustomSelect.Select>
                            <CustomSelect.Field
                                classes={cn(
                                    styleInput.gray,
                                    style.fieldSelect,
                                    {
                                        [styleForm.invalid]: error,
                                    },
                                )}
                            >
                                {selected || t('select_date')}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {dates?.map((item) => (
                                    <CustomSelect.Option
                                        id={item.index}
                                        key={item.index}
                                        onClick={handlerChange}
                                    >
                                        {item.value}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    </div>
                    <div className={styleForm.error}>
                        {error}
                    </div>
                </div>
            )}
        </>
    )
}

export default StoreOrderMakePayDates
