import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IStoreCurrency } from 'interfaces'
import { MARKET_ORDER_STATUS_READY_PAYMENT } from 'config/app'
import { Button } from 'components'
import { dateTimeFormat, priceFormat } from 'utils/helpers'
import style from './StoreOrdersItem.module.css'

type StoreOrdersItemPropType = {
    data: IOrder
    currency?: IStoreCurrency
    onClickPayment: (id: number) => void
}

const ORDER_STATUS_SHOW = [20, 30, 40, 50, 60]
const ORDER_DELIVERY_STATUS_EXCLUDE = [100]

const StoreOrdersItem: React.FC<StoreOrdersItemPropType> = ({
    data,
    currency,
    onClickPayment,
}) => {
    const {
        id,
        market_order_status_name: orderStatus,
        payment_status: paymentStatus,
        invoice_link: invoiceLink,
    } = data
    const { t, i18n } = useTranslation()

    const statusId = useMemo(() => {
        const { market_order_status_id: orderStatusId } = data

        if (orderStatusId && ORDER_STATUS_SHOW.includes(orderStatusId)) {
            return orderStatusId
        }

        return 0
    }, [data])

    const statusesDelivery = useMemo(() => {
        const { track_info } = data

        if (track_info?.length) {
            return track_info.filter((item) => {
                return !ORDER_DELIVERY_STATUS_EXCLUDE.includes(item.status) && item.track_number
            })
        }

        return []
    }, [data])

    const date = useMemo(() => dateTimeFormat(data.create_date, i18n.language), [data])
    const time = useMemo(() => dateTimeFormat(data.create_date, i18n.language, { hour: '2-digit', minute: '2-digit', second: '2-digit' }), [data])
    const price = useMemo(() => priceFormat(data.order_sum, currency), [data, currency])

    const handlerClickPayment = () => {
        if (id) {
            onClickPayment(id)
        }
    }

    return (
        <>
            <tr className={style.row}>
                <td className={style.col}>
                    {id}
                </td>
                <td className={style.col}>
                    {!!statusId && (
                        <div className={cn(style.status, style[`status_${statusId}`])}>
                            {orderStatus}
                        </div>
                    )}
                    {statusesDelivery.map((item) => (
                        <div className={cn(style.status, style.status_default)} key={item.track_number}>
                            {item.track_number}
                            {' - '}
                            {item.message}
                        </div>
                    ))}
                </td>
                <td className={style.col} title={time}>
                    {date}
                </td>
                <td className={style.col}>
                    <div className={style.price}>
                        {price}
                    </div>
                    {paymentStatus === MARKET_ORDER_STATUS_READY_PAYMENT && (
                        <div className={cn(style.text, style.text_action, style.text_danger)}>
                            {t('buy_fail')}
                        </div>
                    )}
                </td>
                <td className={style.col}>
                    {paymentStatus === MARKET_ORDER_STATUS_READY_PAYMENT && (
                        <Button
                            classes={cn(style.text, style.text_action, style.link)}
                            styleType="text"
                            text={t('market_order_purchase_button')}
                            onClick={handlerClickPayment}
                        />
                    )}
                    {invoiceLink && (
                        <a
                            className={cn(style.text, style.text_action, style.link)}
                            href={invoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('item_market_order_invoice_url_title')}
                        </a>
                    )}
                </td>
            </tr>
            <tr>
                <td className={cn(style.col, style.col_bordered)} colSpan={5}>
                    {data.content.map((item, index) => (
                        <React.Fragment key={item.goods.id}>
                            {index > 0 && (', ')}
                            <span className={style.product}>
                                {`${item.goods.name} (${item.quantity})`}
                            </span>
                        </React.Fragment>
                    ))}
                </td>
            </tr>
        </>
    )
}

export default StoreOrdersItem
