import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IPostLike } from 'interfaces'
import { TPostLikesProps } from 'services/CommunityService'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'

export type TFetchFnParams = TPostLikesProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IPostLike[], TError, IPostLike[], TQueryKey>

const key = 'post-likes'

/**
 * Хук API получение списка лайков поста
 */
export default function useFetchPostLikes(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchPostLikes(params)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
