import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IFriendsRequest } from 'interfaces'
import { APP_URL } from 'config/app'
import { Badge, Button } from 'components'
import { FriendsUserRequestItem } from 'containers/Friends/components'
import { vPrimary1 } from 'styles/modules/variables.module.css'
import style from './FriendsRequests.module.css'

type FriendsRequestsPropType = {
    data: IFriendsRequest
    maxItemsShow?: number
    isUpdatingFriendsRequestId?: number
    isMobileView?: boolean
    onFriendRequestConfirm: (id?: number) => void
    onFriendRequestReject: (id: number) => void
}

const FriendsRequests: React.FC<FriendsRequestsPropType> = ({
    data,
    maxItemsShow = 5,
    isUpdatingFriendsRequestId,
    isMobileView,
    onFriendRequestConfirm,
    onFriendRequestReject,
}) => {
    const { t } = useTranslation()

    const [isLoadingAcceptFriendRequests, setIsLoadingAcceptFriendRequests] = useState(true)

    const handlerAcceptFriendRequests = () => {
        setIsLoadingAcceptFriendRequests(false)
        onFriendRequestConfirm()
    }

    useEffect(() => {
        if (isLoadingAcceptFriendRequests) {
            setIsLoadingAcceptFriendRequests(false)
        }
    }, [data])

    return (
        <>
            <div className={cn(style.header, { [style.header_mobile]: isMobileView })}>
                <NavLink
                    className={cn(style.title, { [style.title_mobile]: isMobileView })}
                    to={APP_URL.friendsRequest}
                >
                    {t('friend_requests_title')}
                </NavLink>
                <div className={style.count}>
                    <Badge size="small" backgroundColor={vPrimary1}>
                        {data.total}
                    </Badge>
                </div>
                {isMobileView && (
                    <NavLink
                        className={cn(style.showAllLink, { [style.showAllLink_mobile]: isMobileView })}
                        to={APP_URL.friendsRequest}
                    >
                        {t('show_all_button_title')}
                    </NavLink>
                )}
            </div>
            {data.list.map((item, index) => (
                index < maxItemsShow && (
                    <FriendsUserRequestItem
                        isLoading={item.id === isUpdatingFriendsRequestId}
                        isMobileView={isMobileView}
                        data={item}
                        key={item.id}
                        onFriendRequestConfirm={onFriendRequestConfirm}
                        onFriendRequestReject={onFriendRequestReject}
                    />
                )
            ))}
            {!isMobileView && data.total > maxItemsShow && (
                <>
                    <div className={style.showAll}>
                        <NavLink className={style.showAllLink} to={APP_URL.friendsRequest}>
                            {t('friend_requests_show_all')}
                        </NavLink>
                    </div>
                    <div className={style.actions}>
                        <Button
                            textUpper
                            classes={style.action}
                            size="size46"
                            disabled={isLoadingAcceptFriendRequests}
                            onClick={handlerAcceptFriendRequests}
                        >
                            {t('friend_requests_accept')}
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}

export default FriendsRequests
