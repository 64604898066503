import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IReport, IUserAccountFilter } from 'interfaces'
import { REPORT_CC_MATERIALS_ID, REPORT_PV_MATERIALS_ID } from 'config/app'
import { CardContainer } from 'layout'
import {
    InfoBlock,
    Loader,
    InfiniteScroll,
    ErrorMsg,
} from 'components'
import { useFetchReport, useFetchReportFilterValue } from 'containers/Report/hooks'
import { useFetchFilterAccounts } from 'containers/User/hooks'
import { scrollTop } from 'utils/helpers'
import { ReportItem, ItemAccount } from './components'
import style from './ReportList.module.css'

type ReportPropType = {
    id: string
    classes?: string
    isSetReportLink?: boolean
    onLoad?: (data: IReport) => void
    onClick?: (data: IReport) => void
}

type ReportChildsGroups = {
    id: number | string
    caption?: IReport
    items: IReport[]
    folders: IReport[]
}

const getReportChildsGroups = (childs: IReport[] = []) => {
    const initGroupState = {
        id: 0, // getId(true),
        caption: undefined,
        items: [],
        folders: [],
    }

    return childs.reduce((acc: ReportChildsGroups[], item) => {
        const { type } = item
        const len = acc.length
        let index = len > 0 ? len - 1 : 0

        if (type === 'delimiter' && index > 0) {
            acc[index] = initGroupState
            return acc
        }
        if (type === 'caption') {
            index = acc[index]?.caption ? index + 1 : index
            acc[index] = { ...initGroupState, id: `group_${item.id}`, caption: item }
        }

        if (type === 'folder' || type === 'folder_narrow' || type === 'folder_grid' || type === 'reward') {
            acc[index] = { ...acc[index], folders: [...acc[index].folders, item] }
        } else if (type !== 'caption') {
            acc[index] = { ...acc[index], items: [...acc[index].items, item] }
        }

        return acc
    }, [initGroupState])
}

const ReportList: React.FC<ReportPropType> = ({
    id,
    classes,
    isSetReportLink = true,
    onLoad = () => {},
    onClick = () => {},
}) => {
    const { t } = useTranslation()

    const [reportId, setReportId] = useState<string>(id)
    const [report, setReport] = useState<IReport>()
    const [reportFilterIds, setReportFilterIds] = useState<string[]>([])
    const [reportAccounts, setReportAccounts] = useState<IUserAccountFilter[]>([])
    const [reportItems, setReportItems] = useState<IReport[]>()

    const [infinitePage, setInfinitePage] = useState(0)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const [rootReports] = useState({
        [REPORT_CC_MATERIALS_ID]: {
            id: REPORT_CC_MATERIALS_ID,
            name: t('materials'),
        },
        [REPORT_PV_MATERIALS_ID]: {
            id: REPORT_PV_MATERIALS_ID,
            name: t('materials'),
        },
    })

    const { isInitialLoading: isLoadingReport, data: reportData } = useFetchReport({
        id: reportId,
        displayFull: false,
        count: false,
    })

    const { data: reportFilterValueData } = useFetchReportFilterValue({
        ids: reportFilterIds,
    }, {
        enabled: !!reportFilterIds.length,
    })

    const { data: filterAccountsData } = useFetchFilterAccounts({
        reportId: report?.id ?? '',
        filterId: report?.filterId ?? 0,
        page: infinitePage,
    }, {
        enabled: !!report?.filterId,
    })

    const reportChildsGroups = useMemo(() => getReportChildsGroups(report?.childs), [report])

    const handlerOnLoad = ({ page }: { page: number }) => {
        if (page > 0) {
            setInfinitePage(page)
        }
    }

    function resetReportAccounts() {
        setInfinitePage(0)
        setReportAccounts([])
    }

    function resetReportItems() {
        setReportItems(undefined)
    }

    useEffect(() => {
        if (id) {
            setReport(undefined)
            setReportId(id)
            resetReportAccounts()
            resetReportItems()
        }
    }, [id])

    useEffect(() => {
        if (report?.filterId) {
            resetReportAccounts()
            setTimeout(scrollTop)
        }
    }, [report])

    useEffect(() => {
        if (reportData) {
            if (reportData.length === 1) {
                const [data] = reportData

                setReport(data)
                onLoad(data)

                if (data.countable) {
                    setReportFilterIds([data.id])
                } else if (data.childs.length) {
                    const filters = data.childs.filter((item) => item.countable)
                    setReportFilterIds(filters.map((item) => item.id))
                }
            } else {
                setReportItems(reportData)

                if (reportId in rootReports) {
                    onLoad(rootReports[reportId])
                }
            }
        }
    }, [reportData])

    useEffect(() => {
        if (report && reportFilterValueData) {
            if (report.countable) {
                const [filterData] = reportFilterValueData
                setReport(filterData)
            } else {
                setReport((prevState) => {
                    if (!prevState) {
                        return undefined
                    }
                    const childs = prevState.childs.map((item) => {
                        const filterData = reportFilterValueData.find((filter) => filter.id === item.id)
                        return filterData ? { ...item, ...filterData } : item
                    })
                    return { ...prevState, childs }
                })
            }
        }
    }, [reportFilterValueData])

    useEffect(() => {
        if (filterAccountsData) {
            const filterAccountsLen = filterAccountsData.length

            if (!filterAccountsLen || filterAccountsLen < 10) {
                setIsHideInfiniteLoader(true)
            }

            setReportAccounts((prevState) => ([...prevState, ...filterAccountsData]))
        }
    }, [filterAccountsData])

    return (
        <div className={classes}>
            {isLoadingReport && (
                <Loader />
            )}

            {!isLoadingReport && (!reportData || !reportData.length) && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {!isLoadingReport && report?.hasChilds && reportChildsGroups.map((group) => (
                <React.Fragment key={group.id}>
                    {group.caption ? (
                        <InfoBlock classes={style.block}>
                            <InfoBlock.Body classes={style.blockBody}>
                                <ReportItem
                                    data={group.caption}
                                    isSetReportLink={false}
                                    key={group.caption.id}
                                />
                            </InfoBlock.Body>
                            <InfoBlock.Footer>
                                {group.items.map((item) => (
                                    <ReportItem
                                        classes={style.list}
                                        data={item}
                                        isSetReportLink={isSetReportLink}
                                        key={item.id}
                                        onClick={onClick}
                                    />
                                ))}

                                <CardContainer>
                                    {group.folders.map((item) => (
                                        <ReportItem
                                            data={item}
                                            isSetReportLink={isSetReportLink}
                                            key={item.id}
                                            onClick={onClick}
                                        />
                                    ))}
                                </CardContainer>
                            </InfoBlock.Footer>
                        </InfoBlock>
                    ) : (
                        <>
                            {!!group.folders.length && (
                                <CardContainer classes={style.container}>
                                    {group.folders.map((item) => (
                                        <ReportItem
                                            data={item}
                                            isSetReportLink={isSetReportLink}
                                            key={item.id}
                                            onClick={onClick}
                                        />
                                    ))}
                                </CardContainer>
                            )}
                            {!!group.items.length && (
                                <InfoBlock classes={style.block}>
                                    <InfoBlock.Body classes={style.blockBody}>
                                        {group.items.map((item) => (
                                            <ReportItem
                                                classes={style.list}
                                                data={item}
                                                isSetReportLink={isSetReportLink}
                                                key={item.id}
                                                onClick={onClick}
                                            />
                                        ))}
                                    </InfoBlock.Body>
                                </InfoBlock>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))}

            {!isLoadingReport && !report?.hasChilds && (
                <CardContainer>
                    <InfiniteScroll
                        isActive={!!report?.filterId}
                        options={{ rootMargin: '0px' }}
                        isHideLoader={isHideInfiniteLoader}
                        onLoad={handlerOnLoad}
                    >
                        {reportAccounts.map((item) => (
                            <ItemAccount data={item} key={item.id} />
                        ))}
                    </InfiniteScroll>
                </CardContainer>
            )}

            {!isLoadingReport && reportItems && (
                <CardContainer>
                    {reportItems.map((item) => (
                        <ReportItem data={item} key={item.id} />
                    ))}
                </CardContainer>
            )}
        </div>
    )
}

export default ReportList
