import {
    IPostProps,
    ICalendarPostProps,
    IPostFileCommon,
    IPostContent,
    IPostContentAttacheParams,
    ICommunityDefaultImage,
    IGoodsData,
    IUploadedFileData,
} from 'interfaces'
import {
    POST_FILE_GROUP_ID_1,
    POST_FILE_GROUP_ID_2,
    POST_FILE_GROUP_ID_4,
    REGEXP_CONTENT_TAGS,
} from 'config/app'
import { AppService, SimService } from 'services'
import {
    getFileImageElement,
    getFileTypeByMimeType,
    getRequestError,
    getId,
    parseContentTags,
    convertContentTags,
    setTextWrap,
} from 'utils/helpers'

export type TPostProps = { id?: number} & IPostProps

export type TAddFileFnParams = {
    file: File
    params: IPostContentAttacheParams // TODO IPostContentFileParams
    data: TPostData
}

export type TAddGoodsFnParams = {
    goods: IGoodsData
    params: IPostContentAttacheParams // TODO IPostContentFileParams
    data: TPostData
}

export type TAddImageFnParams = {
    image: ICommunityDefaultImage
    params: IPostContentAttacheParams // TODO IPostContentFileParams
    data: TPostData
}

export type TPostData = {
    files: IPostProps['files']
    content: IPostContent[]
}

type TFileDataProps = {
    path: string
    groupId: number
    id?: number
    mimeType?: string
    goodsId?: number
    isAttach?: boolean
    params?: { width?: number, height?: number }
}

const GALLERY_DEFAULT_GROUP_ID = 10

function prepareUploadFile(file: File) {
    const { type: fileType } = getFileTypeByMimeType(file.type)

    if (fileType === 'image') {
        return AppService.normalizeImageFile(file)
    }

    return Promise.resolve({ file })
}

const getGroupId = (files: IPostProps['files'], galleryId: number, isAttach?: boolean, contentId?: number): number => {
    if (contentId) {
        if (files.length) {
            const currentGroupId = 'group' in files[0] ? files[0].group : files[0].fileGroup

            if (currentGroupId && currentGroupId > galleryId) {
                return currentGroupId
            }

            return galleryId + 1
        }
        return POST_FILE_GROUP_ID_2
    }
    if (isAttach) {
        return POST_FILE_GROUP_ID_4
    }

    return POST_FILE_GROUP_ID_1
}

const getNewFileData = ({
    isAttach,
    id,
    path,
    groupId,
    goodsId,
    mimeType,
    params = {},
}: TFileDataProps): IPostFileCommon => {
    const fileId = id || getId(true)
    const fileData: IPostFileCommon = {
        id: goodsId || fileId,
        fileGroup: groupId,
        src: path,
        type: mimeType || 'image/jpeg',
        ...params,
    }

    if (isAttach) {
        fileData.attach_id = fileId
    }
    if (goodsId) {
        fileData.goods = goodsId
    }

    return fileData
}
// TODO ? getFileData
const getPostContentFileData = ({
    file,
    goods,
    image,
}: {
    file?: IUploadedFileData
    goods?: IGoodsData
    image?: ICommunityDefaultImage
}, {
    isAttach,
    contentId,
}: IPostContentAttacheParams, {
    files: postFiles,
    content: postContent,
}: TPostData): Promise<IPostFileCommon> => {
    const galleryLastGroupId = postContent.reduce((acc, item) => {
        if (item.type === 'gallery' && item.files.length) {
            const [itemFile] = item.files
            return ('group' in itemFile ? itemFile.group : itemFile.fileGroup) || acc
        }
        return acc
    }, GALLERY_DEFAULT_GROUP_ID)

    const files = isAttach && contentId
        ? postContent.find((item) => item.id === contentId)?.files || []
        : postFiles

    if (file) {
        const { path, mime_type: mimeType } = file
        const fileData = getNewFileData({
            path,
            mimeType,
            isAttach,
            groupId: getGroupId(files, galleryLastGroupId, isAttach, contentId),
        })

        return Promise.resolve(fileData)
    }
    if (goods) {
        const { id: goodsId, images = [] } = goods
        const [img] = images
        const {
            id: fileId,
            path,
            width,
            height,
        } = img

        return Promise.resolve(getNewFileData({
            id: fileId,
            path,
            groupId: getGroupId(files, galleryLastGroupId, isAttach, contentId),
            goodsId,
            params: { width, height },
        }))
    }
    if (image) {
        const { id: imageId, image_web_path: path } = image

        return Promise.resolve(getNewFileData({
            id: imageId,
            path,
            groupId: getGroupId(files, galleryLastGroupId, isAttach, contentId),
            // params: { width, height },
        }))
    }

    return Promise.resolve({} as IPostFileCommon)
}

const setGallery = (data: IPostContent, groupId: number) => {
    return {
        ...data,
        type: 'gallery',
        files: data.files.map((item) => {
            return 'group' in item ? { ...item, group: groupId } : { ...item, fileGroup: groupId }
        }),
    }
}

const fileUploadAction = (file: File): Promise<IUploadedFileData> => {
    return SimService.filesUpload(file)
        .then(({ data: fileData }) => {
            return fileData
        })
        .catch((err) => {
            return Promise.reject(getRequestError(err))
        })
}

export default function usePost() {
    /**
     * Get post main text
     */
    const getMainText = (text: string) => {
        const [value = ''] = text.split(REGEXP_CONTENT_TAGS)
        return setTextWrap(value)
    }

    /**
     * Get post main files
     */
    const getMainFiles = (files: IPostProps['files'], groups = [POST_FILE_GROUP_ID_1]) => {
        return files
            .filter((item) => {
                if ('group' in item) {
                    return groups.includes(item.group)
                }
                if ('fileGroup' in item && item.fileGroup) {
                    return groups.includes(item.fileGroup)
                }

                return false
            })
    }

    /**
     * Get post content groups
     */
    const getContent = (text: IPostProps['text'], files: IPostProps['files']) => {
        return parseContentTags(text, files)
    }

    const getTextResult = (text: string, content: IPostContent[]) => {
        const postContent = [...content]

        if (postContent.length && !content[0].type) {
            postContent[0].html = text
        } else {
            postContent.unshift({
                id: 0,
                type: '',
                html: text,
                files: [],
            })
        }

        return convertContentTags(postContent)
    }

    const getFilesResult = (files: ICalendarPostProps['files'], content: IPostContent[]) => {
        return content.reduce((acc, item) => {
            return [...acc, ...(item.files)]
        }, files)
    }

    const addFile = ({ file, params: { isAttach, contentId }, data }: TAddFileFnParams) => {
        let sourceFile: File

        return prepareUploadFile(file)
            .then((preparedFile) => {
                sourceFile = preparedFile.file
                return fileUploadAction(preparedFile.file)
            })
            .then((uploadedFileData) => {
                return getPostContentFileData({ file: uploadedFileData }, { isAttach, contentId }, data)
            })
            .then((fileData) => {
                const { type: fileType } = getFileTypeByMimeType(sourceFile.type)

                if (fileType === 'image') {
                    return getFileImageElement(sourceFile)
                        .then(({ naturalWidth: width, naturalHeight: height }) => {
                            return { ...fileData, width, height }
                        })
                        .catch(() => {
                            return fileData
                        })
                }
                return fileData
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    const addGoods = ({ goods, params: { isAttach, contentId }, data }: TAddGoodsFnParams) => {
        return getPostContentFileData({ goods }, { isAttach, contentId }, data)
            .then((fileData) => {
                return fileData
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    const addImage = ({ image, params: { isAttach, contentId }, data }: TAddImageFnParams) => {
        return getPostContentFileData({ image }, { isAttach, contentId }, data)
            .then((fileData) => {
                return fileData
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    const addContent = (content: IPostContent[], data: IPostContent, groupId?: number) => {
        const index = content.findIndex((item) => item.id === data.id)

        if (index >= 0 && groupId) {
            return content.map((item, idx) => {
                if (index === idx) {
                    return setGallery({ ...item, files: [...item.files, ...data.files] }, groupId)
                }
                return item
            })
        }

        return [...content, data]
    }

    const deletePostFile = (files: TPostData['files'], id: number) => {
        return files.map((item) => (item.id === id ? { ...item, delete: 1 } : item))
    }

    const deleteContentFile = (content: IPostContent[], id: number) => {
        return content.reduce<IPostContent[]>((acc, item) => {
            const { files } = item

            if (files?.length) {
                const updatedFiles = files.filter((file) => file.id !== id)
                // if length === 0 , unset gallery to image // TODO
                return updatedFiles.length ? [...acc, { ...item, files: updatedFiles }] : acc
            }

            return [...acc, item]
        }, [])
    }

    return {
        getMainText,
        getMainFiles,
        getContent,
        getTextResult,
        getFilesResult,
        addFile,
        addGoods,
        addImage,
        addContent,
        deletePostFile,
        deleteContentFile,
    }
}
