import React, { useContext } from 'react'
import cn from 'classnames'

import { Context } from '../Context'
import style from './MenuListItem.module.css'

export type MenuListItemPropType = {
    classes?: string
    active?: boolean
    disabled?: boolean
    onClick?: () => void
}

const MenuListItem: React.FC<MenuListItemPropType> = ({
    children,
    classes,
    active,
    disabled,
    onClick = () => {},
}) => {
    const { type, hover } = useContext(Context)

    return (
        <li
            className={cn(
                style.item,
                style[`item_${type}`],
                {
                    [style.item_hover]: hover,
                    [style.item_active]: active,
                    [style.item_disabled]: disabled,
                },
                classes,
            )}
            role="presentation"
            onClick={onClick}
        >
            {children}
        </li>
    )
}

export default MenuListItem
