import {
    ICommunity,
    ICommunityDefaultImage,
    IPost,
    ICalendarPost,
    IPostComments,
    IPostCommentResponse,
    IPostLike,
    IPostProps,
    ICalendarPostProps,
} from 'interfaces'
import { AppScheme } from 'enums'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TCommunityServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type fetchPostsCommunityPropType = {
    comment_limit?: 0
    only_checks?: number
    group?: string
    category_id?: number
    date_from?: number
    sort_by?: 'rating' | 'comments' | 'likes' | 'date'
    q?: string
    limit?: number
    offset?: number
}

export type fetchPostsUserPropType = {
    userId: number
    store_id?: number
    company_id?: number
    comment_limit?: 0
    only_checks?: 0 | 1
    limit?: number
    offset?: number
}

export type TPostProps = {
    id: number
    page?: number // ? кол-во страниц комментариев
    comment_limit?: number
}

export type TAddPostProps = IPostProps

export type TUpdatePostProps = { id: number } & IPostProps

export type TDeletePostProps = { id: number } & IPostProps

export type TAddCalendarPostProps = ICalendarPostProps

export type TUpdateCalendarPostProps = { id: number } & ICalendarPostProps

export type TDeleteCalendarPostProps = { id: number } & ICalendarPostProps

export type fetchCommentsPropType = {
    postId: number
}

export type addCommentPropType = {
    postId: number
    comment: string // text | attach url | sticker
    parent?: number // parent id for reply
}

export type updateCommentPropType = {
    id: number
    comment: string
}

export type deleteCommentPropType = {
    id: number
}

export type restoreCommentPropType = {
    id: number
}

export type fetchCommunityPropType = {
    can_publish?: boolean
}

export type fetchCommunityDefaultImagesPropType = {
    app_id?: number
    limit?: number
    offset?: number
}

export type unsubscribeCommunityPropType = {
    communityId: number
}

export type TLikePostProps = {
    id: number
}

export type TPostLikesProps = {
    id: number
}

export type fetchCommunityLeadersPropType = {
    type?: keyof typeof AppScheme
    page?: string
    group?: string
    comment_limit?: number
    category_id?: number
    date_from?: string
    sort_by?: 'rating' | 'comments' | 'likes' | 'date'
    q?: string
    limit?: number
    offset?: number
}

export type fetchCommunityPresidentPropType = {
    type?: keyof typeof AppScheme
    page?: string
    store_id?: number
    company_id?: number
    comment_limit?: number
    only_checks?: boolean
    limit?: number
    offset?: number
}

export type TCalendarPostsProps = {
    from?: string
    to?: string
    comment_limit?: number
}

export type TPostsResponse = IPost[]

export type TPostResponse = IPost

export type TCalendarPostsResponse = ICalendarPost[]

export type TCalendarPostResponse = ICalendarPost

export type TLikePostResponse = Pick<IPost, 'is_like' | 'like_count'>

class CommunityService {
    static fetchPostsCommunity({ comment_limit = 0, ...opts }: fetchPostsCommunityPropType) {
        return requestClient<TPostsResponse>(API_URL.postsCommunity, {
            params: { comment_limit, ...opts },
        })
    }

    static fetchPostsUser({ userId, comment_limit = 0, ...opts }: fetchPostsUserPropType) {
        const url = parseTpl(API_URL.postsUser, { userId })
        return requestClient<TPostsResponse>(url, {
            params: { comment_limit, ...opts },
        })
    }

    static fetchPost({ id, page = 0, comment_limit = 0 }: TPostProps) {
        const url = parseTpl(API_URL.post, { id, page })
        return requestClient<TPostResponse>(url, { params: { comment_limit } })
    }

    static addPost<T extends(TAddPostProps | TAddCalendarPostProps)>(params: T) {
        return requestClient<
            T extends TAddCalendarPostProps
                ? TCalendarPostResponse
                : TPostResponse
        >(API_URL.postAdd, {
            method: 'post',
            data: { posts: params },
        })
    }

    static updatePost<T extends(TUpdatePostProps | TUpdateCalendarPostProps)>(params: T) {
        const { id, ...data } = params
        const url = parseTpl(API_URL.postUpdate, { id })

        return requestClient<T extends TUpdateCalendarPostProps ? ICalendarPost : IPost>(url, {
            method: 'post',
            data: { posts: { id, ...data } },
        })
    }

    static deletePost<T extends(TDeletePostProps | TDeleteCalendarPostProps)>(params: T) {
        const { id, ...data } = params
        const url = parseTpl(API_URL.postUpdate, { id })

        return requestClient<T extends TDeleteCalendarPostProps ? ICalendarPost : IPost>(url, {
            method: 'post',
            data: { posts: { id, ...data } },
        })
    }

    static fetchComments({ postId }: fetchCommentsPropType) {
        const url = parseTpl(API_URL.comments, { post_id: postId })
        return requestClient<IPostComments>(url)
    }

    static addComment({ postId, comment, parent }: addCommentPropType) {
        const data = parent ? { parent, post: postId, comment } : { post: postId, comment }
        return requestClient<IPostCommentResponse>(API_URL.commentAdd, {
            method: 'post',
            data: {
                comment: data,
            },
        })
    }

    static updateComment({ id, comment }: updateCommentPropType) {
        const url = parseTpl(API_URL.comment, { id })
        return requestClient<[]>(url, { method: 'put', data: { comment } })
    }

    static deleteComment({ id }: deleteCommentPropType) {
        const url = parseTpl(API_URL.comment, { id })
        return requestClient<''>(url, { method: 'delete' })
    }

    static restoreComment({ id }: restoreCommentPropType) {
        const url = parseTpl(API_URL.commentRestore, { id })
        return requestClient<''>(url, { method: 'post' })
    }

    static fetchCommunity({ can_publish }: fetchCommunityPropType = {}) {
        return requestClient<ICommunity[]>(API_URL.communitySettings, { params: { can_publish } })
    }

    static fetchCommunityDefaultImages(params: fetchCommunityDefaultImagesPropType) {
        return requestClient<ICommunityDefaultImage[]>(API_URL.communityDefaultImages, { params })
    }

    static unsubscribeCommunity({ communityId }: unsubscribeCommunityPropType) {
        return requestClient<null>(API_URL.communitySettings, {
            method: 'post',
            data: {
                settings: [{
                    id: communityId,
                    subscribe: false,
                }],
            },
        })
    }

    static fetchPostLikes({ id }: TPostLikesProps) {
        const url = parseTpl(API_URL.postLikes, { id })
        return requestClient<IPostLike[]>(url)
    }

    static likePost({ id }: TLikePostProps) {
        const url = parseTpl(API_URL.postLike, { id })
        return requestClient<TLikePostResponse>(url)
    }

    static fetchCommunityLeaders({
        type,
        page = '0',
        comment_limit = 0,
        ...params
    }: fetchCommunityLeadersPropType) {
        return requestClient<IPost[]>(`${API_URL.communityLeaders}/${type}/${page}`, {
            params: { comment_limit, ...params },
        })
    }

    static fetchCommunityPresident({
        type,
        page = '0',
        comment_limit = 0,
        ...params
    }: fetchCommunityPresidentPropType) {
        return requestClient<IPost[]>(`${API_URL.communityPresident}/${type}/${page}`, {
            params: { comment_limit, ...params },
        })
    }

    static fetchCalendarPosts(params: TCalendarPostsProps) {
        return requestClient<TCalendarPostsResponse>(API_URL.calendarPosts, { params })
    }
}

export default CommunityService
