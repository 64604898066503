import React from 'react'
import cn from 'classnames'

import style from './PostMainImagePreview.module.css'

type PostMainImagePreviewPropType = {
    preview: string // base64
    isHide?: boolean
}

const PostMainImagePreview: React.FC<PostMainImagePreviewPropType> = ({ preview, isHide = true }) => {
    return (
        <div
            className={cn(style.preview, isHide ? style.preview_hide : '')}
            style={{ backgroundImage: `url(data:image/jpeg;base64,${preview})` }}
        />
    )
}

export default PostMainImagePreview
