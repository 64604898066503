import React from 'react'

import { APP_VERSION } from 'config/app'
import { Container, Block } from 'layout'
import { Translations } from 'containers'

/**
 * Техническая страница
 *
 * @name ServicePage
 * @memberOf module:Layouts
 */
const ServicePage: React.FC = () => {
    return (
        <Container>
            <Block styles={{ marginTop: '40px', padding: '20px' }}>
                <div>
                    Версия приложения:
                    {' '}
                    {APP_VERSION}
                </div>

                <Translations />
            </Block>
        </Container>
    )
}

export default ServicePage
