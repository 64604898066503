import React from 'react'
import cn from 'classnames'

import style from './PageTitle.module.css'

type PageTitlePropType = {
    title?: string
    classes?: string
}

const PageTitle: React.FC<PageTitlePropType> = ({ children, title, classes }) => {
    return (
        <div className={cn(style.container, classes)}>
            <span className={style.title}>
                {title}
            </span>

            {children}
        </div>
    )
}

export default PageTitle
