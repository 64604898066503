import React from 'react'
import cn from 'classnames'

import { IPostContent } from 'interfaces'
import { SLIDER_ITEM_CLASS } from 'config/app'
import { Post, Slider } from 'components'
import style from './PostContent.module.css'

export type PostContentPropType = {
    content: IPostContent
    renderContentText?: (data: string) => void
    onClickDeleteContentFile?: (id: number) => void
}

const PostContent: React.FC<PostContentPropType> = ({
    children,
    content,
    renderContentText = () => {},
    onClickDeleteContentFile,
}) => {
    const { type, files, html } = content

    return (
        <div className={style.content}>
            {!type && html && renderContentText(html)}
            {(type === 'image' || type === 'video' || type === 'file') && !!files?.length && !('delete' in files[0]) && (
                <Post.ContentFile
                    file={files[0]}
                    onDelete={onClickDeleteContentFile}
                />
            )}
            {type === 'gallery' && !!files?.length && (
                <Slider classes={style.slider}>
                    {files
                        .filter((item) => !('delete' in item))
                        .map((file) => (
                            <div className={cn(SLIDER_ITEM_CLASS, style.slide)} key={file.id}>
                                <Post.ContentFile
                                    file={file}
                                    onDelete={onClickDeleteContentFile}
                                />
                            </div>
                        ))}
                </Slider>
            )}
            {children}
        </div>
    )
}

export default PostContent
