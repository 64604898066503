import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMessengerChat, IUser, IUserData } from 'interfaces'
import { APP_URL } from 'config/app'
import { MessengerItemInner } from 'containers/Messenger/components'
import { useMessenger } from 'containers/Messenger/hooks'
import { Avatar, AvatarStack, Link } from 'components'
import { numberFormat, plural } from 'utils/helpers'
import style from './MessengerItemChat.module.css'

type MessengerItemChatPropType = {
    classes?: string
    isActive?: boolean
    data: IMessengerChat
    user: IUser
    chatUserData?: IUserData
}

const COUNT_FRIENDS = 3

const MessengerItemChat: React.FC<MessengerItemChatPropType> = ({
    classes,
    isActive,
    data,
    user,
    chatUserData,
}) => {
    const { t } = useTranslation()

    const { isReceivedLastMessage, isReadLastMessage } = useMessenger()

    const [chatUser] = useState(user.id === data.starterUserId ? data.followerUser : data.starterUser)

    const isReceived = useMemo(() => {
        return isReceivedLastMessage(user.id, data.lastMessage)
    }, [data])

    const isRead = useMemo(() => {
        return isReadLastMessage(user.id, data.lastMessage)
    }, [data])

    const urlChat = useMemo(() => {
        return !isActive ? APP_URL.messengerChat.replace(':id', String(chatUser.id)) : undefined
    }, [data, isActive])

    const urlMutualFriends = useMemo(() => {
        return chatUserData ? APP_URL.friendsMutual.replace(':id', String(chatUserData.user.id)) : ''
    }, [chatUserData])

    const mutualFriends = useMemo(() => {
        if (chatUserData?.friends) {
            return chatUserData.friends.map((item) => ({
                id: item.id,
                name: item.name ?? '',
                surname: item.surname ?? '',
                photo: item.photo ?? '',
            }))
        }
        return []
    }, [chatUserData])

    const mutualFriendsCountMore = useMemo(() => {
        return !!chatUserData?.total && chatUserData.total > COUNT_FRIENDS ? chatUserData.total - COUNT_FRIENDS : 0
    }, [chatUserData])

    const mutualFriendsText = useMemo(() => {
        if (mutualFriendsCountMore) {
            const textPart1 = plural(mutualFriendsCountMore, [t('mutual_1x'), t('mutual_2x'), t('mutual_5x')])
            const textPart2 = plural(mutualFriendsCountMore, [t('friend_1x'), t('friend_2x'), t('friend_5x')])

            return `+${numberFormat(mutualFriendsCountMore)} ${textPart1} ${textPart2}`
        }
        return ''
    }, [mutualFriendsCountMore])

    const avatarTpl = (
        <Link className={style.user} url={urlChat}>
            <Avatar
                name={chatUser.name}
                surname={chatUser.surname}
                src={chatUser.photoFullUrl}
                width={48}
                height={48}
            />
        </Link>
    )

    const contentTpl = (
        <Link className={style.friends} url={urlMutualFriends}>
            <AvatarStack
                isKeepHeight={false}
                users={mutualFriends}
                count={3}
                size={28}
            />
            <div className={cn(style.friendsText, { [style.friendsText_mLeft]: !!mutualFriendsText })}>
                {mutualFriendsText && mutualFriendsText}
            </div>
        </Link>
    )

    return (
        <MessengerItemInner
            classes={cn(style.item, classes)}
            title={`${chatUser.name ?? ''} ${chatUser.surname ?? ''}`}
            avatar={avatarTpl}
            content={contentTpl}
            message={data.lastMessage?.text || data.lastMessage?.forwardedMessage || ''}
            date={data.lastMessage?.createdAt || data.createdAt}
            url={urlChat}
            isSimpleMessage
            isHover
            isActive={isActive}
            isReceived={isReceived}
            isRead={isRead}
            isError={false}
        />
    )
}

export default MessengerItemChat
