import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TMlmReportsRanksCurrentUsersProps, fetchMlmReportsRanksCurrentUsersResponseType } from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TMlmReportsRanksCurrentUsersProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<
    fetchMlmReportsRanksCurrentUsersResponseType,
    TError,
    fetchMlmReportsRanksCurrentUsersResponseType,
    TQueryKey
>

const key = QueryCacheKeys.mlmReportsRanksCurrentUsers

/**
 * Хук API
 */
export default function useFetchMlmReportsRanksCurrentUsers(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.fetchMlmReportsRanksCurrentUsers(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
