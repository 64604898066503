import React, { useEffect, useMemo } from 'react'
import { useForm, useField } from 'react-final-form'

import { CustomSelect } from 'components'

type TItem = {
    id: number
    value: string
}

type FieldSelectPropType = {
    classes?: string
    name: string
    items: TItem[]
    defaultItem?: TItem
    placeholder?: string
    disabled?: boolean
}

const FieldSelect: React.FC<FieldSelectPropType> = ({
    classes,
    name,
    items,
    defaultItem,
    placeholder,
    disabled,
}) => {
    const { mutators } = useForm()
    const { input } = useField<string>(name)

    const itemsData = useMemo(() => {
        return items.reduce<Record<TItem['id'], TItem>>((acc, item) => {
            return { ...acc, [item.id]: item }
        }, {})
    }, [items])

    const handlerChange = (id: number) => {
        mutateFieldValue(id)
    }

    function mutateFieldValue(id: number) {
        if (name in mutators) {
            mutators[name](name, itemsData[id])
        }
    }

    useEffect(() => {
        if (defaultItem) {
            mutateFieldValue(defaultItem.id)
        }
    }, [])

    return (
        <CustomSelect.Select classes={classes}>
            <CustomSelect.Field disabled={disabled}>
                {input.value || placeholder}
            </CustomSelect.Field>
            <CustomSelect.Options>
                {items.map((item) => (
                    <CustomSelect.Option
                        id={item.id}
                        isActive={item.value === input.value}
                        key={item.id}
                        onClick={handlerChange}
                    >
                        {item.value}
                    </CustomSelect.Option>
                ))}
            </CustomSelect.Options>
        </CustomSelect.Select>
    )
}

export default FieldSelect
