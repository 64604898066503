import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ICommunity } from 'interfaces'
import { fetchCommunityPropType } from 'services/CommunityService'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'

export type TFetchFnParams = fetchCommunityPropType
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<ICommunity[], TError, ICommunity[], TQueryKey>

const key = 'communities'

/**
 * Хук API получение списка сообществ
 */
export default function useFetchCommunity(params: TFetchFnParams, {
    cacheTime = 3600 * 1000, // 1h
    staleTime = 3600 * 1000, // 1h
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunity(params)
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
