import React, { useContext } from 'react'
import cn from 'classnames'

import { Button } from 'components'
import { Context } from '../Context'
import style from './ModalHeader.module.css'

export type ModalHeaderPropType = {
    classes?: string
    classesTitle?: string
    title?: string
    titlePos?: 'center' | 'left'
    isCloseButton?: boolean
}

const ModalHeader: React.FC<ModalHeaderPropType> = ({
    children,
    classes,
    classesTitle,
    title,
    titlePos = 'center',
    isCloseButton,
}) => {
    const { toClose } = useContext(Context)

    return (
        <div className={cn(style.header, isCloseButton ? style.header_pRight : '', classes)}>
            {isCloseButton && (
                <Button classes={style.close} styleType="text" onClick={toClose} />
            )}
            {title && (
                <div className={cn(style.title, style[`title_${titlePos}`], classesTitle)}>
                    {title}
                </div>
            )}

            {children}
        </div>
    )
}

export default ModalHeader
