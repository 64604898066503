import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPayoutHistoryProps,
    TPayoutHistoryResponse,
} from 'services/PayoutService'
import { QueryCacheKeys } from 'enums'
import { PayoutService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPayoutHistoryProps
export type TQueryFnOpts = UseQueryOptions<
    TPayoutHistoryResponse,
    TRequestError<TFetchFnParams>,
    TPayoutHistoryResponse,
    [string, TFetchFnParams]
>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TPayoutHistoryResponse,
    TRequestError<TFetchFnParams>,
    TPayoutHistoryResponse,
    TPayoutHistoryResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.payoutHistory

/**
 * Hook API wallet transaction list
 */
export default function useFetchPayoutHistory(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfinitePayoutHistory(params: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useInfiniteQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
