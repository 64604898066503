import React, { useEffect, useState } from 'react'

import { EVENT_TYPE_MODAL_CONTENT_OPEN } from '../../config/app'
import { Modal } from '../index'
import eventBus from '../../utils/EventBus'

type EventModalContentPropType = {
    title?: string
    contentView?: React.FC
    size?: 'small' | 'medium' | 'large'
}

const initialEventDataState: EventModalContentPropType = {
    title: '',
    contentView: undefined,
    size: 'small',
}

/**
 * Модальное окно для вывода различного контента
 *
 * @memberOf module:Components
 * @listens EVENT_TYPE_MODAL_CONTENT_OPEN
 */
const ModalContent: React.FC = () => {
    const [eventData, setEventData] = useState<EventModalContentPropType>(initialEventDataState)
    const [isOpen, setIsOpen] = useState(false)

    const handlerEventModalContent = (data: EventModalContentPropType) => {
        setEventData(data)
        setIsOpen(true)
    }

    const handlerClose = () => {
        setEventData(initialEventDataState)
        setIsOpen(false)
    }

    useEffect(() => {
        eventBus.on(EVENT_TYPE_MODAL_CONTENT_OPEN, handlerEventModalContent)

        return () => {
            eventBus.off(EVENT_TYPE_MODAL_CONTENT_OPEN, handlerEventModalContent)
        }
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            size={eventData.size}
            onClose={handlerClose}
        >
            <Modal.Header isCloseButton title={eventData.title} />
            <Modal.Body>
                <div>
                    {eventData.contentView}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalContent
