import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { APP_URL } from 'config/app'
import {
    AuthPage,
    HomePage,
    LKPage,
    SupportPage,
    ServicePage,
} from 'pages'
import { useApp } from 'containers/App/hooks'
import { Logout } from 'containers'

import RouteClient from './RouteClient'

type RoutesPropType = {
    isAuthorized: boolean
}

const Routes: React.FC<RoutesPropType> = ({ isAuthorized }) => {
    const { mainPrivateRoute } = useApp()

    return (
        <Switch>
            <RouteClient isAuthorized={isAuthorized} path={APP_URL.auth} component={AuthPage} />
            <RouteClient isAuthorized={isAuthorized} path={APP_URL.registration} component={HomePage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.logout} component={Logout} />

            <RouteClient
                exact
                isPrivate={isAuthorized}
                isAuthorized={isAuthorized}
                path={APP_URL.support}
                component={isAuthorized ? LKPage : SupportPage}
            />
            <RouteClient
                isPrivate={isAuthorized}
                isAuthorized={isAuthorized}
                path={APP_URL.ticket}
                component={isAuthorized ? LKPage : SupportPage}
            />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.account} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.profile} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.userSettings} component={LKPage} />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.settings} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.settingsLab} component={LKPage} />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.lenta} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.lentaUser} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.post} component={LKPage} />

            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.shopsFavorite} component={LKPage} />
            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.shopsSettings} component={LKPage} />
            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.market} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.category} component={LKPage} />

            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.store} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.storeProfile} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.catalog} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.basket} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.orders} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.orderMake} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.orderPayment} component={LKPage} />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.goods} component={LKPage} />

            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.reports} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.reportsAccounts} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.reportsFreedom} component={LKPage} />
            <RouteClient
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.reportsRankUsers}
                component={LKPage}
            />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.report} component={LKPage} />

            <RouteClient exact isPrivate isAuthorized={isAuthorized} path={APP_URL.friends} component={LKPage} />
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.friendsRequest}
                component={LKPage}
            />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.friendsMutual} component={LKPage} />

            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.messenger} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.calendar} component={LKPage} />

            {/* TODO */}
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.community} component={LKPage} />

            {/* dev */}
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.kicks} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.wallet} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.rating} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.popular} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.invite} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.receipt} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.lessons} component={LKPage} />
            {/* /dev */}

            {/* for create link redirect */}
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.app} component={LKPage} />
            <RouteClient isPrivate isAuthorized={isAuthorized} path={APP_URL.kick} component={LKPage} />

            {/* for service */}
            <RouteClient
                exact
                isPrivate
                isAuthorized={isAuthorized}
                path={APP_URL.service}
                component={ServicePage}
            />

            <Redirect from="*" to={isAuthorized ? mainPrivateRoute : APP_URL.auth} />
        </Switch>
    )
}

export default Routes
