import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IShopFilter } from 'interfaces'
import { showAlertNotify } from 'utils/helpers'
import { ShopFilterItem } from '../index'
import style from './ShopFilter.module.css'

type ShopFilterPropType = {
    items: IShopFilter[]
    classes?: string
    activeId?: number
    disabled?: boolean
    onClick: (data: IShopFilter[]) => void
}

const ShopFilter: React.FC<ShopFilterPropType> = ({
    items,
    classes,
    activeId,
    disabled = false,
    onClick,
}) => {
    const { t } = useTranslation()
    const [filterItems, setFilterItems] = useState(items)

    const handlerClickItem = (id: number) => {
        if (disabled) {
            showAlertNotify({ message: t('allow_location_for_sorting') })
            return
        }
        changeFilter(id)
    }

    function changeFilter(id: number) {
        let isActiveItem = false
        const filters = filterItems.map((item) => {
            if (item.id === id && !item.active) {
                isActiveItem = true
                return { ...item, active: true }
            }
            return item.active ? { ...item, active: false } : item
        })

        if (isActiveItem) {
            setFilterItems(filters)
            onClick(filters)
        }
    }

    useEffect(() => {
        if (activeId) {
            changeFilter(activeId)
        }
    }, [activeId])

    return (
        <div className={cn(style.container, classes)}>
            <div className={style.desc}>
                {`${t('Show')}:`}
            </div>

            <ul className={style.filters}>
                {filterItems.map((filter) => (
                    <ShopFilterItem filter={filter} key={filter.id} onClick={handlerClickItem} />
                ))}
            </ul>
        </div>
    )
}

export default ShopFilter
