import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, generatePath } from 'react-router-dom'
import cn from 'classnames'

import { IProfile, IUserData } from 'interfaces'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { Block } from 'layout'
import * as userSelectors from 'containers/User/user-selectors'
import {
    Avatar,
    AvatarStack,
    Button,
    TimeAgo,
    Popover,
    MenuList,
} from 'components'
import { useWindowResize, useAlertDialog } from 'hooks'
import { sumFormat, plural, showAlertNotify } from 'utils/helpers'
import style from './UserProfileData.module.css'

type UserProfileDataPropType = {
    profile: IProfile
    data: IUserData
    onAddFriends: (value: IProfile) => void
    onDeleteFriends: (value: IProfile) => void
    onCancelFriendsRequest: (value: IProfile) => void
    onSubscribe: (value: IProfile) => void
    onUnsubscribe: (value: IProfile) => void
    onBlockUser: (value: IProfile) => void
    onUnblockUser: (value: IProfile) => void
    onReport: (value: IProfile) => void
}

const COUNT_FRIENDS = 5
const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }

const UserProfileData: React.FC<UserProfileDataPropType> = ({
    profile,
    data,
    onAddFriends,
    onDeleteFriends,
    onCancelFriendsRequest,
    onSubscribe,
    onUnsubscribe,
    onBlockUser,
    onUnblockUser,
    onReport,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()
    const { showAlertDialog } = useAlertDialog()

    const user = useSelector(userSelectors.user)

    const [isOpenMenu, setIsOpenMenu] = useState(false)

    const urlMutualFriends = useMemo(() => {
        return data?.user ? generatePath(APP_URL.friendsMutual, { id: data.user.id }) : ''
    }, [data])

    const mutualFriends = useMemo(() => {
        if (data?.friends) {
            return data.friends.map((item) => ({
                id: item.id,
                name: item.name,
                surname: item.surname,
                photo: item.photo ?? '',
            }))
        }
        return []
    }, [data])

    const mutualFriendsText = useMemo(() => {
        if (data?.total) {
            const countMore = data.total > COUNT_FRIENDS ? data.total - COUNT_FRIENDS : 0

            if (countMore) {
                const textPart1 = plural(countMore, [t('mutual_1x'), t('mutual_2x'), t('mutual_5x')])
                const textPart2 = plural(countMore, [t('friend_1x'), t('friend_2x'), t('friend_5x')])

                return `+${sumFormat(countMore, 0)} ${textPart1} ${textPart2}`
            }
        }

        return t('mutual_friends')
    }, [data])

    const handlerOpenMenu = () => {
        setIsOpenMenu(true)
    }

    const handlerCloseMenu = () => {
        setIsOpenMenu(false)
    }

    const handlerClickMessenger = () => {
        if (profile.chat) {
            history.push(generatePath(APP_URL.messengerChat, { id: data.user.id }))
        } else {
            showAlertNotify({
                type: 'error',
                message: t('chat_ban').replace('%s', `${profile?.name ?? ''} ${profile?.surname ?? ''}`),
            })
        }
    }

    const handlerAddFriends = () => {
        setIsOpenMenu(false)
        onAddFriends(profile)
    }

    const handlerRemoveFriends = () => {
        setIsOpenMenu(false)
        showAlertDialog({
            isSetPerformText: false,
            message: `${t('user_profile_delete_friend')}: ${data.user.name ?? ''} ${data.user.surname ?? ''}`,
            payload: profile,
            actionConfirmText: t('user_profile_delete_friend'),
            onConfirm: onDeleteFriends,
        })
    }

    const handlerCancelFriendsRequest = () => {
        setIsOpenMenu(false)
        showAlertDialog({
            isSetPerformText: false,
            message: `${t('user_profile_reject_request')}: ${data.user.name ?? ''} ${data.user.surname ?? ''}`,
            payload: profile,
            actionConfirmText: t('user_profile_reject_request'),
            onConfirm: onCancelFriendsRequest,
        })
    }

    const handlerSubscribe = () => {
        setIsOpenMenu(false)

        if (profile.block) {
            showAlertNotify({ type: 'error', message: t("You can't subscribe user blocked by you") })
        } else {
            onSubscribe(profile)
        }
    }

    const handlerUnsubscribe = () => {
        setIsOpenMenu(false)
        onUnsubscribe(profile)
    }

    const handlerBlockUser = () => {
        setIsOpenMenu(false)
        onBlockUser(profile)
    }

    const handlerUnblockUser = () => {
        setIsOpenMenu(false)
        onUnblockUser(profile)
    }

    const handlerReport = () => {
        setIsOpenMenu(false)
        onReport(profile)
    }

    return (
        <Block classes={style.wrap}>
            <Popover
                classes={style.menu}
                classesBody={style.menuBody}
                isPopup={windowWidth < BREAKPOINTS.tablet}
                isAutoClose={false}
                isShowArrow={false}
                isDisabled={user.id === profile.id}
                isOpen={isOpenMenu}
                position="bottom"
                side="left"
                trigger={windowWidth >= BREAKPOINTS.tablet ? <Popover.TriggerMore /> : <Popover.TriggerDots position="vertical" />}
                onOpen={handlerOpenMenu}
                onClose={handlerCloseMenu}
            >
                <MenuList classes={style.menuList}>
                    {profile.is_friend && (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerRemoveFriends}>
                            {t('no_friends')}
                        </MenuList.Item>
                    )}
                    {!profile.is_friend && !profile.is_friend_request && !profile.block && (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerAddFriends}>
                            {t('add_to_friends')}
                        </MenuList.Item>
                    )}
                    {profile.is_friend_request && (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerCancelFriendsRequest}>
                            {t('cancel_friend_request')}
                        </MenuList.Item>
                    )}
                    {profile.subscribe ? (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerUnsubscribe}>
                            {t('Unsubscribe')}
                        </MenuList.Item>
                    ) : (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerSubscribe}>
                            {t('subscribe_to_user')}
                        </MenuList.Item>
                    )}
                    {profile.block ? (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerUnblockUser}>
                            {t('user_profile_unblock')}
                        </MenuList.Item>
                    ) : (
                        <MenuList.Item classes={style.menuListItem} onClick={handlerBlockUser}>
                            {t('user_profile_block')}
                        </MenuList.Item>
                    )}
                    <MenuList.Item classes={style.menuListItem} onClick={handlerReport}>
                        {t('report_content')}
                    </MenuList.Item>
                </MenuList>
            </Popover>
            <Avatar
                name={profile.name}
                surname={profile.surname}
                src={profile.photo}
                width={windowWidth >= BREAKPOINTS.tabletLandscape ? 118 : 56}
                height={windowWidth >= BREAKPOINTS.tabletLandscape ? 118 : 56}
            />
            <div className={style.content}>
                {profile.last_login_date && (
                    <div className={style.date}>
                        {t('user_profile_was')}
                        {' '}
                        <TimeAgo
                            time={profile.last_login_date}
                            options={DATE_FORMAT_OPTIONS}
                        />
                    </div>
                )}
                <div className={style.name}>
                    {`${profile.name ?? ''} ${profile.surname ?? ''}`}
                </div>
                <div className={style.status}>
                    {profile.profileStatus}
                </div>
                <div className={style.actions}>
                    <Button
                        textUpper
                        classes={cn(style.action, style.action_button)}
                        size="size46"
                        disabled={user.id === profile.id}
                        onClick={handlerClickMessenger}
                    >
                        {t('message_to_user')}
                    </Button>
                    <div className={style.friends}>
                        <NavLink to={urlMutualFriends}>
                            <AvatarStack
                                isKeepHeight={false}
                                users={mutualFriends}
                                count={COUNT_FRIENDS}
                            />
                        </NavLink>
                        {mutualFriendsText && (
                            <div className={style.text}>
                                {mutualFriendsText}
                            </div>
                        )}
                        {data?.total === 0 && (
                            <div className={style.text}>
                                {t('no_mutual_friends')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Block>
    )
}

export default UserProfileData
