import { combineReducers, Reducer, AnyAction } from 'redux'

import {
    ISettings,
    IRootState,
    IDarkDoor,
    IFlashMessageData,
    ILanguage,
    ICountry,
    ITranslation,
    ICity,
    IStickers,
    ICountryIdentify,
    IResource,
    ICurrency,
    IGeolocation,
} from 'interfaces'
import {
    ADD_MODAL,
    REMOVE_MODAL,
    SET_SETTINGS,
    UPDATE_SETTINGS,
    SET_DARK_DOOR,
    FETCH_LANGUAGES,
    FETCH_COUNTRIES,
    FETCH_RESOURCES,
    FETCH_TRANSLATIONS,
    SET_TRANSLATIONS,
    FETCH_CITIES,
    FETCH_STICKERS,
    FETCH_CURRENCIES,
    SET_COUNTRY_IDENTIFY,
    ADD_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_MESSAGES,
    SET_GEOLOCATION,
} from 'reducers/types'
import {
    addModalActionType,
    removeModalActionType,
    setSettingsActionType,
    updateSettingsActionType,
    setDarkDoorActionType,
    setGeolocationActionType,
    fetchLanguagesActionType,
    fetchCountriesActionType,
    fetchTranslationsActionType,
    fetchCitiesActionType,
    fetchStickersActionType,
    fetchCurrenciesActionType,
    fetchCountryIdentifyActionType,
    fetchResourcesActionType,
} from './app-actions-type'

type modalActionType = addModalActionType | removeModalActionType
type settingsActionType = setSettingsActionType | updateSettingsActionType

const modalsReducer = (state: number[] = [], action: modalActionType): number[] => {
    switch (action.type) {
        case ADD_MODAL:
            return [...state, action.payload]

        case REMOVE_MODAL:
            return state.filter((item) => item !== action.payload)

        default:
            return state
    }
}

const settingsReducer = (state: ISettings = {} as ISettings, action: settingsActionType): ISettings => {
    switch (action.type) {
        case SET_SETTINGS:
            return action.payload

        case UPDATE_SETTINGS: {
            const id = 'meta' in action ? action.meta.id : null
            return id ? { ...state, [id]: action.payload } : state
        }

        default:
            return state
    }
}

const darkDoorReducer = (state: object | IDarkDoor = {}, action: setDarkDoorActionType): object | IDarkDoor => {
    switch (action.type) {
        case SET_DARK_DOOR:
            return action.payload

        default:
            return state
    }
}

const geolocationReducer = (state: null | IGeolocation = null, action: setGeolocationActionType)
    : null | IGeolocation => {
    switch (action.type) {
        case SET_GEOLOCATION:
            return action.payload

        default:
            return state
    }
}

/** AlertFlash */
const messageReducer: Reducer<IRootState['app']['messages'], AnyAction> = (state = [], action)
    : IFlashMessageData[] => {
    switch (action.type) {
        case ADD_MESSAGE:
            return [...state, action.payload]

        case REMOVE_MESSAGE:
            return state.filter((item) => item.id !== action.payload)

        case REMOVE_MESSAGES:
            return []

        default:
            return state
    }
}

const languageReducer = (state: ILanguage[] = [], action: fetchLanguagesActionType): ILanguage[] => {
    switch (action.type) {
        case FETCH_LANGUAGES:
            return [...state, ...action.payload]

        default:
            return state
    }
}

const countryReducer = (state: ICountry[] = [], action: fetchCountriesActionType): ICountry[] => {
    switch (action.type) {
        case FETCH_COUNTRIES:
            return action.payload

        default:
            return state
    }
}

const resourceReducer = (state: IResource[] = [], action: fetchResourcesActionType): IResource[] => {
    switch (action.type) {
        case FETCH_RESOURCES:
            return action.payload

        default:
            return state
    }
}

const translationReducer = (state: object | ITranslation = {}, action: fetchTranslationsActionType)
    : object | ITranslation => {
    switch (action.type) {
        case FETCH_TRANSLATIONS:
        case SET_TRANSLATIONS:
            return action.payload

        default:
            return state
    }
}

const cityReducer = (state: ICity[] = [], action: fetchCitiesActionType): ICity[] => {
    switch (action.type) {
        case FETCH_CITIES:
            return action.payload

        default:
            return state
    }
}

const stickerReducer = (state: object | IStickers = {}, action: fetchStickersActionType): object | IStickers => {
    switch (action.type) {
        case FETCH_STICKERS:
            return action.payload

        default:
            return state
    }
}

const currencyReducer = (state: ICurrency[] = [], action: fetchCurrenciesActionType): ICurrency[] => {
    switch (action.type) {
        case FETCH_CURRENCIES:
            return action.payload

        default:
            return state
    }
}

const countryIdentifyReducer = (state = {}, action: fetchCountryIdentifyActionType): object | ICountryIdentify => {
    switch (action.type) {
        case SET_COUNTRY_IDENTIFY:
            return action.payload

        default:
            return state
    }
}

const appReducer = combineReducers({
    modals: modalsReducer,
    settings: settingsReducer,
    darkDoor: darkDoorReducer,
    messages: messageReducer,
    languages: languageReducer,
    countries: countryReducer,
    country: countryIdentifyReducer,
    resources: resourceReducer,
    cities: cityReducer,
    translations: translationReducer,
    stickers: stickerReducer,
    currencies: currencyReducer,
})

export { appReducer, geolocationReducer }
