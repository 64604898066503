import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IReportMlmChartBlock } from 'interfaces'
import { ChartConfiguration } from 'components/Chart/Chart'
import { MlmChartType, AppScheme } from 'enums'
import { CLIENT_STORAGE_REPORTS_CLIENTS_KEY, CLIENT_STORAGE_REPORTS_PARTNERS_KEY } from 'config/app'
import { useStorageCount } from 'containers/App/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { Chart, Badge } from 'components'
import noDataText from 'components/Chart/plugins/noDataText'
import { numberFormat, convertHexToRgb } from 'utils/helpers'
import { vBlue9, vRed4, vGray14 } from 'styles/modules/variables.module.css'
import style from './ReportsMlmChart.module.css'

type ReportsMlmChartPropType = {
    type: keyof typeof AppScheme
    data: IReportMlmChartBlock
}

const ReportsMlmChart: React.FC<ReportsMlmChartPropType> = ({ type, data }) => {
    const defaults = {
        color: type === AppScheme.pv ? vBlue9 : vRed4,
    }

    const {
        id,
        title,
        subtitle,
        total,
        color,
        data: dataList,
        unit,
    } = data || {}

    const { t } = useTranslation()

    const user = useSelector(userSelectors.user)

    const { getCount, saveCount } = useStorageCount()

    const [count, setCount] = useState(0)
    const [reportsClientsKey] = useState(`${CLIENT_STORAGE_REPORTS_CLIENTS_KEY}.${user.id}.${type}`)
    const [reportsPartnersKey] = useState(`${CLIENT_STORAGE_REPORTS_PARTNERS_KEY}.${user.id}.${type}`)

    const labels = useMemo(() => {
        const items = dataList.map((item) => item.date)
        return items.length === 1 ? ['', ...items] : items
    }, [type])

    const values = useMemo(() => {
        const items = dataList.map((item) => item.value)
        return items.length === 1 ? [0, ...items] : items
    }, [type])

    const fillColor = useMemo(() => {
        const rgbColor = convertHexToRgb(color).join(',')
        return rgbColor ? `rgba(${rgbColor}, 0.1)` : color
    }, [type])

    const chartConfig: ChartConfiguration = useMemo(() => {
        return {
            type: 'line',
            data: {
                labels,
                datasets: [{
                    borderColor: color,
                    borderWidth: 1,
                    fill: {
                        target: 'origin',
                        above: fillColor,
                        below: fillColor,
                    },
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    pointRadius: values.length === 1 ? 1 : 0,
                    data: values,
                }],
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    noDataText: {
                        enabled: !values.length,
                        font: '14px',
                        fontColor: vGray14,
                        text: t('no_data'),
                    },
                },
            },
            plugins: [noDataText],
        }
    }, [values])

    const updateStorageCount = () => {
        if (id === MlmChartType.clients) {
            saveCount(reportsClientsKey, total)
        } else if (id === MlmChartType.activePartners) {
            saveCount(reportsPartnersKey, total)
        }
    }

    const getStorageCount = () => {
        if (id === MlmChartType.clients) {
            setCount(getCount(reportsClientsKey, total))
        } else if (id === MlmChartType.activePartners) {
            setCount(getCount(reportsPartnersKey, total))
        }
    }

    useEffect(() => {
        updateStorageCount()
        getStorageCount()
    }, [])

    return (
        <>
            <div className={style.header}>
                <span className={style.title}>
                    {title}
                </span>

                {(id === MlmChartType.clients || id === MlmChartType.activePartners) && (
                    <Badge
                        size="small"
                        backgroundColor={count ? defaults.color : vGray14}
                    >
                        {count}
                    </Badge>
                )}
            </div>
            <div className={style.subtitle}>
                {subtitle}
            </div>
            <div className={style.value} style={{ color: total ? color : vGray14 }}>
                {numberFormat(total)}
                {' '}
                {unit}
            </div>
            <div className={style.chart}>
                <Chart config={chartConfig} options={{ height: 100 }} />
            </div>
        </>
    )
}

export default ReportsMlmChart
