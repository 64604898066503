import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPost } from 'interfaces'
import { APP_URL } from 'config/app'
import { Context } from 'containers/Community/Context'
import {
    Avatar,
    Popover,
    Link,
} from 'components'
import { PostDropDownMenu } from 'components/Post/components'
import { useTimeAgo } from 'hooks'
import { dateTimeFormat, parseTpl } from 'utils/helpers'
import { vGray5 } from 'styles/modules/variables.module.css'
import style from '../../Post.module.css' // FIXME

export type PostHeaderPropType = {
    classes?: string
    data: IPost
    isSowMenu?: boolean
}

const PARSE_TEXT_TPL_PARAMS = { prefix: '', suffix: '' }

const PostHeader: React.FC<PostHeaderPropType> = ({ classes, data, isSowMenu = true }) => {
    const { i18n, t } = useTranslation()

    const {
        onEditPost,
        onDeletePost,
        onUnsubscribeUser,
        onUnsubscribeUserInStore,
        onUnsubscribeStore,
        onUnsubscribeCommunity,
        onUnsubscribeCompany,
        onBlockUser,
    } = useContext(Context)

    const { getTimeAgo } = useTimeAgo({ today: 'ago' })

    const userUrl = useMemo(() => {
        return data.user ? APP_URL.profile.replace(':id', String(data.user.id)) : ''
    }, [data])

    const date = useMemo(() => {
        return getTimeAgo(data.date_published)
    }, [data])

    const time = useMemo(() => {
        return dateTimeFormat(data.date_published, i18n.language, {
            year: '2-digit',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
    }, [data])

    const isShowAuthor = useMemo(() => {
        const { is_show_author } = data
        return is_show_author === undefined || is_show_author === null || is_show_author
    }, [data])

    const handlerClickEditPost = () => {
        onEditPost(data)
    }

    const handlerClickDeletePost = () => {
        onDeletePost(data, t('ui_screen_post_delete_post_warning'))
    }

    const handlerClickUnsubscribeUser = () => {
        onUnsubscribeUser(data.user.id, t('post_menu_unsubscribe_user'))
    }

    const handlerClickUnsubscribeUserInStore = () => {
        if (data.order) {
            const message = parseTpl(t('post_menu_unsubscribe_user_in_store'), {
                '%0': `${data.user?.name} ${data.user?.surname}`,
                '%1': data.order?.store_name,
            }, PARSE_TEXT_TPL_PARAMS)

            onUnsubscribeUserInStore(data.order.store_id, data.user.id, message)
        }
    }

    const handlerClickUnsubscribeStore = () => {
        if (data.order) {
            onUnsubscribeStore(data.order.store_id, t('post_menu_unsubscribe_from_store'))
        }
    }

    const handlerClickUnsubscribeCommunity = () => {
        const message = parseTpl(t('post_menu_unsubscribe_community'), {
            '%s': data.community?.title,
        }, PARSE_TEXT_TPL_PARAMS)

        onUnsubscribeCommunity(data.community.id, message)
    }

    const handlerClickUnsubscribeCompany = () => {
        const message = parseTpl(t('post_menu_unsubscribe_company'), {
            '%s': data.community?.company_name,
        }, PARSE_TEXT_TPL_PARAMS)

        onUnsubscribeCompany(data.community.company_id, message)
    }

    const handlerClickBlockUser = () => {
        onBlockUser(data.user.id, t('post_menu_block_user'))
    }

    const handlerClickReport = () => {
        //
    }

    return (
        <div className={cn(style.header, classes)}>
            <Link url={userUrl}>
                <Avatar
                    src={isShowAuthor ? data.user.photo : data.community.logo}
                    width={56}
                    height={56}
                    name={isShowAuthor ? data.user.name : data.community.title}
                    surname={isShowAuthor ? data.user.surname : undefined}
                    bgColor={!isShowAuthor ? (data.community.color || vGray5) : undefined}
                />
            </Link>
            <div className={style.headerContent}>
                <div className={style.headerAuthor}>
                    {isShowAuthor ? (
                        `${data.user.name ?? ''} ${data.user.surname ?? ''}`
                    ) : (
                        data.community.title
                    )}
                </div>
                <div className={style.headerInfo}>
                    {data?.user?.rank_pv && (
                        <>
                            <span className={style.headerRank}>
                                {data.user.rank_pv.name}
                            </span>
                            <span className={style.headerDot}>
                                &bull;
                            </span>
                        </>
                    )}
                    <span className={style.headerDate} title={time}>
                        {date}
                    </span>
                </div>
            </div>
            {isSowMenu && (
                <div className={style.headerMenu}>
                    <Popover trigger={<Popover.TriggerDots />} position="bottom" side="left">
                        <PostDropDownMenu
                            post={data}
                            isShowAuthor={isShowAuthor}
                            onClickDeletePost={handlerClickDeletePost}
                            onClickEditPost={handlerClickEditPost}
                            onClickUnsubscribeUser={handlerClickUnsubscribeUser}
                            onClickUnsubscribeUserInStore={handlerClickUnsubscribeUserInStore}
                            onClickUnsubscribeStore={handlerClickUnsubscribeStore}
                            onClickBlockUser={handlerClickBlockUser}
                            onClickUnsubscribeCommunity={handlerClickUnsubscribeCommunity}
                            onClickUnsubscribeCompany={handlerClickUnsubscribeCompany}
                            onClickReport={handlerClickReport}
                        />
                    </Popover>
                </div>
            )}
        </div>
    )
}

export default PostHeader
