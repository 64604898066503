import React from 'react'

import { NavBar, MenuNavMainPublic, Footer } from 'components'
import { Auth } from 'containers'
import style from './AuthPage.module.css'

/**
 * Страница авторизации
 *
 * @name AuthPage
 * @memberOf module:Layouts
 */
const AuthPage: React.FC = () => {
    return (
        <>
            <div className={style.page}>
                <NavBar classes={style.navBar}>
                    <MenuNavMainPublic />
                </NavBar>

                <div className={style.content}>
                    <Auth />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default AuthPage
