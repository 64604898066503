import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EVENT_TYPE_ALERT_DIALOG } from 'config/app'
import { Button, Modal, ImgResource } from 'components'
import eventBus from 'utils/EventBus'
import { splitStr } from 'utils/helpers'
import styleAlert from 'styles/modules/alert.module.css'
import style from './AlertDialog.module.css'

export type EventDialogPropType<T = unknown> = {
    isSetPerformText?: boolean
    isSetQuestionText?: boolean
    message?: string
    payload?: T
    actionConfirmText?: string
    actionAbortText?: string
    onAbort?: (data: T) => void
    onConfirm: (data: T) => void
}

/**
 * Диалоговое окно с подтверждением пользователем выбранного действия
 * @listens EVENT_TYPE_ALERT_DIALOG
 */
const AlertDialog: React.FC = () => {
    const { t } = useTranslation()

    const [eventData, setEventData] = useState<EventDialogPropType>()
    const [isOpen, setIsOpen] = useState(false)

    const {
        isSetPerformText = true,
        isSetQuestionText = true,
        message,
        payload,
        actionConfirmText = t('perform'),
        actionAbortText = t('cancel'),
        onAbort = () => {},
        onConfirm = () => {},
    } = useMemo(() => eventData || {} as EventDialogPropType, [eventData])

    const messageItems = useMemo(() => {
        return message ? splitStr(message) : []
    }, [message])

    const handlerEventAlertDialog = (params: EventDialogPropType) => {
        setEventData(params)
        setIsOpen(true)
    }

    const handlerConfirm = () => {
        onConfirm(payload)
        setIsOpen(false)
    }

    const handlerAbort = () => {
        onAbort(payload)
        setIsOpen(false)
    }

    useEffect(() => {
        eventBus.on(EVENT_TYPE_ALERT_DIALOG, handlerEventAlertDialog)

        return () => {
            eventBus.off(EVENT_TYPE_ALERT_DIALOG, handlerEventAlertDialog)
        }
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            size="small"
            classes={styleAlert.content}
            classesOverlay={style.overlay}
            onClose={handlerAbort}
        >
            <Modal.Header title={t('warning')} />
            <Modal.Body>
                <div className={styleAlert.imageWrap}>
                    <ImgResource
                        isShowLoader={false}
                        resourceKey="baba_lucky_png"
                        classes={styleAlert.image}
                    />
                </div>
                <div className={styleAlert.textBlock}>
                    {!!messageItems.length && (
                        <div>
                            {isSetPerformText && (
                                <span>
                                    {`${t('perform')}: `}
                                </span>
                            )}
                            {messageItems.length === 1 ? (
                                <span className={styleAlert.textBlock_strong}>
                                    {messageItems[0].text}
                                </span>
                            ) : (
                                messageItems.map((item) => (
                                    <div className={styleAlert.textBlock_strong} key={item.id}>
                                        {item.text}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                    {isSetQuestionText && (
                        <div>
                            {t('are_u_sure')}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    textUpper
                    classes={styleAlert.control}
                    size="size40"
                    text={actionConfirmText}
                    onClick={handlerConfirm}
                />
                <Button
                    textUpper
                    classes={styleAlert.control}
                    styleType="bordered"
                    size="size40"
                    text={actionAbortText}
                    onClick={handlerAbort}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default AlertDialog
