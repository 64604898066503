import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { APP_URL } from 'config/app'
import { ContentContainer } from 'layout'
import {
    ReportsFreedomClients,
    ReportsFreedomRanks,
    ReportsFreedomActivePartners,
    ReportsFreedomActivePartnersUsers,
    ReportsFreedomTurnover,
    ReportsFreedomIncome,
} from 'containers'
import { scrollTop } from 'utils/helpers'

const ReportsFreedom: React.FC = () => {
    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer size="half">
            <Switch>
                <Route path={APP_URL.reportsFreedomRanks} component={ReportsFreedomRanks} />
                <Route path={APP_URL.reportsFreedomClients} component={ReportsFreedomClients} />
                <Route exact path={APP_URL.reportsFreedomActivePartners} component={ReportsFreedomActivePartners} />
                <Route path={APP_URL.reportsFreedomActivePartnersUsers} component={ReportsFreedomActivePartnersUsers} />
                <Route path={APP_URL.reportsFreedomTurnover} component={ReportsFreedomTurnover} />
                <Route path={APP_URL.reportsFreedomIncome} component={ReportsFreedomIncome} />

                <Redirect from="*" to={APP_URL.reports} />
            </Switch>
        </ContentContainer>
    )
}

export default ReportsFreedom
