import React, { ForwardedRef, forwardRef } from 'react'
import { LinkProps, Link as ReactRouterLink } from 'react-router-dom'

type LinkPropType = { url?: string } & Omit<LinkProps, 'to'>

const Link = forwardRef<HTMLAnchorElement|HTMLDivElement, LinkPropType>(({
    children,
    className,
    url,
    ...props
}, ref) => {
    return (
        <>
            {url && (
                <ReactRouterLink
                    {...props}
                    className={className}
                    to={url}
                    ref={ref as ForwardedRef<HTMLAnchorElement>}
                >
                    {children}
                </ReactRouterLink>
            )}
            {(!url && className) && (
                <div className={className} ref={ref as ForwardedRef<HTMLDivElement>}>
                    {children}
                </div>
            )}
            {(!url && !className) && (
                <>
                    {children}
                </>
            )}
        </>
    )
})

export default Link
