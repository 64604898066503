import React from 'react'
import cn from 'classnames'

import style from './BlockHeader.module.css'

export type BlockHeaderPropType = {
    classes?: string
}

const BlockHeader: React.FC<BlockHeaderPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.header, classes)}>
            { children }
        </div>
    )
}

export default BlockHeader
