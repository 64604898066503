import React, { useMemo } from 'react'
import cn from 'classnames'

import { IRatingRank } from 'interfaces'
import { SvgResource } from 'components'
import { numberFormat } from 'utils/helpers'
import style from './RatingRank.module.css'

type RatingRankPropType = {
    classes?: string
    isActive?: boolean
    data: IRatingRank
}

const RatingRank: React.FC<RatingRankPropType> = ({ classes, isActive, data }) => {
    const { name, icon } = data

    const rateSum = useMemo(() => numberFormat(data.rate), [data])

    return (
        <div className={cn(style.rank, classes, { [style.rank_active]: isActive })}>
            <div className={cn(style.iconWrap, { [style.iconWrap_active]: isActive })}>
                <SvgResource
                    classes={style.icon}
                    resourceKey={icon}
                    width={30}
                    height={30}
                />
            </div>
            <div className={cn(style.name, { [style.name_active]: isActive })}>
                {name}
            </div>
            <div className={cn(style.rate, { [style.rate_active]: isActive })}>
                {rateSum}
            </div>
        </div>
    )
}

export default RatingRank
