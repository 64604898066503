import React from 'react'

import { IStoreHeader, IStoreFolder, IStoreGoods } from 'interfaces'
import { CardContainer } from 'layout'
import { CatalogShowcaseFolderPropType } from './components/CatalogShowcaseFolder/CatalogShowcaseFolder'
import { CatalogShowcaseHeaderPropType } from './components/CatalogShowcaseHeader/CatalogShowcaseHeader'
import { CatalogShowcaseGoodsPropType } from './components/CatalogShowcaseGoods/CatalogShowcaseGoods'
import { CatalogShowcaseItemAddPropType } from './components/CatalogShowcaseItemAdd/CatalogShowcaseItemAdd'
import {
    CatalogShowcaseFolder,
    CatalogShowcaseHeader,
    CatalogShowcaseGoods,
    CatalogShowcaseItemAdd,
} from './components'

type CatalogShowcasePropType = {
    classes?: string
    data?: (IStoreHeader | IStoreFolder | IStoreGoods)[]
    renderHeader?: (data: IStoreHeader) => JSX.Element
    renderFolder?: (data: IStoreFolder) => JSX.Element
    renderGoods?: (data: IStoreGoods) => JSX.Element
}

type CatalogShowcaseComposition = {
    Folder: React.FC<CatalogShowcaseFolderPropType>
    Header: React.FC<CatalogShowcaseHeaderPropType>
    Goods: React.FC<CatalogShowcaseGoodsPropType>
    Add: React.FC<CatalogShowcaseItemAddPropType>
}

/**
 * Store catalog showcase - headers, folders, goods
 */
const CatalogShowcase: React.FC<CatalogShowcasePropType> & CatalogShowcaseComposition = ({
    children,
    classes,
}) => {
    return (
        <CardContainer classes={classes}>
            {children}
        </CardContainer>
    )
}

CatalogShowcase.Folder = CatalogShowcaseFolder
CatalogShowcase.Header = CatalogShowcaseHeader
CatalogShowcase.Goods = CatalogShowcaseGoods
CatalogShowcase.Add = CatalogShowcaseItemAdd

export default CatalogShowcase
