import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TUserServiceError, TRatingListResponse } from 'services/UserService'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

import { QueryCacheKeys } from 'enums'

export type TFetchFnResponse = TRatingListResponse
export type TFetchFnError = AxiosError<TUserServiceError>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

const key = QueryCacheKeys.ratingList

/**
 * Хук API получить список всех положений в рейтингах
 */
export default function useFetchRatingList(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return UserService.fetchRatingList()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
