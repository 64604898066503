import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IDeliveryAddress } from 'interfaces'
import { Button } from 'components'
import style from './StoreOrderDeliveryItem.module.css'

export type StoreOrderDeliveryItemPropType = {
    classes?: string
    data: IDeliveryAddress
    activeId?: number
    index?: number
    onSelect: (id: number) => void
    onEdit: (data: IDeliveryAddress) => void
    onDelete: (data: IDeliveryAddress) => void
}

const StoreOrderDeliveryItem: React.FC<StoreOrderDeliveryItemPropType> = ({
    classes,
    data,
    activeId,
    index,
    onSelect,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation()

    const handlerSelect = () => {
        onSelect(data.id)
    }

    const handlerEdit = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        onEdit(data)
    }

    const handlerDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        onDelete(data)
    }

    return (
        <div
            className={cn(
                style.block,
                { [style.block_active]: data.id === activeId },
                classes,
            )}
            role="button"
            tabIndex={index}
            onClick={handlerSelect}
            onKeyDown={handlerSelect}
        >
            {/*
                <div className={style.label}>
                    по умолчанию
                </div>
            */}
            <div className={style.name}>
                {data?.full_name}
            </div>

            <div className={style.address}>
                {data?.post_code}
                ,
                {' '}
                {data?.country?.en_name}
                ,
                {' '}
                {data?.city_name}
                ,
                {' '}
                {data?.address1}
            </div>

            <div className={style.controls}>
                <Button
                    classes={style.control}
                    styleType="text"
                    text={t('edit')}
                    onClick={handlerEdit}
                />

                <Button
                    classes={cn(style.control, style.control_danger)}
                    styleType="text"
                    text={t('delete')}
                    onClick={handlerDelete}
                />
            </div>
        </div>
    )
}

export default StoreOrderDeliveryItem
