import React, { useMemo } from 'react'
import cn from 'classnames'

import { IReportMlmRank } from 'interfaces'
import { API_HOST } from 'config/api'
import { Image } from 'components'
import { numberFormat } from 'utils/helpers'
import style from './ReportsFreedomRankCard.module.css'

type ReportsFreedomRankCardPropType = {
    classes?: string
    data: IReportMlmRank
}

const ReportsFreedomRankCard: React.FC<ReportsFreedomRankCardPropType> = ({ classes, data }) => {
    const count = useMemo(() => (data.count !== undefined ? numberFormat(data.count) : undefined), [data])

    return (
        <div className={cn(style.card, classes)}>
            <Image
                classesWrap={style.imageWrap}
                classesImage={style.image}
                isPlaceholder={false}
                src={`${API_HOST}/${data.icon}`}
                width={80}
                height={49}
                resize={{ width: 160, height: 97, quality: 100 }}
            />
            <div className={style.title}>
                {data.name}
            </div>
            <div className={style.text}>
                {count}
            </div>
        </div>
    )
}

export default ReportsFreedomRankCard
