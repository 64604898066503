import React, { useMemo } from 'react'
import cn from 'classnames'

import { ICalendarDay } from 'interfaces'
import { Button } from 'components'
import style from './CalendarMonthCell.module.css'

type CalendarMonthCellPropType = {
    classes?: string
    data: ICalendarDay
    onClickDay: (value: Date) => void
    onClickAddEvent: (value: Date) => void
}

const CalendarMonthCell: React.FC<CalendarMonthCellPropType> = ({
    children,
    classes,
    data,
    onClickDay,
    onClickAddEvent,
}) => {
    const date = useMemo(() => {
        return String(data.date.getDate())
    }, [data])

    const handlerClickDay = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onClickDay(data.date)
    }

    const handlerClickAddEvent = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation()
        onClickAddEvent(data.date)
    }

    return (
        <div
            className={cn(style.cell, classes)}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={handlerClickAddEvent}
        >
            <div className={style.dateWrap}>
                <Button
                    classes={cn(
                        style.date,
                        {
                            [style.date_active]: data.currentMonth,
                            [style.date_current]: data.currentDay,
                        },
                    )}
                    styleType="transparent"
                    onClick={handlerClickDay}
                >
                    {date}
                </Button>
            </div>
            <div className={style.content}>
                {children}
            </div>
        </div>
    )
}

export default CalendarMonthCell
