import React, { useState, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICountry, ICity } from 'interfaces'
import { REGEXP_DATA_IMAGE_BASE64 } from 'config/app'
import { useCacheCities } from 'containers/App/hooks'
import {
    Avatar,
    Cropper,
    Input,
    Radio,
    Button,
    CountryDataSelect,
    CityDataSelect,
    SvgResource,
    Modal,
} from 'components'
import { useUploadFiles } from 'hooks'
import styleForm from 'styles/modules/form.module.css'
import style from './RegistrationUserForm.module.css'

export enum FieldName {
    name = 'name',
    surname = 'surname',
    email = 'email',
    city = 'city',
    birthDate = 'birthDate',
    photo = 'photo',
    gender = 'gender',
    guarantor_code = 'guarantor_code',
}

export type FormDataType = {
    [FieldName.name]: string
    [FieldName.surname]: string
    [FieldName.email]?: string
    [FieldName.city]?: number
    [FieldName.birthDate]?: string
    [FieldName.photo]?: string
    [FieldName.gender]?: 0 | 1
    [FieldName.guarantor_code]?: string
}

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
    [FieldName.surname]: '',
}

export type RegistrationUserFormPropType = {
    isSubmitting: boolean
    maxBirthDayRestrictions: string
    countries: ICountry[]
    country: ICountry
    onChange?: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

const RegistrationUserForm: React.FC<RegistrationUserFormPropType> = ({
    countries,
    country,
    maxBirthDayRestrictions,
    isSubmitting,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const { upload: uploadFiles } = useUploadFiles()
    const { getAll: getAllCacheCities } = useCacheCities()

    const [countryData, setCountryData] = useState<ICountry>(country)
    const [cityData, setCityData] = useState<ICity>()
    const [image, setImage] = useState<string>() // base64
    const [imageCropped, setImageCropped] = useState<string>() // base64
    const [imageCroppedType, setImageCroppedType] = useState<string>() // mime type
    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)

    const { change } = useForm()
    const { values, hasValidationErrors } = useFormState<FormDataType>({
        onChange: (formState) => {
            if (onChange) {
                onChange(formState.values)
            }
        },
    })

    const handlerAddPhoto = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = currentTarget

        if (files?.length) {
            uploadFiles(files, { isUpload: false })
                .then(([data]) => {
                    const { imageBase64 } = data

                    if (imageBase64) {
                        setImage(imageBase64)
                        setIsShowModalAvatar(true)
                    }
                })
                .catch((e) => {
                    //
                })
        }
    }

    const handlerCropAvatar = (value: string, type: string) => {
        setImageCropped(value)
        setImageCroppedType(type)
    }

    const handlerSetAvatar = () => {
        if (imageCropped) {
            change(FieldName.photo, imageCropped)
            setImage(undefined)
            setImageCropped(undefined)
            setIsShowModalAvatar(false)
        }
    }

    useEffect(() => {
        if (values[FieldName.city]) {
            const cacheCities = getAllCacheCities()

            /**
             * Fix: find city data in cache queries for get city name by id
             */
            cacheCities.find(([queryKey, queryValues]) => {
                if (values) {
                    const city = queryValues?.find((item) => item.id === values[FieldName.city])
                    setCityData(city)

                    return city
                }

                return undefined
            })
        }
    }, [values[FieldName.city]])

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.photo}
                        render={({ input, meta }) => (
                            <div className={cn(styleForm.field, style.fieldPhoto)}>
                                <label className={style.labelPhoto}>
                                    <Input
                                        classes={styleForm.inputFile}
                                        type="file"
                                        styleType="clear"
                                        accept={ACCEPT_IMAGE}
                                        onChange={handlerAddPhoto}
                                    />
                                    <Avatar
                                        isResize={false}
                                        src={input.value && REGEXP_DATA_IMAGE_BASE64.test(input.value)
                                            ? input.value
                                            : `data:${imageCroppedType ?? 'image/jpeg'};base64,${input.value}`}
                                        width={96}
                                        height={96}
                                    />
                                    {!input.value && (
                                        <SvgResource
                                            classes={style.iconAddPhoto}
                                            resourceKey="add_photo_svg"
                                            width={96}
                                            height={96}
                                        />
                                    )}
                                </label>
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.name}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    placeholder={`${t('firstname')} *`}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.surname}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    placeholder={`${t('lastname')} *`}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.guarantor_code}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    placeholder={t('Promocode')}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.email}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    placeholder={t('email')}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.birthDate}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn('input-default', { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    type="date"
                                    styleType="default"
                                    max={maxBirthDayRestrictions}
                                    placeholder={t('borndate')}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <div className={cn(styleForm.group, style.group)}>
                        <Field
                            name={FieldName.city}
                            render={({ input }) => (
                                <>
                                    <CountryDataSelect
                                        isShowSelectArrow
                                        classes={cn(
                                            styleForm.groupItem,
                                            styleForm.groupItem_static,
                                            styleForm.groupItem_minWidth,
                                        )}
                                        countries={countries}
                                        selected={countryData}
                                        onChange={(value) => {
                                            input.onChange(undefined)
                                            setCityData(undefined)
                                            setCountryData(value)
                                        }}
                                    />
                                    <CityDataSelect
                                        classes={cn(
                                            styleForm.groupItem,
                                            styleForm.groupItem_static,
                                            styleForm.groupItem_maxWidth,
                                            style.citySelect,
                                        )}
                                        countryId={countryData?.id}
                                        countryLang={countryData?.lang}
                                        selected={cityData}
                                        onChange={(value) => input.onChange(value.id)}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        <FormDataType[FieldName.gender]>
                        name={FieldName.gender}
                        render={({ input }) => (
                            <>
                                <Radio
                                    {...input}
                                    classes={style.radio}
                                    text={t('female')}
                                    value="0"
                                    checked={input.value === 0}
                                    onChange={(e) => input.onChange(Number(e.currentTarget.value))}
                                />
                                <Radio
                                    {...input}
                                    classes={style.radio}
                                    text={t('male')}
                                    value="1"
                                    checked={input.value === 1}
                                    onChange={(e) => input.onChange(Number(e.currentTarget.value))}
                                />
                            </>
                        )}
                    />
                </div>
                <div className={style.controls}>
                    <Button
                        text={t('Сomplete the registration')}
                        size="size44"
                        type="submit"
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                onClose={() => setIsShowModalAvatar(false)}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper
                        source={image}
                        onCrop={handlerCropAvatar}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('install')}
                        size="size40"
                        onClick={handlerSetAvatar}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegistrationUserForm
