import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IMlmRankUser, IReportMlmRank } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { AppScheme, AppTitle } from 'enums'
import { APP_URL } from 'config/app'
import { CardContainer } from 'layout'
import { useFetchMlmReportsRanksCurrentUsers } from 'containers/ReportsFreedom/hooks'
import { ReportsRankUsersListItem } from 'containers/Reports/components'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    InfiniteScroll,
    NoDataInfo,
} from 'components'
import { scrollTop } from 'utils/helpers'

type TReportsFreedomActivePartnersUsersStateProps = {
    type?: keyof typeof AppScheme
    rank?: IReportMlmRank
    dateFilter?: DatePairType
}

const ReportsFreedomActivePartnersUsers: React.FC = () => {
    const { state }: { state?: TReportsFreedomActivePartnersUsersStateProps } = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const [type] = useState<keyof typeof AppScheme>(state?.type ?? AppScheme.pv)
    const [userList, setUserList] = useState<IMlmRankUser[]>([])
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: AppTitle[type] },
        { id: 3, name: t('active_partners'), url: APP_URL.reportsFreedomActivePartners },
        { id: 4, name: state!.rank?.name ?? '' },
    ])

    const { isInitialLoading: isLoading, data: dataReportsRanksCurrentUsers } = useFetchMlmReportsRanksCurrentUsers({
        type,
        limit,
        offset,
        rankId: state?.rank?.id,
        dateFrom: state?.dateFilter?.dateFrom,
        dateTo: state?.dateFilter?.dateTo,
    })

    const handlerOnLoad = ({ page }: { page: number }) => {
        setOffset((prevSate) => prevSate + limit)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (!state?.rank) {
            history.push(APP_URL.reportsFreedomActivePartners)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataReportsRanksCurrentUsers) {
            const { data } = dataReportsRanksCurrentUsers

            setUserList((prevState) => [...prevState, ...data])

            if (data.length < limit) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataReportsRanksCurrentUsers])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            <CardContainer>
                <InfiniteScroll
                    isActive={!isLoading}
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideInfiniteLoader}
                    onLoad={handlerOnLoad}
                >
                    {userList.map((item) => (
                        <ReportsRankUsersListItem
                            data={item}
                            key={item.id}
                        />
                    ))}
                    {!isLoading && !userList.length && (
                        <NoDataInfo text={t('nothing_found')} />
                    )}
                </InfiniteScroll>
            </CardContainer>
        </>
    )
}

export default ReportsFreedomActivePartnersUsers
