import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPost } from 'interfaces'
import { APP_URL } from 'config/app'
import { Context } from 'containers/Community/Context'
import {
    Button,
    SvgResource,
    Popover,
    Share,
    Link,
} from 'components'
import styleCommon from 'styles/modules/common.module.css'
import style from '../../Post.module.css' // FIXME

export type PostFooterPropType = {
    classes?: string
    data: IPost
}

const PostFooter: React.FC<PostFooterPropType> = ({ classes, data }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const {
        isLoading,
        onLikePost,
        onLikesPost,
    } = useContext(Context)

    const [isLoadingLike, setIsLoadingLike] = useState(false)

    const storeUrl = useMemo(() => {
        const { order } = data
        return order?.store_id ? generatePath(APP_URL.store, { id: order.store_id }) : ''
    }, [data])

    const postUrl = useMemo(() => {
        return generatePath(APP_URL.post, { id: data.id })
    }, [data])

    const handlerAddToBasket = () => {
        if (data.order) {
            history.push(generatePath(APP_URL.basketFromOrder, { id: data.order.store_id, orderId: data.order.id }))
        }
    }

    const handlerClickLike = () => {
        setIsLoadingLike(true)
        onLikePost(data)
    }

    const handlerClickLikes = () => {
        if (data.like_count) {
            onLikesPost(data)
        }
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoadingLike(false)
        }
    }, [isLoading])

    return (
        <div className={cn(style.footer, classes)}>
            <div className={style.footerLeft}>
                <div className={style.footerItem}>
                    <Button
                        classes={cn(style.footerAction, styleCommon.clickable, {
                            [styleCommon.updating]: isLoadingLike,
                        })}
                        styleType="text"
                        onClick={handlerClickLike}
                    >
                        {data.is_like ? (
                            <SvgResource
                                classes={cn(style.footerItemIcon, style.iconLikeOn)}
                                resourceKey="ic_post_likeon2_svg"
                                width={32}
                                height={32}
                            />
                        ) : (
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.footerItemIcon, style.iconLikeOff)}
                                resourceKey="ic_post_likeoff2_svg"
                                width={32}
                                height={32}
                            />
                        )}
                    </Button>
                    <Button
                        classes={cn(style.footerCounter, { [style.footerCounter_disabled]: !data.like_count })}
                        styleType="text"
                        title={t('likes')}
                        onClick={handlerClickLikes}
                    >
                        {data.like_count}
                    </Button>
                </div>
                <div className={style.footerItem}>
                    {location.pathname === postUrl ? (
                        <>
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.footerItemIcon, style.iconComment)}
                                resourceKey="ic_post_comments2_svg"
                                width={32}
                                height={32}
                            />
                            <span className={style.footerCounter}>
                                {data.comment_count}
                            </span>
                        </>
                    ) : (
                        <>
                            <Link
                                className={cn(style.footerAction, styleCommon.clickable)}
                                url={`${postUrl}#comment-form`}
                            >
                                <SvgResource
                                    isImgTag={false}
                                    classes={cn(style.footerItemIcon, style.iconComment)}
                                    resourceKey="ic_post_comments2_svg"
                                    width={32}
                                    height={32}
                                />
                            </Link>
                            <Link
                                className={style.footerCounter}
                                url={`${postUrl}#comments`}
                            >
                                {data.comment_count}
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className={style.footerRight}>
                {data.order && (
                    <>
                        <Link
                            className={cn(style.footerItem, style.footerItem_clickable)}
                            title={t('button_cpa_store')}
                            url={storeUrl}
                        >
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.footerItemIcon, style.iconShop)}
                                resourceKey="ic_post_shop2_svg"
                                width={32}
                                height={32}
                            />
                        </Link>
                        <Button
                            classes={cn(style.footerItem, style.footerItem_clickable)}
                            styleType="text"
                            title={t('Add to cart')}
                            onClick={handlerAddToBasket}
                        >
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.footerItemIcon, style.iconCart)}
                                resourceKey="ic_post_cart2_svg"
                                width={32}
                                height={32}
                            />
                        </Button>
                    </>
                )}
                {data.sharing_link && (
                    <div className={style.footerItem}>
                        <Popover
                            classesBody={style.popoverShare}
                            side="left"
                            trigger={(
                                <SvgResource
                                    isImgTag={false}
                                    classes={style.iconShare}
                                    resourceKey="ic_post_share2_andr_svg"
                                    width={32}
                                    height={32}
                                />
                            )}
                        >
                            <Share
                                link={data.sharing_link}
                                text={data.sharing_text}
                            />
                        </Popover>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PostFooter
