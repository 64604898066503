import { createContext } from 'react'

import { IPost, IPostOrder } from 'interfaces'

export type ContextType = {
    isLoading: boolean
    onLikePost: (post: IPost) => void
    onLikesPost: (post: IPost) => void
    onEditPost: (post: IPost) => void
    onDeletePost: (post: IPost, message: string) => void
    onUnsubscribeUser: (userId: number, message: string) => void
    onUnsubscribeUserInStore: (userId: number, storeId: number, message: string) => void
    onUnsubscribeStore: (storeId: number, message: string) => void
    onUnsubscribeCommunity: (communityId: number, message: string) => void
    onUnsubscribeCompany: (companyId: number, message: string) => void
    onBlockUser: (userId: number, message: string) => void
    onShowChek: (data: IPostOrder) => void
}

export const Context = createContext<ContextType>({
    isLoading: false,
    onLikePost: () => {},
    onLikesPost: () => {},
    onEditPost: () => {},
    onDeletePost: () => {},
    onUnsubscribeUser: () => {},
    onUnsubscribeUserInStore: () => {},
    onUnsubscribeStore: () => {},
    onUnsubscribeCommunity: () => {},
    onUnsubscribeCompany: () => {},
    onBlockUser: () => {},
    onShowChek: () => {},
})
