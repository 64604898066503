import React, { useMemo } from 'react'

import { IRatingSum } from 'interfaces'
import { numberFormat } from 'utils/helpers'
import style from './RatingTypeSum.module.css'

type RatingTypeSumPropType = {
    data: IRatingSum
}

const RatingTypeSum: React.FC<RatingTypeSumPropType> = ({ data }) => {
    const { name } = data

    const totalSum = useMemo(() => numberFormat(data.total), [data])

    return (
        <div className={style.rating}>
            <div className={style.name}>
                {name}
            </div>
            <div className={style.total}>
                {totalSum}
            </div>
        </div>
    )
}

export default RatingTypeSum
