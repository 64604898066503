import React from 'react'

import {
    Logo,
    Link,
    MenuNavMain,
} from 'components'
import { useApp } from 'containers/App/hooks'
import style from './NavMenu.module.css'

type NavMenuPropType = {}

const NavMenu: React.FC<NavMenuPropType> = () => {
    const { mainPrivateRoute } = useApp()

    return (
        <>
            <div className={style.colLeft}>
                <Link url={mainPrivateRoute}>
                    <Logo />
                </Link>
            </div>
            <div className={style.colCenter}>
                {/**/}
            </div>
            <div className={style.colRight}>
                <MenuNavMain />
            </div>
        </>
    )
}

export default NavMenu
