import React, { useMemo } from 'react'

import { IStoreHeader } from 'interfaces'
import { Link } from 'components'
import style from './CatalogShowcaseHeader.module.css'

export type CatalogShowcaseHeaderPropType = {
    data: IStoreHeader
    url?: string
    onClick?: (value: IStoreHeader) => void
}

const CatalogShowcaseHeader: React.FC<CatalogShowcaseHeaderPropType> = ({
    children,
    data,
    url,
    onClick,
}) => {
    const background = useMemo(() => {
        if (data.bg_color) {
            return data.bg_color.includes('#') ? data.bg_color : `#${data.bg_color}`
        }
        return undefined
    }, [data])

    const handlerClick = () => {
        if (onClick) {
            onClick(data)
        }
    }

    return (
        <Link
            className={style.header}
            style={{ backgroundColor: background }}
            url={url}
            onClick={onClick && handlerClick}
        >
            <div className={style.name}>
                {data.name}
            </div>
            <div className={style.actions}>
                {children}
            </div>
        </Link>
    )
}

export default CatalogShowcaseHeader
