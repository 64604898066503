import React from 'react'

import { ICommunity } from 'interfaces'
import { Card } from 'components'
import style from './ItemCommunity.module.css'

type ItemCommunityPropType = {
    data: ICommunity
    onClick: (data: ICommunity) => void
}

const ItemCommunity: React.FC<ItemCommunityPropType> = ({ data, onClick }) => {
    const handlerClick = () => {
        onClick(data)
    }

    return (
        <Card.Item
            classes={style.item}
            onClick={handlerClick}
        >
            <Card.Image src={data.community.logo_image} />
            <Card.Body title={data.community.title} />
        </Card.Item>
    )
}

export default ItemCommunity
