import React, { useEffect, useMemo, useState } from 'react'

import { defaultDateFormat } from 'utils/helpers'
import style from './CalendarDayTimeLine.module.css'

type CalendarDayTimeLinePropType = {
    date: Date
    gridItemHeight: number
    timeStartHour: number
}

const CalendarDayTimeLine: React.FC<CalendarDayTimeLinePropType> = ({ date, gridItemHeight, timeStartHour }) => {
    const [offset, setOffset] = useState(0)

    const isShowTimeLine = useMemo(() => defaultDateFormat(date) === defaultDateFormat(new Date()), [date])

    const getOffset = () => {
        const dateCurrent = new Date()
        const time = dateCurrent.getHours() + (dateCurrent.getMinutes() / 60)

        return Math.round((time - timeStartHour) * gridItemHeight)
    }

    useEffect(() => {
        let intervalId = 0

        if (isShowTimeLine) {
            setOffset(getOffset())

            intervalId = window.setInterval(() => {
                setOffset(getOffset())
            }, 60 * 1000)
        }

        return () => {
            if (intervalId) {
                window.clearInterval(intervalId)
            }
        }
    }, [isShowTimeLine])

    return (
        <>
            {isShowTimeLine && (
                <div
                    className={style.line}
                    style={{ top: offset }}
                />
            )}
        </>
    )
}

export default CalendarDayTimeLine
