import React from 'react'
import cn from 'classnames'

import { IPost, IPostFile, IPostFileCommon } from 'interfaces'
import { SLIDER_ITEM_CLASS } from 'config/app'
import { Slider, DeleteButton } from 'components'
import { PostMainImageFile, PostMainImageCheckData, PostMainImageTitle } from './components'
import style from './PostMainImage.module.css'

export type PostMainImagePropType = {
    files: (IPostFile | IPostFileCommon)[]
    classes?: string
    preview?: string // base64
    order?: IPost['order']
    url?: string
    isCanPlayVideo?: boolean
    onDeleteFile?: (id: number) => void
}

const PostMainImage: React.FC<PostMainImagePropType> = ({
    files = [],
    classes,
    preview,
    order,
    url,
    isCanPlayVideo,
    onDeleteFile,
}) => {
    return (
        <>
            {files.length === 1 && (
                <PostMainImageFile
                    classes={classes}
                    file={files[0]}
                    preview={preview}
                    url={url}
                    isCanPlayVideo={isCanPlayVideo}
                >
                    {onDeleteFile && (
                        <DeleteButton onClick={() => onDeleteFile(files[0].id)} />
                    )}
                </PostMainImageFile>
            )}

            {files.length > 1 && (
                <Slider classes={style.slider}>
                    {files.map((file, index) => (
                        <React.Fragment key={file.id || file.src}>
                            {index === 0 ? (
                                <PostMainImageFile
                                    classes={cn(SLIDER_ITEM_CLASS, style.slide)}
                                    file={file}
                                    preview={preview}
                                    url={url}
                                    isCanPlayVideo={isCanPlayVideo}
                                >
                                    {order && (
                                        <PostMainImageCheckData data={order} />
                                    )}

                                    {onDeleteFile && (
                                        <DeleteButton onClick={() => onDeleteFile(file.id)} />
                                    )}
                                </PostMainImageFile>
                            ) : (
                                <PostMainImageFile
                                    classes={cn(SLIDER_ITEM_CLASS, style.slide)}
                                    file={file}
                                >
                                    {'goods_id' in file && file.title && (
                                        <PostMainImageTitle
                                            classes={style.imageTitle}
                                            title={file.title}
                                            goodsId={file.goods_id}
                                        />
                                    )}

                                    {onDeleteFile && (
                                        <DeleteButton onClick={() => onDeleteFile(file.id)} />
                                    )}
                                </PostMainImageFile>
                            )}
                        </React.Fragment>
                    ))}
                </Slider>
            )}
        </>
    )
}

export default PostMainImage
