import React, { useRef } from 'react'
import { CSSTransition as ReactCSSTransition } from 'react-transition-group'
import { CSSTransitionProps as ReactCSSTransitionProps } from 'react-transition-group/CSSTransition'

export type CSSTransitionPropType = ReactCSSTransitionProps & { classes?: string }

/**
 * Component defining entering and exiting transitions
 */
const CSSTransition: React.FC<CSSTransitionPropType> = ({
    children,
    classes,
    ...props
}) => {
    const nodeRef = useRef(null)

    return (
        <ReactCSSTransition nodeRef={nodeRef} {...props}>
            <div className={classes} ref={nodeRef}>
                {children}
            </div>
        </ReactCSSTransition>
    )
}

export default CSSTransition
