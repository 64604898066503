import React from 'react'
import { useTranslation } from 'react-i18next'

import { IReportMlmRank } from 'interfaces'
import { API_HOST } from 'config/api'
import { Image } from 'components'
import style from './ReportsFreedomRankUser.module.css'

type ReportsFreedomRankUserPropType = {
    data: IReportMlmRank
}

const ReportsFreedomRankUser: React.FC<ReportsFreedomRankUserPropType> = ({ data }) => {
    const { t } = useTranslation()

    return (
        <div className={style.wrap}>
            <Image
                classesWrap={style.imageWrap}
                isPlaceholder={false}
                src={`${API_HOST}/${data.icon}`}
                width={100}
                height={61}
                resize={{ width: 200, height: 122, quality: 100 }}
            />
            <div className={style.body}>
                <div className={style.tile}>
                    {data.name}
                </div>
                <div className={style.text}>
                    {t('reports_freedom_max_rank')}
                </div>
            </div>
        </div>
    )
}

export default ReportsFreedomRankUser
