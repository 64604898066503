import React, { useEffect, useMemo, useState } from 'react'

import {
    IStoreProfile,
    IStoreHeader,
    IStoreFolder,
    IGoodsData,
} from 'interfaces'
import { STORE_ID_STICKERS } from 'config/app'
import { CardContainer } from 'layout'
import { Catalog } from 'containers'
import {
    useFetchInfiniteStores,
    useFetchStoreProfile,
    useFetchStoreCatalog,
    useFetchStoreShowcase,
} from 'containers/Store/hooks'
import { Loader, InfiniteScroll, ErrorMsg } from 'components'
import { CatalogProductSelectorStore } from './components'

type CatalogProductSelectorPropType = {
    classes?: string
    onSelectGoods: (data: IGoodsData) => void
}

const LIMIT = 12
const OFFSET = 0

const CatalogProductSelector: React.FC<CatalogProductSelectorPropType> = ({ classes, onSelectGoods }) => {
    const [storeId, setStoreId] = useState<number | undefined>()
    const [catalogId, setCatalogId] = useState<number | undefined>()
    const [isHideLoaderStores, setIsHideLoaderStores] = useState(false)

    const isStoreShowcase = useMemo(() => storeId === STORE_ID_STICKERS, [storeId])

    const {
        isInitialLoading: isLoadingStores,
        data: dataStores,
        error: errorStores,
        fetchNextPage: fetchNextPageStores,
    } = useFetchInfiniteStores({ limit: LIMIT, offset: OFFSET })

    const {
        isInitialLoading: isLoadingStoreProfile,
        data: dataStoreProfile,
        error: errorStoreProfile,
    } = useFetchStoreProfile({
        id: storeId ?? 0,
    }, {
        enabled: !!storeId,
    })

    const {
        isInitialLoading: isLoadingStoreCatalog,
        data: dataStoreCatalog,
        error: errorStoreCatalog,
    } = useFetchStoreCatalog({
        storeId: storeId ?? 0,
        node: catalogId,
        view: 'extra-plain',
    }, {
        enabled: !!storeId && !isStoreShowcase,
    })

    const {
        isInitialLoading: isLoadingStoreShowcase,
        data: dataStoreShowcase,
        error: errorStoreShowcase,
    } = useFetchStoreShowcase({
        storeId: storeId ?? 0,
    }, {
        enabled: !!storeId && isStoreShowcase,
    })

    const handlerLoadStores = () => {
        if (!isLoadingStores) {
            fetchNextPageStores()
        }
    }

    const handlerSelectStore = ({ id }: IStoreProfile) => {
        if (id) {
            setStoreId(id)
        }
    }

    const handlerClickCatalogFolder = ({ id }: IStoreHeader | IStoreFolder) => {
        if (id) {
            setCatalogId(id)
        }
    }

    useEffect(() => {
        if (dataStores) {
            const { pages } = dataStores
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoaderStores(true)
            }
        }
    }, [dataStores])

    return (
        <div className={classes}>
            {isLoadingStoreProfile && (
                <Loader />
            )}

            {!isLoadingStores && (errorStores || errorStoreProfile || errorStoreCatalog || errorStoreShowcase) && (
                <ErrorMsg error={(errorStores && errorStores[0])
                    || (errorStoreProfile && errorStoreProfile[0])
                    || (errorStoreCatalog && errorStoreCatalog[0])
                    || (errorStoreShowcase && errorStoreShowcase[0])
                    || ''}
                />
            )}

            {!isLoadingStoreProfile && !dataStoreProfile && (
                <InfiniteScroll isHideLoader={isHideLoaderStores} onLoad={handlerLoadStores}>
                    <CardContainer>
                        {dataStores?.pages.map((page, i) => (
                            // eslint-disable-next-line
                            <React.Fragment key={i}>
                                {page.map((item) => (
                                    <CatalogProductSelectorStore
                                        data={item}
                                        key={item.id}
                                        onClick={handlerSelectStore}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </CardContainer>
                </InfiniteScroll>
            )}

            {dataStoreProfile && (
                <Catalog
                    isSetItemLink={false}
                    isShowBuyButton={false}
                    isLoading={isLoadingStoreCatalog || isLoadingStoreShowcase}
                    storeProfile={dataStoreProfile}
                    data={dataStoreCatalog || dataStoreShowcase}
                    onClickFolder={handlerClickCatalogFolder}
                    onClickGoods={onSelectGoods}
                />
            )}
        </div>
    )
}

export default CatalogProductSelector
