import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { ICurrency } from 'interfaces'
import { DirectoryService } from 'services'

const key = 'currencies'

export type TError = string
export type TQueryKey = string[]
export type TQueryFnOpts = UseQueryOptions<ICurrency[], TError, ICurrency[], TQueryKey>

/**
 * Хук API получение списка валют
 */
export default function useFetchCurrencies({
    cacheTime = 3600 * 1000, // 1h
    staleTime = 1800 * 1000, // 30m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key], () => {
        return DirectoryService.fetchCurrencies()
            .then(({ data }) => {
                return data
            })
            .catch(() => {
                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
