import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICurrency, IPayout } from 'interfaces'
import { Image } from 'components'
import { priceFormat, getCountFractionDigits } from 'utils/helpers'
import style from './WalletItem.module.css'

type WalletPayoutPropType = {
    classes?: string
    data: IPayout
    index: number
    convertedCurrency?: Partial<ICurrency>
    onClick: (value: IPayout) => void
}

const WalletItem: React.FC<WalletPayoutPropType> = ({
    classes,
    data,
    index,
    convertedCurrency,
    onClick,
}) => {
    const { t } = useTranslation()

    const amountSum = useMemo(() => {
        const { convertedBalanceAmount } = data
        const fractionDigits = getCountFractionDigits(convertedCurrency?.roundScale)

        return priceFormat(convertedBalanceAmount ?? 0, {
            symbol: '',
            isoCode: '',
            leftSymbolPlacement: false,
        }, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
    }, [data, convertedCurrency])

    const handlerClick = () => {
        onClick(data)
    }

    return (
        <div
            className={cn(style.payout, classes)}
            style={{ backgroundColor: data.currencyPlaceholderColor }}
            role="button"
            tabIndex={index}
            onClick={handlerClick}
            onKeyDown={handlerClick}
        >
            <Image
                classesWrap={style.background}
                src={data.currencyIcon}
                isPlaceholder={false}
                width={400}
                height={267}
                resize={{ width: 400, quality: 90 }}
            />

            <div className={style.body}>
                <div className={style.balance}>
                    <div className={style.amount}>
                        {amountSum}
                    </div>
                    <div className={style.currency}>
                        {convertedCurrency?.showCode}
                    </div>
                </div>
                <div className={style.desc}>
                    {t('balance')}
                </div>
            </div>
        </div>
    )
}

export default WalletItem
