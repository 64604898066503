import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBasket } from 'interfaces'
import {
    TMarketServiceError,
    TBasketProps,
    TBasketResponse,
    TBasketFromOrderProps,
    TBasketFromOrderResponse,
    TBasketUpdateProps,
    TUpdateBasketResponse,
    TBasketClearProps,
    TClearBasketResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TBasketProps
export type TFetchFnError = AxiosError<TMarketServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TBasketResponse, TError, TBasketResponse, TQueryKey>

export type TGetFnParams = TBasketFromOrderProps
export type TGetFnError = AxiosError<TMarketServiceError, TGetFnParams>
export type TGetError = [string, TGetFnError]
export type TGetQueryKey = [string, TFetchFnParams]
export type TGetQueryFnOpts = UseQueryOptions<
    TBasketFromOrderResponse,
    TGetError,
    TBasketFromOrderResponse,
    TGetQueryKey
>

export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TBasketResponse,
    TError,
    TBasketResponse,
    TBasketResponse,
    TQueryKey
>

export type TUpdateMutationFnError = [string, AxiosError<TMarketServiceError, TBasketUpdateProps>]
export type TClearMutationFnError = [string, AxiosError<TMarketServiceError, TBasketClearProps>]

const key = QueryCacheKeys.basket

/**
 * Хук API получить корзину магазина
 */
export default function useFetchBasket(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchBasket(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

/**
 * Хук API получить корзину из заказа
 */
export function useGetOrderBasket(params: TGetFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.getBasketFromOrder(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TGetFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationBasket() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const update = useMutation<TUpdateBasketResponse, TUpdateMutationFnError, TBasketUpdateProps, unknown>((props) => {
        return MarketService.updateBasket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        onSuccess: (data, { storeId }) => {
            setQueryData(data, { storeId })
        },
    })

    const clear = useMutation<TClearBasketResponse, TClearMutationFnError, TBasketClearProps, unknown>((props) => {
        return MarketService.clearBasket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        onSuccess: (data, { storeId }) => {
            setQueryData(data, { storeId })
        },
    })

    function setQueryData(data: IBasket, params: TFetchFnParams) {
        return queryClient.setQueryData<IBasket>([`${key}-${user.id}`, params], (cacheData) => {
            if (cacheData) {
                return { ...cacheData, ...data }
            }

            return data
        })
    }

    return {
        update,
        clear,
    }
}

export function useInvalidateBasket() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = (
        params: TFetchFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}
