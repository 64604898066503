import React from 'react'
import { useTranslation } from 'react-i18next'

import { MenuStatic, SvgResource } from 'components'
import style from './FooterLK.module.css'

const FooterLK: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.footer}>
            <MenuStatic
                isPublic={false}
                classesNav={style.menu}
                classesItem={style.item}
                classesLink={style.navLink}
            />
            <div className={style.copyright}>
                {t('powered_by_sessia')}
            </div>
            <SvgResource
                isImgTag={false}
                classes={style.copyrightIcon}
                resourceKey="logo_sessia_svg"
                width={48}
                height={62}
            />
        </div>
    )
}

export default FooterLK
