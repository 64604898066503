import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Lines } from 'enums'
import { Button } from 'components'

import styleCommon from '../../../../styles/modules/common.module.css'
import style from './ReportsFreedomClientsLineFilter.module.css'

type ReportsFreedomClientsLineFilterPropType = {
    classes?: string
    defaultLine?: Lines
    activeColor?: string
    isLoading?: boolean
    onChange: (line?: Lines) => void
}

const ReportsFreedomClientsLineFilter: React.FC<ReportsFreedomClientsLineFilterPropType> = ({
    classes,
    defaultLine,
    activeColor,
    isLoading,
    onChange,
}) => {
    const { t } = useTranslation()

    const [lineState] = useState([
        // Lines.all,
        Lines.one,
        Lines.two,
        Lines.three,
        Lines.four,
        Lines.five,
        Lines.six,
    ])

    const [activeLine, setActiveLine] = useState<Lines | undefined>(defaultLine)

    const activeStyles = useMemo(() => {
        return activeColor ? { backgroundColor: activeColor, borderColor: activeColor } : undefined
    }, [activeColor])

    const handlerClickLine = ({ currentTarget }: React.SyntheticEvent<HTMLButtonElement>) => {
        setActiveLine(Lines[currentTarget.name])
    }

    const handlerClickReset = () => {
        setActiveLine(undefined)
    }

    useEffect(() => {
        onChange(activeLine)
    }, [activeLine])

    return (
        <div className={cn(style.filter, classes, { [styleCommon.updating]: isLoading })}>
            <Button
                classes={cn(
                    style.lineAction,
                    { [style.lineAction_active]: !activeLine },
                )}
                style={!activeLine ? activeStyles : undefined}
                styleType="custom"
                size="size32"
                onClick={handlerClickReset}
            >
                {t('ui_charts_period_all')}
            </Button>
            {lineState.map((line) => (
                <Button
                    classes={cn(
                        style.lineAction,
                        { [style.lineAction_active]: line === activeLine },
                    )}
                    style={line === activeLine ? activeStyles : undefined}
                    styleType="custom"
                    size="size32"
                    name={Lines[line]}
                    key={line}
                    onClick={handlerClickLine}
                >
                    {line === 0 ? (
                        t('ui_charts_period_all')
                    ) : (
                        line
                    )}
                </Button>
            ))}
        </div>
    )
}

export default ReportsFreedomClientsLineFilter
