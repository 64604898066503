import React from 'react'
import cn from 'classnames'

import style from './CardFooter.module.css'

export type CardFooterPropType = {
    classes?: string
}

const CardFooter: React.FC<CardFooterPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.footer, classes)}>
            {children}
        </div>
    )
}

export default CardFooter
