import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { IMAGE_RESOURCE_PATH } from 'config/api'
import { DirectoryService } from 'services'
import styleCommon from 'styles/modules/common.module.css'
import style from './SvgResource.module.css'

export type SvgResourcePropType = {
    resourceKey: string
    classes?: string
    width?: number
    height?: number
    placeholder?: string
    title?: string
    isImgTag?: boolean
}

const setUniqueClassSvg = (svgSource: string, key: string) => {
    const classAttributes = svgSource.match(/class=".*?"/g) || []
    const classNames = Array.from(classAttributes).reduce<string[]>((acc, item) => {
        const [, className = ''] = item.match(/"(.*?)"/) || []
        return className && !acc.includes(className) ? [...acc, className] : acc
    }, [])
    const classNamesUnique = classNames.map((item) => `${item}_${key}`)
    let result = svgSource

    classNames.forEach((item, i) => {
        result = result.replaceAll(item, classNamesUnique[i])
    })

    return result
}

const SvgResource: React.FC<SvgResourcePropType> = ({
    resourceKey,
    classes,
    width,
    height,
    placeholder,
    title,
    isImgTag = true,
}) => {
    const [url, setUrl] = useState('')
    const [svg, setSvg] = useState('')

    useEffect(() => {
        const path = `${IMAGE_RESOURCE_PATH}/${resourceKey}`

        if (isImgTag) {
            setUrl(path)
        } else {
            DirectoryService.fetchResource({ path })
                .then((res) => {
                    setSvg(setUniqueClassSvg(res, resourceKey))
                })
                .catch(() => {
                    //
                })
        }
    }, [resourceKey])

    return (
        <>
            {isImgTag ? (
                <img
                    className={cn(classes, styleCommon.image, { [styleCommon.image_loading]: !url })}
                    src={url}
                    width={width}
                    height={height}
                    title={title}
                    alt={placeholder}
                />
            ) : (
                <div
                    className={cn(classes, style.svg)}
                    style={{ width, height }}
                    dangerouslySetInnerHTML={{ __html: svg }}
                    title={title}
                />
            )}
        </>
    )
}

export default SvgResource
