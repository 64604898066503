import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
    IGoodsData,
    IPost,
    IPostContent,
    IPostFile,
    IPostFileCommon,
    ICommunityDefaultImage,
    IPostContentAttacheParams,
    ICommunity,
} from 'interfaces'
import {
    TPostProps,
    TAddFileFnParams,
    TAddGoodsFnParams,
    TAddImageFnParams,
} from 'hooks/usePost'
import { FormDataType, FieldName } from 'forms/PostForm/PostForm'
import { TAddPostProps, TUpdatePostProps } from 'services/CommunityService'
import { useMutationPost } from 'containers/Community/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { CatalogProductSelector, PhotoBankImageSelector, Modal } from 'components'
import { PostForm } from 'forms'
import { useAlertDialog, usePost } from 'hooks'
import { showAlertNotify, getFileTypeByMimeType, getId } from 'utils/helpers'
import styleModalBody from 'components/Modal/ModalBody/ModalBody.module.css'
import styleModalFooter from 'components/Modal/ModalFooter/ModalFooter.module.css'
import styleOverlay from 'styles/modules/overlay.module.css'

export type PostActionPropType = {
    isActiveCommunity: boolean
    postProps: TPostProps
    communities?: ICommunity[]
    onComplete?: (data: IPost) => void
}

type PostFiles = (IPostFile | IPostFileCommon)[]

const PostAction: React.FC<PostActionPropType> = ({
    isActiveCommunity,
    postProps,
    communities,
    onComplete = () => {},
}) => {
    const isMounted = useRef(false)
    const { t } = useTranslation()
    const { showAlertDialog } = useAlertDialog()

    const profile = useSelector(userSelectors.profile)

    const {
        getMainText,
        getMainFiles,
        getContent,
        getTextResult,
        getFilesResult,
        addFile,
        addGoods,
        addImage,
        addContent,
        deletePostFile,
        deleteContentFile,
    } = usePost()

    const [props] = useState<FormDataType>(postProps)

    /** All added post files */
    const [postFiles, setPostFiles] = useState<PostFiles>(getMainFiles(props[FieldName.files]))
    /** Parsed post text to array by image or gallery tags */
    const [postContent, setPostContent] = useState<IPostContent[]>(getContent(
        props[FieldName.text],
        props[FieldName.files],
    ))
    const [initialValues] = useState<FormDataType>({
        ...props,
        [FieldName.text]: getMainText(props[FieldName.text]),
    })
    const [goodsAttachParams, setGoodsAttachParams] = useState<IPostContentAttacheParams>({})
    const [imageAttachParams, setImageAttachParams] = useState<IPostContentAttacheParams>({})

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenModalCatalog, setIsOpenModalCatalog] = useState(false)
    const [isOpenModalImage, setIsOpenModalImage] = useState(false)

    const { addPost, updatePost } = useMutationPost()

    const handlerCloseModalCatalog = () => {
        closeModalCatalog()
    }

    const handlerCloseModalImage = () => {
        closeModalImage()
    }

    const handlerSubmit = (params: FormDataType) => {
        const text = getTextResult(params.text, postContent)
        const files = getFilesResult(postFiles, postContent)

        if ('id' in params) {
            updatePostAction({ ...params, text, files })
        } else {
            addPostAction({ ...params, text, files })
        }
    }

    const handlerAddFiles = (files: FileList, params: IPostContentAttacheParams) => {
        const [file] = files

        if (file) {
            addFileAction({ file, params, data: { files: postFiles, content: postContent } })
        }
    }

    const handlerAddGoods = (params: IPostContentAttacheParams) => {
        setGoodsAttachParams(params)
        setIsOpenModalCatalog(true)
    }

    const handlerAddImage = (params: IPostContentAttacheParams) => {
        setImageAttachParams(params)
        setIsOpenModalImage(true)
    }

    const handlerChangeContentDesc = (id: number, value: string) => {
        setPostContent((prevState) => prevState.map((item) => {
            return item.id === id ? { ...item, html: value } : item
        }))
    }

    const handlerSelectGoods = (goods: IGoodsData) => {
        const { id: goodsId, images = [] } = goods || {}
        const [image] = images

        if (goodsId && image) {
            addGoodsAction({ goods, params: goodsAttachParams, data: { files: postFiles, content: postContent } })
            closeModalCatalog()
        }
    }

    const handlerSelectImage = (image: ICommunityDefaultImage) => {
        addImageAction({ image, params: imageAttachParams, data: { files: postFiles, content: postContent } })
        closeModalImage()
    }

    const handlerDeletePostFile = (id: number) => {
        showAlertDialog({
            message: t('delete'),
            payload: { id },
            onConfirm: removePostFile,
        })
    }

    const handlerDeleteContentFile = (id: number) => {
        showAlertDialog({
            message: t('delete'),
            payload: { id },
            onConfirm: removeContentFile,
        })
    }

    function closeModalCatalog() {
        setGoodsAttachParams({})
        setIsOpenModalCatalog(false)
    }

    function closeModalImage() {
        setImageAttachParams({})
        setIsOpenModalImage(false)
    }

    function addPostFile(params: IPostFileCommon) {
        setPostFiles((prevState) => [...prevState, params])
    }

    function removePostFile({ id }: { id: number }) {
        setPostFiles((prevState) => deletePostFile(prevState, id))
    }

    function addPostContent(data: IPostContent, groupId?: number) {
        setPostContent((prevState) => addContent(prevState, data, groupId))
    }

    function removeContentFile({ id }: { id: number }) {
        setPostContent((prevState) => deleteContentFile(prevState, id))
    }

    function addPostAction(params: TAddPostProps) {
        setIsSubmitting(true)
        addPost.mutate(params, {
            onSuccess: (res) => {
                onComplete(res)
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
            onSettled: () => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            },
        })
    }

    function updatePostAction(params: TUpdatePostProps) {
        setIsSubmitting(true)
        updatePost.mutate(params, {
            onSuccess: (res) => {
                onComplete(res)
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
            onSettled: () => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            },
        })
    }

    function addFileAction({ file, params: { isAttach, contentId }, data }: TAddFileFnParams) {
        setIsSubmitting(true)
        addFile({ file, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: getFileTypeByMimeType(fileData.type).type,
                        html: '',
                        text: '',
                        files: [fileData],
                    }, fileData.fileGroup)
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function addGoodsAction({ goods, params: { isAttach, contentId }, data }: TAddGoodsFnParams) {
        const { name } = goods

        setIsSubmitting(true)
        addGoods({ goods, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: 'image',
                        html: '',
                        text: name,
                        files: [fileData],
                    })
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function addImageAction({ image, params: { isAttach, contentId }, data }: TAddImageFnParams) {
        addImage({ image, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: 'image',
                        html: '',
                        text: '',
                        files: [fileData],
                    })
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handlerSubmit}
                validate={(values) => {
                    const errors = {}

                    if (values[FieldName.title] === undefined || values[FieldName.title] === '') {
                        errors[FieldName.title] = t('error_field_is_empty')
                    }
                    if (values[FieldName.text] === undefined || values[FieldName.text] === '') {
                        errors[FieldName.text] = t('error_field_is_empty')
                    }

                    return errors
                }}
                render={({ handleSubmit }) => (
                    <PostForm
                        classesContent={styleModalBody.body}
                        classesControls={styleModalFooter.footer}
                        profile={profile}
                        isActiveCommunity={isActiveCommunity}
                        isSubmitting={isSubmitting}
                        files={postFiles}
                        content={postContent}
                        communities={communities}
                        onAddFiles={handlerAddFiles}
                        onAddGoods={handlerAddGoods}
                        onAddImage={handlerAddImage}
                        onChangeContentDesc={handlerChangeContentDesc}
                        onDeletePostFile={handlerDeletePostFile}
                        onDeleteContentFile={handlerDeleteContentFile}
                        onSubmit={handleSubmit}
                    />
                )}
            />

            <Modal
                isOpen={isOpenModalCatalog}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={handlerCloseModalCatalog}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('market_select_product')}
                />
                <Modal.Body background>
                    <CatalogProductSelector
                        onSelectGoods={handlerSelectGoods}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isOpenModalImage}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={handlerCloseModalImage}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('posts_default_images')}
                />
                <Modal.Body background>
                    <PhotoBankImageSelector
                        onSelectImage={handlerSelectImage}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PostAction
