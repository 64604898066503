import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TSupportServiceError,
    TTicketProps,
    TTicketResponse,
    TAddTicketProps,
    TAddTicketResponse,
} from 'services/SupportService'
import { QueryCacheKeys } from 'enums'
import { SupportService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TTicketProps
export type TFetchFnError = AxiosError<TSupportServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TTicketResponse, TError, TTicketResponse, TQueryKey>

export type TAddTicketMutationFnError = [string, AxiosError<TSupportServiceError, TAddTicketProps>]

const key = QueryCacheKeys.ticket

export default function useFetchTicket(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    const queryKey = user ? `${key}-${user.id}` : key

    return useQuery([queryKey, params], () => {
        return SupportService.fetchTicket(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationTicket() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddTicketResponse,
        TAddTicketMutationFnError,
        TAddTicketProps,
        unknown
    >((props) => {
        return SupportService.addTicket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
    }
}
