import React, { useMemo } from 'react'

import { MessengerItemInner } from 'containers/Messenger/components'
import style from './MessengerSearchItem.module.css'

type MessengerSearchItemPropType = {
    classes?: string
    avatar: JSX.Element
    title: string
    date: string
    searchText: string
    message?: string | null
    url?: string
}

/**
 * Search item with highlight content text
 */
const MessengerSearchItem: React.FC<MessengerSearchItemPropType> = ({
    classes,
    avatar,
    title,
    date,
    message,
    searchText,
    url,
}) => {
    const contentText = useMemo(() => {
        return message
            ? message.replace(new RegExp(searchText, 'i'), `<span class="${style.highlight}">$&</span>`)
            : ''
    }, [searchText])

    return (
        <MessengerItemInner
            isSimpleMessage
            classes={classes}
            title={title}
            avatar={avatar}
            message={contentText}
            date={date}
            url={url}
        />
    )
}

export default MessengerSearchItem
