import React from 'react'

import style from './MessengerFooter.module.css'

const MessengerFooter: React.FC = ({ children }) => {
    return (
        <div className={style.footer}>
            {children}
        </div>
    )
}

export default MessengerFooter
