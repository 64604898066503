import React, { useMemo } from 'react'
import { useForm, useField } from 'react-final-form'
import cn from 'classnames'

import { ICommunity } from 'interfaces'
import { CustomSelect } from 'components'
import style from './FieldCommunity.module.css'

type FieldCommunityPropType = {
    classes?: string
    classesField?: string
    name: string
    communities?: ICommunity[]
    activeId?: number
    disabled?: boolean
}

const FieldCommunity: React.FC<FieldCommunityPropType> = ({
    classes,
    classesField,
    name,
    communities,
    activeId,
    disabled,
}) => {
    const { mutators } = useForm()
    const { input } = useField<number>(name)

    const community = useMemo(() => {
        return input.value && communities
            ? communities.find((item) => item.community.id === input.value)
            : undefined
    }, [input.value, communities])

    const handlerChange = (id: number) => {
        if (name in mutators) {
            mutators[name](name, id)
        }
    }

    return (
        <CustomSelect.Select classes={classes}>
            <CustomSelect.Field
                classes={cn(style.field, classesField, { [style.field_disabled]: disabled })}
                disabled={disabled || !communities}
            >
                {community?.community.title}
            </CustomSelect.Field>
            <CustomSelect.Options>
                {communities?.map((item) => (
                    <CustomSelect.Option
                        id={item.community.id}
                        isActive={item.community.id === activeId}
                        key={item.community.id}
                        onClick={handlerChange}
                    >
                        {item.community.title}
                    </CustomSelect.Option>
                ))}
            </CustomSelect.Options>
        </CustomSelect.Select>
    )
}

export default FieldCommunity
