import React, { useEffect, useState } from 'react'

import { IMessengerChat, IMessengerChannel, IUser } from 'interfaces'
import { TConversationListResponse } from 'services/ChatMicroService'
import { useFetchInfiniteConversationList } from 'containers/Messenger/hooks'
import { Loader, InfiniteScroll } from 'components'

type MessengerConversationListPropType = {
    user: IUser
    limit?: number
    render: (data: IMessengerChat | IMessengerChannel) => void
    onLoad?: (res: TConversationListResponse) => void
}

const MessengerConversationList: React.FC<MessengerConversationListPropType> = ({
    user,
    limit = 10,
    render,
    onLoad = () => {},
}) => {
    const [isHideLoaderConversationList, setIsHideLoaderConversationList] = useState(false)

    const {
        isInitialLoading: isLoadingConversationList,
        data: dataConversationList,
        fetchNextPage: fetchNextPageConversationList,
    } = useFetchInfiniteConversationList({
        userId: user.id,
        page: 1,
        paginatedBy: limit,
    }, {
        enabled: !!user,
        staleTime: 60 * 1000, // 1m
    })

    const handlerLoadConversationList = () => {
        fetchNextPageConversationList()
    }

    useEffect(() => {
        if (dataConversationList) {
            const { pages } = dataConversationList
            const pagesLen = pages.length
            const lastPage = pagesLen ? pages[pagesLen - 1] : undefined

            if (lastPage) {
                onLoad(lastPage)
            }
            if (lastPage && lastPage.currentPage === lastPage.totalPages) {
                setIsHideLoaderConversationList(true)
            }
        }
    }, [dataConversationList])

    return (
        <>
            {isLoadingConversationList && (
                <Loader />
            )}
            <InfiniteScroll
                isActive={!!dataConversationList}
                isHideLoader={isHideLoaderConversationList}
                onLoad={handlerLoadConversationList}
            >
                {dataConversationList?.pages.map((page) => (
                    <React.Fragment key={page.currentPage}>
                        {page.data.map((item) => (
                            <React.Fragment key={item.id}>
                                {render(item)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </InfiniteScroll>
        </>
    )
}

export default MessengerConversationList
