import React, { useState } from 'react'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, SvgResource } from 'components'
import { FieldFile, FieldStickers, FieldTextContentEditable } from 'forms/fields'
import styleForm from 'styles/modules/form.module.css'
import styleInput from 'components/Input/Input.module.css'
import style from './MessageForm.module.css'

export enum FieldName {
    text = 'text',
    sticker = 'sticker',
    file = 'file',
}

export type FormDataType = {
    [FieldName.text]: string
    [FieldName.sticker]?: string
    [FieldName.file]?: string
}

export const formDataInitial: FormDataType = {
    [FieldName.text]: '',
    [FieldName.sticker]: '',
    [FieldName.file]: '',
}

export type MessageFormPropType = {
    classes?: string
    isFocus?: boolean
    isDisabled?: boolean
    isSubmitting?: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ACCEPT_FILES = 'image/jpeg,image/png,image/gif,video/mp4,video/mpeg,video/quicktime'

const MessageForm: React.FC<MessageFormPropType> = ({
    children,
    classes,
    isFocus,
    isDisabled,
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { hasValidationErrors, values } = useFormState<FormDataType>()

    const [isUploading, setIsUploading] = useState(false)

    const handlerUploading = (value: boolean) => {
        setIsUploading(value)
    }

    return (
        <form
            className={cn(
                style.form,
                {
                    [style.form_disabled]: isDisabled,
                    [styleForm.submitting]: isUploading || isSubmitting,
                },
                classes,
            )}
            onSubmit={onSubmit}
        >
            <FieldStickers
                classes={style.actionStickers}
                name={FieldName.sticker}
            >
                <SvgResource
                    resourceKey="ic_chat_stickers_svg"
                    width={20}
                    height={20}
                />
            </FieldStickers>
            <div className={cn(style.fieldInput, styleInput.gray)}>
                {children}
                <FieldTextContentEditable
                    classes={style.textContent}
                    name={FieldName.text}
                    isFocus={isFocus}
                    isDisabled={isDisabled}
                />
            </div>
            <FieldFile
                classes={style.actionAttach}
                accept={ACCEPT_FILES}
                name={FieldName.file}
                onUploading={handlerUploading}
            >
                <SvgResource
                    resourceKey="ic_chat_attach_svg"
                    width={20}
                    height={20}
                />
            </FieldFile>
            <Button
                disabled={isDisabled || !values[FieldName.text] || hasValidationErrors}
                classes={style.actionSend}
                styleType="transparent"
                title={t('send')}
                type="submit"
            >
                <SvgResource
                    resourceKey="ic_chat_send_svg"
                    width={20}
                    height={20}
                />
            </Button>
        </form>
    )
}

export default MessageForm
