import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITicketCategory, IUploadedFileData } from 'interfaces'
import { AppDispatch } from 'store'
import { FormDataType } from 'forms/SupportForm/SupportForm'
import { TAddTicketProps } from 'services/SupportService'
import { APP_VERSION } from 'config/app'
import { SupportForm } from 'forms'
import { addTicket } from 'containers/Support/support-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { SupportService, SimService } from 'services'
import { getFileImageElement, showAlertNotify } from 'utils/helpers'

import styleModalBody from 'components/Modal/ModalBody/ModalBody.module.css'
import styleModalFooter from 'components/Modal/ModalFooter/ModalFooter.module.css'

export type SupportActionPropType = {
    guid: string
    classes?: {
        body?: string
        footer?: string
    }
    onComplete?: () => void
}

const ticketData = {
    app_version: (APP_VERSION as string),
    os_version: window.navigator.userAgent,
    phone_model: window.navigator.userAgent,
}

const SupportAction: React.FC<SupportActionPropType> = ({ guid, classes, onComplete = () => {} }) => {
    const isMounted = useRef(false)
    const { i18n } = useTranslation()
    const dispatch: AppDispatch = useDispatch()

    const user = useSelector(userSelectors.user)

    const [formData] = useState({
        name: user ? user.name : '',
        email: user ? user.email : '',
        phone: user?.user_phones?.length ? user.user_phones[0].phone_number : '',
    })

    const [formAttaches, setFormAttaches] = useState<string[]>([])
    const [categories, setCategories] = useState<ITicketCategory[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const lang = useMemo(() => i18n.language, [i18n.language])

    const handlerSubmit = (supportFormData: FormDataType) => {
        addTicketAction(supportFormData)
    }

    const handlerAddAttach = (file: File) => {
        fileUploadAction(file)
            .then(({ path }) => {
                getFileImageElement(file)
                    .then(({ width, height }) => {
                        const url = `${path}?sswidth=${width}&ssheight=${height}`
                        setFormAttaches((prevState) => [...prevState, url])
                    })
                    .catch(() => {
                        setFormAttaches((prevState) => [...prevState, path])
                    })
            })
            .catch(() => {
                //
            })
    }

    const handlerRemoveAttach = () => {
        setFormAttaches([])
    }

    function getTicketActionParams(supportFormData: FormDataType) {
        const {
            category,
            name,
            email,
            phone,
            message,
        } = supportFormData

        const params: TAddTicketProps = {
            ...ticketData,
            category,
            generated_user_id: guid,
            member_id: user?.member_id,
            member_name: user?.name || name,
            member_phone: phone,
            message_text: message,
        }

        if (!user || email !== user.email) {
            params.member_email = email
        }

        return params
    }

    function fetchTicketCategoriesAction() {
        SupportService.fetchTicketCategories({ lang_short: lang })
            .then(({ data }) => {
                setCategories(data)
            })
            .catch(() => {})
    }

    function addTicketAction(supportFormData: FormDataType) {
        const params = getTicketActionParams(supportFormData)

        setIsSubmitting(true)
        dispatch(addTicket(params))
            .then(({ id }) => {
                if (formAttaches.length) {
                    addTicketAttachAction(id, formAttaches[0])
                } else {
                    onComplete()
                }
            })
            .catch((err) => {
                const { error } = err?.response?.data || {}

                if (error && typeof error === 'string') {
                    showAlertNotify({ type: 'error', message: error })
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    function addTicketAttachAction(id: number, url: string) {
        setIsSubmitting(true)
        SupportService.addTicketMessage({ id, generated_user_id: guid, message: url })
            .then(() => {
                onComplete()
            })
            .catch(() => {
                //
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    function fileUploadAction(file: File): Promise<IUploadedFileData> {
        setIsSubmitting(true)
        return SimService.filesUpload(file)
            .then(({ data: fileData }) => {
                return fileData
            })
            .catch(() => {
                return Promise.reject()
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    useEffect(() => {
        isMounted.current = true
        fetchTicketCategoriesAction()

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <SupportForm
            isAuthorized={!!user}
            data={formData}
            images={formAttaches}
            categories={categories}
            classesContent={cn(styleModalBody.body, classes?.body)}
            classesControls={cn(styleModalFooter.footer, classes?.footer)}
            isSubmitting={isSubmitting}
            onAddAttach={handlerAddAttach}
            onRemoveAttach={handlerRemoveAttach}
            onSubmit={handlerSubmit}
        />
    )
}

export default SupportAction
