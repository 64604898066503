import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    SetDataOptions,
    QueryFilters,
    Updater,
    useQueryClient,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TGoodsResponse,
    TMarketServiceError,
    TShopsStaticCategoryProps,
    TShopsStaticCategoryResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TShopsStaticCategoryProps
export type TFetchFnError = AxiosError<TMarketServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<
    TShopsStaticCategoryResponse,
    TError,
    TShopsStaticCategoryResponse,
    TQueryKey
>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TShopsStaticCategoryResponse,
    TError,
    TShopsStaticCategoryResponse,
    TShopsStaticCategoryResponse,
    TQueryKey
>

const key = QueryCacheKeys.shopsStatic

/**
 * Hook API favorite shops
 */
export default function useFetchShopsStatic(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], ({ signal }) => {
        return MarketService.fetchShopsStaticCategory(params, { signal })
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteShopsStatic(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams, signal }) => {
        return MarketService.fetchShopsStaticCategory(pageParam, { signal })
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}

/**
 * Management cached data
 */
export function useQueriesDataShopsStatic() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const setQueryData = (
        params: TFetchFnParams,
        updater: Updater<TGoodsResponse[0] | undefined, TGoodsResponse[0] | undefined>,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueriesData([`${key}-${user.id}`, params], updater, options)
    }

    const remove = (params: TFetchFnParams, { exact = true, ...filters }: QueryFilters = {}) => {
        return queryClient.removeQueries([`${key}-${user.id}`, params], { exact, ...filters })
    }

    return {
        setQueryData,
        remove,
    }
}
