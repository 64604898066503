import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { IStickerCollection } from 'interfaces'
import * as appSelectors from 'containers/App/app-selectors'
import { Button, SvgResource } from 'components'
import { useOutsideClick } from 'hooks'
import { StickersList, StickersMenu } from './components'
import style from './Stickers.module.css'

type StickersPropType = {
    classes?: string
    trigger?: JSX.Element | React.ReactNode
    side?: 'left' | 'right'
    onClick: (type: string, stickerId: number) => void
}

const Stickers: React.FC<StickersPropType> = ({
    classes,
    trigger,
    side = 'right',
    onClick,
}) => {
    const wrapperRef = useRef(null)

    const { collections = [] } = useSelector(appSelectors.stickers)

    const [stickerCollections, setStickerCollections] = useState<IStickerCollection[]>([])
    const [activeCollectionIndex, setActiveCollectionIndex] = useState(0)
    const [isShowPopup, setIsShowPopup] = useState(false)

    const handlerClickStickers = () => {
        setIsShowPopup((prevState) => !prevState)
    }

    const handlerClickCollectionItem = (collectionId: number) => {
        setActiveCollectionIndex(stickerCollections.findIndex((item) => item.id === collectionId))
    }

    const handlerClickStickerItem = (stickerId: number) => {
        onClick(stickerCollections[activeCollectionIndex].type, stickerId)
        setIsShowPopup(false)
    }

    const triggerDefault = (
        <SvgResource
            isImgTag={false}
            classes={style.iconSticker}
            resourceKey="ic_sticker_svg"
            width={32}
            height={32}
        />
    )

    useEffect(() => {
        if (isShowPopup && !stickerCollections.length) {
            setStickerCollections(collections)
        }
    }, [isShowPopup])

    useOutsideClick(wrapperRef, () => {
        setIsShowPopup(false)
    })

    return (
        <div className={cn(style.stickers, classes)} ref={wrapperRef}>
            <Button classes={style.trigger} styleType="text" onClick={handlerClickStickers}>
                {trigger || triggerDefault}
            </Button>

            {isShowPopup && !!stickerCollections.length && (
                <div className={cn(style.popup, style[`popup_${side}`])}>
                    <div className={style.popupBody}>
                        <StickersList
                            collection={stickerCollections[activeCollectionIndex]}
                            onClickItem={handlerClickStickerItem}
                        />
                    </div>
                    <div className={style.popupFooter}>
                        <StickersMenu
                            collections={stickerCollections}
                            activeIndex={activeCollectionIndex}
                            onClickItem={handlerClickCollectionItem}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Stickers
