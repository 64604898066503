import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { TranslationService } from 'services'
import errorLog from 'utils/errorLog'

import('./translations.json')
    .then((module) => {
        TranslationService.initialization(module.default)
    })
    .catch((err) => {
        errorLog('translations.json', err)
    })

i18n.use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: ['en', 'ru'],
        debug: false,
        keySeparator: false, // fix for key with "."
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {},
        react: {
            bindI18nStore: 'added',
        },
    })
    .then(() => {
        //
    })

export default i18n
