import React, {
    HTMLProps,
    CSSProperties,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react'
import cn from 'classnames'

import { ImgResource } from 'components'
import style from './Video.module.css'

export type VideoPropType = {
    classesWrap?: string
    classes?: string
    styles?: CSSProperties
    src?: string
    type?: string // mime-type
    isPlayIcon?: boolean
} & Omit<HTMLProps<HTMLVideoElement>, 'className'>

const Video = forwardRef<HTMLVideoElement, VideoPropType>(({
    classesWrap,
    classes,
    styles,
    src = '',
    type,
    isPlayIcon = false,
    ...props
}, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const handlerClickPlay = () => {
        if (videoRef.current) {
            videoRef.current.click()
        }
    }

    useImperativeHandle(ref, () => videoRef.current!, [])

    return (
        <div className={cn(style.wrap, classesWrap)}>
            <video
                {...props}
                className={classes}
                style={styles}
                ref={videoRef}
            >
                <source src={src} type={type} />
            </video>
            {isPlayIcon && (
                <div className={style.overlay}>
                    <ImgResource
                        isShowLoader={false}
                        classes={style.iconPlay}
                        resourceKey="slide_audio_play_png"
                        onClick={handlerClickPlay}
                    />
                </div>
            )}
        </div>
    )
})

export default Video
