import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import cn from 'classnames'

import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { Input, SvgResource } from 'components'
import style from './MessengerSearch.module.css'

type ChatSearchPropType = {
    classes?: string
    isCompact?: boolean
    isDisabled?: boolean
    onSearch: (value: string) => void
}

const SEARCH_MAX_CHARS = 64

const MessengerSearch: React.FC<ChatSearchPropType> = ({
    children,
    classes,
    isCompact,
    isDisabled,
    onSearch,
}) => {
    const { t } = useTranslation()

    const debounce = useDebouncedCallback(changeCallback, INPUT_DEBOUNCE_TIME + 200)

    const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debounce(e.target.value)
    }

    function changeCallback(value: string) {
        onSearch(value.trim())
    }

    return (
        <div className={cn(style.search, { [style.search_compact]: isCompact }, classes)}>
            <label className={style.field}>
                <Input
                    classes={style.input}
                    placeholder={t('search')}
                    maxLength={SEARCH_MAX_CHARS}
                    disabled={isDisabled}
                    onChange={handlerChange}
                />
                <SvgResource
                    isImgTag={false}
                    classes={cn(style.iconSearch, { [style.iconSearch_disabled]: isDisabled })}
                    resourceKey="ic_search_white2_svg"
                    width={26}
                    height={26}
                />
            </label>
            {children && (
                <div className={style.actions}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default MessengerSearch
