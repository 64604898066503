import React from 'react'
import cn from 'classnames'

import { SLIDER_ITEM_CLASS } from 'config/app'

export type SliderItemPropType = {
    classes?: string
}

const SliderItem: React.FC<SliderItemPropType> = ({ children, classes }) => {
    return (
        <div className={cn(SLIDER_ITEM_CLASS, classes)}>
            {children}
        </div>
    )
}

export default SliderItem
