import React from 'react'
import { useTranslation } from 'react-i18next'

import { IPostContent } from 'interfaces'
import { Textarea } from 'components'
import style from './PostContentTextArea.module.css'

export type PostContentTextAreaPropType = {
    data: IPostContent
    onChange: (id: number, text: string) => void
}

const PostContentTextArea: React.FC<PostContentTextAreaPropType> = ({ data, onChange }) => {
    const { t } = useTranslation()

    const handlerChange = ({ currentTarget }: React.SyntheticEvent<HTMLTextAreaElement>) => {
        onChange(data.id, currentTarget.value)
    }

    return (
        <div className={style.desc}>
            <Textarea
                classes={style.textarea}
                styleType="dynamic"
                dynamicPlaceholder={t('ui_posts_post_text_hint')}
                name="text"
                value={data.html}
                onChange={handlerChange}
            />
        </div>
    )
}

export default PostContentTextArea
