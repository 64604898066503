import React from 'react'
import cn from 'classnames'

import { Badge } from 'components'
import style from './TabsItem.module.css'

export type TabsItemType = {
    id: number
    name: string
    count?: string
    badge?: string
    extra?: any
    disabled?: boolean
}

type TabsItemPropType = {
    item: TabsItemType
    classes?: string
    classesActive?: string
    classesText?: string
    active?: boolean
    onClick: (id: number, extra: any) => void
}

const TabsItem: React.FC<TabsItemPropType> = ({
    item,
    classes,
    classesActive,
    classesText,
    active,
    onClick,
}) => {
    const {
        id,
        name,
        extra,
        count,
        badge,
        disabled,
    } = item

    const handlerClick = () => {
        onClick(id, extra)
    }

    return (
        // eslint-disable-next-line
        <li
            className={cn(
                style.item,
                classes,
                {
                    [classesActive || style.item_active]: active,
                    [style.item_counted]: count,
                    [style.item_badged]: badge,
                    [style.disabled]: disabled,
                },
            )}
            key={id}
            onClick={handlerClick}
        >
            <span className={cn(
                style.text,
                classesText,
                {
                    [style.text_active]: active,
                },
            )}
            >
                {name}
            </span>
            {count && (
                <span className={style.count}>
                    {count}
                </span>
            )}
            {badge && (
                <Badge classes={style.badge}>
                    {badge}
                </Badge>
            )}
        </li>
    )
}

export default TabsItem
