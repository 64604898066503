import i18n from 'i18next'

import { ITranslation, ITranslationSource, IObjectLiteral } from 'interfaces'
import { StorageService } from 'services'
import errorLog from 'utils/errorLog'
import {
    CLIENT_STORAGE_TRANSLATIONS_KEY,
    TRANSLATION_TIME_TO_UPDATE,
} from 'config/app'
import { API_HOST_PRODUCTION, API_URL, API_BASE } from 'config/api'
import { defaultDateFormat } from 'utils/helpers'
import requestClient from 'utils/requestClient'
import WebWorkerEnabler from 'workers/WebWorkerEnabler'
import { TranslationNormalize } from 'workers'

type translationType = ITranslation['translations']

type fetchTranslationsPropType = {
    date?: number // timestamp
}

type translationInitPropType = ITranslation

type setAppTranslationsPropType = translationType

type setTranslationsPropType = ITranslation

type mergeTranslationsPropType = {
    currentTranslations: translationType
    newTranslations: translationType
}

/**
 * API translations
 */
class TranslationService {
    static fetchTranslations({ date }: fetchTranslationsPropType = {}): Promise<{ data: null | ITranslation }> {
        return new Promise((resolve, reject) => {
            requestClient(API_URL.translations, {
                baseURL: `${API_HOST_PRODUCTION}/${API_BASE}`,
                authorization: false,
                params: {
                    date: date ? defaultDateFormat(date, true) : undefined,
                },
            })
                .then(({ data }) => {
                    if (Array.isArray(data) && !!data[0]) {
                        return TranslationService.normalizeTranslations(data)
                            .then((translations) => {
                                const result: ITranslation = {
                                    date: date || Date.now(),
                                    translations,
                                }
                                return resolve({ data: result })
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    }

                    return resolve({ data: null })
                })
                .catch(() => {
                    reject()
                })
        })
    }

    static changeLanguage(lang: string) {
        if (i18n.hasResourceBundle(lang, 'translation')) {
            i18n.changeLanguage(lang).then(() => {
                document.documentElement.lang = lang

                const descElem = Array.from(document.getElementsByTagName('meta')).find((item) => {
                    return item.name === 'description'
                })

                if (descElem) {
                    descElem.content = i18n.t('druzya_dorozhe')
                }
            })
        }
    }

    /**
     * Normalize translations for i18next resources
     * @param sources
     */
    static normalizeTranslations(sources: ITranslationSource[]): Promise<translationType> {
        if (typeof WebWorkerEnabler === 'undefined') {
            return Promise.reject()
        }

        const translationNormalize = new WebWorkerEnabler(TranslationNormalize) as Worker
        translationNormalize.postMessage(sources)

        return new Promise((resolve, reject) => {
            translationNormalize.onmessage = (e: MessageEvent) => {
                const normalized = e.data
                translationNormalize.terminate()

                return resolve(normalized)
            }
            translationNormalize.onerror = (e: ErrorEvent) => {
                return reject(e)
            }
        })
    }

    /**
     * Add translations bundles
     * @param translations
     */
    static setAppTranslations({ translations }: setAppTranslationsPropType) {
        if (translations && Object.keys(translations).length) {
            Object.keys(translations).forEach((lng: string) => {
                if (lng in translations && translations[lng]?.translation) {
                    i18n.addResourceBundle(lng, 'translation', translations[lng].translation, true, true)
                }
            })
        }
    }

    /**
     * @deprecated
     */
    static getAndSetTranslations(date?: number) {
        TranslationService.fetchTranslations({ date }).then(({ data }) => {
            const { date: newDate, translations } = data || {}

            if (newDate && Object.keys(translations || {}).length) {
                TranslationService.setTranslations({ date: newDate, translations: (translations as translationType) })
            }
        }).catch((err) => {
            errorLog('getAndSetTranslations->fetchTranslations', err)
        })
    }

    static getAndUpdateTranslations({ date, translations }: ITranslation)
        : Promise<{ date: number, translations: IObjectLiteral } | null> {
        const dateUpdate = Date.now()

        return new Promise((resolve, reject) => {
            TranslationService.fetchTranslations({ date })
                .then(({ data }) => {
                    const { translations: newTranslations } = data || {}

                    if (newTranslations && Object.keys(newTranslations).length) {
                        const mergedTranslations = TranslationService.mergeTranslations({
                            currentTranslations: translations,
                            newTranslations,
                        })

                        TranslationService.setAppTranslations({ translations: mergedTranslations })
                        resolve({ date: dateUpdate, translations: mergedTranslations })
                    }

                    resolve(null)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    static mergeTranslations({ currentTranslations, newTranslations }: mergeTranslationsPropType): IObjectLiteral {
        const translations: IObjectLiteral = { ...currentTranslations }
        const newTranslationsKeys = Object.keys(newTranslations)

        if (newTranslationsKeys.length) {
            newTranslationsKeys.forEach((key: string) => {
                if (!translations[key] || !translations[key].translation) {
                    translations[key] = { translation: {} }
                }

                translations[key].translation = {
                    ...translations[key].translation,
                    ...newTranslations[key].translation,
                }
            })
        }

        return translations
    }

    /**
     * @deprecated
     */
    static setTranslations({ date, translations }: setTranslationsPropType) {
        TranslationService.setAppTranslations({ translations })
        StorageService.setItem(CLIENT_STORAGE_TRANSLATIONS_KEY, { date, translations })
    }

    static startCheckingUpdate({ date, translations }: ITranslation) {
        let dateUpdate = date
        let translationsCurrent = translations

        TranslationService.getAndUpdateTranslations({ date: dateUpdate, translations: translationsCurrent })
            .then((data) => {
                if (data) {
                    dateUpdate = data.date
                    translationsCurrent = data.translations
                }
            })
            .catch(() => {})

        setInterval(() => {
            TranslationService.getAndUpdateTranslations({ date: dateUpdate, translations: translationsCurrent })
                .then((data) => {
                    if (data) {
                        dateUpdate = data.date
                        translationsCurrent = data.translations
                    }
                })
                .catch(() => {})
        }, TRANSLATION_TIME_TO_UPDATE)
    }

    static initialization({ date, translations }: translationInitPropType) {
        TranslationService.setAppTranslations({ translations })
        TranslationService.startCheckingUpdate({ date, translations })
    }
}

export default TranslationService
