import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { TUserDataRecord } from 'containers/User/hooks/useFetchUsersData'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import * as userSelectors from 'containers/User/user-selectors'
import { fetchCounts } from 'containers/User/user-actions'
import {
    useFetchInfiniteFriendsRequest,
    useInvalidateFriendsRequest,
    useMutationFriendsRequest,
    useFetchUsersData,
    useCacheUsersData,
    useInvalidateProfileFriends,
} from 'containers/User/hooks'
import { FriendsUserCard } from 'containers/Friends/components'
import {
    PageTitle,
    Breadcrumbs,
    Button,
    InfiniteScroll,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { useWindowResize } from 'hooks'
import { plural, scrollTop, showAlertNotify } from 'utils/helpers'
import style from './FriendsRequest.module.css'

const LIMIT_FRIENDS_REQUEST = 12
const OFFSET_FRIENDS_REQUEST = 0

const FriendsRequest: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)

    const [usersDataIds, setUsersDataIds] = useState('')
    const [usersData, setUsersData] = useState<TUserDataRecord>({})
    const [isUpdatingFriendsUserId, setIsUpdatingFriendsUserId] = useState(0)
    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isLoading: isLoadingFriendsRequest,
        data: dataFriendsRequest,
        error: errorFriendsRequest,
        fetchNextPage: fetchNextPageFriendsRequest,
    } = useFetchInfiniteFriendsRequest({
        limit: LIMIT_FRIENDS_REQUEST,
        offset: OFFSET_FRIENDS_REQUEST,
    }, {
        refetchInterval: (res) => (res ? 30 * 1000 : 60 * 1000), // 30s | 1m
    })

    const { data: dataUsersData } = useFetchUsersData({
        users: usersDataIds,
        is_mutual_friends: 1,
        limit_friends: 3,
        is_checks: 0,
        is_subs_community: 0,
    }, {
        enabled: !!usersDataIds,
        staleTime: 300 * 1000,
        cacheTime: 300 * 1000,
    })

    const { invalidate: invalidateFriendsRequest } = useInvalidateFriendsRequest({}, {
        exact: false,
        refetchType: 'all',
    })

    const { invalidate: invalidateFriends } = useInvalidateProfileFriends({ userId: user.id }, {
        exact: false,
        refetchType: 'all',
    })

    const { set: setCacheUsersData } = useCacheUsersData()
    const { confirm: confirmFriendsRequest, reject: rejectFriendsRequest } = useMutationFriendsRequest()

    const breadcrumbs: IBreadcrumb[] = useMemo(() => ([
        { id: 1, name: t('friends'), url: APP_URL.friends },
        { id: 2, name: t('friend_requests_title') },
    ]), [])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const acceptAllText = useMemo(() => {
        if (dataFriendsRequest?.pages.length) {
            const { total } = dataFriendsRequest.pages[0]
            const tRequests = [t('request_1x'), t('request_2x'), t('request_5x')]

            return `${t('accept_all')} ${total} ${plural(total, tRequests)}`
        }
        return ''
    }, [dataFriendsRequest])

    const isShowAcceptAll = useMemo(() => {
        if (dataFriendsRequest?.pages.length) {
            return dataFriendsRequest.pages[0].total > 1
        }
        return false
    }, [dataFriendsRequest])

    const handlerLoad = () => {
        if (!isLoadingFriendsRequest) {
            fetchNextPageFriendsRequest()
        }
    }

    const handlerFriendRequestConfirm = (userId?: number) => {
        if (userId) {
            setIsUpdatingFriendsUserId(userId)
        }

        confirmFriendsRequest.mutate({ userId }, {
            onSuccess: () => {
                invalidateFriendsRequest().finally(() => {
                    setIsUpdatingFriendsUserId(0)
                })
                invalidateFriends()
                dispatch(fetchCounts())
            },
            onError: ([errorText]) => {
                setIsUpdatingFriendsUserId(0)
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    const handlerFriendRequestReject = (userId: number) => {
        setIsUpdatingFriendsUserId(userId)
        rejectFriendsRequest.mutate({ userId }, {
            onSuccess: () => {
                invalidateFriendsRequest().finally(() => {
                    setIsUpdatingFriendsUserId(0)
                })
                invalidateFriends()
                dispatch(fetchCounts())
            },
            onError: ([errorText]) => {
                setIsUpdatingFriendsUserId(0)
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    const handlerClickAcceptAllRequests = () => {
        handlerFriendRequestConfirm()
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataFriendsRequest) {
            const { pages } = dataFriendsRequest
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].list.length : 0

            if (lastListLen) {
                setUsersDataIds(pages[pagesLen - 1].list.map((item) => item.id).join(','))
            }
            if (lastListLen < LIMIT_FRIENDS_REQUEST) {
                setIsHideLoader(true)
            }
        }
    }, [dataFriendsRequest])

    useEffect(() => {
        if (dataUsersData) {
            const usersDataItems = dataUsersData.reduce((acc, item) => {
                return { ...acc, [item.user.id]: item }
            }, {})

            const newUsersDataItems = setCacheUsersData(usersDataItems)

            if (newUsersDataItems) {
                setUsersData(newUsersDataItems)
            }
        }
    }, [dataUsersData])

    useEffect(() => {
        if (errorFriendsRequest) {
            setIsHideLoader(true)
        }
    }, [errorFriendsRequest])

    return (
        <ContentContainer size="half">
            <PageTitle classes={style.title}>
                <Breadcrumbs items={breadcrumbs} />
                {isShowAcceptAll && (
                    <Button
                        textUpper
                        classes={style.titleAction}
                        size="size46"
                        onClick={handlerClickAcceptAllRequests}
                    >
                        {acceptAllText}
                    </Button>
                )}
            </PageTitle>

            {!isLoadingFriendsRequest && errorFriendsRequest?.length && (
                <ErrorMsg error={errorFriendsRequest[0]} />
            )}

            {!isLoadingFriendsRequest
                && !errorFriendsRequest
                && dataFriendsRequest?.pages.length
                && !dataFriendsRequest.pages[0].list.length
            && (
                <NoDataInfo isShowImg text={t('you_have_no_friend_requests')} />
            )}

            <CardContainer>
                <InfiniteScroll
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideLoader}
                    onLoad={handlerLoad}
                >
                    {dataFriendsRequest?.pages.map((page, i) => (
                        // eslint-disable-next-line
                        <React.Fragment key={i}>
                            {page.list.map((item) => (
                                <FriendsUserCard
                                    isUpdating={isUpdatingFriendsUserId === item.id}
                                    user={item}
                                    size={itemSize}
                                    data={usersData[item.id]}
                                    key={item.id}
                                    onFriendRequestConfirm={handlerFriendRequestConfirm}
                                    onFriendRequestReject={handlerFriendRequestReject}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </CardContainer>
        </ContentContainer>
    )
}

export default FriendsRequest
