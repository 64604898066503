import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { dateTimeFormat } from 'utils/helpers'
import style from './ReportsFreedomIncomeListGroup.module.css'

type ReportsFreedomIncomeListGroupPropType = {
    date: string
}

const ReportsFreedomIncomeListGroup: React.FC<ReportsFreedomIncomeListGroupPropType> = ({
    children,
    date,
}) => {
    const { i18n } = useTranslation()

    const dateFormatted = useMemo(() => {
        const dateCurrent = new Date()
        const d = new Date(date)

        if (dateCurrent.getFullYear() === d.getFullYear()) {
            return dateTimeFormat(date, i18n.language, { day: '2-digit', month: 'long' })
        }

        return dateTimeFormat(date, i18n.language, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }, {
            sliceRusYearStr: true,
        })
    }, [date])

    return (
        <div className={style.group}>
            <div className={style.date}>
                {dateFormatted}
            </div>
            {children}
        </div>
    )
}

export default ReportsFreedomIncomeListGroup
