import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IMlmRank, IMlmRankUserExtended } from 'interfaces'
import { AppScheme } from 'enums'
import { APP_URL } from 'config/app'
import { CardContainer, ContentContainer } from 'layout'
import { PageHeader, Loader, InfiniteScroll } from 'components'
import { ReportsRankUsersListItem } from 'containers/Reports/components'
import { useFetchMlmRankUsers } from 'containers/User/hooks'
import { scrollTop } from 'utils/helpers'
// import style from './ReportsRankUsers.module.css'

const ReportsRankUsers: React.FC = () => {
    const { t } = useTranslation()
    const { state }: { state?: { type: keyof typeof AppScheme, rank?: IMlmRank } } = useLocation()
    const history = useHistory()

    const [userList, setUserList] = useState<IMlmRankUserExtended[]>([])
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const { isInitialLoading: isLoading, data: dataMlmRankUsers } = useFetchMlmRankUsers({
        type: state?.type,
        rankId: state?.rank?.id,
        limit,
        offset,
    }, {
        enabled: !!state?.type,
    })

    const handlerClickBack = () => {
        history.push(APP_URL.reports, { type: state?.type })
    }

    const handlerOnLoad = ({ page }: { page: number }) => {
        setOffset((prevSate) => prevSate + limit)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (!state) {
            history.push(APP_URL.reports)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataMlmRankUsers) {
            const { data } = dataMlmRankUsers
            const dataLen = data.length

            if (dataLen) {
                setUserList((prevState) => [...prevState, ...data])
            }
            if (dataLen < limit) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataMlmRankUsers])

    return (
        <ContentContainer size="half">
            <PageHeader
                title={state?.rank?.name || t('mlm_privilege_program_member')}
                onClickBack={handlerClickBack}
            />

            {isLoading && (
                <Loader />
            )}

            <CardContainer>
                <InfiniteScroll
                    isActive={!!userList.length}
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideInfiniteLoader}
                    onLoad={handlerOnLoad}
                >
                    {userList.map((item) => (
                        <ReportsRankUsersListItem
                            data={item}
                            key={item.id}
                        />
                    ))}
                </InfiniteScroll>
            </CardContainer>
        </ContentContainer>
    )
}

export default ReportsRankUsers
