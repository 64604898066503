import React, { useMemo } from 'react'
import cn from 'classnames'

import useOnLoadImage from 'hooks/useOnLoadImage'
import { getFirstChars, stringToHslColor, getImageResizeSrc } from 'utils/helpers'
import { vGray5 } from 'styles/modules/variables.module.css'
import styleCommon from 'styles/modules/common.module.css'
import style from './Avatar.module.css'

type AvatarPropType = {
    isDoubleSize?: boolean
    isRound?: boolean
    isResize?: boolean
    classes?: string
    styles?: object
    width?: number
    height?: number
    src?: string
    name?: string
    surname?: string
    bgColor?: string
}

const Avatar: React.FC<AvatarPropType> = ({
    isDoubleSize,
    isRound = true,
    isResize = true,
    classes,
    styles = {},
    width,
    height,
    src,
    name = '',
    surname = '',
    bgColor,
}) => {
    const userInitials = useMemo(() => getFirstChars(name, surname), [name, surname])
    const background = useMemo(() => getColor(), [userInitials])
    const imageSrc = useMemo(() => (src ? getImageSrc() : ''), [src])

    const [loadedImg, errorImg] = useOnLoadImage(imageSrc)

    function getColor() {
        return userInitials ? stringToHslColor(userInitials) : vGray5
    }

    function getImageSrc() {
        const resizeParams = {
            quality: 100,
            src,
            width,
            height,
        }
        return isResize ? getImageResizeSrc(resizeParams) : src
    }

    return (
        <div
            className={cn(style.wrap, !isRound ? style.wrap_unround : '', classes)}
            style={{
                width: width && isDoubleSize ? Math.round(width / 2) : width,
                height: height && isDoubleSize ? Math.round(height / 2) : width,
                backgroundColor: !loadedImg || errorImg ? bgColor : '',
                ...styles,
            }}
        >
            {(src && !errorImg) ? (
                <div
                    className={cn(
                        style.image,
                        styleCommon.image,
                        loadedImg ? '' : styleCommon.image_loading,
                    )}
                    style={{
                        backgroundColor: bgColor,
                        backgroundImage: loadedImg ? `url(${loadedImg.src})` : '',
                    }}
                />
            ) : (
                <svg viewBox="0 0 100 100">
                    <rect
                        width="100%"
                        height="100%"
                        fill={background}
                    />
                    <text
                        x="50%"
                        y="54%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize="3rem"
                        fill="#fff"
                    >
                        {userInitials}
                    </text>
                </svg>
            )}
        </div>
    )
}

export default Avatar
