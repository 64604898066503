import React from 'react'

import { IStoreProfile } from 'interfaces'
import { Card } from 'components'
import style from './ItemStore.module.css'

type ItemShopPropType = {
    data: IStoreProfile
    onClick: (data: IStoreProfile) => void
}
/**
 * @deprecated
 * remove
 */
const ItemStore: React.FC<ItemShopPropType> = ({ data, onClick }) => {
    const handlerClick = () => {
        onClick(data)
    }

    return (
        <Card.Item classes={style.item} onClick={handlerClick}>
            <Card.Image src={data.logo_image} />
            <Card.Body title={data.name} />
        </Card.Item>
    )
}

export default ItemStore
