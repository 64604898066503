import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { Image } from 'components'
import { ImagePropType } from 'components/Image/Image'
import style from './CardImage.module.css'

export type CardImagePropType = {
    classes?: string
    url?: string
    onClick?: (e: React.SyntheticEvent) => void
} & ImagePropType

const CardImage: React.FC<CardImagePropType> = ({
    classes,
    url,
    onClick,
    isImg = false,
    width,
    height,
    ...props
}) => {
    const handlerClick = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (onClick) {
            onClick(e)
        }
    }

    const imageTpl = (
        <Image
            isImg={isImg}
            width={width}
            height={height}
            resize={{ quality: 90, width, height }}
            {...props}
        />
    )

    return (
        <>
            {url && (
                <NavLink className={cn(style.image, style.image_link, classes)} to={url}>
                    {imageTpl}
                </NavLink>
            )}

            {!url && onClick && (
                // eslint-disable-next-line
                <div className={cn(style.image, style.image_link, classes)} onClick={handlerClick}>
                    {imageTpl}
                </div>
            )}

            {!url && !onClick && (
                <div className={cn(style.image, classes)}>
                    {imageTpl}
                </div>
            )}
        </>
    )
}

export default CardImage
