import React, { useEffect, useMemo, useState } from 'react'
import {
    useParams,
    useLocation,
    useHistory,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IProfile, IUserData } from 'interfaces'
import { TAddFriendsRequestProps, TCancelFriendsRequestProps, TDeleteFriendsProps } from 'services/UserService'
import {
    TBlockUserProps,
    TSubscribeUserProps,
    TUnblockUserProps,
    TUnsubscribeUserProps,
} from 'services/NetworkService'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, CardContainer } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import {
    useFetchProfile,
    useFetchUsersData,
    useFetchInfiniteProfileFriends,
    useInvalidateProfile,
    useCacheUsersData,
    useMutationFriends,
    useMutationFriendsRequest,
} from 'containers/User/hooks'
import { useMutationSubscribe, useMutationBlocks } from 'containers/Network/hooks'
import { FeedPostsUser } from 'containers/Feed/components'
import {
    Loader,
    FeedNav,
    UserCard,
    NoDataInfo,
    ErrorMsg,
    Modal,
    InfiniteScroll,
} from 'components'
import { ReportAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { scrollTop, numberFormat, showAlertNotify } from 'utils/helpers'
import { UserProfileData, UserProfileInfo } from './components'
import style from './UserProfile.module.css'

enum TabsKey {
    posts = 1,
    friends = 2
}

const POSTS_LIMIT = 10
const POSTS_TILE_LIMIT = 18
const LIMIT_FRIENDS = 12

const UserProfile: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)
    const { feedPostTile: isPostTile } = useSelector(appSelectors.settings)

    const [usersDataIds, setUsersDataIds] = useState(id)
    const [usersData, setUsersData] = useState<Record<number, IUserData>>({})
    const [isHideLoaderFriends, setIsHideLoaderFriends] = useState(false)
    const [isShowReportModal, setIsShowReportModal] = useState(false)

    const userId = useMemo(() => Number(id), [id])

    const {
        isInitialLoading: isLoadingProfile,
        data: dataProfile,
        error: errorProfile,
    } = useFetchProfile({ userId }, { enabled: !Number.isNaN(userId) })

    const { data: dataUsersData } = useFetchUsersData({
        users: usersDataIds,
        is_mutual_friends: 1,
        limit_friends: 5,
        is_checks: 1,
        is_subs_community: 1,
    }, {
        enabled: user.id !== userId && !!usersDataIds,
        staleTime: 300 * 1000,
        cacheTime: 300 * 1000,
    })

    const {
        isInitialLoading: isLoadingFriends,
        data: dataFriends,
        error: errorFriends,
        fetchNextPage: fetchNextPageFriends,
    } = useFetchInfiniteProfileFriends({
        userId,
        limit: LIMIT_FRIENDS,
        offset: 0,
    }, {
        enabled: user.id !== userId && !!matchPath(pathname, { path: APP_URL.profileFriends }) && !Number.isNaN(userId),
        staleTime: 300 * 1000,
        cacheTime: 300 * 1000,
    })

    const { set: setCacheUsersData } = useCacheUsersData()

    const { deleteFriends } = useMutationFriends()

    const { add: addFriendsRequest, cancel: cancelFriendsRequest } = useMutationFriendsRequest()

    const { subscribeUser, unsubscribeUser } = useMutationSubscribe()

    const { blockUser, unblockUser } = useMutationBlocks()

    const { invalidate: invalidateProfile } = useInvalidateProfile()

    const tabItems = useMemo(() => {
        const { counts } = dataProfile || {}
        return {
            [TabsKey.posts]: {
                id: TabsKey.posts,
                name: t('user_profile_posts'),
                count: counts ? numberFormat((counts.posts ?? 0) + (counts.checks ?? 0)) : '-',
                path: dataProfile ? APP_URL.profilePosts.replace(':id', String(dataProfile.id)) : '',
            },
            [TabsKey.friends]: {
                id: TabsKey.friends,
                name: t('friends'),
                count: counts ? numberFormat(counts.friends ?? 0) : '-',
                path: dataProfile ? APP_URL.profileFriends.replace(':id', String(dataProfile.id)) : '',
            },
        }
    }, [dataProfile])

    const tabs = useMemo(() => {
        return Object.values(tabItems).map(({ id: tabId, name, count }) => ({ id: tabId, name, count }))
    }, [tabItems])

    const activeTabId = useMemo(() => {
        if (matchPath(pathname, { path: APP_URL.profileFriends })) {
            return TabsKey.friends
        }
        return TabsKey.posts
    }, [pathname])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerChangeTab = (tabId: number) => {
        if (tabId !== activeTabId) {
            history.push(tabItems[tabId].path)
        }
    }

    const handlerLoad = () => {
        if (!isLoadingFriends) {
            fetchNextPageFriends()
        }
    }

    const handlerAddFriends = ({ id: profileUserId, account: profileUserAccount, user_phones: phones }: IProfile) => {
        addFriendsRequestAction({
            id: profileUserId,
            member_id: profileUserAccount.member_id,
            phone: phones?.length ? phones[0].phone_number : undefined,
        })
    }

    const handlerDeleteFriends = (userProfile: IProfile) => {
        deleteFriendsAction({ userId: userProfile.id })
    }

    const handlerCancelFriendsRequest = (userProfile: IProfile) => {
        cancelFriendsRequestAction({ userId: userProfile.id })
    }

    const handlerSubscribe = ({ id: profileUserId, account: profileUserAccount, user_phones: phones }: IProfile) => {
        subscribeUserAction({
            id: profileUserId,
            member_id: profileUserAccount.member_id,
            phone: phones?.length ? phones[0].phone_number : undefined,
        })
    }

    const handlerUnsubscribe = (userProfile: IProfile) => {
        unsubscribeUserAction({ userId: userProfile.id })
    }

    const handlerBlockUser = (userProfile: IProfile) => {
        blockUserAction({ userId: userProfile.id })
    }

    const handlerUnblockUser = (userProfile: IProfile) => {
        unblockUserAction({ userId: userProfile.id })
    }

    const handlerReport = (userProfile: IProfile) => {
        setIsShowReportModal(true)
    }

    const handlerCloseModalReport = () => {
        setIsShowReportModal(false)
    }

    const handlerSuccessReport = () => {
        setIsShowReportModal(false)
        showAlertNotify({ type: 'success', message: t('complaint_report_sent_message') })
    }

    const handlerErrorReport = (errorText: string) => {
        showAlertNotify({ type: 'error', message: errorText })
    }

    function addFriendsRequestAction(params: TAddFriendsRequestProps) {
        addFriendsRequest.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function deleteFriendsAction(params: TDeleteFriendsProps) {
        deleteFriends.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function cancelFriendsRequestAction(params: TCancelFriendsRequestProps) {
        cancelFriendsRequest.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function subscribeUserAction(params: TSubscribeUserProps) {
        subscribeUser.mutate(params, {
            onSuccess: () => {
                invalidateProfile({ userId })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function unsubscribeUserAction(params: TUnsubscribeUserProps) {
        unsubscribeUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function blockUserAction(params: TBlockUserProps) {
        blockUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    function unblockUserAction(params: TUnblockUserProps) {
        unblockUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (matchPath(pathname, { path: APP_URL.profile, exact: true })) {
            history.replace(generatePath(APP_URL.profilePosts, { id }))
        }
        if (isHideLoaderFriends) {
            setIsHideLoaderFriends(false)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [id])

    useEffect(() => {
        if (dataUsersData) {
            const usersDataItems = dataUsersData.reduce((acc, item) => {
                return { ...acc, [item.user.id]: item }
            }, {})

            const newUsersDataItems = setCacheUsersData(usersDataItems)

            if (newUsersDataItems) {
                setUsersData(newUsersDataItems)
            }
        }
    }, [dataUsersData])

    useEffect(() => {
        if (dataFriends) {
            const { pages } = dataFriends
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].list.length : 0

            if (lastListLen) {
                setUsersDataIds(pages[pagesLen - 1].list.map((item) => item.id).join(','))
            }
            if (lastListLen < LIMIT_FRIENDS) {
                setIsHideLoaderFriends(true)
            }
        }
    }, [dataFriends])

    useEffect(() => {
        if (errorFriends) {
            setIsHideLoaderFriends(true)
        }
    }, [errorFriends])

    return (
        <>
            <ContentContainer size="half">
                {isLoadingProfile && (
                    <Loader />
                )}
                {!isLoadingProfile && errorProfile?.length && (
                    <ErrorMsg error={errorProfile[0]} />
                )}
                {dataProfile && (
                    <>
                        <UserProfileData
                            profile={dataProfile}
                            data={usersData[dataProfile.id]}
                            onAddFriends={handlerAddFriends}
                            onDeleteFriends={handlerDeleteFriends}
                            onCancelFriendsRequest={handlerCancelFriendsRequest}
                            onSubscribe={handlerSubscribe}
                            onUnsubscribe={handlerUnsubscribe}
                            onBlockUser={handlerBlockUser}
                            onUnblockUser={handlerUnblockUser}
                            onReport={handlerReport}
                        />
                        <FeedNav
                            classesItem={style.tabsItem}
                            active={activeTabId}
                            items={tabs}
                            isShowPostViewActions={activeTabId === TabsKey.posts}
                            onChange={handlerChangeTab}
                        />

                        {activeTabId === TabsKey.posts && (
                            <div className={style.feed}>
                                <FeedPostsUser
                                    isPostTile={!!isPostTile}
                                    queryParams={{
                                        userId: dataProfile.id,
                                        limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT,
                                    }}
                                    noDataTitleKey="posts_by_user_empty"
                                    noDataTextKey="posts_by_user_empty_message"
                                />
                            </div>
                        )}
                        {activeTabId === TabsKey.friends && (
                            <>
                                {!isLoadingFriends
                                    && !errorFriends
                                    && dataFriends?.pages.length
                                    && dataFriends.pages[0].total === 0
                                && (
                                    <NoDataInfo
                                        isShowImg
                                        text={t('user_has_no_friends_in_sessia')}
                                    />
                                )}
                                {!isLoadingFriends && errorFriends?.length && (
                                    <ErrorMsg error={errorFriends[0]} />
                                )}
                                <CardContainer classes={style.friends}>
                                    <InfiniteScroll
                                        options={{ rootMargin: '0px 0px 50% 0px' }}
                                        isHideLoader={isHideLoaderFriends}
                                        onLoad={handlerLoad}
                                    >
                                        {dataFriends?.pages.map((page, i) => (
                                            // eslint-disable-next-line
                                            <React.Fragment key={i}>
                                                {page.list.map((item) => (
                                                    <UserCard
                                                        name={item.name}
                                                        surname={item.surname}
                                                        size={itemSize}
                                                        photo={item.photo}
                                                        url={generatePath(APP_URL.profile, { id: item.id })}
                                                        key={item.id}
                                                    />
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </InfiniteScroll>
                                </CardContainer>
                            </>
                        )}
                    </>
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    {dataProfile && dataProfile.chat && (
                        <UserProfileInfo
                            data={dataProfile}
                        />
                    )}
                </SideBarContainer>
            )}

            <Modal
                isOpen={isShowReportModal}
                size="medium"
                onClose={handlerCloseModalReport}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('report_content')}
                />
                <Modal.Body>
                    <ReportAction
                        profile={dataProfile}
                        onSuccess={handlerSuccessReport}
                        onError={handlerErrorReport}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserProfile
