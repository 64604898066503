import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { ICommentFile } from 'interfaces'
import { REGEXP_FILE_URL } from 'config/app'
import { IMAGE_SIM_PATH } from 'config/api'
import { Image, Video } from 'components'
import * as appSelectors from 'containers/App/app-selectors'
import { getURLSearchParams, parseContentStickers } from 'utils/helpers'
import style from './TextContent.module.css'

type CommentContentPropType = {
    content: string
    classesContent?: string
    classesImg?: string
    classesVideo?: string
    classesSticker?: string
    isVideoControls?: boolean
    isVideoPlayIcon?: boolean
    imgResize?: {
        width?: number
        height?: number
    }
    stickerSize?: {
        width?: number
        height?: number
    }
    onClick?: (e: React.SyntheticEvent) => void
}

const IMAGE_RESIZE_PARAMS = { quality: 90, width: 600 }

const TextContent: React.FC<CommentContentPropType> = ({
    content = '',
    classesContent,
    classesImg,
    classesVideo,
    classesSticker,
    isVideoControls = true,
    isVideoPlayIcon = false,
    imgResize = {},
    stickerSize = {},
    onClick,
}) => {
    const resizeParams = { ...IMAGE_RESIZE_PARAMS, ...imgResize }

    const { big_url: stickerUrl } = useSelector(appSelectors.stickers)

    const { commentHtml, commentFile } = useMemo(() => {
        const [, type = ''] = content.match(REGEXP_FILE_URL) || []
        const [url = '', search = ''] = type ? content.split('?') : []
        const params: ICommentFile = type ? getURLSearchParams(search) : {}
        const contentHtml = !type
            ? parseContentStickers(
                content, stickerUrl,
                classesSticker || style.smile,
                stickerSize.width,
                stickerSize.height,
            )
            : ''

        return { commentHtml: contentHtml, commentFile: { type, url, params } }
    }, [content])

    const filePreview: string = useMemo(() => {
        const { params: { sspreview } } = commentFile
        return sspreview ? `${IMAGE_SIM_PATH}/${sspreview}.jpg` : ''
    }, [commentFile])

    switch (commentFile.type) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif': {
            const { url, params: { sswidth, ssheight } } = commentFile
            return (
                <Image
                    classesWrap={classesImg}
                    width={sswidth ? Number(sswidth) : undefined}
                    height={ssheight ? Number(ssheight) : undefined}
                    src={url}
                    resize={resizeParams}
                    onClick={onClick}
                />
            )
        }
        case 'mp4':
            return (
                <Video
                    classes={classesVideo}
                    src={commentFile.url}
                    poster={filePreview}
                    controls={isVideoControls}
                    isPlayIcon={isVideoPlayIcon}
                    onClick={onClick}
                />
            )
        default:
            return (
                <span
                    className={cn(style.content, classesContent)}
                    dangerouslySetInnerHTML={{ __html: commentHtml }}
                />
            )
    }
}

export default TextContent
