import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    IUser,
    IMessengerChat,
    IMessengerChannel,
    IMessengerWsForwardMessageProps,
} from 'interfaces'
import { Button, Modal } from 'components'
import {
    MessengerConversationList,
    MessengerChannelAvatar,
    MessengerSearch,
    MessengerSearchConversationList,
    MessengerItem,
    MessengerItemCheckBox,
    MessengerItemUser,
} from 'containers/Messenger/components'
import { useMessenger } from 'containers/Messenger/hooks'
import style from './MessengerModalForward.module.css'

type MessengerModalForwardPropType = {
    isOpen: boolean
    isSingleForward: boolean
    forwardMessageId: string
    user: IUser
    onSend: (value: IMessengerWsForwardMessageProps[]) => void
    onClose: () => void
}

const SEARCH_CHAR_COUNT = 2

const MessengerModalForward: React.FC<MessengerModalForwardPropType> = ({
    isOpen,
    isSingleForward,
    user,
    forwardMessageId,
    onSend,
    onClose,
}) => {
    const { t } = useTranslation()

    const [searchForwardText, setSearchForwardText] = useState('')
    const [forwardMessagesData, setForwardMessagesData] = useState<Record<string, IMessengerWsForwardMessageProps>>({})

    const isSearch = useMemo(() => {
        return searchForwardText.length >= SEARCH_CHAR_COUNT
    }, [searchForwardText])

    const isCanDisabled = useMemo(() => {
        return isSingleForward && !!Object.values(forwardMessagesData).length
    }, [forwardMessagesData, isSingleForward])

    const { getForwardMessageProps } = useMessenger()

    const handlerSearchForward = (value: string) => {
        setSearchForwardText(value)
    }

    const handlerChangeForward = (entity: { toUserId: number } | { toChannelId: string }, isChecked: boolean) => {
        const [, entityId] = Object.entries<number | string>(entity)[0]

        setForwardMessagesData((prevState) => {
            if (!isChecked) {
                const { [entityId]: forwardId, ...state } = prevState
                return state
            }
            return {
                ...prevState,
                [entityId]: getForwardMessageProps({ forwardMessageId, ...entity }),
            }
        })
    }

    const handlerClose = () => {
        setSearchForwardText('')
        setForwardMessagesData({})
        onClose()
    }

    const handlerSendForward = () => {
        setSearchForwardText('')
        setForwardMessagesData({})
        onSend(Object.values(forwardMessagesData))
    }

    const handlerChangeChat = (chat: IMessengerChat, isChecked: boolean) => {
        const chatUserId = user.id === chat.starterUserId ? chat.followerUserId : chat.starterUserId
        handlerChangeForward({ toUserId: chatUserId }, isChecked)
    }

    const handlerChangeChannel = (channel: IMessengerChannel, isChecked: boolean) => {
        handlerChangeForward({ toChannelId: channel.channelId }, isChecked)
    }

    return (
        <Modal
            isOpen={isOpen}
            size="small"
            onClose={handlerClose}
        >
            <Modal.Header isCloseButton>
                <MessengerSearch
                    isCompact
                    classes={style.searchModal}
                    onSearch={handlerSearchForward}
                />
            </Modal.Header>
            <Modal.Body classes={style.modalBody}>
                {isSearch && (
                    <MessengerSearchConversationList
                        user={user}
                        searchText={searchForwardText}
                        render={(data) => (
                            <React.Fragment key={data.id}>
                                {'channelId' in data ? (
                                    <MessengerItemCheckBox
                                        name={data.channelId}
                                        checked={!!forwardMessagesData[data.channelId]}
                                        disabled={isCanDisabled && !forwardMessagesData[data.channelId]}
                                        onChange={(name, value) => handlerChangeChannel(data, value)}
                                    >
                                        <MessengerItem
                                            classesContent={style.itemContent}
                                            avatar={(
                                                <MessengerChannelAvatar
                                                    userId={user.id}
                                                    title={data.channel.title}
                                                    image={data.channel.image}
                                                />
                                            )}
                                            content={data.channel.title}
                                        />
                                    </MessengerItemCheckBox>
                                ) : (
                                    <MessengerItemCheckBox
                                        name={data.id}
                                        checked={!!forwardMessagesData[user.id === data.starterUserId
                                            ? data.followerUserId
                                            : data.starterUserId]}
                                        disabled={isCanDisabled && !forwardMessagesData[user.id === data.starterUserId
                                            ? data.followerUserId
                                            : data.starterUserId]}
                                        onChange={(name, value) => handlerChangeChat(data, value)}
                                    >
                                        <MessengerItemUser
                                            data={user.id === data.starterUserId
                                                ? data.followerUser
                                                : data.starterUser}
                                        />
                                    </MessengerItemCheckBox>
                                )}
                            </React.Fragment>
                        )}
                    />
                )}
                {!isSearch && (
                    <MessengerConversationList
                        user={user}
                        render={(data) => (
                            <React.Fragment key={data.id}>
                                {'channelId' in data ? (
                                    <MessengerItemCheckBox
                                        name={data.channelId}
                                        checked={!!forwardMessagesData[data.channelId]}
                                        disabled={isCanDisabled && !forwardMessagesData[data.channelId]}
                                        onChange={(name, value) => handlerChangeChannel(data, value)}
                                    >
                                        <MessengerItem
                                            classesContent={style.itemContent}
                                            avatar={(
                                                <MessengerChannelAvatar
                                                    userId={user.id}
                                                    title={data.channel.title}
                                                    image={data.channel.image}
                                                />
                                            )}
                                            content={data.channel.title}
                                        />
                                    </MessengerItemCheckBox>
                                ) : (
                                    <MessengerItemCheckBox
                                        name={data.id}
                                        checked={!!forwardMessagesData[user.id === data.starterUserId
                                            ? data.followerUserId
                                            : data.starterUserId]}
                                        disabled={isCanDisabled && !forwardMessagesData[user.id === data.starterUserId
                                            ? data.followerUserId
                                            : data.starterUserId]}
                                        onChange={(name, value) => handlerChangeChat(data, value)}
                                    >
                                        <MessengerItemUser
                                            data={user.id === data.starterUserId
                                                ? data.followerUser
                                                : data.starterUser}
                                        />
                                    </MessengerItemCheckBox>
                                )}
                            </React.Fragment>
                        )}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="size40"
                    disabled={!Object.keys(forwardMessagesData).length}
                    onClick={handlerSendForward}
                >
                    {t('send')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MessengerModalForward
