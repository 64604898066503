import React from 'react'
import { Collapse } from 'react-collapse'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { Link } from 'components'
import style from './MenuNavDropDownChild.module.css'

type MenuNavDropDownChildPropType = {
    item: IMenuNavItemData
    isOpen: boolean
    onChangeSubMenu: (itemId: number) => void
    onClosePopover: () => void
}

const MenuNavDropDownChild: React.FC<MenuNavDropDownChildPropType> = ({
    item,
    isOpen,
    onChangeSubMenu,
    onClosePopover,
}) => {
    const { t } = useTranslation()

    const handleClickSubMenu = () => {
        onChangeSubMenu(item.id)
    }

    return (
        <>
            {item.translateKey && (
                <>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div className={style.linkChild} onClick={handleClickSubMenu}>
                        <div className={style.linkChildInner}>
                            <div>
                                {t(item.translateKey)}
                            </div>
                            <div className={cn(style.iconDropDown, { [style.iconUp]: isOpen })} />
                        </div>
                        <Collapse isOpened={isOpen} className={style.collapse}>
                            {Object.values(item.children!).map((child) => (
                                <React.Fragment key={child.id}>
                                    {child.disabled ? (
                                        <div className={style.link}>
                                            <div className={cn(
                                                style.childInner,
                                                { [style.link_disabled]: child.disabled },
                                            )}
                                            >
                                                {t(child.translateKey!)}
                                            </div>
                                        </div>
                                    ) : (
                                        <Link
                                            className={style.link}
                                            url={child.url}
                                            onClick={onClosePopover}
                                        >
                                            <div className={style.childInner}>
                                                {t(child.translateKey!)}
                                            </div>
                                        </Link>
                                    )}
                                </React.Fragment>
                            ))}
                        </Collapse>
                    </div>
                </>
            )}
        </>
    )
}

export default MenuNavDropDownChild
