import React, { useEffect, useRef, useState } from 'react'
import { FormApi } from 'final-form'
import { Form } from 'react-final-form'

import {
    IHistoryMessage,
    IMessengerWsChatMessageProps,
    IMessengerWsChannelMessageProps,
    IMessengerWsStatusMessageProps,
    IMessengerWsForwardMessageProps,
    IMessengerWsReplyMessageProps,
    IMessengerWsEventMessageStatus,
    IMessengerWsEventMessageNew,
    IMessengerWsEventMessageError,
} from 'interfaces'
import { MessengerMessageType } from 'enums'
import { FieldName, FormDataType, formDataInitial } from 'forms/MessageForm/MessageForm'
import { TFile } from 'forms/fields/FieldFile/FieldFile'
import { API_URL, WSS_CHAT_BASE_URL, WSS_CHAT_TOKEN } from 'config/api'
import { useMessenger } from 'containers/Messenger/hooks'
import { useWebSocket, useOnline } from 'hooks'
import { MessageForm } from 'forms'

type TMessageProps = IMessengerWsStatusMessageProps
    | IMessengerWsChatMessageProps
    | IMessengerWsChannelMessageProps
    | IMessengerWsForwardMessageProps
    | IMessengerWsReplyMessageProps

type TEventMessage = IMessengerWsEventMessageStatus | IMessengerWsEventMessageNew | IMessengerWsEventMessageError

type MessageActionPropType = {
    classes?: string
    isBan?: boolean
    isFocus?: boolean
    isEnabled?: boolean
    // TODO to messages?: TMessageProps[]
    replyMessage?: IHistoryMessage // FIXME
    retryMessages?: (IMessengerWsChatMessageProps | IMessengerWsChannelMessageProps)[]
    statusMessages?: IMessengerWsStatusMessageProps[]
    forwardMessages?: IMessengerWsForwardMessageProps[]
    //
    messages?: TMessageProps[]
    onMessage?: (data: TEventMessage) => void
    onError?: (data: TMessageProps) => void
    onChatBan?: () => void
} & ({ toUserId: number } | { toChannelId: string })

const formDecorators = [(form: FormApi<FormDataType, FormDataType>) => {
    return form.subscribe(({ values }) => {
        if (values[FieldName.sticker] || values[FieldName.file]) {
            form.submit()
        }
    }, { values: true })
}]

const MessageAction: React.FC<MessageActionPropType> = ({
    children,
    classes,
    isBan,
    isFocus,
    isEnabled = true,
    replyMessage,
    retryMessages,
    statusMessages,
    forwardMessages,
    messages,
    onMessage = () => {},
    onError = () => {},
    onChatBan = () => {},
    ...props
}) => {
    const isMounted = useRef(false)
    const [isOnline] = useOnline()

    const [initialValues] = useState<FormDataType>(formDataInitial)

    const {
        send: wsSend,
        readyState: wsReadyState,
    } = useWebSocket<TEventMessage>(`${WSS_CHAT_BASE_URL}/${API_URL.wssChat}`, {
        enabled: isEnabled,
        accessToken: WSS_CHAT_TOKEN,
        onMessage: handlerMessage,
        onError: () => {},
    })

    const { getMessageProps, getReplyMessageProps, getMessageError } = useMessenger()

    const handlerSubmit = async (
        { text, sticker, file }: FormDataType,
        { reset }: FormApi<FormDataType, FormDataType>,
    ) => {
        if (isBan) {
            onChatBan()
        } else {
            const resetValues = () => setTimeout(() => reset({
                ...initialValues,
                [FieldName.text]: (sticker || file) ? text : '',
                [FieldName.sticker]: '',
                [FieldName.file]: '',
            }))
            sendMessage(sticker || file || text, resetValues)
        }
    }

    function handlerMessage(e: TEventMessage) {
        onMessage(e)
    }

    function sendMessage(text: string, actionCallback?: () => void) {
        if (replyMessage) {
            sendMessageAction(getReplyMessageProps({ toMessageId: replyMessage.id, text }), actionCallback)
        } else if ('toUserId' in props) {
            sendMessageAction(getMessageProps({ toUserId: props.toUserId, text }), actionCallback)
        } else if ('toChannelId' in props) {
            sendMessageAction(getMessageProps({ toChannelId: props.toChannelId, text }), actionCallback)
        }
    }

    function sendMessageAction(params: TMessageProps, callback?: () => void) {
        const { messageType, payload } = params

        if (isOnline && wsReadyState() === WebSocket.OPEN) {
            wsSend(JSON.stringify(params))

            if (callback) {
                callback()
            }
        } else if (messageType !== MessengerMessageType.status && messageType !== MessengerMessageType.forward
            && 'randomId' in payload
            && 'text' in payload
            && ('toUserId' in payload || 'toChannelId' in payload)
        ) {
            /** return not sent message like error message */
            // TODO ? reply params
            const { randomId, text = '', ...conversationParams } = payload
            handlerMessage(getMessageError({ randomId, text, ...conversationParams }))

            if (callback) {
                callback()
            }
        } else if (messageType !== MessengerMessageType.status) {
            onError(params)
        }
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (retryMessages) {
            retryMessages.forEach((item) => sendMessageAction(item))
        }
    }, [retryMessages])

    useEffect(() => {
        if (statusMessages) {
            statusMessages.forEach((item) => sendMessageAction(item))
        }
    }, [statusMessages])

    useEffect(() => {
        if (forwardMessages) {
            forwardMessages.forEach((item) => sendMessageAction(item))
        }
    }, [forwardMessages])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            decorators={formDecorators}
            mutators={{
                [FieldName.text]: ([key, value]: [FieldName.text, string], state, { changeValue }) => {
                    changeValue(state, key, () => value)
                },
                [FieldName.sticker]: ([key, value]: [FieldName.sticker, string], state, { changeValue }) => {
                    changeValue(state, key, () => value)
                },
                [FieldName.file]: ([key, value]: [FieldName.file, TFile[]], state, { changeValue }) => {
                    const [file] = value || []
                    let path = ''

                    if (file.fileData) {
                        path = file.fileData.path
                    }
                    if (file.image) {
                        path += `?sswidth=${file.image.width}&ssheight=${file.image.height}`
                    }
                    if (path) {
                        changeValue(state, key, () => path)
                    }
                },
            }}
            validate={(values) => {
                const errors = {}

                if (!!values[FieldName.text] && !values[FieldName.text].trim()) {
                    errors[FieldName.text] = true
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <MessageForm
                    classes={classes}
                    isFocus={isFocus}
                    isSubmitting={submitting}
                    isDisabled={!isEnabled}
                    onSubmit={handleSubmit}
                >
                    {children}
                </MessageForm>
            )}
        />
    )
}

export default MessageAction
