import React, { useMemo, useState } from 'react'
import { useLocation, useHistory, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FeedTabsId } from 'enums'
import { APP_URL, PRESIDENT_USER_ID } from 'config/app'
import { ContentContainer } from 'layout'
import { FeedNav } from 'components'
import { PostForm } from 'containers'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { useInvalidatePostsCommunity, useInvalidatePostsUser } from 'containers/Community/hooks'
import { UserService } from 'services'
import { getURLSearchParams } from 'utils/helpers'
import { FeedPostsCommunity, FeedPostsUser } from './components'
import style from './Feed.module.css'

const POSTS_LIMIT = 10
const POSTS_TILE_LIMIT = 18

const Feed: React.FC = () => {
    const { t } = useTranslation()
    const { search, pathname } = useLocation()
    const history = useHistory()

    const { tabId } = getURLSearchParams(search)
    const isLentaUser = matchPath(pathname, { path: APP_URL.lentaUser })

    const { feedPostTile: isPostTile } = useSelector(appSelectors.settings)
    const user = useSelector(userSelectors.user)

    const [isSetCompanyAccountId5] = useState(!!UserService.getCompanyAccountId5(user))

    const { invalidate: invalidatePostsCommunity } = useInvalidatePostsCommunity({}, { exact: false })
    const { invalidate: invalidatePostsUser } = useInvalidatePostsUser({ userId: user.id }, { exact: false })

    const tabs = useMemo(() => {
        if (isLentaUser) {
            return [
                { id: FeedTabsId.userPosts, name: t('user_profile_posts') },
                { id: FeedTabsId.friends, name: t('user_profile_friends') },
            ]
        }
        if (isSetCompanyAccountId5) {
            return [
                { id: FeedTabsId.allPosts, name: t('feed_all_articles') },
                { id: FeedTabsId.companyPosts, name: t('company_post') },
                { id: FeedTabsId.presidentPosts, name: t('president_posts') },
            ]
        }

        return [{ id: FeedTabsId.allPosts, name: t('feed_all_articles') }]
    }, [pathname])

    const activeTabId: number = useMemo(() => {
        if (tabId === undefined) {
            if (isLentaUser) {
                return FeedTabsId.userPosts
            }
            return FeedTabsId.allPosts
        }
        return Number(tabId)
    }, [tabId, pathname])

    const handlerChangeTab = (id: number) => {
        if (id !== activeTabId) {
            switch (id) {
                case FeedTabsId.userPosts:
                    history.push(APP_URL.lentaUser)
                    break
                case FeedTabsId.friends:
                    history.push(APP_URL.friends)
                    break
                default:
                    history.replace(`${APP_URL.lenta}?tabId=${id}`)
            }
        }
    }

    const handlerCompletePost = () => {
        if (activeTabId === FeedTabsId.allPosts || activeTabId === FeedTabsId.companyPosts) {
            invalidatePostsCommunity()
        }
        if (activeTabId === FeedTabsId.userPosts
            || activeTabId === FeedTabsId.friends
            || activeTabId === FeedTabsId.presidentPosts
        ) {
            invalidatePostsUser()
        }
    }

    return (
        <ContentContainer classes={style.content} size="half">
            <PostForm
                onComplete={handlerCompletePost}
            />
            <FeedNav
                classesItem={style.tabsItem}
                active={activeTabId}
                items={tabs}
                onChange={handlerChangeTab}
            />
            <div className={style.feed}>
                {activeTabId === FeedTabsId.allPosts && (
                    <FeedPostsCommunity
                        isPostTile={!!isPostTile}
                        queryParams={{ limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.userPosts && (
                    <FeedPostsUser
                        isPostTile={!!isPostTile}
                        queryParams={{ userId: user.id, limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.companyPosts && (
                    <FeedPostsCommunity
                        isPostTile={!!isPostTile}
                        queryParams={{ group: '22,61,63,230,55,56,11', limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.presidentPosts && (
                    <FeedPostsUser
                        isPostTile={!!isPostTile}
                        queryParams={{ userId: PRESIDENT_USER_ID, limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
            </div>
        </ContentContainer>
    )
}

export default Feed
