import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { MenuList } from 'components'
import { useOutsideClick } from 'hooks'
import style from './MessengerMenuMessage.module.css'

type MessengerMenuMessagePropType = {
    isOpen: boolean
    x: number
    y: number
    onReply: () => void
    onForward: () => void
    onClose: () => void
    onDelete?: () => void
}

const MessengerMenuMessage: React.FC<MessengerMenuMessagePropType> = ({
    isOpen,
    x,
    y,
    onReply,
    onForward,
    onClose,
    onDelete,
}) => {
    const { t } = useTranslation()
    const ref = useRef<HTMLDivElement>(null)

    // TODO
    // x + sizeMenu > window.innerWidth ? x = window.innerWidth - sizeMenu : x

    const handlerAsk = () => {
        onReply()
        onClose()
    }

    const handlerForward = () => {
        onForward()
        onClose()
    }

    const handlerDelete = () => {
        if (onDelete) {
            onDelete()
            onClose()
        }
    }

    useOutsideClick(ref, () => {
        onClose()
    })

    return createPortal(
        isOpen && (
            <div style={{ left: x, top: y }} className={style.menu} ref={ref}>
                <MenuList type="large">
                    <MenuList.Item
                        onClick={handlerAsk}
                    >
                        {t('ask')}
                    </MenuList.Item>
                    <MenuList.Item
                        onClick={handlerForward}
                    >
                        {t('chat_message_menu_forward')}
                    </MenuList.Item>
                    {onDelete && (
                        <MenuList.Item
                            classes={cn(style.item, style.item_danger)}
                            onClick={handlerDelete}
                        >
                            {t('chat_message_menu_delete')}
                        </MenuList.Item>
                    )}
                </MenuList>
            </div>
        ),
        document.body,
    )
}

export default MessengerMenuMessage
