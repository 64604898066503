import { AxiosError } from 'axios'
import {
    InvalidateOptions,
    InvalidateQueryFilters,
    UseQueryOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TSupportServiceError,
    TTicketsProps,
    TTicketsResponse,
} from 'services/SupportService'
import { QueryCacheKeys } from 'enums'
import { SupportService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TTicketsProps
export type TFetchFnError = AxiosError<TSupportServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TTicketsResponse, TError, TTicketsResponse, TQueryKey>

const key = QueryCacheKeys.tickets

export default function useFetchTickets(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    const queryKey = user ? `${key}-${user.id}` : key

    return useQuery([queryKey, params], () => {
        return SupportService.fetchTickets(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useInvalidateTickets(
    params: TFetchFnParams, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const queryKey = user ? `${key}-${user.id}` : key

    const invalidate = () => {
        return queryClient.invalidateQueries([queryKey, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}
