import { Chart, ChartTypeRegistry, Plugin } from 'chart.js'

export type DoughnutTextCenterOptions = {
    enabled: boolean
    text: string
    font?: string
    fontColor?: string
}

const defaults = {
    font: 'bolder 20px sans-serif',
    fontColor: '#000',
}

const doughnutTextCenter: Plugin = {
    id: 'doughnutTextCenter',
    beforeDraw(chart: Chart<keyof ChartTypeRegistry>, args: { cancelable: true }, options: DoughnutTextCenterOptions) {
        const { ctx, chartArea } = chart
        const {
            top,
            //     right,
            //     bottom,
            //     left,
            width,
            height,
        } = chartArea

        if (options.enabled) {
            ctx.save()
            ctx.font = options.font ?? defaults.font
            ctx.fillStyle = options.fontColor ?? defaults.fontColor
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillText(options.text ?? '', width / 2, top + (height / 2))
        }
    },
}

export default doughnutTextCenter
