import React from 'react'

import { TransitionGroup, CSSTransition } from './components'
import { TransitionGroupPropType } from './components/TransitionGroup/TransitionGroup'
import { CSSTransitionPropType } from './components/CSSTransition/CSSTransition'

type TransitionComposition = {
    Group: React.FC<TransitionGroupPropType>
    CSSTransition: React.FC<CSSTransitionPropType>
}

/**
 * @see https://reactcommunity.org/react-transition-group/
 */
const Transition: React.FC <null> & TransitionComposition = () => {
    return null
}

Transition.Group = TransitionGroup
Transition.CSSTransition = CSSTransition

export default Transition
