import React from 'react'

import { Arrow } from 'components'
import style from './MessengerStatusArrow.module.css'

const MessengerStatusArrow: React.FC = () => {
    return (
        <Arrow
            classes={style.arrow}
        />
    )
}

export default MessengerStatusArrow
