import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IRootState } from 'interfaces'
import { SuggestItemPropType } from 'components/Suggest/Suggest'
import { USER_TAG_ID_MLM_MENU } from 'config/app'
import { Suggest } from 'components'
import { UserService } from 'services'

import style from 'containers/StoreOrderMake/StoreOrderMake.module.css'
import styleInput from 'components/Input/Input.module.css'
import styleForm from 'styles/modules/form.module.css'

export type StoreOrderMakeFormBuyerPropType = {
    error?: string | boolean
    onSelect: (id: number | undefined) => void
}

const StoreOrderMakeBuyer: React.FC<StoreOrderMakeFormBuyerPropType> = ({
    error,
    onSelect,
}) => {
    const { t } = useTranslation()

    const tags = useSelector((state: IRootState) => state.tags)

    const [isShow, setIsShow] = useState(false)
    const [buyersSuggestions, setBuyersSuggestions] = useState<SuggestItemPropType[]>([])

    const handlerSearch = (query: string) => {
        if (query) {
            UserService.filterAccountsForSales({ limit: 1, offset: 20, q: query })
                .then(({ data }) => {
                    if (Array.isArray(data)) {
                        setBuyersSuggestions(data.map((item) => ({
                            id: item.id,
                            value: `${item.fio} (${item.member_id})`,
                        })))
                    }
                })
                .catch(() => {})
        } else {
            onSelect(undefined)
        }
    }

    const handlerSelect = (buyer: SuggestItemPropType) => {
        setBuyersSuggestions([]) // reset suggest list hints
        onSelect(buyer.id)
    }

    useEffect(() => {
        if (tags?.find((item) => item?.colored_tag.id === USER_TAG_ID_MLM_MENU)) {
            setIsShow(true)
        }
    }, [tags])

    return (
        <>
            {isShow && (
                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <div className={cn(styleForm.fieldHead, style.fieldLabel)}>
                        {t('market_buyer_account_title')}
                    </div>
                    <div className={style.field}>
                        <Suggest
                            classesField={cn(
                                styleInput.gray,
                                error ? styleForm.invalid : '',
                            )}
                            items={buyersSuggestions}
                            placeholder={t('dialog_screen_store_bonus_queryable_request_hint')}
                            onSearch={handlerSearch}
                            onSelect={handlerSelect}
                        />
                        <div className={styleForm.error}>
                            {error}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default StoreOrderMakeBuyer
