import React from 'react'

import { Button, SvgResource } from 'components'
import style from './ModalTopBarMedia.module.css'

export type ModalTopBarMediaPropType = {
    avatar?: JSX.Element
    name?: string
    date?: string
    onShare?: () => void
    onDownload?: () => void
    onClose: () => void
}

const ModalTopBarMedia: React.FC<ModalTopBarMediaPropType> = ({
    avatar,
    name,
    date,
    onShare,
    onDownload,
    onClose,
}) => {
    return (
        <div className={style.mediaBar}>
            {avatar}
            {(name || date) && (
                <div className={style.info}>
                    {name && (
                        <div className={style.name}>
                            {name}
                            {/* {`${user.name ?? ''} ${user.surname ?? ''}`} */}
                        </div>
                    )}
                    {date && (
                        <div className={style.date}>
                            {date}
                            {/* {`${date}, ${time}`} */}
                        </div>
                    )}
                </div>
            )}
            <div className={style.actions}>
                {onShare && (
                    <Button
                        classes={style.action}
                        styleType="transparent"
                        onClick={onShare}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconShare}
                            resourceKey="ic_post_share_svg"
                            width={20}
                            height={20}
                        />
                    </Button>
                )}
                {onDownload && (
                    <Button
                        classes={style.action}
                        styleType="transparent"
                        onClick={onDownload}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconDownload}
                            resourceKey="ic_download_svg"
                            width={20}
                            height={20}
                        />
                    </Button>
                )}
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={onClose}
                >
                    <span
                        className={style.iconClose}
                    />
                </Button>
            </div>
        </div>
    )
}

export default ModalTopBarMedia
