import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { CardSize } from 'enums'
import { CardV2 } from 'components'
import style from './CatalogShowcaseItemAdd.module.css'
import styleCatalog from '../../CatalogShowcase.module.css'

export type CatalogShowcaseItemAddPropType = {
    classes?: string
    size: keyof typeof CardSize
    title?: string
    onClick: () => void
}

/**
 * Empty catalog card for add new item
 */
const CatalogShowcaseItemAdd: React.FC<CatalogShowcaseItemAddPropType> = ({
    classes,
    size,
    title,
    onClick,
}) => {
    const { t } = useTranslation()

    return (
        <CardV2
            classes={classes}
            size={size}
            onClick={onClick}
        >
            <CardV2.Image
                classes={style.image}
                isPlaceholder={false}
            >
                <div className={style.add}>
                    <div className={style.iconAdd} />
                </div>
            </CardV2.Image>
            <CardV2.Body
                classes={cn(styleCatalog.body, style.body)}
                classesTitle={style.title}
                title={title || t('add')}
            />
        </CardV2>
    )
}

export default CatalogShowcaseItemAdd
