import React, { CSSProperties } from 'react'
import cn from 'classnames'

import { Block } from 'layout'
import { InfoBlockBodyPropType } from './components/InfoBlockBody/InfoBlockBody'
import { InfoBlockFooterPropType } from './components/InfoBlockFooter/InfoBlockFooter'
import { InfoBlockBody, InfoBlockFooter } from './components'
import style from './InfoBlock.module.css'

export type InfoBlockPropType = {
    classes?: string
    styles?: CSSProperties
}

type InfoBlockComposition = {
    Body: React.FC<InfoBlockBodyPropType>
    Footer: React.FC<InfoBlockFooterPropType>
}

const InfoBlock: React.FC<InfoBlockPropType> & InfoBlockComposition = ({
    children,
    classes,
    styles,
}) => {
    return (
        <Block classes={cn(style.block, classes)} styles={styles}>
            {children}
        </Block>
    )
}

InfoBlock.Body = InfoBlockBody
InfoBlock.Footer = InfoBlockFooter

export default InfoBlock
