import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Loader, ErrorMsg } from 'components'
import style from './Iframe.module.css'

type IframePropType = {
    title: string
    src: string
    onLoad?: () => void
    onError?: () => void
}

const Iframe: React.FC<IframePropType> = ({
    title,
    src,
    onLoad = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const handlerLoad = () => {
        setIsLoading(false)
        onLoad()
    }

    const handlerError = () => {
        setIsLoading(false)
        setIsError(true)
        onError()
    }

    return (
        <div className={style.wrap}>
            {isLoading && !isError && (
                <Loader
                    classes={style.loader}
                    size="large"
                    position="center"
                />
            )}

            {!isLoading && isError && (
                <div className={style.errorText}>
                    <ErrorMsg error={t('update_error')} />
                </div>
            )}

            {!isError && (
                <iframe
                    className={cn(style.iframe, { [style.iframe_loading]: isLoading })}
                    title={title}
                    src={src}
                    onLoad={handlerLoad}
                    onError={handlerError}
                />
            )}
        </div>
    )
}

export default Iframe
